import { useState } from 'react';
import PropTypes from 'prop-types';
import { Pressable, StyleSheet, Text, View } from 'react-native';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';
import OPACITIES from '../../opacities';

// icons
import icnLayers from '../../assets/icons/icnLayers.png';
import icnLayersOn from '../../assets/icons/icnLayersOn.png';
import RoundButton from '../../components/button/RoundButton';

// hoocks
import { useTranslation } from '../../hooks/useTranslation';

// presenters
import {
  MapStyleMode,
  MapDisplayMode,
  findMapStyleByPosition,
  findMapStyleTranslationKey,
} from '../../presenters/MapDisplayMode';

const MapDisplayModeChange = ({ mapStyle, changeMapStyle, mapMode, displayMapMode, toggleMapMode }) => {
  const { t } = useTranslation();
  const [layersSelectorDisplayed, setLayersSelectorDisplayed] = useState(false);

  const showLayersSelector = () => {
    setLayersSelectorDisplayed(!layersSelectorDisplayed);
  };

  const mapStyleTitle = (position) => {
    const buttonMapStyle = findMapStyleByPosition(position, mapStyle);
    return t(findMapStyleTranslationKey(buttonMapStyle));
  };

  const onChangeMapStyle = (position) => {
    changeMapStyle(findMapStyleByPosition(position, mapStyle));
    setLayersSelectorDisplayed(false);
  };

  const mapModeTitle = () => {
    return mapMode === MapDisplayMode.TENSION ? t('weather_temperature') : t('irrigation');
  };

  const onToggleMapMode = () => {
    toggleMapMode();
    setLayersSelectorDisplayed(false);
  };

  return (
    <View style={styles.container}>
      <RoundButton
        icon={layersSelectorDisplayed ? icnLayersOn : icnLayers}
        onPress={showLayersSelector}
        testID="map__layers-selector-button"
      />

      {displayMapMode && layersSelectorDisplayed && mapStyle !== MapStyleMode.NDVI ? (
        <Pressable
          activeOpacity={OPACITIES.pressedButton}
          onPress={onToggleMapMode}
          style={/* istanbul ignore next */ ({ pressed }) => [pressed ? styles.pressedButton : {}, styles.mapButton]}
          testID="map__map-mode-button">
          <View style={styles.mapModeButtonTitle}>
            <Text style={styles.textButton}>{mapModeTitle()}</Text>
          </View>
        </Pressable>
      ) : null}

      {layersSelectorDisplayed ? (
        <Pressable
          activeOpacity={OPACITIES.pressedButton}
          onPress={() => onChangeMapStyle(0)}
          style={/* istanbul ignore next */ ({ pressed }) => [pressed ? styles.pressedButton : {}, styles.mapButton]}
          testID="map__map-style-mode-button-0">
          <View style={styles.mapStyleButtonTitle}>
            <Text style={styles.textButton}>{mapStyleTitle(0)}</Text>
          </View>
        </Pressable>
      ) : null}

      {layersSelectorDisplayed ? (
        <Pressable
          activeOpacity={OPACITIES.pressedButton}
          onPress={() => onChangeMapStyle(1)}
          style={/* istanbul ignore next */ ({ pressed }) => [pressed ? styles.pressedButton : {}, styles.mapButton]}
          testID="map__map-style-mode-button-1">
          <View style={styles.mapStyleButtonTitle}>
            <Text style={styles.textButton}>{mapStyleTitle(1)}</Text>
          </View>
        </Pressable>
      ) : null}
      <View style={styles.spacer} />
    </View>
  );
};

MapDisplayModeChange.propTypes = {
  displayMapMode: PropTypes.bool.isRequired,
  mapMode: PropTypes.string.isRequired,
  changeMapStyle: PropTypes.func.isRequired,
  toggleMapMode: PropTypes.func.isRequired,
  mapStyle: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 5,
    right: 5,
    alignItems: 'flex-end',
  },
  mapButton: {
    padding: 10,
  },
  mapStyleButtonTitle: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 115,
    borderRadius: 2,
    backgroundColor: COLORS.white,
  },
  mapModeButtonTitle: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 115,
    borderRadius: 40,
    backgroundColor: COLORS.white,
  },
  spacer: {
    flex: 1,
  },
  pressedButton: {
    opacity: OPACITIES.pressedButton,
  },
  textButton: {
    paddingHorizontal: 25,
    paddingVertical: 12.5,
    fontFamily: FONTS.firaSans,
    fontSize: 14,
    color: COLORS.greyishBrown,
  },
});

export default MapDisplayModeChange;
