import { gql } from '@apollo/client';

export const WEATHER_STATION_BLOCKS = gql`
  query weatherStation($siteId: ID!, $dataStreamTypes: [dataStreamType]) {
    viewer {
      site(id: $siteId) {
        id
        blocks(filters: { capabilities: [WEATHER_STATION] }) {
          id
          name
          alias
          lastUpdated

          capabilities {
            weatherStation {
              airTemperature {
                unit
                value
              }
              windSpeed {
                unit
                value
              }
              windDirection {
                unit
                value
              }
            }
          }
          sensors {
            id
            dataStreams(dataStreamTypes: $dataStreamTypes) {
              id
              dataStreamType
              value
              unit
              isVirtual
              description
            }
          }
        }
      }
    }
  }
`;
