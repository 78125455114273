import { Dimensions, Text, View, Pressable, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

// constants
import FONTS from '../../fonts';
import COLORS from '../../colors';

// services
import { BlueBandIndex } from '../../models/entities/blueBandIndex';
import BlueBandIndexStatuses from '../../models/entities/blueBandIndexStatuses';
import { useTranslation } from '../../hooks/useTranslation';

// components
import SkeletonPlaceholder from '../skeletons/SkeletonPlaceholder';
import ChartComponent from '../graph/ChartComponent';

const SCREEN_WIDTH = Dimensions.get('screen').width;
const SMALL_WIDTH = 400;
const isSmallWidth = SCREEN_WIDTH < SMALL_WIDTH;

const BlueBandIndexPie = ({ blueBandIndexData, loading, onPress, testId }) => {
  const { t } = useTranslation();
  let data = [];
  let color = [];

  const blueBandIndexes = new BlueBandIndexStatuses(blueBandIndexData);
  const blueBandIndexStatuses = Object.values(blueBandIndexes.statuses(t));
  const hasData = blueBandIndexStatuses && blueBandIndexStatuses.length > 0;

  if (hasData) {
    for (let blueBandIndexStatus of blueBandIndexStatuses) {
      if (blueBandIndexStatus.value > 0) {
        const { name, value, color: statusColor } = blueBandIndexStatus;
        data.push({ name, value });
        color.push(statusColor);
      }
    }
  } else {
    const defaultStatus = new BlueBandIndex(t('blue_band_index_na'), COLORS.desertStorm, 100);
    const { name, value, color: defaultColor } = defaultStatus;
    data.push({ name, value });
    color.push(defaultColor);
  }

  const pieOption = {
    series: [
      {
        type: 'pie',
        radius: ['40%', '80%'],
        animation: false,
        emphasis: {
          disabled: true,
        },
        avoidLabelOverlap: true,
        itemStyle: {
          borderRadius: 4,
          borderColor: COLORS.desertStorm,
          borderWidth: 3,
        },
        label: {
          show: true,
          overflow: 'break',
          bleedMargin: 4,
          color: COLORS.greyishBrown,
          fontWeight: 'bold',
          fontFamily: FONTS.notoSansBold,
          fontSize: data.length > 1 ? 12 : 16,
          position: data.length > 1 ? 'outside' : 'center',
        },
        labelLine: {
          show: true,
          length: /* istanbul ignore next */ isSmallWidth ? 5 : 12,
          length2: /* istanbul ignore next */ isSmallWidth ? 5 : 12,
        },
        data,
        color,
      },
    ],
  };

  return (
    <View style={styles.container} testID={testId}>
      {loading ? (
        <View style={styles.skeletonContainer} testID="loadingBlueBandIndex">
          <SkeletonPlaceholder width={CIRCLE_SIZE} height={CIRCLE_SIZE} style={styles.skeletonCircle} />
        </View>
      ) : (
        <Pressable onPress={onPress}>
          <ChartComponent height={250} width={SCREEN_WIDTH} option={pieOption} />
        </Pressable>
      )}
      <Text style={styles.titleText}>{t('blue_band_index_description')}</Text>
    </View>
  );
};

BlueBandIndexPie.propTypes = {
  blueBandIndexData: PropTypes.object,
  loading: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

const CIRCLE_SIZE = 200;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  skeletonCircle: {
    borderRadius: CIRCLE_SIZE / 2,
  },
  skeletonContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 25,
  },
  titleText: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '60%',
    paddingTop: 12,
    paddingBottom: 20,
    textAlign: 'center',
    fontFamily: FONTS.firaSans,
    fontSize: 13,
    color: COLORS.greyishBrown,
  },
});

export default BlueBandIndexPie;
