import { gql } from '@apollo/client';

export const REPORT_ALPHA = gql`
  query WaterReport($siteId: ID!, $filter: MainSwitchStatsFilter!) {
    viewer {
      site(id: $siteId) {
        report {
          volumeUnit
        }

        mainSwitchStats(filter: $filter) {
          name
          alias
          mainSwitches {
            name
            type
            appRate
            calendar {
              x
              y
            }
          }
        }
      }
    }
  }
`;
