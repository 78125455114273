import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

// constants
import COLORS from '../colors';

// shape
import { styleShape } from '../shapes/style';

const Divider = ({ style, testID }) => <View style={StyleSheet.flatten([styles.divider, style])} testID={testID} />;

Divider.propTypes = {
  style: styleShape,
  testID: PropTypes.string,
};

const styles = StyleSheet.create({
  divider: {
    borderBottomColor: COLORS.divider,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
});

export default Divider;
