/* istanbul ignore file */
import { useEffect } from 'react';
import moment from 'moment-timezone';
import 'moment/min/locales';
import { useDispatch } from 'react-redux';
import { uses24HourClock } from 'react-native-localize';

import { useTranslation } from '../../hooks/useTranslation';
import { pastDatesString, secondsFromNowString } from '../../utils/dateUtils';

const Bootstrapper = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    function initialize() {
      dispatch.preferences.readAuthenticationTokenFromStorage();
      dispatch.preferences.readSelectedSiteFromStorage();
      dispatch.preferences.readMapPreferenceFromStorage();
      dispatch.preferences.readReportPreferenceFromStorage();
    }

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function updateLocaleMoment(locale) {
      const is24Hour = uses24HourClock();

      return moment.updateLocale(locale, {
        relativeTime: {
          ...moment.localeData(locale)._relativeTime,
          s: secondsFromNowString(t),
          past: pastDatesString(t),
        },
        longDateFormat: {
          LT: is24Hour ? 'HH:mm' : 'hh:mm a',
          LTH: is24Hour ? 'HH:00' : 'h a',
          LTS: is24Hour ? 'HH:mm:ss' : 'hh:mm:ss a',
        },
        calendar: {
          nextWeek: 'dddd',
        },
      });
    }

    moment.locale(i18n.language);
    moment.relativeTimeThreshold('s', 60);
    moment.relativeTimeThreshold('m', 60);
    moment.relativeTimeThreshold('h', 24);
    moment.relativeTimeThreshold('d', 30);
    moment.relativeTimeThreshold('M', 12);
    moment.relativeTimeRounding(Math.floor);

    updateLocaleMoment(i18n.language);
  }, [t, i18n]);

  return <></>;
};

export default Bootstrapper;
