import { BLE_ON, getBluetoothService, validatePermissions } from '../services/BluetoothService';
import { consoleDebug } from '../utils/loggerUtil';
import BluetoothDeviceEntity from './entities/bluetoothDevice';

export const console = {
  state: {
    devices: [],
    selectedDevice: undefined,
    loading: false,
    discovering: false,
    error: false,
    bleState: undefined,
  },

  reducers: {
    updateLoading(state, value) {
      return { ...state, loading: value };
    },

    updateDiscovering(state, value) {
      return { ...state, discovering: value };
    },

    updateError(state, value) {
      return { ...state, error: value, discovering: false };
    },

    updateDevices(state, value) {
      return { ...state, devices: value };
    },

    updateSelectedDevice(state, value) {
      return { ...state, selectedDevice: value };
    },

    updateBleState(state, value) {
      return { ...state, bleState: value };
    },
  },

  effects: (dispatch) => ({
    async load(payload, rootstate) {
      try {
        await getBluetoothService().start();
      } catch {
        dispatch.console.updateLoading(false);
        dispatch.console.updateError(true);
      }
    },

    async scan(payload, rootState) {
      try {
        const bleState = await getBluetoothService().checkState();
        dispatch.console.updateBleState(bleState);

        if (bleState === BLE_ON && !rootState.console.discovering) {
          dispatch.console.updateDiscovering(true);
          const data = await getBluetoothService().scan(payload);
          const entities = data.map((device) => new BluetoothDeviceEntity(device, dispatch));
          dispatch.console.updateDevices(entities);
          dispatch.console.updateDiscovering(false);
        }
      } catch {
        dispatch.console.updateDiscovering(false);
        dispatch.console.updateError(true);
      }
    },

    async setLoading(payload, rootState) {
      dispatch.console.updateLoading(payload);
    },

    async setSelectedDevice(payload, rootState) {
      dispatch.console.updateSelectedDevice(payload);
    },

    async setDevices(payload, rootState) {
      dispatch.console.updateDevices(payload);
    },

    async unload(payload, rootState) {
      getBluetoothService()?.stop();
    },

    async connect(payload, rootState) {
      try {
        await payload.connect();
      } catch (e) {
        consoleDebug(e);
      }
    },

    async disconnect(payload, rootState) {
      try {
        await payload.disconnect();
      } catch (e) {
        consoleDebug(e);
      }
    },

    async sendCommand({ device, command }, rootState) {
      try {
        await device.sendCommand(command);
      } catch (e) {
        consoleDebug(e);
      }
    },

    async enableBluetooth(refresh) {
      try {
        await validatePermissions();
        await getBluetoothService().enableBluetooth();
        refresh();
      } catch (err) {
        consoleDebug(err);
      }
    },

    async setShowData({ device, shouldShow }, rootState) {
      await device.setShowData(shouldShow);
    },
  }),
};
