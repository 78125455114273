import { useRef } from 'react';
import { StyleSheet, Text, TextInput } from 'react-native';

// hooks
import PropTypes from 'prop-types';
import { useTranslation } from '../../../hooks/useTranslation';

// services
import { isIos } from '../../../services/PlatformService';

// constants
import COLORS from '../../../colors';
import FONTS from '../../../fonts';

// components
import Divider from '../../Divider';
import GenericSection from '../../layouts/GenericSection';
import RowSection from '../../layouts/RowSection';
import { translationForRuleThresholds } from '../../../models/automationRuleType';

const AutomationRuleEditThreshold = ({ onChangeStartValue, onChangeStopValue, ruleType, unit, startValue, stopValue }) => {
  const { t } = useTranslation();
  const startInputRef = useRef();
  const stopInputRef = useRef();

  const thresholdTranslationKeys = translationForRuleThresholds(ruleType);
  const startTranslation = thresholdTranslationKeys ? t(thresholdTranslationKeys?.start) : '';
  const stopTranslation = thresholdTranslationKeys ? t(thresholdTranslationKeys?.stop) : '';
  const stringifiedStartValue = isNaN(startValue) ? '' : startValue.toString();
  const stringifiedStopValue = isNaN(stopValue) ? '' : stopValue.toString();

  // istanbul ignore next
  const setFocusStartInput = () => {
    if (startInputRef.current) {
      startInputRef.current.focus();
    }
  };

  // istanbul ignore next
  const setFocusStopInput = () => {
    if (stopInputRef.current) {
      stopInputRef.current.focus();
    }
  };

  const keyboardType = isIos() ? 'numbers-and-punctuation' : 'numeric';

  return (
    <GenericSection title={t('automation_rule_threshold')}>
      <RowSection onPress={setFocusStartInput} title={startTranslation}>
        <TextInput
          ref={startInputRef}
          style={[styles.text, styles.textInput]}
          keyboardType={keyboardType}
          textAlign={'right'}
          multiline={false}
          defaultValue={stringifiedStartValue}
          onChangeText={onChangeStartValue}
          selectTextOnFocus
          maxLength={10}
          testID={'automation-rule-edit__threshold-start-input'}
        />
        <Text style={styles.text}>{unit}</Text>
      </RowSection>

      <Divider style={styles.divider} />

      <RowSection onPress={setFocusStopInput} title={stopTranslation}>
        <TextInput
          ref={stopInputRef}
          style={[styles.text, styles.textInput]}
          keyboardType={keyboardType}
          textAlign={'right'}
          multiline={false}
          defaultValue={stringifiedStopValue}
          onChangeText={onChangeStopValue}
          selectTextOnFocus
          maxLength={10}
          testID={'automation-rule-edit__threshold-stop-input'}
        />
        <Text style={styles.text}>{unit}</Text>
      </RowSection>
    </GenericSection>
  );
};

AutomationRuleEditThreshold.propTypes = {
  onChangeStartValue: PropTypes.func.isRequired,
  onChangeStopValue: PropTypes.func.isRequired,
  ruleType: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  startValue: PropTypes.number.isRequired,
  stopValue: PropTypes.number.isRequired,
};

const styles = StyleSheet.create({
  divider: {
    marginLeft: 16,
  },
  text: {
    fontFamily: FONTS.firaSans,
    fontSize: 15,
    color: COLORS.greyishBrown,
  },
  textInput: {
    width: 75,
    height: 30,
    padding: 0,
  },
});

export default AutomationRuleEditThreshold;
