import { gql } from '@apollo/client';

export const AUTOMATION_RULES = gql`
  query AutomationRules($siteId: ID!) {
    viewer {
      site(id: $siteId) {
        automationRules {
          id
          name
          enabled
          properties {
            ... on AutomationBySensor {
              ruleType
              triggerValues {
                ... on AutomationTriggerOnHighValue {
                  startAbove
                  stopBelow
                  unit
                }
                ... on AutomationTriggerOnLowValue {
                  startBelow
                  stopAbove
                  unit
                  cycling {
                    enabled
                    cyclingDuration
                    cyclingRestartTrigger
                  }
                }
              }
            }
          }
          componentsToAutomate {
            summaryId
            summaryAlias
          }
        }
      }
    }
  }
`;
