import { StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

// utils
import { findEventColor } from '../../../models/calendarType';

// constants
import FONTS from '../../../fonts';
import COLORS from '../../../colors';

const DIVIDER_WIDTH_DAY_LIMIT = 99;
const DIVIDER_WIDTH_NORMAL = 20;
const DIVIDER_WIDTH_WIDE = 25;

const EventItemDividerAndNumberOfDays = ({ eventType, numberOfDays }) => {
  const dividerWidth = numberOfDays > DIVIDER_WIDTH_DAY_LIMIT ? DIVIDER_WIDTH_WIDE : DIVIDER_WIDTH_NORMAL;

  const dividerColor = findEventColor(eventType);

  return (
    <View style={styles.spanSection}>
      <Text style={[styles.verticalLine, { backgroundColor: dividerColor }]} />
      {numberOfDays > 0 ? (
        <View style={[styles.daySpan, { width: dividerWidth }]}>
          <Text style={styles.daySpanText}>+{numberOfDays}</Text>
        </View>
      ) : null}
    </View>
  );
};

EventItemDividerAndNumberOfDays.propTypes = {
  eventType: PropTypes.string,
  numberOfDays: PropTypes.number,
};

const styles = StyleSheet.create({
  daySpan: {
    position: 'absolute',
    top: '20%',
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    borderRadius: 50,
    backgroundColor: COLORS.white,
  },
  daySpanText: {
    fontFamily: FONTS.notoSansBold,
    fontSize: 10,
    letterSpacing: 0.47,
    color: COLORS.greyishBrown,
  },
  spanSection: {
    alignItems: 'center',
    width: 20,
  },
  verticalLine: {
    width: 3,
    height: '75%',
  },
});

export default EventItemDividerAndNumberOfDays;
