import PropTypes from 'prop-types';
import { StyleSheet, Text } from 'react-native';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

const ForecastDetailsData = ({ value = '--', boldFont = true }) => {
  return (
    <Text style={[styles.columnContent, boldFont && styles.boldFont]} adjustsFontSizeToFit={true} numberOfLines={1}>
      {value}
    </Text>
  );
};

ForecastDetailsData.propTypes = {
  value: PropTypes.string,
  boldFont: PropTypes.bool,
};

const styles = StyleSheet.create({
  columnContent: {
    paddingHorizontal: '5%',
    fontFamily: FONTS.notoSans,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
  boldFont: {
    fontWeight: 'bold',
  },
});

export default ForecastDetailsData;
