import { useEffect, useState } from 'react';
import { Image, StyleSheet, Text, Pressable, View } from 'react-native';
import PropTypes from 'prop-types';

// icons
import icnCheck from '../../../../assets/icons/icnCheck.png';

// constants
import COLORS from '../../../../colors';
import FONTS from '../../../../fonts';

// styles
import { globalStyles } from '../../../../styles';

const SelectBlockItem = ({ block, onSelect, selectedBlocks }) => {
  const [isBlockSelected, setIsBlockSelected] = useState(false);
  const selectButtonStyle = isBlockSelected ? styles.selectBlock : styles.unselectBlock;

  useEffect(() => {
    const index = selectedBlocks.findIndex((selectedBlock) => block.id === selectedBlock.id);
    setIsBlockSelected(index > -1);
  }, [block, selectedBlocks]);

  const selectBlock = () => onSelect(block);

  return (
    <Pressable
      onPress={selectBlock}
      style={/* istanbul ignore next */ ({ pressed }) => [pressed ? globalStyles.touchOpacity : {}, styles.container]}
      testID={`schedule__selected-block-${block.id}`}>
      <Text style={styles.blockName} ellipsizeMode={'tail'} numberOfLines={1}>
        {block.alias} - {block.name}
      </Text>
      <View style={[styles.blockSelection, selectButtonStyle]}>
        {isBlockSelected && <Image source={icnCheck} style={styles.selectIcon} testID="block__item--checked" />}
      </View>
    </Pressable>
  );
};

SelectBlockItem.propTypes = {
  block: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  selectedBlocks: PropTypes.array,
};

const styles = StyleSheet.create({
  blockName: {
    flex: 1,
    fontFamily: FONTS.firaSans,
    fontSize: 17,
    letterSpacing: 0.6,
    color: COLORS.greyishBrown,
  },
  blockSelection: {
    width: 24,
    height: 24,
    borderWidth: 1.5,
    borderRadius: 3,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: COLORS.white,
  },
  selectBlock: {
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: COLORS.seafoamBlue,
    backgroundColor: COLORS.lightSeafoamBlue,
  },
  selectIcon: {
    width: 10,
    height: 10,
  },
  unselectBlock: {
    borderColor: COLORS.lightPeach,
  },
});

export default SelectBlockItem;
