import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { StyleSheet, View } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { SafeAreaView } from 'react-native-safe-area-context';
import PropTypes from 'prop-types';

// services
import { navigationShape } from '../../shapes/navigation';
import { SORT_ORDER, toggleSort } from '../../utils/sorting';
import { useBackHandler } from '../../hooks/useBackHandler';
import { useTranslation } from '../../hooks/useTranslation';
import ANALYTICS from '../../services/AnalyticsEvents';

// styles
import { globalStyles } from '../../styles';

// constants
import COLORS from '../../colors';
import ROUTES from '../../navigation/routes';

// components
import SortableList from '../../components/SortableList';
import SkeletonPlaceholder from '../../components/skeletons/SkeletonPlaceholder';
import AutomationRuleItem from '../../components/automation/rule/AutomationRuleItem';
import HeaderBack from '../../components/header/HeaderBack';
import Divider from '../../components/Divider';
import NoDataView from '../../components/NoDataView';
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import SortableAutomationRuleHeader from './SortableAutomationRuleHeader';
import { SORT_COLUMNS } from './sorting';

const initialSortOrderState = {
  type: SORT_ORDER.NONE,
  name: SORT_ORDER.NONE,
  threshold: SORT_ORDER.NONE,
};

const AutomationRulesScreen = ({ navigation, route }) => {
  const store = useStore();
  const { t } = useTranslation();
  const analyticsService = useAnalyticsContext();
  const dispatch = useDispatch();
  const listRef = useRef(undefined);
  const [refreshing, setRefreshing] = useState(false);
  const currentSite = useSelector((state) => state.site.currentSite);
  const loading = useSelector((state) => !!state.loading.effects.automations.loadAutomationRules);
  const rules = useSelector((state) => state.automations.sortedAutomationRules);
  const { reloadData, routeToGoBackTo } = route.params || {};
  const canModifyAutomationRules = useSelector(store.select.user.hasModifyAutomationRulesPermission);

  const [sortedColumn, setSortedColumn] = useState(initialSortOrderState);
  const toggle = toggleSort(sortedColumn, setSortedColumn, initialSortOrderState);

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      setSortedColumn({ ...initialSortOrderState, [SORT_COLUMNS.NAME]: SORT_ORDER.ASC });

      if (reloadData) {
        refreshAutomationRules();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadData]),
  );

  /* istanbul ignore next */
  useEffect(() => {
    return () => setSortedColumn(initialSortOrderState);
  }, [currentSite]);

  useEffect(() => {
    sortRulesFromSortedColumn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedColumn]);

  const goBack = useCallback(async () => {
    if (routeToGoBackTo) {
      navigation.navigate(routeToGoBackTo);
    }
    return true;
  }, [navigation, routeToGoBackTo]);

  useBackHandler(goBack);

  const onPressAutomationRuleItem = (id) => {
    if (canModifyAutomationRules) {
      analyticsService.trackNavigationEvent(ANALYTICS.eventViewAutomationRuleDetail);
      dispatch.automations.updateSelectedAutomationRule(id);

      navigation.navigate(ROUTES.AUTOMATION_RULE_EDIT, {
        routeToGoBackTo: ROUTES.AUTOMATION_RULES,
        routeToGoBackFromParent: routeToGoBackTo,
      });
    }
  };

  const sortRulesFromSortedColumn = () => {
    if (sortedColumn.type !== SORT_ORDER.NONE) {
      dispatch.automations.sortAutomationRules({ column: SORT_COLUMNS.TYPE, order: sortedColumn.type });
    } else if (sortedColumn.name !== SORT_ORDER.NONE) {
      dispatch.automations.sortAutomationRules({ column: SORT_COLUMNS.NAME, order: sortedColumn.name });
    } else if (sortedColumn.threshold !== SORT_ORDER.NONE) {
      dispatch.automations.sortAutomationRules({ column: SORT_COLUMNS.THRESHOLD, order: sortedColumn.threshold });
    }
  };

  const refreshAutomationRules = async () => {
    setRefreshing(true);
    await dispatch.automations.loadAutomationRules();
    sortRulesFromSortedColumn();
    setRefreshing(false);
  };

  /* istanbul ignore next */
  const toggleSortAndScrollToTop = (payload) => {
    if (listRef.current && !loading) {
      listRef.current.scrollToOffset({ x: 0, y: 0, animated: false });
      toggle(payload);
    }
  };

  const renderAutomationRuleItem = (element) => {
    return (
      <AutomationRuleItem
        ruleID={element.item.id}
        enabled={element.item.enabled}
        unit={element.item.unit}
        keyIndex={element.index}
        ruleType={element.item.ruleType}
        name={element.item.name}
        thresholdDown={element.item.thresholdDown}
        thresholdUp={element.item.thresholdUp}
        onSelect={onPressAutomationRuleItem}
      />
    );
  };

  const renderEmptyView = () => {
    return (
      <View testID="loadingActivity">
        {[...Array(5)].map((_e, i) => (
          <View style={styles.skeletonRow} key={'row' + i}>
            <SkeletonPlaceholder width={60} height={45} />
            <SkeletonPlaceholder width={95} height={15} style={styles.skeletonText} />
            <SkeletonPlaceholder width={60} height={45} />
          </View>
        ))}
      </View>
    );
  };

  return (
    <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
      <View style={globalStyles.header}>
        <HeaderBack
          screenName="automation-rules"
          navigation={navigation}
          goBack={goBack}
          title={t('automation_rule_view_title')}
          testID="automation-rules__screen-title"
        />
      </View>

      <Divider />

      <View testID={'automation-rules__subscreen-container'} style={globalStyles.bottomContainer}>
        <SortableAutomationRuleHeader
          loading={loading}
          navigation={navigation}
          siteName={currentSite.name}
          sortedColumn={sortedColumn}
          toggle={toggleSortAndScrollToTop}
        />

        {rules.length > 0 ? (
          <SortableList
            testID={'automation-rules__subscreen_list'}
            ref={listRef}
            elements={rules}
            loading={loading}
            refreshing={refreshing}
            renderEmptyView={renderEmptyView}
            renderItem={renderAutomationRuleItem}
            screenName={'automationRules'}
            onRefresh={refreshAutomationRules}
          />
        ) : (
          <NoDataView message={t('automation_rule_view_no_data')} />
        )}
      </View>
    </SafeAreaView>
  );
};

AutomationRulesScreen.propTypes = {
  navigation: navigationShape.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      reloadData: PropTypes.bool,
    }),
  }),
};

const styles = StyleSheet.create({
  skeletonRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderColor: COLORS.greyish21,
    borderWidth: 1,
    borderRadius: 4,
    marginHorizontal: 10,
    marginVertical: 5,
    backgroundColor: COLORS.white,
  },
});

export default AutomationRulesScreen;
