import { StyleSheet, View, Text } from 'react-native';
import moment from 'moment-timezone';

// hooks
import { useTranslation } from '../../../hooks/useTranslation';

// constant
import COLORS from '../../../colors';
import FONTS from '../../../fonts';

const TodayDate = () => {
  const { t } = useTranslation();

  return (
    <View>
      <Text style={[styles.textStyle, styles.todaySize]}>{t('to_day')}</Text>
      <Text style={styles.textStyle}>{moment().format(t('today_moment_day_month'))}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  todaySize: {
    fontSize: 17,
  },
  textStyle: {
    textAlign: 'right',
    fontFamily: FONTS.firaSans,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
});

export default TodayDate;
