// const
import COLORS from '../../colors';

export class NutrientEntity {
  constructor(nutrient) {
    if (nutrient) {
      this.id = nutrient.reading.id;
      this.serial = nutrient.serial;
      this.name = nutrient.name;
      this.max = nutrient.max;
      this.min = nutrient.min;
      this.value = nutrient.reading.value;
      this.unit = nutrient.reading.unit;
      this.type = nutrient.reading.type;
      this.levels = nutrient.gaugeLevels?.map((level) => [level.ratio, getGaugeLevels(level.name).color]);
      this.trend = getTrends(nutrient.trend).image;
      this.status = nutrient.status;
      this.statusColor = getStatus(nutrient.status)?.color;
    }
  }
}

const GaugeLevels = {
  LOW: { color: COLORS.maize },
  GOOD: { color: COLORS.emerald },
  HIGH: { color: COLORS.dustyOrange },
};

const Trends = {
  INCREASING: { image: require('../../assets/icons/icnIncreasing.png') },
  DECREASING: { image: require('../../assets/icons/icnDecreasing.png') },
  STABLE: { image: require('../../assets/icons/icnStable.png') },
  UNAVAILABLE: { image: '' },
};

export const NutrientStatus = {
  LOW: { value: 'LOW', color: COLORS.maize },
  GOOD: { value: 'GOOD', color: COLORS.emerald },
  HIGH: { value: 'HIGH', color: COLORS.dustyOrange },
  COMMUNICATION_ERROR: { value: 'COMMUNICATION_ERROR', color: COLORS.pinkishGrey },
  OUT_OF_FIELD: { value: 'OUT_OF_FIELD', color: COLORS.pinkishGrey },
};

const getGaugeLevels = (level) => GaugeLevels[level];

const getTrends = (trend) => Trends[trend] || Trends.UNAVAILABLE;

const getStatus = (status) => NutrientStatus[status];
