import { gql } from '@apollo/client';

export const IRRIGATION_BLOCKS = gql`
  query irrigationBlocks($siteId: ID!, $blockSorting: blockSort, $filters: blockFilters) {
    viewer {
      site(id: $siteId) {
        id
        blocks(sortBy: $blockSorting, filters: $filters) {
          id
          name
          alias
          order
          capabilities {
            irrigation {
              isIrrigating
              currentEvent {
                id
                startDate
                endDate
                subSensorId
              }
            }
            tension {
              trend
              status
              blueBandIndex {
                good
              }
              readings {
                id
                depth
                unit
                value
                colorCode
              }
            }
          }
          schedule {
            nextEvent {
              type
              startDate
              endDate
              anytime
              duration
            }
          }
        }
      }
    }
  }
`;
