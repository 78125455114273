import PropTypes from 'prop-types';
import { Image, StyleSheet, View, Text } from 'react-native';

// shapes
import { imageSourceShape } from '../../../shapes/imageSource';

// constants
import COLORS from '../../../colors';
import FONTS from '../../../fonts';

// components
import SkeletonPlaceholder from '../../skeletons/SkeletonPlaceholder';

const TodayForecast = ({ formattedTemperature, weatherText, weatherImage, loading }) => {
  const renderForecast = () => {
    if (loading) {
      return (
        <View style={styles.skeletonContainer}>
          <SkeletonPlaceholder width={148} height={55} />
          <SkeletonPlaceholder width={101} height={14} style={styles.skeletonDescription} />
        </View>
      );
    }
    return (
      <>
        <Text style={styles.temperatureValue}>{formattedTemperature}</Text>
        <Text style={styles.description}>{weatherText}</Text>
      </>
    );
  };

  const renderIcon = () => {
    if (loading) {
      return <SkeletonPlaceholder width={160} height={160} style={styles.skeletonIcon} />;
    }
    return <Image source={weatherImage} testID={'weather_icon'} />;
  };

  return (
    <View style={styles.container}>
      <View style={styles.forecast}>{renderForecast()}</View>
      <View styles={styles.weatherIcon}>{renderIcon()}</View>
    </View>
  );
};

TodayForecast.propTypes = {
  formattedTemperature: PropTypes.string,
  weatherText: PropTypes.string,
  weatherImage: imageSourceShape,
  loading: PropTypes.bool.isRequired,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5%',
    marginRight: '-17%',
  },
  description: {
    marginTop: '-4%',
    textAlign: 'center',
    fontFamily: FONTS.notoSans,
    fontSize: 36,
    color: COLORS.greyishBrown,
  },
  forecast: {
    marginTop: '-3%',
    marginLeft: '5%',
  },
  skeletonContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 15,
  },
  skeletonDescription: {
    marginTop: 30,
  },
  skeletonIcon: {
    borderRadius: 80,
  },
  temperatureValue: {
    fontFamily: FONTS.notoSansBold,
    fontSize: 72,
    color: COLORS.greyishBrownHalf,
  },
  weatherIcon: {
    overflow: 'hidden',
  },
});

export default TodayForecast;
