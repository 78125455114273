import { getApolloClient } from '../clients/apollo';
import { GRAPHQL_VERSION_V2 } from '../consts';
import { NUTRIENTS } from './nutrients.graphql';

const fetchNutrients = async (siteId) => {
  const { loading, error, data } = await getApolloClient().query({
    query: NUTRIENTS,
    variables: { siteId },
    context: { version: GRAPHQL_VERSION_V2 },
  });

  let blocks;
  if (data) {
    blocks = data.viewer.site.blocks;
  }

  return {
    loading,
    error,
    blocks,
  };
};

export { fetchNutrients };
