import PropTypes from 'prop-types';
import { ActivityIndicator, Pressable, StyleSheet, Text } from 'react-native';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

const SubmitButton = ({ isSubmittable, loading = false, onSubmit, title, testId }) => {
  const isDisabled = !isSubmittable();

  return (
    <Pressable
      style={({ pressed }) => [
        styles.buttonStyle,
        isDisabled && styles.disabledButton,
        /* istanbul ignore next */ pressed && styles.pressedButton,
      ]}
      onPress={onSubmit}
      disabled={isDisabled}
      testID={testId}>
      {loading ? (
        <ActivityIndicator color={styles.title.color} />
      ) : (
        <Text style={[styles.title, isDisabled && styles.disabledButtonTitle]}>{title}</Text>
      )}
    </Pressable>
  );
};

SubmitButton.propTypes = {
  isSubmittable: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  testId: PropTypes.string,
  title: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  buttonStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 48,
    borderRadius: 30,
    backgroundColor: COLORS.seafoamBlue,
  },
  disabledButton: {
    backgroundColor: COLORS.whiteWithEvenLessOpacity,
  },
  disabledButtonTitle: {
    opacity: 0.4,
    color: COLORS.white,
  },
  title: {
    fontFamily: FONTS.notoSansBold,
    fontSize: 16,
    color: COLORS.white,
  },
  pressedButton: {
    opacity: 0.6,
  },
});

export default SubmitButton;
