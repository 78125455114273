import { useEffect } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withRepeat,
  withSequence,
  withTiming,
  Easing,
} from 'react-native-reanimated';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

// entities
import AlertEntity from '../../models/entities/alertEntity';

// services
import { useTranslation } from '../../hooks/useTranslation';
import { navigationShape } from '../../shapes/navigation';

// components
import ListBox from '../../components/list/ListBox';

const AlertItem = ({ alert, keyIndex, navigation }) => {
  const { t } = useTranslation();

  const goToAlertDetail = () => {};

  const alertEntity = new AlertEntity(alert);

  const flashValue = useSharedValue(0);

  useEffect(() => {
    if (alertEntity.inAlarm()) {
      flashValue.value = withRepeat(
        withSequence(
          withTiming(1, { duration: 750, easing: Easing.inOut(Easing.ease) }),
          withTiming(0, { duration: 750, easing: Easing.inOut(Easing.ease) }),
        ),
        -1,
        true,
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertEntity.inAlarm]);

  const flashShadow = useAnimatedStyle(() => ({ textShadowRadius: flashValue.value * 2 }));

  return (
    <ListBox
      customStyle={styles.listBox}
      testID={`alerts__alertItem-${keyIndex}`}
      onPress={goToAlertDetail}
      renderContent={() => (
        <>
          <View style={styles.leftSection}>
            <View style={styles.statusBox}>
              <Image source={alertEntity.statusIcon()} style={styles.statusImage} />
            </View>

            <Text style={[styles.fontStyle, styles.nameText]}>{alertEntity.name}</Text>
          </View>

          <View style={styles.rightSection}>
          {alertEntity.inAlarm() ? (
              <Animated.Text
                style={[styles.fontStyle, styles.inAlarm, flashShadow]}
              >
                {t(alertEntity.statusText())}
              </Animated.Text>
            ) : (
              <Text style={styles.fontStyle}>{t(alertEntity.statusText())}</Text>
            )}
          </View>
        </>
      )}
    />
  );
};

AlertItem.propTypes = {
  alert: PropTypes.object.isRequired,
  keyIndex: PropTypes.number,
  navigation: navigationShape.isRequired,
};

const styles = StyleSheet.create({
  fontStyle: {
    fontFamily: FONTS.notoSans,
    fontSize: 11,
    color: COLORS.greyishBrown,
  },
  inAlarm:  {
    color: COLORS.dustyOrange,
    textShadowColor: COLORS.dustyOrange,
    textShadowOffset: {
      width: 0,
      height: 0,
    },
  },
  leftSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  listBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 60,
    paddingEnd: 0,
    paddingStart: 15,
  },
  rightSection: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: 15,
  },
  statusBox: {
    flexDirection: 'column',
    width: 40,
  },
  statusImage: {
    height: 24,
  },
});

export default AlertItem;
