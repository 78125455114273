import { Image, StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';
import ROUTES from '../../navigation/routes';

// services
import { useTranslation } from '../../hooks/useTranslation';
import { navigationShape } from '../../shapes/navigation';
import ANALYTICS from '../../services/AnalyticsEvents';
import DeviceEntity from '../../models/entities/deviceEntity';
import { formattedDurationFromNow } from '../../utils/dateUtils';

// components
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import ListBox from '../../components/list/ListBox';

const DeviceItem = ({ device, keyIndex, navigation }) => {
  const { t } = useTranslation();
  const analyticsService = useAnalyticsContext();
  const dispatch = useDispatch();

  const blockAlias = device.block.alias;
  const lastUpdated = formattedDurationFromNow(device.lastUpdated, t);

  const goToDeviceDetail = () => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewDeviceDetails);
    dispatch.devices.updateSelectedDevice(device);
    navigation.navigate(ROUTES.DEVICE_DETAILS);
  };

  return (
    <ListBox
      customStyle={styles.listBox}
      testID={`devices__deviceItem-${keyIndex}`}
      onPress={goToDeviceDetail}
      renderContent={() => (
        <>
          <View style={styles.leftSection}>
            <View style={styles.statusBox}>
              <Image source={device.statusIcon()} style={styles.statusImage} />
              <Text style={[styles.fontStyle, styles.statusText]}>{lastUpdated}</Text>
            </View>

            <Text style={[styles.fontStyle, styles.serialText]}>{device.serialNumber}</Text>
            <Text style={[styles.fontStyle, styles.blockText]}>{blockAlias}</Text>
          </View>

          <View style={styles.rightSection}>
            <Text style={[styles.fontStyle2, styles.batteryText]}>{device.batteryText(t)}</Text>
            <Text style={[styles.fontStyle2, styles.signalText]}>{device.signalText(t)}</Text>
          </View>
        </>
      )}
    />
  );
};

DeviceItem.propTypes = {
  device: PropTypes.instanceOf(DeviceEntity).isRequired,
  keyIndex: PropTypes.number,
  navigation: navigationShape.isRequired,
};

const styles = StyleSheet.create({
  batteryText: {
    width: 40,
    textAlign: 'right',
    marginEnd: 5,
  },
  blockText: {
    width: 80,
  },
  fontStyle: {
    fontFamily: FONTS.notoSans,
    fontSize: 11,
    color: COLORS.greyishBrown,
  },
  fontStyle2: {
    fontFamily: FONTS.notoSansBold,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
  leftSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  listBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingEnd: 0,
    paddingStart: 15,
  },
  rightSection: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  serialText: {
    width: 80,
    marginStart: 5,
  },
  signalText: {
    width: 55,
    textAlign: 'right',
    marginEnd: 10,
  },
  statusBox: {
    flexDirection: 'column',
    width: 50,
  },
  statusImage: {
    width: 20,
    height: 20,
  },
  statusText: {
    alignSelf: 'flex-start',
    minWidth: 20,
    marginTop: 5,
    textAlign: 'center',
  },
});

export default DeviceItem;
