import { navigationShape } from '../../shapes/navigation';

// Screen
import * as LocalizedImages from '../../i18n/LocalizedImages';
import PreviewScreen from './PreviewScreen';

const TemperaturePreviewScreen = ({ navigation }) => {
  return (
    <PreviewScreen
      navigation={navigation}
      screenName={'temperature_preview_screen'}
      testId={'temperature__preview__screen__'}
      localizedImages={LocalizedImages.TEMPERATURE_PREVIEW}
    />
  );
};

TemperaturePreviewScreen.propTypes = {
  navigation: navigationShape.isRequired,
};

export default TemperaturePreviewScreen;
