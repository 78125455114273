import { StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

const GenericSection = ({ title, children }) => {
  return (
    <View>
      <View style={styles.header}>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View>{children}</View>
    </View>
  );
};

GenericSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

const styles = StyleSheet.create({
  header: {
    justifyContent: 'center',
    height: 28,
    paddingLeft: 16,
    backgroundColor: COLORS.whiteThree,
  },
  title: {
    fontFamily: FONTS.notoSansBold,
    fontSize: 14,
    color: COLORS.greyishBrown,
  },
});

export default GenericSection;
