import { Image, StyleSheet, Text, Pressable, View } from 'react-native';
import Modal from 'react-native-modal';
import Proptypes from 'prop-types';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

// styles
import { globalStyles } from '../../styles';

// hooks
import { useTranslation } from '../../hooks/useTranslation';

//icons
import icnCancel from '../../assets/icons/icnCancel.png';
import icnError from '../../assets/icons/icnError.png';
import icnWarning from '../../assets/icons/icnWarning.png';
import icnUpdate from '../../assets/icons/icnUpdate.png';
import { AlertTypes } from './AlertConstants';

const Alert = ({
  show,
  alertType,
  title,
  message,
  onPress,
  onCancel,
  okButtonKey,
  cancelButtonKey,
  cancelDisabled = false,
  messageAdvanced,
}) => {
  return (
    <Modal
      testID={'alert__error'}
      isVisible={show}
      onBackButtonPress={!cancelDisabled ? onCancel : undefined}
      onRequestClose={!cancelDisabled ? onCancel : undefined}
      onBackdropPress={undefined}
      useNativeDriver
      useNativeDriverForBackdrop
      hideModalContentWhileAnimating
      style={styles.container}>
      <View style={styles.modalContainer}>
        <AlertHeader title={title} onCancel={onCancel} cancelDisabled={cancelDisabled} />

        <AlertIcon alertType={alertType} />

        {messageAdvanced ? messageAdvanced : <AlertMessage message={message} />}

        <AlertFooter
          alertType={alertType}
          onPress={onPress}
          onCancel={onCancel}
          okButtonKey={okButtonKey}
          cancelButtonKey={cancelButtonKey}
          cancelDisabled={cancelDisabled}
        />
      </View>
    </Modal>
  );
};

Alert.propTypes = {
  alertType: Proptypes.string,
  cancelButtonKey: Proptypes.string,
  cancelDisabled: Proptypes.bool,
  message: Proptypes.string,
  messageAdvanced: Proptypes.node,
  okButtonKey: Proptypes.string,
  onCancel: Proptypes.func,
  onPress: Proptypes.func,
  show: Proptypes.bool,
  title: Proptypes.string,
};

const AlertHeader = ({ title, onCancel, cancelDisabled }) => {
  return (
    <View style={styles.headerContainer}>
      <Text style={styles.headerTitle}>{title}</Text>
      <View style={styles.headerButtonContainer}>
        {!cancelDisabled ? (
          <Pressable
            style={/* istanbul ignore next */ ({ pressed }) => (pressed ? globalStyles.touchOpacity : {})}
            onPress={onCancel}
            testID={'message_header-cancel-button'}>
            <Image source={icnCancel} style={styles.closeIcon} />
          </Pressable>
        ) : null}
      </View>
    </View>
  );
};

AlertHeader.propTypes = {
  cancelDisabled: Proptypes.bool,
  onCancel: Proptypes.func,
  title: Proptypes.string,
};

const AlertIcon = ({ alertType }) => {
  let imgSource;

  switch (alertType) {
    case AlertTypes.ERROR:
      imgSource = icnError;
      break;
    case AlertTypes.WARNING:
      imgSource = icnWarning;
      break;
    case AlertTypes.UPDATE:
      imgSource = icnUpdate;
      break;
  }

  return (
    <View style={styles.iconContainer}>
      <Image source={imgSource} style={styles.icons} />
    </View>
  );
};

AlertIcon.propTypes = {
  alertType: Proptypes.string,
};

const AlertMessage = ({ message }) => {
  return (
    <View style={styles.modalTextContainer}>
      <Text style={styles.modalDescription}>{message}</Text>
    </View>
  );
};

AlertMessage.propTypes = {
  message: Proptypes.string,
};

const AlertFooter = ({ alertType, onPress, onCancel, okButtonKey, cancelButtonKey, cancelDisabled }) => {
  const { t } = useTranslation();

  const alertTypeIsError = alertType === AlertTypes.ERROR;
  const alertTypeIsWarning = alertType === AlertTypes.WARNING;
  const alertTypeIsUpdate = alertType === AlertTypes.UPDATE;

  let okButton, cancelButton;

  if (alertTypeIsError) {
    okButton = okButtonKey ? okButtonKey : 'generic_button_close';
  }

  if (alertTypeIsWarning) {
    okButton = okButtonKey ? okButtonKey : 'answer_yes';
    cancelButton = cancelButtonKey ? cancelButtonKey : 'answer_no';
  }

  if (alertTypeIsUpdate) {
    okButton = okButtonKey ? okButtonKey : 'killswitch_update';
    cancelButton = cancelButtonKey ? cancelButtonKey : 'generic_button_cancel';
  }

  return (
    <>
      {alertTypeIsError ? (
        <View style={styles.footContainer}>
          <AlertFooterError alertType={alertType} buttonText={t(okButton)} onPress={onPress} />
        </View>
      ) : null}

      {alertTypeIsWarning ? (
        <View style={styles.footContainer}>
          <AlertFooterWarning onPress={onPress} onCancel={onCancel} cancelText={t(cancelButton)} okText={t(okButton)} />
        </View>
      ) : null}

      {alertTypeIsUpdate && cancelDisabled ? (
        <View style={styles.footContainer}>
          <AlertFooterError alertType={alertType} buttonText={t(okButton)} onPress={onPress} />
        </View>
      ) : null}

      {alertTypeIsUpdate && !cancelDisabled ? (
        <View style={styles.footContainer}>
          <AlertFooterWarning onPress={onPress} onCancel={onCancel} cancelText={t(cancelButton)} okText={t(okButton)} />
        </View>
      ) : null}
    </>
  );
};

AlertFooter.propTypes = {
  alertType: Proptypes.string,
  onPress: Proptypes.func,
  onCancel: Proptypes.func,
  okButtonKey: Proptypes.string,
  cancelButtonKey: Proptypes.string,
  cancelDisabled: Proptypes.bool,
};

const AlertFooterError = ({ alertType, buttonText, onPress }) => {
  let textColor = COLORS.dustyOrange;

  if (alertType === AlertTypes.UPDATE) {
    textColor = COLORS.azure;
  }

  return (
    <View style={styles.buttonContainer}>
      <Pressable
        style={/* istanbul ignore next */ ({ pressed }) => (pressed ? globalStyles.touchOpacity : {})}
        onPress={onPress}
        testID={'message_action-button'}>
        <Text style={[styles.errorButtonText, { color: textColor }]}>{buttonText}</Text>
      </Pressable>
    </View>
  );
};

AlertFooterError.propTypes = {
  alertType: Proptypes.string,
  onPress: Proptypes.func,
  buttonText: Proptypes.string,
};

const AlertFooterWarning = ({ onPress, onCancel, cancelText, okText }) => {
  return (
    <>
      <View style={styles.footLeftContainer}>
        <Pressable
          style={/* istanbul ignore next */ ({ pressed }) => (pressed ? globalStyles.touchOpacity : {})}
          onPress={onCancel}
          testID={'message_no-button'}>
          <Text style={[styles.errorButtonText, { color: COLORS.greyishBrown }]}>{cancelText}</Text>
        </Pressable>
      </View>
      <View style={styles.footRightContainer}>
        <Pressable
          style={/* istanbul ignore next */ ({ pressed }) => (pressed ? globalStyles.touchOpacity : {})}
          onPress={onPress}
          testID={'message_yes-button'}>
          <Text style={[styles.errorButtonText, { color: COLORS.greyishBrown }]}>{okText}</Text>
        </Pressable>
      </View>
    </>
  );
};

AlertFooterWarning.propTypes = {
  onPress: Proptypes.func,
  onCancel: Proptypes.func,
  cancelText: Proptypes.string,
  okText: Proptypes.string,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  buttonContainer: {
    width: '100%',
  },
  closeIcon: {
    width: 18,
    height: 18,
    resizeMode: 'contain',
  },
  errorButtonText: {
    textAlign: 'center',
    fontFamily: FONTS.firaSans,
    fontSize: 16,
  },
  footContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 41,
    paddingHorizontal: 16,
    marginTop: 20,
    borderTopColor: COLORS.whiteThree,
    borderTopWidth: StyleSheet.hairlineWidth,
  },
  footLeftContainer: {
    justifyContent: 'center',
    width: '50%',
    height: '100%',
    borderRightColor: COLORS.whiteThree,
    borderRightWidth: StyleSheet.hairlineWidth,
  },
  footRightContainer: {
    justifyContent: 'center',
    width: '50%',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 41,
    paddingHorizontal: 16,
    backgroundColor: COLORS.whiteFive,
    borderBottomColor: COLORS.whiteThree,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  headerButtonContainer: {
    position: 'absolute',
    right: 10,
  },
  headerTitle: {
    textAlign: 'center',
    fontFamily: FONTS.firaSansMedium,
    fontSize: 18,
    letterSpacing: 1,
    color: COLORS.greyishBrown,
  },
  iconContainer: {
    alignItems: 'center',
    marginTop: 20,
  },
  icons: {
    width: 49,
    height: 48,
    resizeMode: 'contain',
  },
  modalContainer: {
    alignSelf: 'center',
    width: '90%',
    borderRadius: 7,
    backgroundColor: COLORS.white,
  },
  modalDescription: {
    paddingHorizontal: 10,
    marginTop: 20,
    textAlign: 'center',
    fontFamily: FONTS.firaSans,
    fontSize: 16,
    color: COLORS.greyishBrown,
  },
  modalTextContainer: {
    alignItems: 'center',
    paddingHorizontal: 5,
  },
});

export default Alert;
