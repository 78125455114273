import { Image, StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';

// service
import { iconForRule, translationForRule } from '../../../models/automationRuleType';

// components
import AutomationRuleThresholds from './AutomationRuleThresholds';

const AutomationRuleItemMinified = ({ ruleType, thresholdUp, thresholdDown, unit }) => {
  const icon = iconForRule(ruleType, true);
  const testID = translationForRule(ruleType);

  return (
    <View style={styles.container} testID={testID}>
      <View style={styles.columnIcon}>
        <Image source={icon} style={styles.icon} />
      </View>

      <AutomationRuleThresholds thresholdDown={thresholdDown} thresholdUp={thresholdUp} unit={unit} />
    </View>
  );
};

AutomationRuleItemMinified.propTypes = {
  ruleType: PropTypes.string.isRequired,
  thresholdUp: PropTypes.number.isRequired,
  thresholdDown: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  columnIcon: {
    width: 20,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 40,
  },
  icon: {
    width: 18,
    height: 31,
  },
});

export default AutomationRuleItemMinified;
