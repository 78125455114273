import { memo } from 'react';
import { RefreshControl, ScrollView, StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';
import OPACITIES from '../../opacities';

// hooks
import { useTranslation } from '../../hooks/useTranslation';

// services
import { isAndroid } from '../../services/PlatformService';

// Components
import CustomButton from '../button/CustomButton';

const BluetoothState = ({ bleState, onPress, onRefresh, testId }) => {
  const { t } = useTranslation();

  return (
    <ScrollView
      testID={testId}
      contentContainerStyle={styles.scrollViewContainer}
      refreshControl={<RefreshControl refreshing={false} onRefresh={onRefresh} />}>
      <View style={styles.viewContainer} testID="bluetooth-state__container">
        <Text style={styles.text} testID="bluetooth-state__text">
          {t('console_ble_state_' + bleState)}
        </Text>
        {isAndroid() && (
          <CustomButton
            testId={testId}
            onPress={onPress}
            buttonStyle={styles.buttonStyle}
            pressedStyle={[styles.buttonPressed]}
            underlayColor={COLORS.whiteTwo}>
            <View style={styles.buttonContent}>
              <Text style={styles.buttonText}>{t('console_enable_bluetooth')}</Text>
            </View>
          </CustomButton>
        )}
      </View>
    </ScrollView>
  );
};

BluetoothState.propTypes = {
  bleState: PropTypes.string,
  onRefresh: PropTypes.func.isRequired,
  onPress: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  buttonStyle: {
    width: '50%',
    height: 64,
    borderRadius: 4,
    backgroundColor: COLORS.greyish22,
    elevation: 10,
    shadowColor: COLORS.greyShadow,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: OPACITIES.dataStreamButtonShadow,
    shadowRadius: 2,
  },
  buttonPressed: {
    borderColor: COLORS.greyish22,
    borderWidth: 2,
    backgroundColor: COLORS.white,
    shadowColor: COLORS.white,
  },
  buttonContent: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingHorizontal: 15,
    elevation: 5,
  },
  buttonText: {
    fontFamily: FONTS.notoSans,
    fontSize: 13,
    fontWeight: 'bold',
    color: COLORS.greyishBrown,
  },
  scrollViewContainer: {
    flex: 0.8,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.greyish20,
  },
  text: {
    paddingHorizontal: 22,
    paddingVertical: 10,
    textAlign: 'center',
    fontFamily: FONTS.firaSans,
    fontSize: 13,
    fontStyle: 'italic',
    lineHeight: 16,
    color: COLORS.greyishBrown,
  },
  viewContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default memo(BluetoothState);
