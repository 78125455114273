import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import Animated, { Easing, useAnimatedStyle, useSharedValue, withRepeat, withTiming } from 'react-native-reanimated';

// icons
import icnLoading from '../assets/icons/icnLoading.png';

// constants
import COLORS from '../colors';
import OPACITIES from '../opacities';

const LOADING_BOX_SIZE = 65;

const LoadingOverlay = ({ isLoading = false, handleTouchEvents = true }) => {
  const isRunning = useRef(false);
  const spinValue = useSharedValue(0);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ rotate: `${spinValue.value}deg` }],
    };
  });

  useEffect(() => {
    if (isLoading && !isRunning.current) {
      spinValue.value = withRepeat(withTiming(360, { duration: 1500, easing: Easing.linear }), -1, false);
      isRunning.current = true;
    } else if (!isLoading && isRunning.current) {
      spinValue.value = 0;
      isRunning.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (!isLoading) {
    return null;
  }

  return (
    <View style={styles.activityIndicator} pointerEvents={handleTouchEvents ? 'box-none' : 'box-only'}>
      <View style={styles.roundedBox}>
        <Animated.Image style={[styles.animatedImage, animatedStyle]} source={icnLoading} />
      </View>
    </View>
  );
};

LoadingOverlay.propTypes = {
  isLoading: PropTypes.bool,
  handleTouchEvents: PropTypes.bool,
};

const styles = StyleSheet.create({
  activityIndicator: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  animatedImage: {
    width: 31,
    height: 39,
  },
  roundedBox: {
    justifyContent: 'center',
    alignItems: 'center',
    width: LOADING_BOX_SIZE,
    height: LOADING_BOX_SIZE,
    borderRadius: LOADING_BOX_SIZE / 2,
    backgroundColor: COLORS.white,
    elevation: 5,
    shadowColor: COLORS.darkGrey,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: OPACITIES.dataStreamButtonShadow,
    shadowRadius: 3,
  },
});

export default LoadingOverlay;
