// client
import { getApolloClient } from '../clients/apollo';

//graphQL
import { MANAGE_EVENT } from './manageEvent.graphql';

async function createEvent(eventDetail, eventId) {
  try {
    const response = await getApolloClient().mutate({
      mutation: MANAGE_EVENT,
      variables: {
        eventId,
        event: eventDetail,
      },
    });

    return { event: response };
  } catch (error) {
    if (error.graphQLErrors?.length > 0) {
      return { errors: error.graphQLErrors };
    } else {
      return { errors: [{}] };
    }
  }
}

export { createEvent };
