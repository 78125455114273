import { StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

// constants
import FONTS from '../../fonts';
import COLORS from '../../colors';

// shape
import { styleShape } from '../../shapes/style';

const Schedule = ({ presenter, style }) => {
  const { status, value, subValue } = presenter.scheduledStatusAndTime();

  return (
    <View style={style}>
      <Text style={styles.scheduledText} ellipsizeMode="tail" numberOfLines={1}>
        {status}
      </Text>
      {value ? (
        <Text style={styles.scheduledWhenText} ellipsizeMode="tail" numberOfLines={1}>
          {value}
        </Text>
      ) : null}
      {subValue ? (
        <Text style={styles.scheduledWhenText} ellipsizeMode="tail" numberOfLines={1}>
          {subValue}
        </Text>
      ) : null}
    </View>
  );
};

Schedule.propTypes = {
  presenter: PropTypes.object.isRequired,
  style: styleShape,
};

const styles = StyleSheet.create({
  scheduledText: {
    fontFamily: FONTS.firaSans,
    fontSize: 11,
    lineHeight: 13,
    color: COLORS.greyishBrown,
  },
  scheduledWhenText: {
    fontFamily: FONTS.firaSansBold,
    fontSize: 11,
    lineHeight: 13,
    color: COLORS.greyishBrown,
  },
});

export default Schedule;
