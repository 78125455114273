import PropTypes from 'prop-types';
import { StyleSheet, Text } from 'react-native';

// constants
import FONTS from '../../../fonts';
import COLORS from '../../../colors';

const ForecasDetailsTitle = ({ value }) => {
  return <Text style={styles.columnTitle}>{value}</Text>;
};

ForecasDetailsTitle.propTypes = {
  value: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  columnTitle: {
    paddingVertical: 10,
    textAlign: 'center',
    fontFamily: FONTS.notoSans,
    fontSize: 12,
    color: COLORS.warmGrey,
  },
});

export default ForecasDetailsTitle;
