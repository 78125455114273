import { navigationShape } from '../../shapes/navigation';

// Screen
import * as LocalizedImages from '../../i18n/LocalizedImages';
import PreviewScreen from './PreviewScreen';

const AutomationPreviewScreen = ({ navigation }) => {
  return (
    <PreviewScreen
      navigation={navigation}
      screenName={'automation_preview_screen'}
      testId={'automation__preview__screen__'}
      localizedImages={LocalizedImages.AUTOMATION_PREVIEW}
    />
  );
};

AutomationPreviewScreen.propTypes = {
  navigation: navigationShape.isRequired,
};

export default AutomationPreviewScreen;
