import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import { useFocusEffect } from '@react-navigation/native';
import { navigationShape } from '../../shapes/navigation';
import { useTranslation } from '../../hooks/useTranslation';
import { useBackHandler } from '../../hooks/useBackHandler';

// icons
import icnDevice from '../../assets/icons/console/device.png';

// styles
import { globalStyles } from '../../styles';

// services
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import { BLE_ON } from '../../services/BluetoothService';
import ANALYTICS from '../../services/AnalyticsEvents';
import ROUTES from '../../navigation/routes';

// components
import HeaderBack from '../../components/header/HeaderBack';
import ConsoleListHeader from '../../components/console/ConsoleListHeader';
import ConsoleListItem from '../../components/console/ConsoleListItem';
import EmptyViewWithRefresh from '../../components/EmptyViewWithRefresh';
import COLORS from '../../colors';
import Divider from '../../components/Divider';
import ConsoleDiscoveryView from '../../components/console/ConsoleDiscoveryView';
import BluetoothState from '../../components/console/BluetoothState';

// constants
import { MENU_ANIMATION_TIME } from '../../consts';

const ScanTimeSeconds = 7;

const ConsoleScreen = ({ navigation }) => {
  const currentSite = useSelector((state) => state.site.currentSite);
  const devices = useSelector((state) => state.console.devices);
  const discovering = useSelector((state) => state.console.discovering);
  const loading = useSelector((state) => state.console.loading);
  const bleState = useSelector((state) => state.console.bleState);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const analyticsService = useAnalyticsContext();

  const initialLoad = async () => {
    dispatch.console.setLoading(true);
    await dispatch.console.load();
  };

  const initialScan = async () => {
    await dispatch.console.scan(ScanTimeSeconds);
    dispatch.console.setLoading(false);
  };

  const refresh = () => {
    dispatch.console.scan(ScanTimeSeconds);
  };

  const goToDetails = (device) => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewConsoleDetails);
    dispatch.console.setSelectedDevice(device);
    navigation.navigate(ROUTES.CONSOLE_DETAILS);
  };

  const enableBluetooth = () => dispatch.console.enableBluetooth(refresh);

  // TODO : useAppState for going to background?
  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      initialLoad();
      setTimeout(initialScan, MENU_ANIMATION_TIME);
      return () => {
        const currentState = navigation.getState();
        const goToRoute = currentState.routeNames[currentState.index];
        if (goToRoute !== ROUTES.CONSOLE_DETAILS) {
          dispatch.console.unload();
        }
        dispatch.console.setDevices([]);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigation]),
  );

  const renderItem = ({ item }) => {
    const name = item.getName();
    return (
      <ConsoleListItem
        onPress={() => {
          goToDetails(item);
        }}
        serialNumber={name}
        testID={`console-item-${name}`}
      />
    );
  };

  const renderScreen = () => {
    let content;

    if (bleState !== undefined && bleState !== BLE_ON) {
      content = <BluetoothState bleState={bleState} onPress={enableBluetooth} onRefresh={refresh} />;
    } else if (loading || discovering) {
      content = <ConsoleDiscoveryView />;
    } else if (devices.length === 0) {
      content = (
        <EmptyViewWithRefresh
          icon={icnDevice}
          iconSizeStyle={styles.emptyIcon}
          textKey={'console_list_empty'}
          onRefresh={refresh}
        />
      );
    } else {
      content = (
        <FlashList
          testID={'console__screen-list'}
          data={devices}
          renderItem={renderItem}
          estimatedItemSize={100}
          disableHorizontalListHeightMeasurement={true}
          ListFooterComponent={<View style={styles.listComponents} />}
          ListHeaderComponent={<View style={styles.listComponents} />}
          contentContainerStyle={globalStyles.scrollContainer}
          onRefresh={refresh}
          refreshing={false}
          showsVerticalScrollIndicator={false}
        />
      );
    }

    return <View style={styles.fullContainer}>{content}</View>;
  };

  const goBack = useCallback(() => {
    navigation.navigate(ROUTES.DEVICES);
    return true; // prevent event bubble up (Android will close the app)
  }, [navigation]);

  useBackHandler(goBack);

  return (
    <>
      <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
        <View style={globalStyles.header}>
          <HeaderBack screenName="console" navigation={navigation} goBack={goBack} title={currentSite.name} />
        </View>
        <Divider />
        <View testID="console__screen-container" style={globalStyles.bottomContainer}>
          <ConsoleListHeader status={t('console_status_title')} title={t('console_header_title')} />
          {renderScreen()}
        </View>
      </SafeAreaView>
      <SafeAreaView style={styles.safeBottomView} edges={['bottom']} />
    </>
  );
};

ConsoleScreen.propTypes = {
  navigation: navigationShape.isRequired,
};

const styles = StyleSheet.create({
  fullContainer: {
    flex: 1,
  },
  emptyIcon: {
    width: 124,
    height: 43,
    margin: 10,
  },
  listComponents: {
    height: 8,
  },
  safeBottomView: {
    backgroundColor: COLORS.greyish20,
  },
});
export default ConsoleScreen;
