import { useEffect, useRef, useState, useCallback } from 'react';
import { StyleSheet, View, ScrollView, RefreshControl, StatusBar, Text, Pressable } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFocusEffect } from '@react-navigation/native';
import { DataProvider, LayoutProvider, RecyclerListView } from 'recyclerlistview';

// icons
import icnAddEventFab from '../../assets/icons/icnAddEventFab.png';

//entity
import NoteEntity from '../../models/entities/noteEntity';

// services
import { formatDateForAPI } from '../../utils/dateUtils';
import { isAndroid, isIos } from '../../services/PlatformService';

import { navigationShape } from '../../shapes/navigation';
import SchedulePresenter from '../../presenters/SchedulePresenter';
import ANALYTICS from '../../services/AnalyticsEvents';
import ROUTES from '../../navigation/routes';
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';

// hooks
import { useTranslation } from '../../hooks/useTranslation';
import { useBackHandlerRouteTo } from '../../hooks/useBackHandler';

// styles
import { globalStyles } from '../../styles';

// components
import BlockDay from '../../components/schedule/BlockDay';
import Day from '../../components/schedule/Day';
import ScheduleHeaderBar from '../../components/header/ScheduleHeaderBar';
import FloatingActionButton from '../../components/FloatingActionButton';
import ExpandButton from '../../components/schedule/ExpandButton';
import BlockPanel from '../../components/schedule/BlocksPanel';
import Divider from '../../components/Divider';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';
import POINTER_EVENTS from '../../pointerEvents';
import LoadingOverlay from '../../components/LoadingOverlay';
import {
  getCurrentWeekStart,
  getCurrentWeekEnd,
  getInitialScrollIndex,
  getScheduleEndDate,
  getScheduleStartDate,
  getWithoutGroupsBlockPanelWidth,
  getWithGroupsBlockPanelWidth,
  ScheduleConstants,
} from './ScheduleConstants';

const layoutProviderDay = new LayoutProvider(
  (index) => {
    return index;
  },
  (type, dimension) => {
    dimension.height = ScheduleConstants.TOP_LABEL_HEIGHT;
    dimension.width = ScheduleConstants.CELL_WIDTH;
  },
);

const layoutProviderBlockDay = new LayoutProvider(
  (index) => {
    return index;
  },
  (type, dimension) => {
    dimension.height = ScheduleConstants.CELL_HEIGHT;
    dimension.width = ScheduleConstants.CELL_WIDTH;
  },
);

// istanbul ignore next
const dataProviderDay = new DataProvider((r1, r2) => {
  return r1 !== r2;
});

// istanbul ignore next
const dataProviderBlockDay = new DataProvider((r1, r2) => {
  return r1 !== r2;
});

const ScheduleScreen = ({ navigation, route }) => {
  useBackHandlerRouteTo(navigation, route);
  const { t } = useTranslation();
  const analyticsService = useAnalyticsContext();
  const topLabelScrollView = useRef(null);
  const dataHorizontalScrollView = useRef(null);

  const dispatch = useDispatch();
  const currentSite = useSelector((state) => state.site.currentSite);
  const schedule = useSelector((state) => state.schedule);
  const loadingSchedule = useSelector((state) => !!state.loading.effects.schedule.loadSchedule);

  const [schedulePresenter, setSchedulePresenter] = useState(() => new SchedulePresenter({ blocks: [], blockGroups: [] }));
  const [dayLabels, setDayLabels] = useState([]);
  const [dateRange, setDateRange] = useState({ from: getCurrentWeekStart(), to: getCurrentWeekEnd() });
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [showFabButton, setShowFabButton] = useState(() => isAndroid());
  const [blocksExpanded, setBlocksExpanded] = useState(false);
  const [scrollIndex, setScrollIndex] = useState(() => getInitialScrollIndex());
  const [noteTitle, setNoteTitle] = useState();

  const [dataDays, setDataDays] = useState(() => dataProviderDay);
  const [dataBlocksDays, setDataBlocksDays] = useState(() => dataProviderBlockDay);

  const blocksLength = schedule.blocks?.length || 0;

  // istanbul ignore next
  useEffect(() => {
    setSchedulePresenter(new SchedulePresenter({ blocks: [], blockGroups: [] }));
    setDayLabels([]);
    setScrollIndex(getInitialScrollIndex());
    setDateRange({ from: getCurrentWeekStart(), to: getCurrentWeekEnd() });
    setDataDays(dataProviderDay.cloneWithRows([]));
    setDataBlocksDays(dataProviderBlockDay.cloneWithRows([]));
  }, [currentSite]);

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      dispatch.schedule.loadSchedule({
        startDate: formatDateForAPI(getScheduleStartDate()),
        endDate: formatDateForAPI(getScheduleEndDate()),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSite]),
  );

  // istanbul ignore next
  useEffect(() => {
    if (!schedule.blocks || !schedule.blockGroups) {
      return;
    }

    const presenter = new SchedulePresenter(schedule);
    const dayLabelsArray = presenter.getDayLabels(getScheduleStartDate(), getScheduleEndDate());
    setDayLabels(dayLabelsArray);
    setSchedulePresenter(presenter);

    const blocksArray = presenter.getBlocks();
    const blocksDays = [];
    const days = [];
    const forecastLength = schedule.dailyForecasts.length;
    let forecastIndex = 0;
    let inForecast = false;

    for (let i = 0; i < dayLabelsArray.length; i++) {
      const day = dayLabelsArray[i];

      if (forecastIndex < forecastLength) {
        if (!inForecast && moment(schedule.dailyForecasts[0].date).isSame(moment(day), 'day')) {
          inForecast = true;
        }
        if (inForecast) {
          const forecast = schedule.dailyForecasts[forecastIndex].weatherCode;
          days.push({ day, forecast, forecastIndex });
          forecastIndex++;
        } else {
          days.push({ day });
        }
      } else {
        days.push({ day });
      }

      for (let j = 0; j < blocksArray.length; j++) {
        blocksDays.push({ day, block: blocksArray[j] });
      }
    }

    setDataDays((prevState) => prevState.cloneWithRows(days));
    setDataBlocksDays((prevState) => prevState.cloneWithRows(blocksDays));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule.blocks, schedule.blockGroups, schedule.dailyForecasts]);

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      if (route.params?.reloadData) {
        refresh();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigation]),
  );

  const goToWeatherForecasts = (forecastIndex) => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewWeatherForecast);
    navigation.navigate(ROUTES.WEATHER_FORECASTS, { forecastIndex });
  };

  // istanbul ignore next
  const onScrollHorizontal = ({ nativeEvent }) => {
    if (blocksExpanded === true) {
      setBlocksExpanded(false);
    }

    if (dataDays.getSize() > 0) {
      topLabelScrollView.current?.scrollToOffset(nativeEvent.contentOffset.x, 0, false);
    }
  };

  // istanbul ignore next
  const onMomentumScrollHorizontal = ({ nativeEvent }) => {
    const { contentOffset, layoutMeasurement } = nativeEvent;
    const currentIndex = Math.round((contentOffset.x / layoutMeasurement.width) * ScheduleConstants.DAYS_IN_A_WEEK);
    if (currentIndex !== scrollIndex) {
      setScrollIndex(currentIndex);
    }
  };

  // istanbul ignore next
  const onScrollVertical = ({ nativeEvent: { layoutMeasurement, contentOffset, contentSize } }) => {
    const isNearBottom = layoutMeasurement.height + contentOffset.y >= contentSize.height - 20;
    const shouldShowFabButton = contentOffset.y <= 0 || !isNearBottom;

    if (showFabButton !== shouldShowFabButton) {
      setShowFabButton(shouldShowFabButton);
    }
  };

  // istanbul ignore next
  useEffect(() => {
    const refreshHeaderLabel = (firstIsoDate) => {
      if (!firstIsoDate) {
        return;
      }
      const first = moment(firstIsoDate);
      if (first.day() !== 1) {
        return;
      }

      const lastDate = first.clone().add(1, 'week');
      setDateRange({ from: first, to: lastDate });

      let noteCount = 0;
      if (schedule.notes) {
        schedule.notes.forEach((note) => {
          const noteEntity = new NoteEntity(note);
          if (noteEntity.isEffective(first, lastDate)) {
            noteCount++;
          }
        });
      }

      if (schedule.blocks) {
        schedule.blocks.forEach((block) => {
          if (block.notes) {
            block.notes.forEach((note) => {
              let noteEntity = new NoteEntity(note);
              if (noteEntity.isEffective(first, lastDate)) {
                noteCount++;
              }
            });
          }
        });
      }

      setNoteTitle(`${t('note_title', { count: noteCount })} (${noteCount})`);
    };

    if (dayLabels) {
      refreshHeaderLabel(dayLabels[scrollIndex]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dayLabels, schedule.blocks, schedule.notes, scrollIndex]);

  // istanbul ignore next
  const refresh = async () => {
    setIsRefreshing(true);
    await dispatch.schedule.loadSchedule({
      startDate: formatDateForAPI(getScheduleStartDate()),
      endDate: formatDateForAPI(getScheduleEndDate()),
    });
    setIsRefreshing(false);
  };

  const renderDays = (_, data) => (
    <Day day={moment(data.day)} weatherCode={data.forecast} onPress={() => goToWeatherForecasts(data.forecastIndex)} />
  );

  // istanbul ignore next
  const renderBlockDay = (_, data) => {
    return (
      <BlockDay block={data.block} presenter={schedulePresenter} day={moment(data.day)} onDayPress={goToScheduleEventList} />
    );
  };

  // istanbul ignore next
  const changeWeek = (index) => {
    if (blocksExpanded) {
      setBlocksExpanded(false);
    }

    if (loadingSchedule) {
      return;
    }

    setScrollIndex(index);
    topLabelScrollView.current?.scrollToIndex(index, false);
    dataHorizontalScrollView.current?.scrollToIndex(index * blocksLength, false);
  };

  // istanbul ignore next
  const scrollCalendarLeft = () => {
    const index = scrollIndex - ScheduleConstants.DAYS_IN_A_WEEK;
    if (index < 0) {
      return;
    }

    analyticsService.trackNavigationEvent(ANALYTICS.schedulePreviousWeekButton);
    changeWeek(index);
  };

  // istanbul ignore next
  const scrollCalendarRight = () => {
    const index = scrollIndex + ScheduleConstants.DAYS_IN_A_WEEK;
    if (index > ScheduleConstants.TOTAL_WEEKS) {
      return;
    }

    analyticsService.trackNavigationEvent(ANALYTICS.scheduleNextWeekButton);
    changeWeek(index);
  };

  // istanbul ignore next
  const shouldDisplayLoading = loadingSchedule && !isRefreshing;

  // istanbul ignore next
  const scrollToToday = () => {
    changeWeek(getInitialScrollIndex());
  };

  // istanbul ignore next
  const getBlockPanelWidth = () => {
    if (!schedule.blockGroups) {
      return ScheduleConstants.DEFAULT_BLOCK_PANEL_WIDTH;
    }
    if (schedule.blockGroups.length > 0) {
      return getWithGroupsBlockPanelWidth(blocksExpanded);
    } else {
      return getWithoutGroupsBlockPanelWidth(blocksExpanded);
    }
  };

  const goToNotes = () => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewNotes);
    dispatch.notes.loadNotes({
      startDate: dateRange.from,
      endDate: dateRange.to,
    });
    navigation.navigate(ROUTES.NOTES, { dateRange });
  };

  // istanbul ignore next
  const returnToCurrentScreen = () => {
    navigation.navigate(ROUTES.SCHEDULE);
    return true; // prevent event bubble up (Android will close the app)
  };

  const goToManageEvent = () => {
    analyticsService.trackEvent(ANALYTICS.createEventButton);
    navigation.navigate(ROUTES.MANAGE_EVENT, { onGoBack: returnToCurrentScreen });
  };

  // istanbul ignore next
  const goToScheduleEventList = (block, day, dailyEvents) => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewScheduleEventList);
    navigation.navigate(ROUTES.SCHEDULE_EVENT_LIST, { block, day, dailyEvents });
  };

  return (
    <SafeAreaView
      style={globalStyles.topContainer}
      pointerEvents={isRefreshing ? POINTER_EVENTS.none : POINTER_EVENTS.auto}
      edges={['top', 'right', 'left']}>
      <View style={styles.page}>
        <StatusBar backgroundColor={COLORS.greyishBrown} barStyle={isIos() ? 'dark-content' : 'default'} translucent={false} />

        <View style={globalStyles.header} testID="main__banner">
          <ScheduleHeaderBar
            siteName={currentSite.name}
            dateRange={dateRange}
            screenName={'schedule'}
            testId={'schedule__header'}
            navigation={navigation}
            onLeftArrowClick={scrollCalendarLeft}
            onRightArrowClick={scrollCalendarRight}
            onTodayButtonPress={scrollToToday}
            onAddEventPress={goToManageEvent}
            loading={loadingSchedule}
          />
        </View>

        <Divider />

        <View testID={'schedule__subscreen-container'} style={styles.container}>
          <View style={styles.scheduleContainer}>
            <View style={styles.container}>
              <View style={[styles.floatingLeftPanel, { width: getBlockPanelWidth() }]}>
                <ExpandButton
                  testId={'schedule__expand-button'}
                  blockPanelWidth={getBlockPanelWidth()}
                  expanded={blocksExpanded}
                  onClick={setBlocksExpanded}
                />
              </View>

              <View style={styles.topRow}>
                {dataDays.getSize() > 0 ? (
                  <RecyclerListView
                    ref={topLabelScrollView}
                    initialRenderIndex={getInitialScrollIndex()}
                    isHorizontal={true}
                    layoutProvider={layoutProviderDay}
                    dataProvider={dataDays}
                    rowRenderer={renderDays}
                    layoutSize={{ width: ScheduleConstants.MAX_WIDTH, height: ScheduleConstants.TOP_LABEL_HEIGHT + 2 }}
                    scrollViewProps={{
                      showsHorizontalScrollIndicator: false,
                      scrollEnabled: false,
                      bounces: false,
                    }}
                    showsVerticalScrollIndicator={false}
                    testID="schedule__scheduled-days"
                  />
                ) : null}
              </View>

              <Divider />

              <ScrollView
                contentContainerStyle={[styles.row, { height: ScheduleConstants.CELL_HEIGHT * blocksLength + 1 }]}
                refreshControl={
                  <RefreshControl testID="schedule__refresh-controller" onRefresh={refresh} refreshing={isRefreshing} />
                }
                showsHorizontalScrollIndicator={false}
                onScroll={onScrollVertical}
                onScrollEndDrag={onScrollVertical}
                scrollEventThrottle={300}
                testID="schedule__vertical-scrollview"
                showsVerticalScrollIndicator={false}>
                <View style={[styles.floatingLeftPanel, styles.floatingBlocks, { width: getBlockPanelWidth() }]}>
                  <BlockPanel
                    expanded={blocksExpanded}
                    blockPanelWidth={getBlockPanelWidth()}
                    schedulePresenter={schedulePresenter}
                  />
                </View>

                {dataBlocksDays.getSize() > 0 ? (
                  <RecyclerListView
                    ref={dataHorizontalScrollView}
                    initialRenderIndex={getInitialScrollIndex() * blocksLength}
                    isHorizontal={true}
                    layoutProvider={layoutProviderBlockDay}
                    dataProvider={dataBlocksDays}
                    rowRenderer={renderBlockDay}
                    onScroll={onScrollHorizontal}
                    canChangeSize={true}
                    layoutSize={{ width: ScheduleConstants.MAX_WIDTH, height: ScheduleConstants.CELL_HEIGHT * blocksLength + 1 }}
                    scrollViewProps={{
                      pagingEnabled: true,
                      scrollEnabled: true,
                      showsHorizontalScrollIndicator: false,
                      bounces: false,
                      onMomentumScrollEnd: onMomentumScrollHorizontal,
                    }}
                    showsVerticalScrollIndicator={false}
                    testID="schedule__scheduled-events"
                  />
                ) : null}
              </ScrollView>
            </View>
          </View>
        </View>

        <SafeAreaView style={styles.screenBottom} edges={['right', 'left', 'bottom']}>
          <View style={styles.noteContainer}>
            <Pressable
              style={/* istanbul ignore next */ ({ pressed }) => (pressed ? globalStyles.touchOpacity : {})}
              onPress={goToNotes}
              testID="schedule__note-counter">
              <Text style={styles.noteText}>{noteTitle}</Text>
            </Pressable>
          </View>
        </SafeAreaView>

        <LoadingOverlay isLoading={shouldDisplayLoading} />

        {isAndroid() ? (
          <FloatingActionButton
            testId={'schedule__add-event-button'}
            animated={true}
            show={showFabButton}
            icon={icnAddEventFab}
            buttonStyle={[globalStyles.fabButtonStyle, styles.fabButtonPosition]}
            buttonTouchStyle={globalStyles.fabButtonTouchStyle}
            iconStyle={styles.modalIconStyle}
            onPress={goToManageEvent}
          />
        ) : null}
      </View>
    </SafeAreaView>
  );
};

ScheduleScreen.propTypes = {
  navigation: navigationShape.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      reloadData: PropTypes.bool,
      routeToGoBackTo: PropTypes.string,
    }),
  }),
};

const styles = StyleSheet.create({
  page: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
  fabButtonPosition: {
    right: 15,
    bottom: 60,
  },
  floatingBlocks: {
    height: '100%',
  },
  floatingLeftPanel: {
    position: 'absolute',
    zIndex: 3,
    top: 0,
    left: 0,
  },
  modalIconStyle: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
    tintColor: COLORS.warmGrey,
  },
  noteContainer: {
    justifyContent: 'center',
    height: 50,
    paddingLeft: 20,
    backgroundColor: COLORS.whiteFour,
  },
  noteText: {
    paddingVertical: 10,
    fontFamily: FONTS.notoSansBold,
    fontSize: 14,
    color: COLORS.greyishBrown,
  },
  row: {
    flexDirection: 'row',
    paddingLeft: ScheduleConstants.DEFAULT_BLOCK_PANEL_WIDTH,
  },
  topRow: {
    flexDirection: 'row',
    height: ScheduleConstants.TOP_LABEL_HEIGHT + 2,
    paddingLeft: ScheduleConstants.DEFAULT_BLOCK_PANEL_WIDTH,
  },
  screenBottom: {
    backgroundColor: COLORS.whiteFour,
  },
  scheduleContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: COLORS.white,
  },
});

export default ScheduleScreen;
