import { gql } from '@apollo/client';

export const TENSION_BLOCKS = gql`
  query tensionBlocks($siteId: ID!, $blockSorting: blockSort, $filters: blockFilters) {
    viewer {
      site(id: $siteId) {
        id
        blocks(sortBy: $blockSorting, filters: $filters) {
          id
          name
          alias
          order
          capabilities {
            tension {
              status
              blueBandIndex {
                dry
                good
                wet
              }
              readings {
                id
                depth
                unit
                value
              }
            }
          }
          lastUpdated
        }
      }
    }
  }
`;
