import { StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';
import COLORS from '../../colors';
import FONTS from '../../fonts';

const HeaderText = ({ text }) => {
  return <Text style={styles.headerText}>{text}</Text>;
};

HeaderText.propTypes = {
  text: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  headerText: {
    marginTop: 40,
    textAlign: 'center',
    fontFamily: FONTS.notoSans,
    fontSize: 18,
    letterSpacing: 1.3,
    color: COLORS.white,
  },
});

export default HeaderText;
