import { gql } from '@apollo/client';

export const BLOCK_DATASTREAMS = gql`
  query blockDataStream($siteId: ID!, $block: ID!, $dataStreamTypes: [dataStreamType]) {
    viewer {
      site(id: $siteId) {
        block(id: $block) {
          id
          alias
          name
          lastUpdated
          eventProducers(eventTypes: [IRRIGATION]) {
            id
            color
            description
            subSensorType
          }
          capabilities {
            tension {
              status
              optimalTension {
                maximum
                minimum
                unit
              }
              readings {
                id
                colorCode
                depth
                depthUnit
                unit
                value
                dataStream {
                  id
                  unit
                  value
                  minimumValue
                  maximumValue
                  dataStreamType
                  specifiedRange {
                    maximum
                  }
                }
              }
            }
            irrigation {
              isIrrigating
            }
            flowStation {
              isFlowing
              status
              flowmeters {
                description
                isFlowing
                totalVolume {
                  unit
                  value
                }
                outflow {
                  unit
                  value
                  dataStream {
                    dataStreamType
                    id
                    isVirtual
                    lastUpdated
                    unit
                    value
                  }
                }
              }
            }
            temperature {
              status
            }
          }
          sensors {
            dataStreams(dataStreamTypes: $dataStreamTypes) {
              id
              unit
              value
              minimumValue
              maximumValue
              dataStreamType
              isVirtual
              description
            }
          }
        }
      }
    }
  }
`;
