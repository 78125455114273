// Queries
import { fetchAlerts } from '../queries/alerts';

//services
import { sortAlerts } from '../screens/alerts/sorting';
import { executeApolloQuery } from './apolloHelper';

export const alerts = {
  state: {
    alerts: [],
    sortedAlerts: [],
  },

  reducers: {
    updateAlerts(state, dataAlerts) {
      return { ...state, alerts: dataAlerts };
    },

    updateSortedAlerts(state, sortedAlerts) {
      return { ...state, sortedAlerts };
    },
  },

  effects: (dispatch) => ({
    async loadAlerts(payload, rootState) {
      await executeApolloQuery(
        dispatch,
        async () => await fetchAlerts(rootState.site.currentSite.id),
        (data) => {
          dispatch.alerts.updateAlerts(data.alerts);
          dispatch.alerts.updateSortedAlerts(data.alerts);
        },
      );
    },

    async sortAlerts(payload, rootState) {
      const { column, order } = payload;
      const alertsDevices = [...rootState.alerts.alerts];
      sortAlerts(alertsDevices, column, order);
      dispatch.alerts.updateSortedAlerts(alertsDevices);
    },

    async reset() {
      dispatch.alerts.updateAlerts([]);
      dispatch.alerts.updateSortedAlerts([]);
    },
  }),
};
