import { Image, StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';

// components
import Irrigation from '../../block/Irrigation';

// utils
import { findEventIcon } from '../../../models/calendarType';

const EventItemIcon = ({ eventType, isEventActive, animatedOpacity }) => {
  const eventIcon = findEventIcon(eventType, false);

  return (
    <>
      {isEventActive ? (
        <Irrigation style={styles.irrigationSection} irrigating={true} animatedOpacity={animatedOpacity} />
      ) : (
        <View style={styles.imageContainer}>
          <Image source={eventIcon} style={styles.eventIcon} />
        </View>
      )}
    </>
  );
};

EventItemIcon.propTypes = {
  eventType: PropTypes.string,
  isEventActive: PropTypes.bool,
  animatedOpacity: PropTypes.shape({
    value: PropTypes.number.isRequired,
  }),
};

const styles = StyleSheet.create({
  eventIcon: {
    width: 20,
    height: 20,
    marginRight: 2,
    marginLeft: 5,
    resizeMode: 'contain',
  },
  imageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 65,
    height: 33,
    marginRight: 2,
  },
  irrigationSection: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 65,
  },
});

export default EventItemIcon;
