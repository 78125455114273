import { navigationShape } from '../../shapes/navigation';

// Screen
import * as LocalizedImages from '../../i18n/LocalizedImages';
import PreviewScreen from './PreviewScreen';

const ReportPreviewScreen = ({ navigation }) => {
  return (
    <PreviewScreen
      navigation={navigation}
      screenName={'waterreport_preview_screen'}
      testId={'waterreport__preview__screen__'}
      localizedImages={LocalizedImages.WATER_REPORT_PREVIEW}
    />
  );
};

ReportPreviewScreen.propTypes = {
  navigation: navigationShape.isRequired,
};

export default ReportPreviewScreen;
