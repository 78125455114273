import { gql } from '@apollo/client';

export const PERMISSIONS = gql`
  query User($siteId: ID!) {
    viewer {
      user {
        id
        permissions(siteId: $siteId) {
          resource {
            id
            type
          }
          action
        }
      }
    }
  }
`;
