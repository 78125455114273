import { memo } from 'react';
import { Pressable, View, StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// proptype
import { navigationShape } from '../../shapes/navigation';
import { styleShape } from '../../shapes/style';

// constants
import COLORS from '../../colors';
import ROUTES from '../../navigation/routes';
import * as BlockType from '../../models/blockType';
import * as TABS from '../blockDetails/BlockDetailsTabs';
import { CAPABILITIES } from '../../models/capability';

// services
import ANALYTICS from '../../services/AnalyticsEvents';

// presenters
import { MapDisplayMode } from '../../presenters/MapDisplayMode';
import MapItemAliasPresenter from '../../presenters/blockAlias/MapItemAliasPresenter';

// entity
import MapItemEntity from '../../models/entities/mapItemEntity';

// hooks
import useCarouselHeight from '../../hooks/useCarouselHeight';

// components
import GraphHeader from '../../components/graph/GraphHeader';
import WeatherStationInformations from '../../components/weatherStation/WeatherStationInformations';
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import LoadingOverlay from '../../components/LoadingOverlay';
import Graph from '../../components/graph/Graph';
import AutomationMapCard from '../../components/automation/AutomationMapCard';
import MapBlockFooter from './MapBlockDetailFooter';

// services
import { getSelectedStreamsAndUnit } from './MapHelpers';

const MapItemDetail = ({
  xAxisStartDate,
  loadingGraph = false,
  item,
  mapMode,
  slideStyle,
  slideContainerStyle,
  navigation,
  showTensionForecast,
}) => {
  const carouseHeight = useCarouselHeight();
  const analyticsService = useAnalyticsContext();
  const dispatch = useDispatch();
  const blockAliasPresenter = new MapItemAliasPresenter(item, mapMode);
  const graphPresenter = item.getGraphPresenter();
  const mapDataType = graphPresenter?.dataType;

  const blockNavigationProps = {
    capability: CAPABILITIES[mapMode],
    routeToGoBackTo: ROUTES.MAP,
    block: item.block,
    displayTabs: true,
  };

  const onClick = (isHeaderPress) => {
    // TODO: Do nothing for now
    if (item.automation) {
      return;
    }

    if (mapMode === MapDisplayMode.TENSION && item.type() === BlockType.FLOW_STATION) {
      goToGraph({ displayTabs: false });
    } else if (mapMode === MapDisplayMode.TEMPERATURE && item.type() === BlockType.WEATHER) {
      goToWeather();
    } else if (isHeaderPress) {
      goToBlockDetail();
    } else {
      goToGraph();
    }
  };

  const goToBlockDetail = () => {
    const { defaultSelectedStreamIds, unit } = getSelectedStreamsAndUnit(mapMode, item.block, false);

    analyticsService.trackNavigationEvent(ANALYTICS.eventViewBlockDetail);
    dispatch.blocks.loadIrrigationBlockById({ blockId: item.id() });
    dispatch.blocks.updateIrrigationBlockEntity(undefined);

    navigation.navigate(ROUTES.BLOCK_DETAILS, {
      ...blockNavigationProps,
      activeTab: TABS.DETAILS,
      defaultSelectedStreamIds,
      unit,
    });
  };

  const goToGraph = ({ displayTabs } = { displayTabs: true }) => {
    const { defaultSelectedStreamIds, unit } = getSelectedStreamsAndUnit(mapMode, item.block);

    analyticsService.trackNavigationEvent(ANALYTICS.eventViewGraph);
    dispatch.blocks.loadIrrigationBlockById({ blockId: item.id() });
    dispatch.blocks.updateIrrigationBlockEntity(undefined);

    navigation.navigate(ROUTES.BLOCK_DETAILS, {
      ...blockNavigationProps,
      activeTab: TABS.GRAPH,
      defaultSelectedStreamIds,
      displayTabs,
      unit,
    });
  };

  const goToWeather = () => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewWeather);
    dispatch.graph.reset();

    navigation.navigate(ROUTES.WEATHER, {
      routeToGoBackTo: ROUTES.MAP,
      initialWeatherStationId: item.id(),
    });
  };

  const onHeaderPress = () => {
    onClick(true);
  };

  const onBodyPress = () => {
    onClick(false);
  };

  const conditionallyRenderBody = () => {
    if (item.automation) {
      return (
        <AutomationMapCard
          irrigatingDates={item.automation.currentExecution}
          sensors={item.readings()}
          entity={item.automation}
        />
      );
    }

    if (mapMode === MapDisplayMode.TEMPERATURE && item.block.hasWeatherStationData()) {
      const topPadding = 30 + (carouseHeight - 285) * 0.5;

      return (
        <WeatherStationInformations
          onClick={goToWeather}
          block={item.block}
          paddingTop={topPadding}
          testId="map-item-detail__weather-station-informations"
        />
      );
    }

    return (
      <View style={styles.graphContainer}>
        {!loadingGraph && (
          <>
            <Pressable style={styles.graph} onPress={onBodyPress} testID={'map-item-detail__graph'}>
              {graphPresenter ? (
                <Graph
                  graphPresenter={graphPresenter}
                  xAxisMinimum={xAxisStartDate}
                  isPortrait={true}
                  isMini={true}
                  testId={`map__itemdetails__graph__${item.id()}`}
                  showTensionForecast={showTensionForecast}
                />
              ) : null}
            </Pressable>
            <MapBlockFooter mapDataType={mapDataType} data={graphPresenter?.legendStreams} />
          </>
        )}
        <LoadingOverlay isLoading={loadingGraph} handleTouchEvents={false} />
      </View>
    );
  };

  return (
    <View style={[styles.panel, slideStyle]}>
      <View style={[styles.panelInnerContainer, slideContainerStyle]}>
        <GraphHeader onAliasPress={onHeaderPress} onRowPress={onHeaderPress} blockAliasPresenter={blockAliasPresenter} />
        {conditionallyRenderBody()}
      </View>
    </View>
  );
};

MapItemDetail.propTypes = {
  item: PropTypes.instanceOf(MapItemEntity).isRequired,
  mapMode: PropTypes.string.isRequired,
  slideStyle: styleShape,
  slideContainerStyle: styleShape,
  navigation: navigationShape.isRequired,
  loadingGraph: PropTypes.bool,
  xAxisStartDate: PropTypes.number,
  showTensionForecast: PropTypes.bool,
};

const styles = StyleSheet.create({
  graph: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
  },
  graphContainer: {
    flex: 1,
  },
  panel: {
    flex: 1,
    backgroundColor: COLORS.transparent,
  },
  panelInnerContainer: {
    height: '100%',
    backgroundColor: COLORS.white,
  },
});

/* istanbul ignore next */
function areEqual(prev, next) {
  return (
    prev.item.id() === next.item.id() &&
    prev.loadingGraph === next.loadingGraph &&
    prev.item.lastUpdated() === next.item.lastUpdated() &&
    prev.mapMode === next.mapMode
  );
}

export default memo(MapItemDetail, areEqual);
