import PropTypes from 'prop-types';
import { StyleSheet, View, Text } from 'react-native';

// services
import COLORS from '../../colors';
import FONTS from '../../fonts';

// constants
import { getGroupItemWidth, ScheduleConstants } from '../../screens/schedule/ScheduleConstants';

// components
import Block from './Block';

const BLOCK_GROUP_NAME_PADDING = 10;

const BlockGroup = ({ expanded, group, groupIndex, blocks }) => {
  const blockGroupHeight = ScheduleConstants.CELL_HEIGHT * blocks.length;
  const blockGroupBandColor = groupIndex % 2 === 0 ? COLORS.blueGrey : COLORS.seafoamBlue;
  const blockGroupNameColor = groupIndex % 2 === 0 ? COLORS.lightBlueGrey : COLORS.paleAqua;
  const blockGroupNameWidth = blockGroupHeight - BLOCK_GROUP_NAME_PADDING;

  return (
    <View style={[styles.blockGroupContainer, { width: getGroupItemWidth(expanded) }]}>
      {expanded && (
        <View style={[styles.blockGroupItem, { height: blockGroupHeight }]}>
          <View
            style={[styles.blockGroupBand, { backgroundColor: blockGroupBandColor }]}
            testID={`schedule__expanded-group-${groupIndex}-band-item`}
          />
          <View
            style={[styles.blockGroupName, { backgroundColor: blockGroupNameColor }]}
            testID={`schedule__expanded-group-${groupIndex}-name-item`}>
            <Text style={[styles.blockGroupNameText, { width: blockGroupNameWidth }]} ellipsizeMode="middle" numberOfLines={1}>
              {group.name}
            </Text>
          </View>
          <View style={styles.blockListItem}>
            {blocks.map((block, index) => (
              <Block
                block={block}
                expanded={expanded}
                key={`label-${block.id}`}
                testId={`schedule__expanded-group-${groupIndex}-block-item-${index}`}
              />
            ))}
          </View>
        </View>
      )}
      {!expanded &&
        blocks.map((block, index) => (
          <View style={styles.blockGroupItem} key={`label-${block.id}`}>
            <View
              style={[styles.blockGroupBand, { backgroundColor: blockGroupBandColor }]}
              testID={`schedule__group-${groupIndex}-band-item-${index}`}
            />
            <Block
              block={block}
              expanded={expanded}
              key={`label-${block.id}`}
              testId={`schedule__group-${groupIndex}-block-item-${index}`}
            />
          </View>
        ))}
    </View>
  );
};

BlockGroup.propTypes = {
  blocks: PropTypes.array.isRequired,
  expanded: PropTypes.bool.isRequired,
  group: PropTypes.object.isRequired,
  groupIndex: PropTypes.number.isRequired,
};

const styles = StyleSheet.create({
  blockGroupContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.whiteTwo,
  },
  blockGroupItem: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: ScheduleConstants.CELL_HEIGHT,
    borderColor: ScheduleConstants.BORDER_COLOR,
    borderBottomWidth: 1,
  },
  blockGroupBand: {
    width: 5,
    height: '100%',
  },
  blockGroupName: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 45,
    height: '100%',
  },
  blockGroupNameText: {
    transform: [{ rotate: '270deg' }],
    textAlign: 'center',
    fontFamily: FONTS.notoSansBold,
    fontSize: 11,
    color: COLORS.greyishBrown,
    textAlignVertical: 'center',
  },
  blockListItem: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 143,
    height: '100%',
  },
});

export default BlockGroup;
