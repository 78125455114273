import { memo } from 'react';
import { RefreshControl, ScrollView, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

// constants
import COLORS from '../colors';

// styles
import { styleShape } from '../shapes/style';

// components
import { imageSourceShape } from '../shapes/imageSource';
import EmptyView from './EmptyView';

const EmptyViewWithRefresh = ({ icon, iconSizeStyle, textKey, onRefresh, testId }) => {
  return (
    <ScrollView
      testID={testId}
      contentContainerStyle={styles.scrollViewContainer}
      refreshControl={<RefreshControl refreshing={false} onRefresh={onRefresh} />}>
      <EmptyView icon={icon} iconSizeStyle={iconSizeStyle} textKey={textKey} />
    </ScrollView>
  );
};

EmptyViewWithRefresh.propTypes = {
  icon: imageSourceShape.isRequired,
  iconSizeStyle: styleShape.isRequired,
  textKey: PropTypes.string.isRequired,
  onRefresh: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  scrollViewContainer: {
    flex: 0.8,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.greyish20,
  },
});

export default memo(EmptyViewWithRefresh);
