import { Image, StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

// hooks
import { useTranslation } from '../hooks/useTranslation';

// icons
import icnNoData from '../assets/icons/icnNoData.png';

// constants
import FONTS from '../fonts';
import COLORS from '../colors';
import OPACITIES from '../opacities';

const NoDataView = ({ message }) => {
  const { t } = useTranslation();
  return (
    <View style={[styles.noDataContainer, styles.containerColor]}>
      <Image source={icnNoData} style={styles.noDataImage} />
      <View style={styles.noDataTextContainer}>
        <Text style={styles.noDataText}>{message ? message : t('no_data_view')}</Text>
      </View>
    </View>
  );
};

NoDataView.propTypes = {
  message: PropTypes.string,
};

const styles = StyleSheet.create({
  noDataContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  noDataImage: {
    alignSelf: 'center',
    width: 66,
    height: 51,
    marginBottom: 10,
  },
  noDataText: {
    paddingHorizontal: 5,
    textAlign: 'center',
    fontFamily: FONTS.firaSansItalic,
    fontSize: 14,
    color: COLORS.greyishBrown,
  },
  noDataTextContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    width: 160,
    height: 60,
    borderRadius: 2,
    marginTop: 10,
    backgroundColor: COLORS.white,
    elevation: 3,
    shadowColor: COLORS.warmGrey,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: OPACITIES.shadow,
    shadowRadius: 4,
  },
});

export default NoDataView;
