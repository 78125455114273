import { memo } from 'react';
import { StyleSheet, View, Text, Pressable } from 'react-native';
import PropTypes from 'prop-types';

// constants
import FONTS from '../../../fonts';
import COLORS from '../../../colors';

// hooks
import { useTranslation } from '../../../hooks/useTranslation';

// components
import Divider from '../../Divider';

const AutomationConfirmModal = ({ onCancel, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <>
      <Divider style={styles.buttonHorizontalDivider} />

      <View style={styles.buttonContainer}>
        <View style={styles.button}>
          <Pressable onPress={onCancel}>
            <Text style={styles.buttonText}>{t('automation_question_answer_no')}</Text>
          </Pressable>
        </View>

        <View style={styles.buttonVerticalDivider} />

        <View style={styles.button}>
          <Pressable onPress={onConfirm}>
            <Text style={styles.buttonText}>{t('automation_question_answer_yes')}</Text>
          </Pressable>
        </View>
      </View>
    </>
  );
};

AutomationConfirmModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    overflow: 'hidden',
    height: 48,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
  },
  buttonHorizontalDivider: {
    height: 1,
    backgroundColor: COLORS.greyBorderColor,
  },
  buttonVerticalDivider: {
    width: 1,
    backgroundColor: COLORS.greyBorderColor,
  },
  buttonText: {
    textAlign: 'center',
    fontFamily: FONTS.notoSans,
    fontSize: 17,
    color: COLORS.greyishBrown,
    textAlignVertical: 'center',
  },
  button: {
    flexGrow: 1,
    justifyContent: 'center',
    height: '100%',
  },
});

export default memo(AutomationConfirmModal);
