import propTypes from 'prop-types';
import { Text, View, ScrollView, StyleSheet } from 'react-native';
import { FlashList } from '@shopify/flash-list';

// Styles and Colors
import COLORS from '../../colors';
import FONTS from '../../fonts';

const Terminal = ({ lines }) => {
  const renderItem = ({ item }) => <Text style={styles.terminalText}>{item}</Text>;
  return (
    <View style={styles.terminal}>
      <ScrollView horizontal={true} indicatorStyle="white" contentContainerStyle={styles.container}>
        <FlashList data={lines} renderItem={renderItem} estimatedItemSize={11} inverted />
      </ScrollView>
    </View>
  );
};

Terminal.propTypes = {
  lines: propTypes.array.isRequired,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  terminal: {
    flex: 1,
    padding: 8.5,
    backgroundColor: COLORS.black,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  terminalText: {
    fontFamily: FONTS.monospace,
    fontSize: 11,
    color: COLORS.greenTrue,
  },
});

export default Terminal;
