import { gql } from '@apollo/client';

export const ALERTS = gql`
  query AlertSummaries($siteId: ID!) {
    viewer {
      site(id: $siteId) {
        id
        alertSummaries {
          id
          name
          criticality
          blockCount
          responderCount
          status
        }
      }
    }
  }
`;
