import { gql } from '@apollo/client';

export const SITE_WEATHER_DATA_STREAMS = gql`
  query weatherDataStreams($siteId: ID!, $dataStreamTypes: [dataStreamType]) {
    viewer {
      site(id: $siteId) {
        id
        blocks(filters: { capabilities: [WEATHER_STATION] }) {
          sensors {
            dataStreams(dataStreamTypes: $dataStreamTypes) {
              id
              dataStreamType
              value
              unit
              isVirtual
              description
            }
          }
        }
      }
    }
  }
`;
