import { gql } from '@apollo/client';

export const DASHBOARD = gql`
  query dashboard($site: ID!, $startDate: String!, $endDate: String!) {
    viewer {
      site(id: $site) {
        id
        modules
        criticalTemperature {
          status
          protectionState
          unit
          value
        }
        blueBandIndex {
          dry
          good
          wet
        }
        blocks(filters: { capabilities: [TENSION, IRRIGATION] }) {
          id
          alias
          name
          capabilities {
            tension {
              status
              readings {
                id
                depth
                unit
                value
              }
            }
            irrigation {
              isIrrigating
            }
            temperature {
              status
              protectionState
            }
          }
          calendars(startDate: $startDate, endDate: $endDate, calendarTypes: [PLANNED], eventTypes: [IRRIGATION]) {
            type
            events {
              type
              startDate
              endDate
            }
          }
          schedule {
            scheduleEvents(startDate: $startDate, endDate: $endDate) {
              type
              startDate
              endDate
            }
          }
        }
      }
    }
  }
`;
