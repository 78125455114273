import { memo } from 'react';
import PropTypes from 'prop-types';
import { Image, StyleSheet, Text, View } from 'react-native';

// shape
import { styleShape } from '../shapes/style';

// services
import COLORS from '../colors';
import FONTS from '../fonts';
import { useTranslation } from '../hooks/useTranslation';
import { imageSourceShape } from '../shapes/imageSource';

const EmptySectionView = ({ icon, iconSizeStyle, backColor, iconColor, textColor, textKey }) => {
  const { t } = useTranslation();

  return (
    <View style={[styles.emptySectionContainer, backColor]} testID="empty-section__container">
      <Image source={icon} style={[styles.emptySectionIcon, iconSizeStyle, iconColor]} testID="empty-section__icon" />
      <Text style={[styles.emptySectionText, textColor]} testID="empty-section__text">
        {t(textKey)}
      </Text>
    </View>
  );
};

EmptySectionView.propTypes = {
  backColor: styleShape,
  style: styleShape,
  icon: imageSourceShape.isRequired,
  iconColor: styleShape,
  iconSizeStyle: styleShape.isRequired,
  textColor: styleShape,
  textKey: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  emptySectionContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 52,
    backgroundColor: COLORS.whiteTwo,
  },
  emptySectionIcon: {
    alignSelf: 'center',
    marginHorizontal: 11,
    tintColor: COLORS.blueishGrey,
  },
  emptySectionText: {
    width: 'auto',
    textAlign: 'left',
    fontFamily: FONTS.firaSansItalic,
    fontSize: 13,
    lineHeight: 16,
    color: COLORS.greyishBrown,
    flexShrink: 1,
    textAlignVertical: 'center',
  },
});

export default memo(EmptySectionView);
