import { gql } from '@apollo/client';

export const SITE = gql`
  query site($siteId: ID!) {
    viewer {
      site(id: $siteId) {
        id
        name
        modules
        isAutomated
        timezone
        cropName
        groupName
        defaultTimeScaleInHours
        supportsTensionForecast

        capabilities {
          automationIsIrrigationSchedulable
        }

        eventConstraints {
          automatedEventConstraints {
            maximumDurationInMinutes
            minimumAllowedStartDelayFromNowInMinutes
            minimumSafeStartDelayFromNowInMinutes
          }
        }
      }
    }
  }
`;
