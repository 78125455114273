import { StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

// service
import { isWeb } from '../../services/PlatformService';

// styles
import { globalStyles, MINIMUM_FONT_SCALE } from '../../styles';

const Title = ({ value, type, color, testId }) => {
  const titleStyle = color ? [globalStyles[type], { color }] : globalStyles[type];

  return (
    <View style={styles.container}>
      <Text
        style={titleStyle}
        adjustsFontSizeToFit
        minimumFontScale={MINIMUM_FONT_SCALE}
        numberOfLines={isWeb() ? 0 : 1}
        testID={testId}>
        {value}
      </Text>
    </View>
  );
};

Title.propTypes = {
  value: PropTypes.string,
  type: PropTypes.oneOf(['title', 'subtitle']).isRequired,
  color: PropTypes.string,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default Title;
