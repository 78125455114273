import { gql } from '@apollo/client';

export const USER = gql`
  {
    viewer {
      user {
        id
        firstname
        lastname
        username
        email
        company
        roles {
          id
          name
        }
        userAccount {
          number
          name
          isHortauStaff
        }
        defaultSite
      }
    }
  }
`;
