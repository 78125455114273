import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';

// services
import { useTranslation } from '../../hooks/useTranslation';

//model
import NoteEntity from '../../models/entities/noteEntity';

// components
import NoteHeader from './NoteHeader';
import NoteItem from './NoteItem';

const GeneralNotes = ({ notes, onNotePress, testId }) => {
  const { t } = useTranslation();

  if (!notes || !notes.length) {
    return null;
  }
  return (
    <>
      <View style={styles.container} testID={testId}>
        <NoteHeader title={t('general_note')} testId="general-notes-header" />
        {notes.map((note) => (
          <NoteItem note={new NoteEntity(note)} onNotePress={onNotePress} key={note.id} testId={`gen-note${note.id}`} />
        ))}
      </View>
    </>
  );
};

GeneralNotes.propTypes = {
  onNotePress: PropTypes.func.isRequired,
  notes: PropTypes.array.isRequired,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 10,
    marginVertical: 10,
  },
});

export default GeneralNotes;
