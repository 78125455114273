/* istanbul ignore file */
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import fetch from 'cross-fetch';
import Config from 'react-native-config';
import { consoleWarn } from '../utils/loggerUtil';

// services
import { isDebug } from '../services/EnvironmentService';

// constants
import { GRAPHQL_RETRY_MAX_ATTEMPTS, GRAPHQL_VERSION_V2 } from '../consts';

let apolloClient;

const extractTokenFromState = (storeToUse, headers) => {
  const state = storeToUse.getState();
  if (state.authentication.token) {
    const token = state.authentication.token.accessToken;
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        'x-partner-id': Config.X_PARTNER_ID || '0',
      },
    };
  }
};

const retryIf = (error, _operation) => {
  return error.statusCode === undefined;
};

const httpLink = new HttpLink({
  uri: `${Config.GRAPHQL_SERVER}/graphql`,
  fetch,
});

const httpLinkV2 = new HttpLink({
  uri: `${Config.GRAPHQL_SERVER_V2}/graphql`,
  fetch,
});

const retryLink = new RetryLink({
  attempts: {
    max: GRAPHQL_RETRY_MAX_ATTEMPTS,
    retryIf,
  },
});

const initApolloClient = (store) => {
  const addAuthorizationToken = setContext((_, { headers }) => extractTokenFromState(store, headers));

  const linkError = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        consoleWarn(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
      );
    }

    if (networkError) {
      consoleWarn(`[Network error]: ${networkError}`);
    }
  });

  const link = ApolloLink.from([addAuthorizationToken, linkError, retryLink, httpLink]);
  const linkV2 = ApolloLink.from([addAuthorizationToken, linkError, retryLink, httpLinkV2]);
  const links = ApolloLink.split((operation) => operation.getContext().version === GRAPHQL_VERSION_V2, linkV2, link);

  apolloClient = new ApolloClient({
    cache: new InMemoryCache({ addTypename: false }),
    link: links,
    connectToDevTools: isDebug(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });
  return apolloClient;
};

const getApolloClient = () => apolloClient;

export { initApolloClient, getApolloClient, extractTokenFromState };
