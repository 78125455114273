import { Alert } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

// services
import { navigationShape } from '../../shapes/navigation';
import ROUTES from '../../navigation/routes';
import { useTranslation } from '../../hooks/useTranslation';

// screens
import ForgotForm from './ForgotForm';

const ForgotUsernameScreen = ({ navigation }) => {
  const loading = useSelector((state) => !!state.loading.effects.authentication.sendForgotUsernameRequest);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = async ({ email }) => {
    await dispatch.authentication.sendForgotUsernameRequest({ email, lang: i18n.language });
    Alert.alert(t('forgot_username_success_title'), t('forgot_username_success_message'), [
      { text: t('generic_button_close'), onPress: () => navigation.navigate(ROUTES.SIGN_IN) },
    ]);
  };

  return (
    <ForgotForm
      onSubmit={(email) => onSubmit({ email })}
      loading={loading}
      placeholder={t('email_placeholder')}
      headerText={t('forgot_username_title')}
      subHeaderText={t('forgot_username_subtitle')}
      navigation={navigation}
    />
  );
};

ForgotUsernameScreen.propTypes = {
  navigation: navigationShape.isRequired,
};

export default ForgotUsernameScreen;
