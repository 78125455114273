import { Pressable, StyleSheet, Text, View } from 'react-native';
import * as PropTypes from 'prop-types';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

const RowSection = ({ children, onPress, title }) => {
  const renderContent = () => {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>{title}</Text>

        <View style={styles.childContainer}>{children}</View>
      </View>
    );
  };

  return (
    <>
      {onPress ? (
        <Pressable onPress={onPress} testID={'row-section__touchable'}>
          {renderContent()}
        </Pressable>
      ) : (
        renderContent()
      )}
    </>
  );
};

RowSection.propTypes = {
  children: PropTypes.node,
  onPress: PropTypes.func,
  title: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  childContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 11,
  },
  text: {
    fontFamily: FONTS.firaSans,
    fontSize: 17,
    color: COLORS.greyishBrown,
  },
});

export default RowSection;
