import PropTypes from 'prop-types';
import { StyleSheet, View, Text } from 'react-native';

//entity
import IrrigationEvent from '../../../models/entities/irrigationEvent';

//services
import { useTranslation } from '../../../hooks/useTranslation';

//constants
import FONTS from '../../../fonts';
import COLORS from '../../../colors';

//components
import EventItem from '../../event/eventItem/EventItem';

const IrrigationActivity = ({ event, titleText, isDateVisible = true, animatedOpacity }) => {
  const { t } = useTranslation();
  const eventEntity = new IrrigationEvent(event);

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <Text style={styles.titleText}>{titleText}</Text>
      </View>
      {isDateVisible && (
        <View style={styles.dateContainer}>
          <Text style={styles.dateText}>{eventEntity.startDate.format(t('date_format_month_day_year'))}</Text>
        </View>
      )}
      <View style={styles.eventItemContainer}>
        <EventItem event={eventEntity} editingDisabled={true} animatedOpacity={animatedOpacity} />
      </View>
    </View>
  );
};

IrrigationActivity.propTypes = {
  event: PropTypes.object.isRequired,
  isDateVisible: PropTypes.bool,
  titleText: PropTypes.string.isRequired,
  animatedOpacity: PropTypes.shape({
    value: PropTypes.number.isRequired,
  }),
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  dateContainer: {
    justifyContent: 'center',
    paddingVertical: 5,
    paddingLeft: 12,
    borderRadius: 2,
    marginHorizontal: 16,
    marginTop: 10,
    backgroundColor: COLORS.whiteTwo,
  },
  dateText: {
    fontFamily: FONTS.firaSansMedium,
    fontSize: 12,
    letterSpacing: 1,
    color: COLORS.greyishBrown,
  },
  eventItemContainer: {
    paddingHorizontal: 10,
    paddingVertical: 6,
    marginBottom: 5,
  },
  titleContainer: {
    marginTop: 10,
    marginLeft: 18,
  },
  titleText: {
    fontFamily: FONTS.firaSansMedium,
    fontSize: 14,
    letterSpacing: 0,
    color: COLORS.darkSkyBlue,
  },
});

export default IrrigationActivity;
