import { useCallback, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SafeAreaView, View, StyleSheet, Text } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';

// services
import { navigationShape } from '../../shapes/navigation';
import { SORT_ORDER, toggleSort } from '../../utils/sorting';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

// styles
import { globalStyles } from '../../styles';

// components
import SkeletonPlaceholder from '../../components/skeletons/SkeletonPlaceholder';
import HeaderBar from '../../components/header/HeaderBar';
import SortableList from '../../components/SortableList';
import { SORT_COLUMNS } from './sorting';
import AlertItem from './AlertItem';
import SortableAlertsHeader from './SortableAlertsHeader';

const AlertsScreen = ({ navigation }) => {
  const loading = useSelector((state) => !!state.loading.effects.alerts.loadAlerts);
  const sortLoading = useSelector((state) => !!state.loading.effects.alerts.sortAlerts);
  const alerts = useSelector((state) => state.alerts.sortedAlerts);
  const currentSite = useSelector((state) => state.site.currentSite);
  const listRef = useRef();

  const initialSortOrderState = {
    [SORT_COLUMNS.LEVEL]: SORT_ORDER.NONE,
    [SORT_COLUMNS.NAME]: SORT_ORDER.NONE,
    [SORT_COLUMNS.STATUS]: SORT_ORDER.NONE,
  };

  const [refreshing, setRefreshing] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [sortedColumn, setSortedColumn] = useState(initialSortOrderState);
  const toggle = toggleSort(sortedColumn, setSortedColumn, initialSortOrderState);

  const dispatch = useDispatch();

  const emptyData = alerts !== undefined && !loading && !initLoading && !refreshing && alerts.length === 0;

  /* istanbul ignore next */
  const initialLoading = async () => {
    setInitLoading(true);
    await dispatch.alerts.loadAlerts();
    setSortedColumn({ ...initialSortOrderState });
    setInitLoading(false);
  };

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      initialLoading();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigation]),
  );

  useEffect(() => {
    return function cleanup() {
      setSortedColumn(initialSortOrderState);
      dispatch.alerts.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSite]);

  useEffect(() => {
    sortAlertsFromSortedColumn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedColumn]);

  const refreshAlerts = async () => {
    setRefreshing(true);
    await dispatch.alerts.loadAlerts();
    sortAlertsFromSortedColumn();
    setRefreshing(false);
  };

  const sortAlertsFromSortedColumn = () => {
    if (sortedColumn[SORT_COLUMNS.LEVEL] !== SORT_ORDER.NONE) {
      dispatch.alerts.sortAlerts({ column: SORT_COLUMNS.LEVEL, order: sortedColumn[SORT_COLUMNS.LEVEL] });
    } else if (sortedColumn[SORT_COLUMNS.NAME] !== SORT_ORDER.NONE) {
      dispatch.alerts.sortAlerts({ column: SORT_COLUMNS.NAME, order: sortedColumn[SORT_COLUMNS.NAME] });
    } else if (sortedColumn[SORT_COLUMNS.STATUS] !== SORT_ORDER.NONE) {
      dispatch.alerts.sortAlerts({ column: SORT_COLUMNS.STATUS, order: sortedColumn[SORT_COLUMNS.STATUS] });
    }
  };

  const toggleSortAndScrollToTop = (payload) => {
    /* istanbul ignore next */
    if (listRef.current && !loading) {
      listRef.current.scrollToOffset({ x: 0, y: 0, animated: false });
      toggle(payload);
    }
  };

  const renderAlertItem = (element) => {
    return (
      <AlertItem alert={element.item} keyIndex={element.index} navigation={navigation} />
    );
  };

  const renderList = () => {
    if (!emptyData) {
      return (
        <SortableList
          testID={'alerts__list'}
          ref={listRef}
          elements={alerts}
          loading={loading || initLoading}
          refreshing={refreshing}
          sort={dispatch.alerts.sortAlerts}
          renderEmptyView={renderSkeletonView}
          renderItem={renderAlertItem}
          screenName={'alerts'}
          onRefresh={refreshAlerts}
        />
      );
    } else {
      return (
        <View style={styles.emptyContainer} testID="alerts__empty-view">
          <Text style={styles.emptyText}>
            Aucune alerte disponible
          </Text>
      </View>
      );
    }
  };

  const renderSkeletonView = () => {
    return (
      <View testID="loadingActivity">
        {[...Array(5)].map((_e, i) => (
          <View style={styles.skeletonContainer} key={'row' + i}>
            <View style={styles.skeletonLeftSection}>
              <SkeletonPlaceholder width={24} height={24} style={styles.skeletonIcon} />
              <SkeletonPlaceholder width={160} height={15} style={styles.skeletonName} />
            </View>

            <View style={styles.skeletonRightSection}>
              <SkeletonPlaceholder width={80} height={15} />
            </View>
          </View>
        ))}
      </View>
    );
  };

  return (
    <>
      <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
        <View style={globalStyles.header}>
          <HeaderBar siteName={currentSite.name} screenName={'alerts'} testId={'alerts__header'} navigation={navigation} />
        </View>

      <View testID="alerts__subscreen-container" style={globalStyles.bottomContainer}>
        <SortableAlertsHeader sortedColumn={sortedColumn} toggle={toggleSortAndScrollToTop} loading={sortLoading} />
        {renderList()}
      </View>
      </SafeAreaView>
    </>
  );
};

const styles = StyleSheet.create({
  emptyContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyText: {
    textAlign: 'center',
    fontFamily: FONTS.notoSans,
    fontSize: 14,
    color: COLORS.greyishBrown,
  },
  skeletonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 20,
    borderColor: COLORS.greyish21,
    borderWidth: 1,
    borderRadius: 4,
    marginHorizontal: 10,
    marginVertical: 5,
    backgroundColor: COLORS.white,
  },
  skeletonLeftSection: {
    flex: 2,
    flexDirection: 'row',
    alignItems: 'center',
  },
  skeletonIcon: {
    marginRight: 15,
  },
  skeletonName: {
    marginLeft: 10,
  },
  skeletonRightSection: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});

AlertsScreen.propTypes = {
  navigation: navigationShape.isRequired,
};

export default AlertsScreen;
