import { View, StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';

//proptypes
import { navigationShape } from '../../shapes/navigation';

// services
import { useTranslation } from '../../hooks/useTranslation';
import ANALYTICS from '../../services/AnalyticsEvents';

//styles
import { globalStyles } from '../../styles';

// constant
import COLORS from '../../colors';
import OPACITIES from '../../opacities';
import FONTS from '../../fonts';
import ROUTES from '../../navigation/routes';

// icons
import icnAutomRules from '../../assets/icons/icnAutomRules.png';

// components
import Divider from '../../components/Divider';
import HeaderBar from '../../components/header/HeaderBar';
import SortableHeaderItem from '../../components/header/SortableHeaderItem';
import TouchIconButton from '../../components/button/TouchIconButton';
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import { SORT_COLUMNS } from './sorting';

const SortableAutomationHeader = ({
  enableAutomationRules = false,
  hasAutomationRules = false,
  hasRPMSubSensors = false,
  loading,
  navigation,
  siteName,
  sortedColumn,
  toggle,
}) => {
  const { t } = useTranslation();
  const analyticsService = useAnalyticsContext();

  const goToRules = () => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewAutomationRules);
    navigation.navigate(ROUTES.AUTOMATION_RULES, { routeToGoBackTo: ROUTES.AUTOMATION });
  };

  return (
    <View style={styles.container}>
      <View style={globalStyles.headerContainer}>
        <HeaderBar siteName={siteName} screenName={'automation'} style={globalStyles.title} navigation={navigation}>
          {hasAutomationRules ? (
            <TouchIconButton
              testId={'list__automation-rules-button'}
              onPress={goToRules}
              icon={icnAutomRules}
              iconStyle={styles.buttonRules}
            />
          ) : null}
        </HeaderBar>
      </View>

      <Divider style={styles.divider} />

      <View style={styles.bar}>
        <SortableHeaderItem
          style={styles.componentHeader}
          loading={loading}
          sortKey={SORT_COLUMNS.COMPONENT}
          sortOrder={sortedColumn.component}
          onPress={toggle}
          testID={'list__automation-header-item-component'}
          title={t('automation_component')}
        />

        <SortableHeaderItem
          style={styles.statusHeader}
          loading={loading}
          sortKey={SORT_COLUMNS.STATUS}
          sortOrder={sortedColumn.status}
          onPress={toggle}
          testID={'list__automation-header-item-status'}
          title={t('automation_status')}
        />

        {hasRPMSubSensors ? (
          <SortableHeaderItem
            style={styles.rpmHeader}
            loading={loading}
            sortKey={SORT_COLUMNS.RPM}
            sortOrder={sortedColumn.rpm}
            onPress={toggle}
            testID={'list__automation-header-item-rpm'}
            title={t('automation_rpm')}
          />
        ) : null}

        {enableAutomationRules ? (
          <Text style={styles.rulesHeader} testID={'list__automation-header-item-rules'}>
            {t('automation_rule_threshold')}
          </Text>
        ) : null}
      </View>

      <Divider style={styles.divider} />
    </View>
  );
};

SortableAutomationHeader.propTypes = {
  enableAutomationRules: PropTypes.bool,
  hasAutomationRules: PropTypes.bool,
  hasRPMSubSensors: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  navigation: navigationShape.isRequired,
  siteName: PropTypes.string.isRequired,
  sortedColumn: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  bar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
    paddingHorizontal: 11,
  },
  buttonRules: {
    width: 27,
    height: 27,
  },
  componentHeader: {
    width: '20%',
    marginLeft: '3%',
  },
  container: {
    zIndex: 2,
    height: 107,
    backgroundColor: COLORS.white,
    elevation: 3,
    shadowColor: COLORS.warmGrey,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: OPACITIES.shadow,
    shadowRadius: 2,
  },
  divider: {
    height: 1,
    backgroundColor: COLORS.lightGrey,
  },
  statusHeader: {
    width: '37%',
  },
  rpmHeader: {
    width: '20%',
  },
  rulesHeader: {
    width: '20%',
    textAlign: 'center',
    fontFamily: FONTS.firaSans,
    fontSize: 12,
    letterSpacing: 0.56,
    color: COLORS.greyishBrownTwo,
  },
});

export default SortableAutomationHeader;
