import { useEffect, useCallback, useState, memo } from 'react';
import { StyleSheet } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import Animated, { useAnimatedStyle, useSharedValue, withTiming, runOnJS } from 'react-native-reanimated';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

// proptype
import { navigationShape } from '../../shapes/navigation';

// utils
import { formatMoment, startOfDayHoursFromNow, momentToMilliseconds } from '../../utils/dateUtils';
import { maxDateWithForecast, maxDateAsMilliseconds } from '../../components/graph/DateChartConstants';

// hooks
import useCarouselHeight from '../../hooks/useCarouselHeight';

// services
import { MapDisplayMode } from '../../presenters/MapDisplayMode';
import { isNullOrUndefined } from '../../utils/util';
import { getSelectedReadingsAndUnit } from './MapHelpers';

// components
import ScrollableItemsDetails from './ScrollableItemsDetails';

const SLIDE_TOGGLE_ANIMATION_DURATION = 200;
const CAROUSEL_Y_ZERO = 0;
const ONE_WEEK_IN_HOURS = 168;
const ONE_DAY_IN_HOURS = 24;

const ItemsDetailsContainer = ({ presenters, onItemSnapped, carouselRef, show, activeBlockId, navigation }) => {
  const carouselHeight = useCarouselHeight();
  const verticalOffset = useSharedValue(carouselHeight);
  const animatedStyles = useAnimatedStyle(() => ({
    transform: [{ translateY: verticalOffset.value }],
    height: carouselHeight,
  }));
  const [firstTimeShow, setFirstTimeShow] = useState(false);

  const mapMode = useSelector((state) => state.map.mode);
  const currentSite = useSelector((state) => state.site.currentSite);
  const dispatch = useDispatch();
  const [xAxisStartDate, setXAxisStartDate] = useState();
  const loadingMapGraph = useSelector((state) => !!state.loading.effects.graph.loadMapGraph);
  const loadingTensionForecast = useSelector((state) => !!state.loading.effects.graph.loadTensionForecast);
  const loading = loadingMapGraph || loadingTensionForecast;
  const showTensionForecast = currentSite.supportsTensionForecast && mapMode === MapDisplayMode.TENSION;

  useEffect(() => {
    const startDate = momentToMilliseconds(getStartDate());
    setXAxisStartDate(startDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapMode, currentSite.timezone, activeBlockId]);

  // istanbul ignore next
  useFocusEffect(
    useCallback(() => {
      return () => {
        setFirstTimeShow(false);
      };
    }, []),
  );

  // istanbul ignore next
  useEffect(() => {
    if (firstTimeShow) {
      setFirstTimeShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapMode]);

  const [debouncedRefresh] = useDebouncedCallback(
    (presenter) => {
      refresh(presenter);
    },
    500,
    { leading: true },
  );

  const getStartDate = () => {
    if (mapMode === MapDisplayMode.TENSION) {
      return startOfDayHoursFromNow(ONE_WEEK_IN_HOURS);
    } else {
      return startOfDayHoursFromNow(ONE_DAY_IN_HOURS);
    }
  };

  const itemSnapped = (presenter) => {
    debouncedRefresh(presenter);
    onItemSnapped(presenter);
  };

  const refresh = async (presenter) => {
    /* istanbul ignore next */
    if (presenter === undefined || isNullOrUndefined(presenter.block)) {
      return;
    }

    const graphPresenter = presenter.getGraphPresenter();

    if (!graphPresenter) {
      const startDate = formatMoment(getStartDate());
      const endDate = formatMoment(moment().endOf('day'));
      const block = presenter.block;
      const { defaultSelectedStreamIds, unit } = getSelectedReadingsAndUnit(mapMode, block);

      dispatch.graph.setDefaultSelectedDataStreams({ left: { ids: defaultSelectedStreamIds, unit } });
      dispatch.blocks.updateSelectedBlock(block);

      dispatch.graph.loadMapGraph({
        siteId: currentSite.id,
        blockId: block.id,
        dataStreamIds: defaultSelectedStreamIds,
        startDate,
        endDate,
      });

      if (showTensionForecast && block.isTensionCapable()) {
        dispatch.graph.loadTensionForecast({
          siteId: currentSite.id,
          blockId: block.id,
          startDate: maxDateAsMilliseconds(),
          endDate: maxDateWithForecast(ONE_WEEK_IN_HOURS),
        });
      }
    }
  };

  useEffect(() => {
    // istanbul ignore next
    function animate(toValue, callback) {
      verticalOffset.value = withTiming(toValue, { duration: SLIDE_TOGGLE_ANIMATION_DURATION }, callback);
    }

    // This function is needed since the onItemSnapped callback is not fired on the first render of item at index 0 because the carousel internal currentIndex has not changed.
    // Removing this or changing the conditions can lead to two behaviors : double loading of graph and graph not showing for presenters at index 0 on first open.
    // istanbul ignore next
    function simulateSnapIfFirstOpen() {
      if (!firstTimeShow && presenters.length > 0) {
        const activeIndex = presenters.findIndex((presenter) => presenter.id() === activeBlockId);
        if (activeIndex === 0) {
          debouncedRefresh(presenters[0]);
        }

        setFirstTimeShow(true);
      }
    }

    if (show) {
      animate(CAROUSEL_Y_ZERO, runOnJS(simulateSnapIfFirstOpen)());
    } else {
      animate(carouselHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (show && activeBlockId && presenters.length > 0) {
      const presenter = presenters.find((p) => p.id() === activeBlockId);
      const block = presenter?.block;
      if (block) {
        dispatch.blocks.loadBlockDataStreams({ blockId: block.id });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presenters]);

  if (presenters.length === 0) {
    return null;
  }

  return (
    <Animated.View style={[styles.container, animatedStyles]}>
      <ScrollableItemsDetails
        xAxisStartDate={xAxisStartDate}
        loadingGraph={loading}
        presenters={presenters}
        onItemSnapped={itemSnapped}
        navigation={navigation}
        carouselRef={carouselRef}
        showTensionForecast={showTensionForecast}
      />
    </Animated.View>
  );
};

ItemsDetailsContainer.propTypes = {
  presenters: PropTypes.arrayOf(PropTypes.object).isRequired,
  carouselRef: PropTypes.any.isRequired,
  onItemSnapped: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  activeBlockId: PropTypes.string,
  navigation: navigationShape.isRequired,
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: CAROUSEL_Y_ZERO,
  },
});

function areEqual(prev, next) {
  return (
    JSON.stringify(prev.presenters) === JSON.stringify(next.presenters) &&
    prev.show === next.show &&
    prev.activeBlockId === next.activeBlockId
  );
}

export default memo(ItemsDetailsContainer, areEqual);
