import { StyleSheet, View } from 'react-native';

// constants
import COLORS from '../../colors';

// components
import SkeletonPlaceholder from '../skeletons/SkeletonPlaceholder';

const BlueBandIndexListSkeleton = () => {
  return (
    <View testID="loadingActivity">
      {[...Array(5)].map((_e, i) => (
        <View style={styles.skeletonRow} key={'row' + i}>
          <SkeletonPlaceholder width={60} height={45} style={styles.skeletonBlock} />
          {[...Array(3)].map((_v, j) => (
            <View style={styles.skeletonTextContainer} key={'text' + j}>
              <SkeletonPlaceholder width={40} height={15} style={styles.skeletonText} />
            </View>
          ))}
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  skeletonBlock: {
    marginRight: 25,
    marginLeft: 15,
  },
  skeletonRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    borderColor: COLORS.greyish21,
    borderWidth: 1,
    borderRadius: 4,
    marginHorizontal: 10,
    marginVertical: 5,
    backgroundColor: COLORS.white,
  },
  skeletonText: {
    alignSelf: 'center',
  },
  skeletonTextContainer: {
    flexGrow: 1,
  },
});

export default BlueBandIndexListSkeleton;
