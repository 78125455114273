import { getApolloClient } from '../clients/apollo';
import { GRAPHQL_VERSION_V2 } from '../consts';
import { ALERTS } from './alerts.graphql';

const fetchAlerts = async (siteId) => {
  let alerts;

  const { loading, error, data } = await getApolloClient().query({
    query: ALERTS,
    variables: { siteId },
    context: { version: GRAPHQL_VERSION_V2 },
  });

  if (data) {
    alerts = data.viewer.site.alertSummaries;
  }

  return {
    loading,
    error,
    alerts,
  };
};

export { fetchAlerts };
