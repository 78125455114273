import { Switch } from 'react-native';
import PropTypes from 'prop-types';

// constant
import COLORS from '../../colors';

// service
import { isIos } from '../../services/PlatformService';

const thumbColor = isIos() ? {} : { thumbColor: COLORS.white };

const ToggleSwitch = ({ onValueChange, value, testID, style }) => {
  return (
    <Switch
      onValueChange={onValueChange}
      value={value}
      trackColor={{ false: COLORS.silverChalice, true: COLORS.emerald2 }}
      style={style}
      {...thumbColor}
      testID={testID}
    />
  );
};

ToggleSwitch.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  testID: PropTypes.string,
  style: PropTypes.object,
};

export default ToggleSwitch;
