import { forwardRef, memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// constants
import COLORS from '../../colors';

// entity
import DeviceEntity from '../../models/entities/deviceEntity';

// models
import { GraphDataType } from '../../models/graphDataType';

// hooks
import useGraph from '../../hooks/useGraph';

// components
import ShadowedDivider from '../ShadowedDivider';
import DeviceDataStreams from '../graph/DeviceDataStreams';
import Graph from '../graph/Graph';
import DeviceDetailsSummary from './DeviceDetailsSummary';
import DeviceDetailsContentHeader from './DeviceDetailsContentHeader';

const BORDER_RADIUS = 6;

const DeviceDetails = forwardRef(({ device, onGraphInteraction, selectedDataStreams }, ref) => {
  const currentSite = useSelector((state) => state.site.currentSite);
  const dataStreams = useSelector((state) => state.graph.dataStreams);
  const loadingDataStreams = useSelector((state) => !!state.loading.effects.graph.loadDataStreams);

  const [state, onToggleDataStreams] = useGraph({
    dataStreams,
    legendStreams: device.sortedDataStreams(),
    selectedDataStreams,
    defaultTimeScaleInHours: currentSite.defaultTimeScaleInHours,
    dataType: GraphDataType.DEVICE,
    isLoading: loadingDataStreams,
  });

  const onGraphRefresh = (dataRange) => {
    onGraphInteraction(dataRange);
  };

  return (
    <View style={styles.container} testID={'devices__deviceDetail'}>
      <View style={styles.item}>
        <DeviceDetailsContentHeader device={device} testId={'devices__deviceDetailsContentHeader'} />
        <ShadowedDivider />
        <View style={styles.informationsContainer}>
          <DeviceDetailsSummary device={device} testId={'devices__deviceDetailsSummary'} />
        </View>
        <ShadowedDivider />
        <Graph
          ref={ref}
          graphPresenter={state.graphPresenter}
          onRefresh={onGraphRefresh}
          isLoading={loadingDataStreams || !state.graphPresenter.dataType}
          testId={'device__graph'}
        />
        {device?.dataStreams?.length > 0 && (
          <DeviceDataStreams
            device={device}
            onToggle={onToggleDataStreams}
            selectedDataStreams={state.graphPresenter.selectedDataStreams}
          />
        )}
      </View>
    </View>
  );
});

DeviceDetails.displayName = 'DeviceDetails';

DeviceDetails.propTypes = {
  device: PropTypes.instanceOf(DeviceEntity).isRequired,
  onGraphInteraction: PropTypes.func.isRequired,
  selectedDataStreams: PropTypes.object,
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    paddingHorizontal: 12,
    paddingBottom: 11,
  },
  informationsContainer: {
    marginTop: 30,
    marginBottom: 45,
  },
  item: {
    flex: 1,
    flexDirection: 'column',
    borderColor: COLORS.greyBorderColor,
    borderWidth: 1,
    borderRadius: BORDER_RADIUS,
    marginTop: 10,
    backgroundColor: COLORS.white,
  },
});

export default memo(DeviceDetails);
