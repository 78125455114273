import PropTypes from 'prop-types';
import { Children, useCallback, cloneElement } from 'react';
import { StatusBar } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import {
  isLandscape,
  lockToPortrait,
  unlockAllOrientations,
  removeOrientationListener,
  addOrientationListener,
} from '../services/DeviceOrientationService';

const RotatableComponent = ({ children, currentOrientation, onOrientationChange }) => {
  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      const onOrientationDidChange = (orientation) => {
        onOrientationChange(orientation);
      };
      addOrientationListener(onOrientationDidChange);
      unlockAllOrientations();

      return () => {
        removeOrientationListener(onOrientationDidChange);
        lockToPortrait();
      };
    }, [onOrientationChange]),
  );

  return (
    <>
      <StatusBar hidden={isLandscape(currentOrientation)} />
      {Children.map(children, (child) => cloneElement(child, { currentOrientation }))}
    </>
  );
};

RotatableComponent.propTypes = {
  children: PropTypes.node.isRequired,
  currentOrientation: PropTypes.string.isRequired,
  onOrientationChange: PropTypes.func.isRequired,
};

export default RotatableComponent;
