/* istanbul ignore file */
import { useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import Proptypes from 'prop-types';

// services
import { useTranslation } from '../../hooks/useTranslation';

// style
import styles from './DurationPicker.style';

const DurationPicker = ({ duration, setDuration }) => {
  const { t } = useTranslation();

  const [selectedHours, setSelectedHours] = useState(0);
  const [selectedMinutes, setSelectedMinutes] = useState(0);

  useEffect(() => {
    setSelectedHours(Math.floor(duration.asHours()));
    setSelectedMinutes(duration.minutes());
  }, [duration]);

  return (
    <View style={styles.pickerContainer}>
      <Text style={styles.durationHour}>
        {selectedHours}
        {`${t('unit_h')}`}
      </Text>
      <Text style={styles.durationMinute}>
        {selectedMinutes}
        {`${t('unit_min')}`}
      </Text>
    </View>
  );
};

DurationPicker.propTypes = {
  duration: Proptypes.object.isRequired,
  setDuration: Proptypes.func.isRequired,
};

export default DurationPicker;
