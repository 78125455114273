import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Image, Pressable, SafeAreaView, StyleSheet, Text, View } from 'react-native';

// services
import { useTranslation } from '../../hooks/useTranslation';
import { navigationShape } from '../../shapes/navigation';
import { setLanguage, languageInfo } from '../../i18n/localize';

// styles
import { globalStyles } from '../../styles';
import COLORS from '../../colors';

// icons
import icnCheck from '../../assets/icons/icnCheck.png';

// components
import HeaderBar from '../../components/header/HeaderBar';
import Divider from '../../components/Divider';

const ProfileScreen = ({ navigation }) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const { t, i18n } = useTranslation();

  const renderItem = (element) => {
    const language = element.item.language;
    const isSelected = i18n.language.includes(language);

    return (
      <Pressable onPress={() => setLanguage(language)} style={styles.item} testID={`profile__item-language-${element.index}`}>
        <Text>{t(element.item.translationKey)}</Text>
        {isSelected ? <Image source={icnCheck} style={styles.selectIcon} /> : null}
      </Pressable>
    );
  };

  return (
    <>
      <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
        <View style={globalStyles.header}>
          <HeaderBar siteName={currentUser.username} screenName={'profile'} testId={'profile__header'} navigation={navigation} />
        </View>

        <Divider />

        <View testID="profile__screen-container" style={globalStyles.bottomContainer}>
          <View style={styles.container}>
            {languageInfo.map((item, index) => (
              <Fragment key={index}>
                {renderItem({ item })}
                {index < languageInfo.length - 1 && <Divider />}
              </Fragment>
            ))}
          </View>
        </View>
      </SafeAreaView>
      <SafeAreaView style={styles.safeBottomView} edges={['bottom']} />
    </>
  );
};

ProfileScreen.propTypes = {
  navigation: navigationShape.isRequired,
};

const styles = StyleSheet.create({
  container: {
    margin: 10,
    backgroundColor: COLORS.white,
  },
  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 10,
  },
  safeBottomView: {
    backgroundColor: COLORS.greyish20,
  },
  selectIcon: {
    width: 10,
    height: 10,
  },
});
export default ProfileScreen;
