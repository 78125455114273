import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { SafeAreaView, StatusBar, StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useFocusEffect } from '@react-navigation/native';

// icons
import icnNormal from '../../assets/icons/icnNormal.png';
import icnBluetooth from '../../assets/icons/console/icnBluetoothOn.png';

// constants
import COLORS from '../../colors';
import ROUTES from '../../navigation/routes';

// services
import { navigationShape } from '../../shapes/navigation';
import { SORT_ORDER, toggleSort } from '../../utils/sorting';
import { isIos } from '../../services/PlatformService';
import ANALYTICS from '../../services/AnalyticsEvents';
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';

// styles
import { globalStyles } from '../../styles';

// components
import SortableList from '../../components/SortableList';
import SkeletonPlaceholder from '../../components/skeletons/SkeletonPlaceholder';
import EmptyView from '../../components/EmptyView';
import HeaderBar from '../../components/header/HeaderBar';
import Divider from '../../components/Divider';
import TouchIconButton from '../../components/button/TouchIconButton';
import { SORT_COLUMNS } from './sorting';
import SortableDeviceHeader from './SortableDeviceHeader';
import DeviceItem from './DeviceItem';

const DeviceScreen = ({ navigation, route }) => {
  const loading = useSelector((state) => !!state.loading.effects.devices.loadDevices);
  const sortLoading = useSelector((state) => !!state.loading.effects.devices.sortDevices);
  const devices = useSelector((state) => state.devices.sortedDevices);
  const currentSite = useSelector((state) => state.site.currentSite);
  const listRef = useRef(undefined);

  const initialSortOrderState = {
    [SORT_COLUMNS.STATUS]: SORT_ORDER.NONE,
    [SORT_COLUMNS.SERIAL]: SORT_ORDER.NONE,
    [SORT_COLUMNS.BLOCK]: SORT_ORDER.NONE,
    [SORT_COLUMNS.BATTERY]: SORT_ORDER.NONE,
    [SORT_COLUMNS.SIGNAL]: SORT_ORDER.NONE,
  };

  const [refreshing, setRefreshing] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [sortedColumn, setSortedColumn] = useState(initialSortOrderState);
  const toggle = toggleSort(sortedColumn, setSortedColumn, initialSortOrderState);

  const dispatch = useDispatch();
  const analyticsService = useAnalyticsContext();

  const emptyData = devices !== undefined && !loading && !initLoading && !refreshing && devices.length === 0;

  /* istanbul ignore next */
  const initialLoading = async () => {
    setInitLoading(true);
    await dispatch.devices.loadDevices();
    setSortedColumn({ ...initialSortOrderState, [SORT_COLUMNS.BLOCK]: SORT_ORDER.ASC });
    setInitLoading(false);
  };

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      if (route.params?.reloadData) {
        initialLoading();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigation]),
  );

  useEffect(() => {
    return function cleanup() {
      setSortedColumn(initialSortOrderState);
      dispatch.devices.updateDevices([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSite]);

  useEffect(() => {
    sortDevicesFromSortedColumn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedColumn]);

  const refreshDevices = async () => {
    setRefreshing(true);
    await dispatch.devices.loadDevices();
    sortDevicesFromSortedColumn();
    setRefreshing(false);
  };

  const sortDevicesFromSortedColumn = () => {
    if (sortedColumn[SORT_COLUMNS.STATUS] !== SORT_ORDER.NONE) {
      dispatch.devices.sortDevices({ column: SORT_COLUMNS.STATUS, order: sortedColumn[SORT_COLUMNS.STATUS] });
    } else if (sortedColumn[SORT_COLUMNS.SERIAL] !== SORT_ORDER.NONE) {
      dispatch.devices.sortDevices({ column: SORT_COLUMNS.SERIAL, order: sortedColumn[SORT_COLUMNS.SERIAL] });
    } else if (sortedColumn[SORT_COLUMNS.BLOCK] !== SORT_ORDER.NONE) {
      dispatch.devices.sortDevices({ column: SORT_COLUMNS.BLOCK, order: sortedColumn[SORT_COLUMNS.BLOCK] });
    } else if (sortedColumn[SORT_COLUMNS.BATTERY] !== SORT_ORDER.NONE) {
      dispatch.devices.sortDevices({ column: SORT_COLUMNS.BATTERY, order: sortedColumn[SORT_COLUMNS.BATTERY] });
    } else if (sortedColumn[SORT_COLUMNS.SIGNAL] !== SORT_ORDER.NONE) {
      dispatch.devices.sortDevices({ column: SORT_COLUMNS.SIGNAL, order: sortedColumn[SORT_COLUMNS.SIGNAL] });
    }
  };

  const toggleSortAndScrollToTop = (payload) => {
    /* istanbul ignore next */
    if (listRef.current && !loading) {
      listRef.current.scrollToOffset({ x: 0, y: 0, animated: false });
      toggle(payload);
    }
  };

  const renderDeviceItem = (element) => {
    const key = `${element.item.id}-${element.item.block.id}`;
    return (
      <DeviceItem device={element.item} keyIndex={element.index} navigation={navigation} testID={`devices__device-item-${key}`} />
    );
  };

  const renderSkeletonView = () => {
    return (
      <View testID="loadingActivity">
        {[...Array(5)].map((_e, i) => (
          <View style={styles.skeletonContainer} key={'row' + i}>
            <SkeletonPlaceholder width={25} height={25} />
            <SkeletonPlaceholder width={70} height={15} style={styles.skeletonSerial} />
            <SkeletonPlaceholder width={70} height={15} />
            <SkeletonPlaceholder width={40} height={15} />
            <SkeletonPlaceholder width={55} height={15} style={styles.skeletonSignal} />
          </View>
        ))}
      </View>
    );
  };

  const renderList = () => {
    if (!emptyData) {
      return (
        <SortableList
          testID={'devices__subscreen_list'}
          ref={listRef}
          elements={devices}
          loading={loading || initLoading}
          refreshing={refreshing}
          sort={dispatch.devices.sortDevices}
          renderEmptyView={renderSkeletonView}
          renderItem={renderDeviceItem}
          screenName={'devices'}
          onRefresh={refreshDevices}
        />
      );
    } else {
      return (
        <View style={styles.emptyContainer}>
          <EmptyView icon={icnNormal} iconSizeStyle={styles.emptyIcon} textKey="device_health_list_empty" />
        </View>
      );
    }
  };

  const showConsoleScreen = () => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewConsole);
    navigation.navigate(ROUTES.CONSOLE);
  };

  return (
    <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']} testID="main__banner">
      <StatusBar backgroundColor={COLORS.greyishBrown} barStyle={isIos() ? 'dark-content' : 'default'} translucent={false} />

      <View style={globalStyles.header}>
        <HeaderBar
          siteName={currentSite.name}
          screenName={'DevicesScreen'}
          style={globalStyles.title}
          testId={'Devices__header'}
          navigation={navigation}>
          <TouchIconButton
            testId={'devices__open__console-button'}
            onPress={showConsoleScreen}
            icon={icnBluetooth}
            iconStyle={styles.iconStyleBluetooth}
          />
        </HeaderBar>
      </View>

      <Divider />

      <View testID="devices__subscreen-container" style={globalStyles.bottomContainer}>
        <SortableDeviceHeader sortedColumn={sortedColumn} toggle={toggleSortAndScrollToTop} loading={sortLoading} />
        {renderList()}
      </View>
    </SafeAreaView>
  );
};

DeviceScreen.propTypes = {
  navigation: navigationShape.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      reloadData: PropTypes.bool,
    }),
  }),
};

const styles = StyleSheet.create({
  emptyContainer: {
    width: '100%',
    height: '100%',
  },
  emptyIcon: {
    width: 66,
    height: 60,
  },
  skeletonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 20,
    borderColor: COLORS.greyish21,
    borderWidth: 1,
    borderRadius: 4,
    marginHorizontal: 10,
    marginVertical: 5,
    backgroundColor: COLORS.white,
  },
  skeletonSerial: {
    marginLeft: 5,
  },
  skeletonSignal: {
    alignItems: 'flex-end',
  },
  iconStyleBluetooth: {
    tintColor: COLORS.pinkishGrey,
  },
});

export default DeviceScreen;
