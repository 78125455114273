import { StyleSheet, Text, Pressable } from 'react-native';
import PropTypes from 'prop-types';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

const NDVIDate = ({ date, isVisible, onPress }) => {
  if (!isVisible || date === '') {
    return null;
  }
  return (
    <Pressable
      style={/* istanbul ignore next */ ({ pressed }) => [pressed ? styles.touchOpacity : {}, styles.button]}
      onPress={onPress}>
      <Text style={styles.text}>{date}</Text>
    </Pressable>
  );
};

NDVIDate.propTypes = {
  date: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  button: {
    position: 'absolute',
    bottom: 70,
    alignSelf: 'center',
    justifyContent: 'center',
    height: 29,
    borderRadius: 100,
    backgroundColor: COLORS.white,
  },
  text: {
    paddingHorizontal: 12,
    textAlign: 'center',
    fontFamily: FONTS.notoSansBold,
    fontSize: 14,
    color: COLORS.greyishBrown,
  },
  touchOpacity: {
    opacity: 0.8,
  },
});

export default NDVIDate;
