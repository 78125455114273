import { memo } from 'react';
import PropTypes from 'prop-types';
import { Image, StyleSheet, Text, View } from 'react-native';
import { useTranslation } from '../hooks/useTranslation';
import COLORS from '../colors';
import FONTS from '../fonts';
import { imageSourceShape } from '../shapes/imageSource';
import { styleShape } from '../shapes/style';
import OPACITIES from '../opacities';

const EmptyView = ({ icon, iconSizeStyle, textKey }) => {
  const { t } = useTranslation();

  return (
    <View style={styles.emptyViewContainer} testID="empty-view__container">
      <Image source={icon} style={[styles.emptyViewIcon, iconSizeStyle]} testID="empty-view__icon" />
      <View style={styles.emptyViewTextContainer}>
        <Text style={styles.emptyViewText} testID="empty-view__text">
          {t(textKey)}
        </Text>
      </View>
    </View>
  );
};

EmptyView.propTypes = {
  icon: imageSourceShape.isRequired,
  iconSizeStyle: styleShape.isRequired,
  textKey: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  emptyViewIcon: {
    tintColor: COLORS.blueishGrey,
  },
  emptyViewContainer: {
    flex: 0.8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyViewTextContainer: {
    borderColor: COLORS.lightGrey,
    borderWidth: 1,
    borderRadius: 2,
    marginTop: 10,
    backgroundColor: COLORS.white,
    shadowColor: COLORS.darkGrey,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: OPACITIES.shadow,
    shadowRadius: 5,
  },
  emptyViewText: {
    width: 150,
    paddingHorizontal: 22,
    paddingVertical: 10,
    textAlign: 'center',
    fontFamily: FONTS.firaSansItalic,
    fontSize: 13,
    lineHeight: 16,
    color: COLORS.greyishBrown,
  },
});

export default memo(EmptyView);
