import { Pressable, StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

import COLORS from '../../colors';
import FONTS from '../../fonts';
import { siteShape } from '../../shapes/site';

const Site = ({ item, onItemClick, index }) => {
  const onPress = () => onItemClick(item.id);

  return (
    <View style={styles.itemsContainerStyle}>
      <Pressable
        style={/* istanbul ignore next */ ({ pressed }) => [styles.button, pressed && { backgroundColor: COLORS.brownGrey }]}
        onPress={onPress}>
        <View style={[styles.itemContainerStyle, index !== 0 && styles.itemContainerBorder]}>
          <Text ellipsizeMode={'middle'} numberOfLines={1} style={styles.siteSelectorItemTextStyle}>
            {item.name}
          </Text>
        </View>
      </Pressable>
    </View>
  );
};

Site.propTypes = {
  item: siteShape.isRequired,
  index: PropTypes.number.isRequired,
  onItemClick: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  button: {
    paddingRight: 10,
    paddingLeft: 38,
  },

  itemsContainerStyle: {
    flex: 1,
    backgroundColor: COLORS.white,
  },

  siteSelectorItemTextStyle: {
    justifyContent: 'center',
    width: '100%',
    paddingLeft: 5,
    fontFamily: FONTS.firaSans,
    fontSize: 13,
    lineHeight: 49,
    letterSpacing: 0.81,
    color: COLORS.greyishBrown,
  },

  itemContainerStyle: {
    flex: 1,
    justifyContent: 'center',
    height: 49.3,
  },

  itemContainerBorder: {
    borderTopColor: COLORS.brownGrey,
    borderTopWidth: 0.5,
  },
});

export default Site;
