import { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Image, StyleSheet, View } from 'react-native';
import Animated, { useAnimatedStyle, withTiming, withSequence, withRepeat, useSharedValue } from 'react-native-reanimated';

// icons
import icnNowIrrigation from '../../assets/icons/icnNowIrrigation.png';
import icnIrrigationOff from '../../assets/icons/icnIrrigationOff.png';

// services
import { disableContinuousAnimations } from '../../services/EnvironmentService';

// shape
import { styleShape } from '../../shapes/style';

const animationDuration = 700;

const Irrigation = ({ irrigating, style, animatedOpacity, big = false }) => {
  const animationOpacity = useSharedValue(1);

  if (animatedOpacity !== undefined) {
    animationOpacity.value = animatedOpacity.value;
  }

  useEffect(() => {
    const startAnimation = () => {
      animationOpacity.value = withRepeat(
        withSequence(withTiming(0.6, { duration: animationDuration }), withTiming(1, { duration: animationDuration })),
        -1,
        true,
      );
    };

    if (irrigating && !disableContinuousAnimations()) {
      startAnimation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [irrigating]);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: animationOpacity.value,
    };
  });

  return (
    <View style={style}>
      {irrigating ? (
        <Animated.Image source={icnNowIrrigation} style={[big ? styles.bigImage : styles.image, animatedStyle]} />
      ) : (
        big && <Image source={icnIrrigationOff} style={styles.bigImage} />
      )}
    </View>
  );
};

Irrigation.propTypes = {
  irrigating: PropTypes.bool.isRequired,
  style: styleShape,
  animatedOpacity: PropTypes.shape({
    value: PropTypes.number.isRequired,
  }),
  big: PropTypes.bool,
};

const styles = StyleSheet.create({
  bigImage: {
    width: 52,
    height: 52,
  },
  image: {
    width: 32,
    height: 32,
  },
});

export default memo(Irrigation);
