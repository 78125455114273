import COLORS from '../../colors';
import { PIN_ICONS } from '../../assets/icons/mapPins/pinIcons';

import HIGH_ICON from '../../assets/icons/icnTensionStatusHigh.png';
import MEDIUM_ICON from '../../assets/icons/icnTensionStatusMedium.png';
import LOW_ICON from '../../assets/icons/icnTensionStatusLow.png';
import WET_ICON from '../../assets/icons/icnTensionStatusWet.png';
import OUT_OF_FIELD_ICON from '../../assets/icons/icnOutOfField.png';
import ERROR_ICON from '../../assets/icons/icnTensionStatusError.png';

export const HIGH = 'HIGH';
export const MEDIUM = 'MEDIUM';
export const LOW = 'LOW';
export const COMMUNICATION_ERROR = 'COMMUNICATION_ERROR';
export const OUT_OF_FIELD = 'OUT_OF_FIELD';
export const WET = 'WET';

const tensionOrder = [HIGH, MEDIUM, LOW, COMMUNICATION_ERROR, OUT_OF_FIELD, WET];

const tensionColorsByStatus = {
  HIGH: COLORS.dustyOrange,
  MEDIUM: COLORS.maize,
  LOW: COLORS.seafoamBlue,
  OUT_OF_FIELD: COLORS.greyishThree,
  WET: COLORS.havelockBlue,
  COMMUNICATION_ERROR: COLORS.greyishThree,
  [undefined]: COLORS.darkBlue,
  default: COLORS.darkBlue,
};

const tensionIconByStatus = {
  HIGH: HIGH_ICON,
  MEDIUM: MEDIUM_ICON,
  LOW: LOW_ICON,
  WET: WET_ICON,
  OUT_OF_FIELD: OUT_OF_FIELD_ICON,
  COMMUNICATION_ERROR: ERROR_ICON,
  [undefined]: null,
  default: null,
};

const mapTensionIconByStatus = {
  HIGH: PIN_ICONS.high,
  MEDIUM: PIN_ICONS.med,
  LOW: PIN_ICONS.low,
  WET: PIN_ICONS.wet,
  OUT_OF_FIELD: PIN_ICONS.outOfField,
  COMMUNICATION_ERROR: PIN_ICONS.problems,
  [undefined]: PIN_ICONS.unknown,
  default: PIN_ICONS.unknown,
};

const tensionTranslationByStatus = {
  HIGH: 'tension_status_high',
  MEDIUM: 'tension_status_medium',
  LOW: 'tension_status_low',
  WET: 'tension_status_wet',
  OUT_OF_FIELD: 'tension_status_out_of_field',
  COMMUNICATION_ERROR: 'tension_status_communication_error',
  [undefined]: 'unknown_status',
  default: 'unknown_status',
};

export const colorForStatus = (status) => tensionColorsByStatus[status] || tensionColorsByStatus.default;

export const iconForStatus = (status) => tensionIconByStatus[status] || tensionIconByStatus.default;

export const iconForMapStatus = (status) => mapTensionIconByStatus[status] || mapTensionIconByStatus.default;

export const translationForStatus = (status) => tensionTranslationByStatus[status] || tensionTranslationByStatus.default;

export const orderByStatus = (status) => tensionOrder.indexOf(status);
