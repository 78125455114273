import { memo } from 'react';
import PropTypes from 'prop-types';
import { Pressable, StyleSheet, Image } from 'react-native';

// shape
import { styleShape } from '../../shapes/style';

import BlockAliasPresenter from '../../presenters/blockAlias/BlockAliasPresenter';
import NutrientsBlockAliasPresenter from '../../presenters/blockAlias/NutrientsBlockAliasPresenter';
import BlockAlias from './BlockAlias';

const BlockAliasButton = ({ presenter, onClick, buttonStyle, testId }) => {
  const statusColor = presenter.getBlockHeaderColor();
  const overlayIcon = presenter.getOverlayIcon();

  return (
    <Pressable
      style={[
        {
          backgroundColor: presenter.getBackgroundColor(),
          borderColor: statusColor,
        },
        styles.defaultButtonStyle,
        buttonStyle,
      ]}
      testID={testId}
      accessibilityLabel="block-alias__button"
      onPress={onClick}>
      <BlockAlias alias={presenter.alias()} color={statusColor} ellipsizeMode="tail" style={styles.alias} />
      {overlayIcon && <Image style={overlayIcon.style} source={overlayIcon.icon} />}
    </Pressable>
  );
};

BlockAliasButton.propTypes = {
  presenter: PropTypes.oneOfType([PropTypes.instanceOf(BlockAliasPresenter), PropTypes.instanceOf(NutrientsBlockAliasPresenter)])
    .isRequired,
  onClick: PropTypes.func,
  buttonStyle: styleShape,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  alias: {
    letterSpacing: 0.61,
  },
  defaultButtonStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 73,
    height: 43,
    padding: 2,
    borderWidth: 2,
    borderRadius: 2,
  },
});

export default memo(BlockAliasButton);
