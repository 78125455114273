import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

// services
import { useTranslation } from '../../hooks/useTranslation';

// components
import Divider from '../../components/Divider';
import SortableHeaderItem from '../../components/header/SortableHeaderItem';

//constants
import COLORS from '../../colors';
import { SORT_COLUMNS } from './sorting';

const SortableBlockBlueBandIndexHeader = ({ sortedColumn, toggle, loading }) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.sortableItemContainer}>
        <SortableHeaderItem
          style={styles.dateHeader}
          title={t('blue_band_index_sort_button_date')}
          onPress={toggle}
          sortOrder={sortedColumn.date}
          sortKey={SORT_COLUMNS.DATE}
          testID="list__block-blue-band-index-header-item-date"
          loading={loading}
        />

        <View style={styles.blueBandSortableItem}>
          <SortableHeaderItem
            style={styles.blueBandHeader}
            title={t('blue_band_index_sort_button_wet')}
            onPress={toggle}
            sortOrder={sortedColumn.wet}
            sortKey={SORT_COLUMNS.WET_BLUE_BAND_INDEX}
            testID="list__block-blue-band-index-header-item-wet"
            loading={loading}
          />

          <View style={[styles.blueBandIndicator, styles.blueBandWetIndicator]} />
        </View>

        <View style={styles.blueBandSortableItem}>
          <SortableHeaderItem
            style={styles.blueBandHeader}
            title={t('blue_band_index_sort_button_good')}
            onPress={toggle}
            sortOrder={sortedColumn.good}
            sortKey={SORT_COLUMNS.GOOD_BLUE_BAND_INDEX}
            testID="list__block-blue-band-index-header-item-good"
            loading={loading}
          />

          <View style={[styles.blueBandIndicator, styles.blueBandGoodIndicator]} />
        </View>

        <View style={styles.blueBandSortableItem}>
          <SortableHeaderItem
            style={styles.blueBandHeader}
            title={t('blue_band_index_sort_button_dry')}
            onPress={toggle}
            sortOrder={sortedColumn.dry}
            sortKey={SORT_COLUMNS.DRY_BLUE_BAND_INDEX}
            testID="list__block-blue-band-index-header-item-dry"
            loading={loading}
          />

          <View style={[styles.blueBandIndicator, styles.blueBandDryIndicator]} />
        </View>
      </View>

      <Divider />
    </View>
  );
};

SortableBlockBlueBandIndexHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
  sortedColumn: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  dateHeader: {
    width: 55,
    marginRight: 35,
    marginLeft: 20,
  },
  blueBandDryIndicator: {
    backgroundColor: COLORS.dustyOrange,
  },
  blueBandGoodIndicator: {
    backgroundColor: COLORS.emeraldRush,
  },
  blueBandHeader: {
    alignSelf: 'center',
    width: 50,
    height: 35,
  },
  blueBandIndicator: {
    width: '100%',
    height: 5,
  },
  blueBandSortableItem: {
    flexGrow: 1,
  },
  blueBandWetIndicator: {
    backgroundColor: COLORS.havelockBlue,
  },
  container: {
    zIndex: 2,
    backgroundColor: COLORS.white,
  },
  sortableItemContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingRight: 10,
  },
});

export default SortableBlockBlueBandIndexHeader;
