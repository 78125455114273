import COLORS from '../colors';

import { LOCAL_DATA_STREAM_ICONS } from '../assets/icons/localDataStreams/localDataStreamIcons';
import { HEALTH_DATA_STREAM_ICONS } from '../assets/icons/healthDataStreams/healthDataStreamIcons';
import { WEATHER_DATA_STREAM_ICONS } from '../assets/icons/weatherDastreamIcons/weatherDataStreamIcons';

export const DATA_STREAM_CATEGORY = {
  AIR_TEMPERATURE: {
    name: 'AIR_TEMPERATURE',
    preferredColor: COLORS.dustyOrange,
    icon: LOCAL_DATA_STREAM_ICONS.DEC_AIR_TEMPERATURE.icon,
  },
  BATTERY: {
    name: 'BATTERY',
    preferredColor: COLORS.paleTeal,
    icon: HEALTH_DATA_STREAM_ICONS.BATTERY.icon,
  },
  CHILL_PORTIONS: {
    name: 'CHILL_PORTIONS',
    preferredColor: COLORS.paleTeal,
    icon: LOCAL_DATA_STREAM_ICONS.CHILL_PORTIONS.icon,
  },
  CHILLING_HOURS: {
    name: 'CHILLING_HOURS',
    preferredColor: COLORS.paleTeal,
    icon: LOCAL_DATA_STREAM_ICONS.CHILLING_HOURS.icon,
  },
  CPU: {
    name: 'CPU',
    preferredColor: COLORS.fadedOrange,
    icon: HEALTH_DATA_STREAM_ICONS.CPU.icon,
  },
  DEGREE_DAYS: {
    name: 'DEGREE_DAYS',
    preferredColor: COLORS.lightGreyBlue,
    icon: LOCAL_DATA_STREAM_ICONS.DEGREE_DAYS.icon,
  },
  DEW: {
    name: 'DEW',
    preferredColor: COLORS.paleTeal,
    icon: LOCAL_DATA_STREAM_ICONS.DEW.icon,
  },
  DIELECTRIC_PERMITTIVITY: {
    name: 'DIELECTRIC_PERMITTIVITY',
    preferredColor: COLORS.fadedOrange,
    icon: LOCAL_DATA_STREAM_ICONS.DIELECTRIC_PERMITTIVITY.icon,
  },
  ELECTRICAL_CONDUCTIVITY: {
    name: 'ELECTRICAL_CONDUCTIVITY',
    preferredColor: COLORS.fadedOrange,
    icon: LOCAL_DATA_STREAM_ICONS.ELECTRICAL_CONDUCTIVITY.icon,
  },
  EVAPORATION: {
    name: 'EVAPORATION',
    preferredColor: COLORS.paleTeal,
    icon: LOCAL_DATA_STREAM_ICONS.ETO_DAILY.icon,
  },
  EXTERNAL_SENSOR: {
    name: 'EXTERNAL_SENSOR',
    preferredColor: COLORS.fadedOrange,
    icon: HEALTH_DATA_STREAM_ICONS.EDXTERNAL_SENSOR.icon,
  },
  FLOW: {
    name: 'FLOW',
    preferredColor: COLORS.darkishPink,
    icon: LOCAL_DATA_STREAM_ICONS.FLOW.icon,
  },
  HAIL: {
    name: 'HAIL',
    preferredColor: COLORS.steelBlue,
    icon: WEATHER_DATA_STREAM_ICONS.HAIL.icon,
  },
  HUMIDITY: {
    name: 'HUMIDITY',
    preferredColor: COLORS.paleTeal,
    icon: LOCAL_DATA_STREAM_ICONS.RELATIVE_HUMIDITY.icon,
  },
  LEAF_WETNESS: {
    name: 'LEAF_WETNESS',
    preferredColor: COLORS.paleTeal,
    icon: LOCAL_DATA_STREAM_ICONS.LEAF_WETNESS.icon,
  },
  LEAF_WETNESS_HOURS: {
    name: 'LEAF_WETNESS_HOURS',
    preferredColor: COLORS.darkishPink,
    icon: LOCAL_DATA_STREAM_ICONS.LEAF_WETNESS_HOURS.icon,
  },
  MUSHROOMS: {
    name: 'MUSHROOMS',
    preferredColor: COLORS.darkishPink,
    icon: LOCAL_DATA_STREAM_ICONS.ROTTEN.icon,
  },
  OTHER_LOCAL: {
    name: 'OTHER_LOCAL',
    preferredColor: COLORS.darkishPink,
    icon: null,
  },
  OTHER_WEATHER: {
    name: 'OTHER_WEATHER',
    preferredColor: COLORS.greyishBrown,
    icon: WEATHER_DATA_STREAM_ICONS.WEATHER.icon,
  },
  PH_200MV: {
    name: 'PH_200MV',
    preferredColor: COLORS.lightGreyBlue,
    icon: LOCAL_DATA_STREAM_ICONS.PH_200MV.icon,
  },
  PORE_WATER_CONDUCTIVITY: {
    name: 'PORE_WATER_CONDUCTIVITY',
    preferredColor: COLORS.stone,
    icon: LOCAL_DATA_STREAM_ICONS.PORE_WATER_CONDUCTIVITY.icon,
  },
  PRECIPITATION: {
    name: 'PRECIPITATION',
    preferredColor: COLORS.waterBlue,
    icon: LOCAL_DATA_STREAM_ICONS.RAIN_FALL.icon,
  },
  PRESSURE: {
    name: 'PRESSURE',
    preferredColor: COLORS.lightGreyBlue,
    icon: LOCAL_DATA_STREAM_ICONS.PIPE_PRESSURE.icon,
  },
  PUMPING_STATION: {
    name: 'PUMPING_STATION',
    preferredColor: COLORS.fadedOrange,
    icon: LOCAL_DATA_STREAM_ICONS.PIPE_PRESSURE.icon,
  },
  RADIATION: {
    name: 'RADIATION',
    preferredColor: COLORS.fadedOrange,
    icon: WEATHER_DATA_STREAM_ICONS.SOLAR_RADIATION.icon,
  },
  RST: {
    name: 'RST',
    preferredColor: COLORS.waterBlue,
    icon: HEALTH_DATA_STREAM_ICONS.INTERNAL_HUMIDITY.icon,
  },
  SALINITY_WATER: {
    name: 'SALINITY_WATER',
    preferredColor: COLORS.fadedBlue,
    icon: LOCAL_DATA_STREAM_ICONS.SALINITY_WATER.icon,
  },
  SIGNAL_STRENGTH: {
    name: 'SIGNAL_STRENGTH',
    preferredColor: COLORS.steelBlue,
    icon: HEALTH_DATA_STREAM_ICONS.SIGNAL_STRENGTH.icon,
  },
  SHIELDED_TEMPERATURE: {
    name: 'SHIELDED_TEMPERATURE',
    preferredColor: COLORS.dustyOrange,
    icon: LOCAL_DATA_STREAM_ICONS.SHIELDED_TEMPERATURE.icon,
  },
  SOIL_ELECTRICAL_CONDUCTIVITY: {
    name: 'SOIL_ELECTRICAL_CONDUCTIVITY',
    preferredColor: COLORS.stone,
    icon: LOCAL_DATA_STREAM_ICONS.SOIL_ELECTRICAL_CONDUCTIVITY.icon,
  },
  SOIL_NITRATE_CONCENTRATION: {
    name: 'SOIL_NITRATE_CONCENTRATION',
    preferredColor: COLORS.greyishBrown,
    icon: LOCAL_DATA_STREAM_ICONS.SOIL_NITRATE_CONCENTRATION.icon,
  },
  SOIL_TEMPERATURE: {
    name: 'SOIL_TEMPERATURE',
    preferredColor: COLORS.stone,
    icon: LOCAL_DATA_STREAM_ICONS.SOIL_TEMPERATURE.icon,
  },
  TENSION: {
    name: 'TENSION',
    preferredColor: COLORS.greyishBrown,
    icon: LOCAL_DATA_STREAM_ICONS.WATER_TENSION.icon,
  },
  TENSION_HOURS: {
    name: 'TENSION_HOURS',
    preferredColor: COLORS.darkishPink,
    icon: LOCAL_DATA_STREAM_ICONS.TENSION_HOURS.icon,
  },
  TIME: {
    name: 'TIME',
    preferredColor: COLORS.darkishPink,
    icon: HEALTH_DATA_STREAM_ICONS.TIME_SINCE_LAST_REPORT.icon,
  },
  UNKNOWN: {
    name: 'UNKNOWN',
    preferredColor: COLORS.darkBlue,
    icon: null,
  },
  INTERNAL_TEMPERATURE: {
    name: 'INTERNAL_TEMPERATURE',
    preferredColor: COLORS.fadedOrange,
    icon: HEALTH_DATA_STREAM_ICONS.INTERNAL_TEMPERATURE.icon,
  },
  UNSHIELDED_TEMPERATURE: {
    name: 'UNSHIELDED_TEMPERATURE',
    preferredColor: COLORS.fadedOrange,
    icon: LOCAL_DATA_STREAM_ICONS.UNSHIELDED_TEMPERATURE.icon,
  },
  VALVE_NO: {
    name: 'VALVE_NO',
    preferredColor: COLORS.waterBlue,
    icon: HEALTH_DATA_STREAM_ICONS.VALVE.icon,
  },
  VOLUME: {
    name: 'VOLUME',
    preferredColor: COLORS.greyishBrown,
    icon: LOCAL_DATA_STREAM_ICONS.TOTAL_VOLUME.icon,
  },
  VPD: {
    name: 'VPD',
    preferredColor: COLORS.darkishPink,
    icon: LOCAL_DATA_STREAM_ICONS.VPD.icon,
  },
  WATER_DEPTH: {
    name: 'WATER_DEPTH',
    preferredColor: COLORS.paleTeal,
    icon: LOCAL_DATA_STREAM_ICONS.WATER_DEPTH.icon,
  },
  WATER_TEMPERATURE: {
    name: 'WATER_TEMPERATURE',
    preferredColor: COLORS.havelockBlue,
    icon: LOCAL_DATA_STREAM_ICONS.SHIELDED_TEMPERATURE.icon,
  },
  WELL_DEPTH: {
    name: 'WELL_DEPTH',
    preferredColor: COLORS.paleTeal,
    icon: LOCAL_DATA_STREAM_ICONS.WELL_DEPTH.icon,
  },
  WIND_DIRECTION: {
    name: 'WIND_DIRECTION',
    preferredColor: COLORS.fadedBlue,
    icon: WEATHER_DATA_STREAM_ICONS.WIND_DIRECTION.icon,
  },
  WIND_GUST: {
    name: 'WIND_GUST',
    preferredColor: COLORS.signalBlue,
    icon: WEATHER_DATA_STREAM_ICONS.WIND_GUST.icon,
  },
  WIND_SPEED: {
    name: 'WIND_SPEED',
    preferredColor: COLORS.fadedBlue,
    icon: WEATHER_DATA_STREAM_ICONS.WIND_SPEED.icon,
  },
};

export const categoryForDataStreamType = (dataStreamType) => {
  switch (dataStreamType) {
    case DATA_STREAMS_TYPES.UNKNOWN.code:
      return DATA_STREAM_CATEGORY.UNKNOWN;
    case DATA_STREAMS_TYPES.WEB_BASE_CPU_TEMPERATURE.code:
    case DATA_STREAMS_TYPES.INTERNAL_TEMP.code:
      return DATA_STREAM_CATEGORY.INTERNAL_TEMPERATURE;
    case DATA_STREAMS_TYPES.UNSHIELDED_TEMPERATURE.code:
      return DATA_STREAM_CATEGORY.UNSHIELDED_TEMPERATURE;
    case DATA_STREAMS_TYPES.SHIELDED_TEMPERATURE.code:
      return DATA_STREAM_CATEGORY.SHIELDED_TEMPERATURE;
    case DATA_STREAMS_TYPES.CPU.code:
    case DATA_STREAMS_TYPES.CPU_USAGE.code:
      return DATA_STREAM_CATEGORY.CPU;
    case DATA_STREAMS_TYPES.DEGREE_DAYS.code:
      return DATA_STREAM_CATEGORY.DEGREE_DAYS;
    case DATA_STREAMS_TYPES.WS_AIR_TEMPERATURE.code:
    case DATA_STREAMS_TYPES.RST_TEMP_1820.code:
    case DATA_STREAMS_TYPES.RST_TEMP_RSNTC.code:
    case DATA_STREAMS_TYPES.DEC_AIR_TEMPERATURE.code:
      return DATA_STREAM_CATEGORY.AIR_TEMPERATURE;
    case DATA_STREAMS_TYPES.FLOW.code:
      return DATA_STREAM_CATEGORY.FLOW;
    case DATA_STREAMS_TYPES.PIPE_PRESSURE.code:
    case DATA_STREAMS_TYPES.TRANSDUCER_PRESSURE.code:
      return DATA_STREAM_CATEGORY.PUMPING_STATION;
    case DATA_STREAMS_TYPES.RG10MINCH.code:
    case DATA_STREAMS_TYPES.RAIN_FALL.code:
    case DATA_STREAMS_TYPES.RAIN_24H.code:
    case DATA_STREAMS_TYPES.WEEKLY_RAIN.code:
    case DATA_STREAMS_TYPES.YEARLY_RAIN.code:
      return DATA_STREAM_CATEGORY.PRECIPITATION;
    case DATA_STREAMS_TYPES.WS_RELATIVE_HUMIDITY.code:
    case DATA_STREAMS_TYPES.RELATIVE_HUMIDITY.code:
      return DATA_STREAM_CATEGORY.HUMIDITY;
    case DATA_STREAMS_TYPES.HAIL_24H.code:
    case DATA_STREAMS_TYPES.HAIL_FALL.code:
      return DATA_STREAM_CATEGORY.HAIL;
    case DATA_STREAMS_TYPES.WSS225MPHHZ.code:
    case DATA_STREAMS_TYPES.WIND_SPEED.code:
      return DATA_STREAM_CATEGORY.WIND_SPEED;
    case DATA_STREAMS_TYPES.WDIRDAVIS.code:
    case DATA_STREAMS_TYPES.WIND_DIRECTION.code:
      return DATA_STREAM_CATEGORY.WIND_DIRECTION;
    case DATA_STREAMS_TYPES.WIND_GUST.code:
      return DATA_STREAM_CATEGORY.WIND_GUST;
    case DATA_STREAMS_TYPES.ATMOSPHERIC_PRESSURE.code:
    case DATA_STREAMS_TYPES.PRESSURIZED_HOURS.code:
      return DATA_STREAM_CATEGORY.PRESSURE;
    case DATA_STREAMS_TYPES.SOLAR_RADIATION.code:
      return DATA_STREAM_CATEGORY.RADIATION;
    case DATA_STREAMS_TYPES.DAILY_ETO.code:
    case DATA_STREAMS_TYPES.HOURLY_ETO.code:
    case DATA_STREAMS_TYPES.MONTHLY_ETO.code:
    case DATA_STREAMS_TYPES.WEEKLY_ETO.code:
    case DATA_STREAMS_TYPES.YEARLY_ETO.code:
      return DATA_STREAM_CATEGORY.EVAPORATION;
    case DATA_STREAMS_TYPES.LEAF_WETNESS.code:
      return DATA_STREAM_CATEGORY.LEAF_WETNESS;
    case DATA_STREAMS_TYPES.LEAF_WETNESS_HOURS.code:
      return DATA_STREAM_CATEGORY.LEAF_WETNESS_HOURS;
    case DATA_STREAMS_TYPES.CHILLING_HOURS.code:
      return DATA_STREAM_CATEGORY.CHILLING_HOURS;
    case DATA_STREAMS_TYPES.CHILL_PORTIONS.code:
      return DATA_STREAM_CATEGORY.CHILL_PORTIONS;
    case DATA_STREAMS_TYPES.DEW_POINT.code:
      return DATA_STREAM_CATEGORY.DEW;
    case DATA_STREAMS_TYPES.WATER_TENSION.code:
      return DATA_STREAM_CATEGORY.TENSION;
    case DATA_STREAMS_TYPES.DIELECTRIC_PERMITTIVITY.code:
      return DATA_STREAM_CATEGORY.DIELECTRIC_PERMITTIVITY;
    case DATA_STREAMS_TYPES.ELECTRICAL_CONDUCTIVITY.code:
      return DATA_STREAM_CATEGORY.ELECTRICAL_CONDUCTIVITY;
    case DATA_STREAMS_TYPES.TENSION_HOURS.code:
      return DATA_STREAM_CATEGORY.TENSION_HOURS;
    case DATA_STREAMS_TYPES.VPD.code:
      return DATA_STREAM_CATEGORY.VPD;
    case DATA_STREAMS_TYPES.PH_200MV.code:
      return DATA_STREAM_CATEGORY.PH_200MV;
    case DATA_STREAMS_TYPES.CURRENT.code:
    case DATA_STREAMS_TYPES.RST_RM210_MAIN_CURRENT.code:
    case DATA_STREAMS_TYPES.RST_RM210_CHRG_CURRENT.code:
    case DATA_STREAMS_TYPES.EXTERNAL_SENSOR_4_TO_20_MA.code:
      return DATA_STREAM_CATEGORY.EXTERNAL_SENSOR;
    case DATA_STREAMS_TYPES.RST_RS_INTHUM.code:
      return DATA_STREAM_CATEGORY.RST;
    case DATA_STREAMS_TYPES.WEB_BASE_VALVE_NO.code:
      return DATA_STREAM_CATEGORY.VALVE_NO;
    case DATA_STREAMS_TYPES.BATTERY_3_7_V.code:
    case DATA_STREAMS_TYPES.BATTERY_12V.code:
    case DATA_STREAMS_TYPES.BATTERY_3V.code:
    case DATA_STREAMS_TYPES.AG_3000_BATTERY_VOLTAGE.code:
    case DATA_STREAMS_TYPES.BATTERY_VOLTAGE_SUPPLY.code:
    case DATA_STREAMS_TYPES.WD_3000_BATTERY_PERCENT.code:
      return DATA_STREAM_CATEGORY.BATTERY;
    case DATA_STREAMS_TYPES.SIGNAL_STRENGTH_DB.code:
    case DATA_STREAMS_TYPES.CELLULAR_SUCCESS_RATE.code:
      return DATA_STREAM_CATEGORY.SIGNAL_STRENGTH;
    case DATA_STREAMS_TYPES.SOIL_ELECTRICAL_CONDUCTIVITY.code:
      return DATA_STREAM_CATEGORY.SOIL_ELECTRICAL_CONDUCTIVITY;
    case DATA_STREAMS_TYPES.SOIL_TEMPERATURE.code:
      return DATA_STREAM_CATEGORY.SOIL_TEMPERATURE;
    case DATA_STREAMS_TYPES.SALINITY_WATER.code:
      return DATA_STREAM_CATEGORY.SALINITY_WATER;
    case DATA_STREAMS_TYPES.MOISISSURE_GRISE_BLEUET.code:
    case DATA_STREAMS_TYPES.SCLEROTINIA_ROT.code:
      return DATA_STREAM_CATEGORY.MUSHROOMS;
    case DATA_STREAMS_TYPES.WATER_DEPTH.code:
      return DATA_STREAM_CATEGORY.WATER_DEPTH;
    case DATA_STREAMS_TYPES.WATER_TEMPERATURE.code:
      return DATA_STREAM_CATEGORY.WATER_TEMPERATURE;
    case DATA_STREAMS_TYPES.WELL_DEPTH.code:
      return DATA_STREAM_CATEGORY.WELL_DEPTH;
    case DATA_STREAMS_TYPES.TOTAL_VOLUME.code:
      return DATA_STREAM_CATEGORY.VOLUME;
    case DATA_STREAMS_TYPES.TIME_SINCE_LAST_REPORT.code:
      return DATA_STREAM_CATEGORY.TIME;
    case DATA_STREAMS_TYPES.PORE_WATER_CONDUCTIVITY.code:
      return DATA_STREAM_CATEGORY.PORE_WATER_CONDUCTIVITY;
    case DATA_STREAMS_TYPES.SOIL_NITRATE_CONCENTRATION.code:
      return DATA_STREAM_CATEGORY.SOIL_NITRATE_CONCENTRATION;
    default:
      return DATA_STREAM_CATEGORY.UNKNOWN;
  }
};

export const DEFAULT_PRIORITY = Number.MAX_SAFE_INTEGER;

export const DATA_STREAMS_TYPES = {
  AG_3000_BATTERY_VOLTAGE: {
    code: 'AG_3000_BATTERY_VOLTAGE',
    priority: 151,
  },
  ALARM_1FMI: {
    code: 'ALARM_1FMI',
    priority: DEFAULT_PRIORITY,
  },
  ALARM_1SPN: {
    code: 'ALARM_1SPN',
    priority: DEFAULT_PRIORITY,
  },
  ALARM_2FMI: {
    code: 'ALARM_2FMI',
    priority: DEFAULT_PRIORITY,
  },
  ALARM_2SPN: {
    code: 'ALARM_2SPN',
    priority: DEFAULT_PRIORITY,
  },
  ALARM_QUANTITY: {
    code: 'ALARM_QUANTITY',
    priority: DEFAULT_PRIORITY,
  },
  ALTERNATOR_CURRENT: {
    code: 'ALTERNATOR_CURRENT',
    priority: DEFAULT_PRIORITY,
  },
  ANTHRACNOSE_BLEUET: {
    code: 'ANTHRACNOSE_BLEUET',
    priority: DEFAULT_PRIORITY,
  },
  ATMOSPHERIC_PRESSURE: {
    code: 'ATMOSPHERIC_PRESSURE',
    priority: 12,
  },
  AUTO_START_SWITCH: {
    code: 'AUTO_START_SWITCH',
    priority: DEFAULT_PRIORITY,
  },
  AUTO_START_SWITCHES_BITMASK: {
    code: 'AUTO_START_SWITCHES_BITMASK',
    priority: DEFAULT_PRIORITY,
  },
  AVAILABLE_WATER: {
    code: 'AVAILABLE_WATER',
    priority: DEFAULT_PRIORITY,
  },
  BATTERY_12V: {
    code: 'BATTERY_12V',
    priority: 40,
  },
  BATTERY_3_7_V: {
    code: 'BATTERY_3_7_V',
    priority: 30,
  },
  BATTERY_3V: {
    code: 'BATTERY_3V',
    priority: 20,
  },
  BATTERY_VOLTAGE_SUPPLY: {
    code: 'BATTERY_VOLTAGE_SUPPLY',
    priority: 10,
  },
  BLUEBERRY_GRAY_MOLD: {
    code: 'MOISISSURE_GRISE_BLEUET',
    priority: 25,
  },
  CELLULAR_SUCCESS_RATE: {
    code: 'CELLULAR_SUCCESS_RATE',
    priority: 90,
  },
  CHILLING_HOURS: {
    code: 'CHILLING_HOURS',
    priority: 6,
  },
  CHILL_PORTIONS: {
    code: 'CHILL_PORTIONS',
    priority: 7,
  },
  CONFIG_ACTIVE_VERSION: {
    code: 'CONFIG_ACTIVE_VERSION',
    priority: DEFAULT_PRIORITY,
  },
  CONFIG_FIRMWARE_VERSION_A: {
    code: 'CONFIG_FIRMWARE_VERSION_A',
    priority: DEFAULT_PRIORITY,
  },
  CONFIG_FIRMWARE_VERSION_B: {
    code: 'CONFIG_FIRMWARE_VERSION_B',
    priority: DEFAULT_PRIORITY,
  },
  CONFIG_RETRIEVAL_DELAY: {
    code: 'CONFIG_RETRIEVAL_DELAY',
    priority: DEFAULT_PRIORITY,
  },
  CONFIG_RETRY_QUANTITY: {
    code: 'CONFIG_RETRY_QUANTITY',
    priority: DEFAULT_PRIORITY,
  },
  CONFIG_SEND_TIMEOUT: {
    code: 'CONFIG_SEND_TIMEOUT',
    priority: DEFAULT_PRIORITY,
  },
  CONFIG_SERIAL_NUMBER: {
    code: 'CONFIG_SERIAL_NUMBER',
    priority: DEFAULT_PRIORITY,
  },
  CONFIG_SYNC_COUNT: {
    code: 'CONFIG_SYNC_COUNT',
    priority: DEFAULT_PRIORITY,
  },
  CONFIG_SYNC_DELAY: {
    code: 'CONFIG_SYNC_DELAY',
    priority: DEFAULT_PRIORITY,
  },
  CONTROLLER_SOFTWARE_VERSION: {
    code: 'CONTROLLER_SOFTWARE_VERSION',
    priority: DEFAULT_PRIORITY,
  },
  COUNT_TRIGGER_PROXY: {
    code: 'COUNT_TRIGGER_PROXY',
    priority: DEFAULT_PRIORITY,
  },
  CPU: {
    code: 'CPU',
    priority: 7,
  },
  CPU_USAGE: {
    code: 'CPU_USAGE',
    priority: 120,
  },
  CURRENT: {
    code: 'CURRENT',
    priority: 50,
  },
  DAILY_ETO: {
    code: 'DAILY_ETO',
    priority: 16,
  },
  DEGREE_DAYS: {
    code: 'DEGREE_DAYS',
    priority: 11,
  },
  DEVICE_TYPE_ON_PORT: {
    code: 'DEVICE_TYPE_ON_PORT',
    priority: DEFAULT_PRIORITY,
  },
  DEW_POINT: {
    code: 'POINT_ROSEE',
    priority: 15,
  },
  DIELECTRIC_PERMITTIVITY: {
    code: 'DIELECTRIC_PERMITTIVITY',
    priority: 25,
  },
  ELECTRICAL_CONDUCTIVITY: {
    code: 'ELECTRICAL_CONDUCTIVITY',
    priority: 25,
  },
  ENGINE_AMPS: {
    code: 'ENGINE_AMPS',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_BITMASK: {
    code: 'ENGINE_BITMASK',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_CONTROLLER_BATTERY_VOLTAGE: {
    code: 'ENGINE_CONTROLLER_BATTERY_VOLTAGE',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_COOL_DOWN_DELAY: {
    code: 'ENGINE_COOL_DOWN_DELAY',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_DISCHARGE_PRESSURE: {
    code: 'ENGINE_DISCHARGE_PRESSURE',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_FUEL_LEVEL: {
    code: 'ENGINE_FUEL_LEVEL',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_INTERCOOLER_TEMPERATURE: {
    code: 'ENGINE_INTERCOOLER_TEMPERATURE',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_MAXIMUM_RPM_SET_POINT: {
    code: 'ENGINE_MAXIMUM_RPM_SET_POINT',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_MAXIMUM_TORQUE_SET_POINT: {
    code: 'ENGINE_MAXIMUM_TORQUE_SET_POINT',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_OIL_PRESSURE: {
    code: 'ENGINE_OIL_PRESSURE',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_OIL_TEMPERATURE: {
    code: 'ENGINE_OIL_TEMPERATURE',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_REASON_UNIT_CANNOT_START: {
    code: 'ENGINE_REASON_UNIT_CANNOT_START',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_RPM: {
    code: 'ENGINE_RPM',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_RUNNING_HOURS: {
    code: 'ENGINE_RUNNING_HOURS',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_SHUTDOWN_STATUS_BYTE: {
    code: 'ENGINE_SHUTDOWN_STATUS_BYTE',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_START_DELAY: {
    code: 'ENGINE_START_DELAY',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_START_STOP: {
    code: 'ENGINE_START_STOP',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_STOP_DELAY: {
    code: 'ENGINE_STOP_DELAY',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_SYSTEM_LEVEL: {
    code: 'ENGINE_SYSTEM_LEVEL',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_SYSTEM_STATUS_BYTE: {
    code: 'ENGINE_SYSTEM_STATUS_BYTE',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_TEMPERATURE: {
    code: 'ENGINE_TEMPERATURE',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_TORQUE: {
    code: 'ENGINE_TORQUE',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_TORQUE_PCT: {
    code: 'ENGINE_TORQUE_PCT',
    priority: DEFAULT_PRIORITY,
  },
  ENGINE_WARMUP_DELAY: {
    code: 'ENGINE_WARMUP_DELAY',
    priority: DEFAULT_PRIORITY,
  },
  ESPACE_DISQUE_LIBRE: {
    code: 'ESPACE_DISQUE_LIBRE',
    priority: DEFAULT_PRIORITY,
  },
  EXTERNAL_SENSOR_4_TO_20_MA: {
    code: 'EXTERNAL_SENSOR_4_TO_20_MA',
    priority: 140,
  },
  FIRMWARE_VERSION: {
    code: 'FIRMWARE_VERSION',
    priority: DEFAULT_PRIORITY,
  },
  FLOW: {
    code: 'FLOW',
    priority: 8,
  },
  FLOW_GEMS_RFO025IN: {
    code: 'FLOW_GEMS_RFO025IN',
    priority: DEFAULT_PRIORITY,
  },
  FUEL_RATE: {
    code: 'FUEL_RATE',
    priority: DEFAULT_PRIORITY,
  },
  GENERIC_BASIC_MODULE_TYPE: {
    code: 'GENERIC_BASIC_MODULE_TYPE',
    priority: DEFAULT_PRIORITY,
  },
  GENERIC_SENSOR_1: {
    code: 'GENERIC_SENSOR_1',
    priority: DEFAULT_PRIORITY,
  },
  GENERIC_SENSOR_10: {
    code: 'GENERIC_SENSOR_10',
    priority: DEFAULT_PRIORITY,
  },
  GENERIC_SENSOR_2: {
    code: 'GENERIC_SENSOR_2',
    priority: DEFAULT_PRIORITY,
  },
  GENERIC_SENSOR_3: {
    code: 'GENERIC_SENSOR_3',
    priority: DEFAULT_PRIORITY,
  },
  GENERIC_SENSOR_4: {
    code: 'GENERIC_SENSOR_4',
    priority: DEFAULT_PRIORITY,
  },
  GENERIC_SENSOR_5: {
    code: 'GENERIC_SENSOR_5',
    priority: DEFAULT_PRIORITY,
  },
  GENERIC_SENSOR_6: {
    code: 'GENERIC_SENSOR_6',
    priority: DEFAULT_PRIORITY,
  },
  GENERIC_SENSOR_7: {
    code: 'GENERIC_SENSOR_7',
    priority: DEFAULT_PRIORITY,
  },
  GENERIC_SENSOR_8: {
    code: 'GENERIC_SENSOR_8',
    priority: DEFAULT_PRIORITY,
  },
  GENERIC_SENSOR_9: {
    code: 'GENERIC_SENSOR_9',
    priority: DEFAULT_PRIORITY,
  },
  GENERIC_SPECIFIC_MODULE_TYPE: {
    code: 'GENERIC_SPECIFIC_MODULE_TYPE',
    priority: DEFAULT_PRIORITY,
  },
  GPS_LATITUDE: {
    code: 'GPS_LATITUDE',
    priority: DEFAULT_PRIORITY,
  },
  GPS_LONGITUDE: {
    code: 'GPS_LONGITUDE',
    priority: DEFAULT_PRIORITY,
  },
  GRAPEVINE_DEAD_ARM: {
    code: 'EXCORIOSE_VIGNE',
    priority: 25,
  },
  HAIL_24H: {
    code: 'HAIL_24H',
    priority: 14,
  },
  HAIL_FALL: {
    code: 'HAIL_FALL',
    priority: 14,
  },
  HORTAU_DRY_CONTACT: {
    code: 'HORTAU_DRY_CONTACT',
    priority: DEFAULT_PRIORITY,
  },
  HORTAU_ENGINE_STATUS: {
    code: 'HORTAU_ENGINE_STATUS',
    priority: DEFAULT_PRIORITY,
  },
  HOURLY_ETO: {
    code: 'HOURLY_ETO',
    priority: 17,
  },
  HP_COUNTER_CONFIG_PWM_IS_SOFTWARE: {
    code: 'HP_COUNTER_CONFIG_PWM_IS_SOFTWARE',
    priority: DEFAULT_PRIORITY,
  },
  HP_GENERIC_CONFIG_12V_ENABLE: {
    code: 'HP_GENERIC_CONFIG_12V_ENABLE',
    priority: DEFAULT_PRIORITY,
  },
  HP_GENERIC_CONFIG_5V_ENABLE: {
    code: 'HP_GENERIC_CONFIG_5V_ENABLE',
    priority: DEFAULT_PRIORITY,
  },
  HP_OXYGEN: {
    code: 'HP_OXYGEN',
    priority: DEFAULT_PRIORITY,
  },
  RST_RS_INTHUM: {
    code: 'RST_RS_INTHUM',
    priority: 130,
  },
  INTERNAL_TEMP: {
    code: 'INTERNAL_TEMP',
    priority: 100,
  },
  LEAF_WETNESS: {
    code: 'LEAF_WETNESS',
    priority: 20,
  },
  LEAF_WETNESS_HOURS: {
    code: 'LEAF_WETNESS_HOURS',
    priority: 21,
  },
  LEVEL_TRANSDUCER: {
    code: 'LEVEL_TRANSDUCER',
    priority: DEFAULT_PRIORITY,
  },
  LOG_ERREURS_WINDOWS_PAR_JOUR: {
    code: 'LOG_ERREURS_WINDOWS_PAR_JOUR',
    priority: DEFAULT_PRIORITY,
  },
  LOG_WARNINGS_WINDOWS_PAR_JOUR: {
    code: 'LOG_WARNINGS_WINDOWS_PAR_JOUR',
    priority: DEFAULT_PRIORITY,
  },
  MEMOIRE_LIBRE: {
    code: 'MEMOIRE_LIBRE',
    priority: DEFAULT_PRIORITY,
  },
  MOISISSURE_GRISE_BLEUET: {
    code: 'MOISISSURE_GRISE_BLEUET',
    priority: 25,
  },
  MONTHLY_ETO: {
    code: 'MONTHLY_ETO',
    priority: 18,
  },
  ON_OFF_AUTO: {
    code: 'ON_OFF_AUTO',
    priority: DEFAULT_PRIORITY,
  },
  PEAR_SCAB: {
    code: 'PEAR_SCAB',
    priority: 25,
  },
  PH_200MV: {
    code: 'PH_200MV',
    priority: 25,
  },
  PIPE_PRESSURE: {
    code: 'PIPE_PRESSURE',
    priority: 13,
  },
  POINT_ROSEE: {
    code: 'POINT_ROSEE',
    priority: 15,
  },
  PORE_WATER_CONDUCTIVITY: {
    code: 'PORE_WATER_CONDUCTIVITY',
    priority: 55,
  },
  PRESSURIZED_HOURS: {
    code: 'PRESSURIZED_HOURS',
    priority: 13,
  },
  PROTOCOL_VERSION: {
    code: 'PROTOCOL_VERSION',
    priority: DEFAULT_PRIORITY,
  },
  PRS_100: {
    code: 'PRS_100',
    priority: DEFAULT_PRIORITY,
  },
  PULSE_VALUE: {
    code: 'PULSE_VALUE',
    priority: DEFAULT_PRIORITY,
  },
  RAIN_24H: {
    code: 'RAIN_24H',
    priority: 5,
  },
  RAIN_FALL: {
    code: 'RAIN_FALL',
    priority: 5,
  },
  REBOOT_REASON_CODE: {
    code: 'REBOOT_REASON_CODE',
    priority: DEFAULT_PRIORITY,
  },
  RELATIVE_HUMIDITY: {
    code: 'RELATIVE_HUMIDITY',
    priority: 6,
  },
  RELAY: {
    code: 'RELAY',
    priority: DEFAULT_PRIORITY,
  },
  RG10MINCH: {
    code: 'RG10MINCH',
    priority: 5,
  },
  RM_BATT_VOLT: {
    code: 'RM_BATT_VOLT',
    priority: DEFAULT_PRIORITY,
  },
  RS6810DVR: {
    code: 'RS6810DVR',
    priority: DEFAULT_PRIORITY,
  },
  RS_3V_LITHIUM: {
    code: 'RS_3V_LITHIUM',
    priority: DEFAULT_PRIORITY,
  },
  RS_FREQ_COUNTER_75: {
    code: 'RS_FREQ_COUNTER_75',
    priority: DEFAULT_PRIORITY,
  },
  RS_HM1500: {
    code: 'RS_HM1500',
    priority: DEFAULT_PRIORITY,
  },
  RST_210_96V_BATT_LEVEL: {
    code: 'RST_210_96V_BATT_LEVEL',
    priority: DEFAULT_PRIORITY,
  },
  RST_210_BATT_CURRENT: {
    code: 'RST_210_BATT_CURRENT',
    priority: DEFAULT_PRIORITY,
  },
  RST_AGLNK: {
    code: 'RST_AGLNK',
    priority: DEFAULT_PRIORITY,
  },
  RST_DECAGON_5TE: {
    code: 'RST_DECAGON_5TE',
    priority: DEFAULT_PRIORITY,
  },
  RST_ERROR_RATE: {
    code: 'RST_ERROR_RATE',
    priority: DEFAULT_PRIORITY,
  },
  RST_HIH40XX: {
    code: 'RST_HIH40XX',
    priority: DEFAULT_PRIORITY,
  },
  RST_HORTAUSM: {
    code: 'RST_HORTAUSM',
    priority: DEFAULT_PRIORITY,
  },
  RST_HORTAU_ATODSIGNAL: {
    code: 'RST_HORTAU_ATODSIGNAL',
    priority: DEFAULT_PRIORITY,
  },
  RST_HORTAU_C1: {
    code: 'RST_HORTAU_C1',
    priority: DEFAULT_PRIORITY,
  },
  RST_HORTAU_C2: {
    code: 'RST_HORTAU_C2',
    priority: DEFAULT_PRIORITY,
  },
  RST_HORTAU_EXTVOLTAGE: {
    code: 'RST_HORTAU_EXTVOLTAGE',
    priority: DEFAULT_PRIORITY,
  },
  RST_HORTAU_RFBITS: {
    code: 'RST_HORTAU_RFBITS',
    priority: DEFAULT_PRIORITY,
  },
  RST_RS_96V_BATT_LEVEL: {
    code: 'RST_RS_96V_BATT_LEVEL',
    priority: DEFAULT_PRIORITY,
  },
  RST_RS_ECHO5_5V: {
    code: 'RST_RS_ECHO5_5V',
    priority: DEFAULT_PRIORITY,
  },
  RST_TEMP_1820: {
    code: 'RST_TEMP_1820',
    priority: 12,
  },
  RST_TEMP_SOIL_RSNTC: {
    code: 'RST_TEMP_SOIL_RSNTC',
    priority: 4,
  },
  RST_TEMP_RSNTC: {
    code: 'RST_TEMP_RSNTC',
    priority: 13,
  },
  SALINITY_WATER: {
    code: 'SALINITY_WATER',
    priority: 51,
  },
  SCLEROTINIA_ROT: {
    code: 'POURRITURE_SCLEROTIQUE',
    priority: 25,
  },
  SHIELDED_TEMPERATURE: {
    code: 'SHIELDED_TEMPERATURE',
    priority: 2,
  },
  SIGNAL_STRENGTH_DB: {
    code: 'SIGNAL_STRENGTH_DB',
    priority: 80,
  },
  SOIL_ELECTRICAL_CONDUCTIVITY: {
    code: 'SOIL_ELECTRICAL_CONDUCTIVITY',
    priority: 53,
  },
  SOIL_NITRATE_CONCENTRATION: {
    code: 'SOIL_NITRATE_CONCENTRATION',
    priority: 4,
  },
  SOIL_TEMPERATURE: {
    code: 'SOIL_TEMPERATURE',
    priority: 54,
  },
  DEC_AIR_TEMPERATURE: {
    code: 'DEC_AIR_TEMPERATURE',
    priority: 3,
  },
  SOLAR_RADIATION: {
    code: 'SOLAR_RADIATION',
    priority: 8,
  },
  STATUS_BYTE: {
    code: 'STATUS_BYTE',
    priority: DEFAULT_PRIORITY,
  },
  STRAWBERRY_LEAF_SPOT: {
    code: 'TACHE_COMM_FRAISIER',
    priority: 25,
  },
  SUCCESS_RATE: {
    code: 'SUCCESS_RATE',
    priority: DEFAULT_PRIORITY,
  },
  TEMPS_DE_BOUCLE: {
    code: 'TEMPS_DE_BOUCLE',
    priority: DEFAULT_PRIORITY,
  },
  TENSION_HOURS: {
    code: 'TENSION_HOURS',
    priority: 25,
  },
  TIME_SINCE_LAST_REPORT: {
    code: 'TIME_SINCE_LAST_REPORT',
    priority: 1,
  },
  TOTAL_VOLUME: {
    code: 'TOTAL_VOLUME',
    priority: 9,
  },
  TO_DELETE_DONNEES_POINT_CALCULE: {
    code: 'TO_DELETE_DONNEES_POINT_CALCULE',
    priority: DEFAULT_PRIORITY,
  },
  TRANSDUCER_PRESSURE: {
    code: 'TRANSDUCER_PRESSURE',
    priority: 13,
  },
  TRANSDUCER_LEVEL: {
    code: 'TRANSDUCER_LEVEL',
    priority: DEFAULT_PRIORITY,
  },
  TX_SIGNAL_STRENGTH: {
    code: 'TX_SIGNAL_STRENGTH',
    priority: DEFAULT_PRIORITY,
  },
  UNKNOWN: {
    code: 'UNKNOWN',
    priority: DEFAULT_PRIORITY,
  },
  UNSHIELDED_TEMPERATURE: {
    code: 'UNSHIELDED_TEMPERATURE',
    priority: 2,
  },
  VPD: {
    code: 'VPD',
    priority: 13,
  },
  VALVE: {
    code: 'VALVE',
    priority: DEFAULT_PRIORITY,
  },
  VOLUME_OFFSET: {
    code: 'VOLUME_OFFSET',
    priority: DEFAULT_PRIORITY,
  },
  WATERMARK_200_SSV: {
    code: 'WATERMARK_200_SSV',
    priority: DEFAULT_PRIORITY,
  },
  WATER_DEPTH: {
    code: 'WATER_DEPTH',
    priority: 10,
  },
  WATER_TENSION: {
    code: 'WATER_TENSION',
    priority: 1,
  },
  WATER_TEMPERATURE: {
    code: 'WATER_TEMPERATURE',
    priority: 52,
  },
  WD_3000_BATTERY_PERCENT: {
    code: 'WD_3000_BATTERY_PERCENT',
    priority: 150,
  },
  WDIRDAVIS: {
    code: 'WDIRDAVIS',
    priority: 9,
  },
  WEB_BASE_VALVE: {
    code: 'WEB_BASE_VALVE',
    priority: DEFAULT_PRIORITY,
  },
  WEB_BASE_VALVE_LATCH: {
    code: 'WEB_BASE_VALVE_LATCH',
    priority: DEFAULT_PRIORITY,
  },
  WEB_BASE_VALVE_MOMENTARY: {
    code: 'WEB_BASE_VALVE_MOMENTARY',
    priority: DEFAULT_PRIORITY,
  },
  WEB_BASE_VALVE_NO: {
    code: 'WEB_BASE_VALVE_NO',
    priority: 150,
  },
  WEB_BASE_CPU_TEMPERATURE: {
    code: 'WEB_BASE_CPU_TEMPERATURE',
    priority: 110,
  },
  RST_RM210_CHRG_CURRENT: {
    code: 'RST_RM210_CHRG_CURRENT',
    priority: 60,
  },
  RST_RM210_MAIN_CURRENT: {
    code: 'RST_RM210_MAIN_CURRENT',
    priority: 70,
  },
  WEEKLY_ETO: {
    code: 'WEEKLY_ETO',
    priority: 19,
  },
  WEEKLY_RAIN: {
    code: 'WEEKLY_RAIN',
    priority: 5,
  },
  WELL_DEPTH: {
    code: 'WELL_DEPTH',
    priority: 10,
  },
  WIND_DIRECTION: {
    code: 'WIND_DIRECTION',
    priority: 9,
  },
  WIND_GUST: {
    code: 'WIND_GUST',
    priority: 9.1,
  },
  WIND_SPEED: {
    code: 'WIND_SPEED',
    priority: 9,
  },
  WR_RECEIVER_BATTERY: {
    code: 'WR_RECEIVER_BATTERY',
    priority: DEFAULT_PRIORITY,
  },
  WS_AIR_TEMPERATURE: {
    code: 'WS_AIR_TEMPERATURE',
    priority: 3,
  },
  WS_RELATIVE_HUMIDITY: {
    code: 'WS_RELATIVE_HUMIDITY',
    priority: 6,
  },
  WSS225MPHHZ: {
    code: 'WSS225MPHHZ',
    priority: 9,
  },
  YARDNEY_12V_RAIL_VOLTAGE: {
    code: 'YARDNEY_12V_RAIL_VOLTAGE',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_ACTIVE_ERROR_CODES: {
    code: 'YARDNEY_ACTIVE_ERROR_CODES',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_ACTIVE_FLUSH_DAYS: {
    code: 'YARDNEY_ACTIVE_FLUSH_DAYS',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_BACKWASH_ATTEMPS: {
    code: 'YARDNEY_BACKWASH_ATTEMPS',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_BACKWASH_COUNT: {
    code: 'YARDNEY_BACKWASH_COUNT',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_BACKWASH_MODE: {
    code: 'YARDNEY_BACKWASH_MODE',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_CORRECTED_SYSTEM_TIME: {
    code: 'YARDNEY_CORRECTED_SYSTEM_TIME',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_CTRL_BOOTLOADER_BUILD: {
    code: 'YARDNEY_CTRL_BOOTLOADER_BUILD',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_CTRL_BOOTLOADER_VERSION: {
    code: 'YARDNEY_CTRL_BOOTLOADER_VERSION',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_CTRL_FIRMWARE_BUILD: {
    code: 'YARDNEY_CTRL_FIRMWARE_BUILD',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_CTRL_FIRMWARE_COMPILE_DATE: {
    code: 'YARDNEY_CTRL_FIRMWARE_COMPILE_DATE',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_CTRL_FIRMWARE_VERSION: {
    code: 'YARDNEY_CTRL_FIRMWARE_VERSION',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_CTRL_SERIAL_NUMBER: {
    code: 'YARDNEY_CTRL_SERIAL_NUMBER',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_DAMAGED_STATIONS: {
    code: 'YARDNEY_DAMAGED_STATIONS',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_DCS_CONFIG: {
    code: 'YARDNEY_DCS_CONFIG',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_DEVICE_STATUS: {
    code: 'YARDNEY_DEVICE_STATUS',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_DIFF_PRESSURE_SENSOR_DELAY: {
    code: 'YARDNEY_DIFF_PRESSURE_SENSOR_DELAY',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_DP_BACKWASH_COUNT: {
    code: 'YARDNEY_DP_BACKWASH_COUNT',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_DP_ERROR_CONTROL: {
    code: 'YARDNEY_DP_ERROR_CONTROL',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_EO_WF_I_SACTIVE: {
    code: 'YARDNEY_EO_WF_I_SACTIVE',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_ERROR_CONFIG_FOR_DCS: {
    code: 'YARDNEY_ERROR_CONFIG_FOR_DCS',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_FLAGS: {
    code: 'YARDNEY_FLAGS',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_GENERAL_STATE: {
    code: 'YARDNEY_GENERAL_STATE',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_INDIVIDUAL_STATION_BACKWASH_DURATION: {
    code: 'YARDNEY_INDIVIDUAL_STATION_BACKWASH_DURATION',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_LANGUAGE: {
    code: 'YARDNEY_LANGUAGE',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_LOG_ENTRY: {
    code: 'YARDNEY_LOG_ENTRY',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_LOG_ENTRY_COUNTER: {
    code: 'YARDNEY_LOG_ENTRY_COUNTER',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_MAC_ADDRESS: {
    code: 'YARDNEY_MAC_ADDRESS',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_MANUFACTURER_ID: {
    code: 'YARDNEY_MANUFACTURER_ID',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_MASTER_VALVE_TIME_BEFORE_ENABLING_STATIONS: {
    code: 'YARDNEY_MASTER_VALVE_TIME_BEFORE_ENABLING_STATIONS',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_NB_OF_WINDOWS_OR_SCHEDULED_BW: {
    code: 'YARDNEY_NB_OF_WINDOWS_OR_SCHEDULED_BW',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_PERIOD: {
    code: 'YARDNEY_PERIOD',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_PRE_BACKWASH_DELAY: {
    code: 'YARDNEY_PRE_BACKWASH_DELAY',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_SCHEDULED_BEGIN_TIME: {
    code: 'YARDNEY_SCHEDULED_BEGIN_TIME',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_SCREEN_TIMEOUT: {
    code: 'YARDNEY_SCREEN_TIMEOUT',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_STATE_TIME_REMAINING: {
    code: 'YARDNEY_STATE_TIME_REMAINING',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_SYSTEM_IS_ON: {
    code: 'YARDNEY_SYSTEM_IS_ON',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_SYSTEM_STATE: {
    code: 'YARDNEY_SYSTEM_STATE',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_SYSTEM_TIME: {
    code: 'YARDNEY_SYSTEM_TIME',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_TIME_OF_NEXT_BACKWASH: {
    code: 'YARDNEY_TIME_OF_NEXT_BACKWASH',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_TIME_OF_PREVIOUS_BACKWASH: {
    code: 'YARDNEY_TIME_OF_PREVIOUS_BACKWASH',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_TOTAL_DURATION_BACKWASH: {
    code: 'YARDNEY_TOTAL_DURATION_BACKWASH',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_WINDOW_BEGIN_TIME: {
    code: 'YARDNEY_WINDOW_BEGIN_TIME',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_WINDOW_END_TIME: {
    code: 'YARDNEY_WINDOW_END_TIME',
    priority: DEFAULT_PRIORITY,
  },
  YARDNEY_WINDOW_INFO: {
    code: 'YARDNEY_WINDOW_INFO',
    priority: DEFAULT_PRIORITY,
  },
  YEARLY_ETO: {
    code: 'YEARLY_ETO',
    priority: 20,
  },
  YEARLY_LEACH: {
    code: 'YEARLY_LEACH',
    priority: 23,
  },
  YEARLY_LEACH_HEIGHT: {
    code: 'YEARLY_LEACH_HEIGHT',
    priority: DEFAULT_PRIORITY,
  },
  YEARLY_RAIN: {
    code: 'YEARLY_RAIN',
    priority: 5,
  },
};

export const LOCAL_DATA = [
  DATA_STREAMS_TYPES.BLUEBERRY_GRAY_MOLD.code,
  DATA_STREAMS_TYPES.CHILL_PORTIONS.code,
  DATA_STREAMS_TYPES.CHILLING_HOURS.code,
  DATA_STREAMS_TYPES.CURRENT.code,
  DATA_STREAMS_TYPES.DAILY_ETO.code,
  DATA_STREAMS_TYPES.DEGREE_DAYS.code,
  DATA_STREAMS_TYPES.DEW_POINT.code,
  DATA_STREAMS_TYPES.DIELECTRIC_PERMITTIVITY.code,
  DATA_STREAMS_TYPES.ELECTRICAL_CONDUCTIVITY.code,
  DATA_STREAMS_TYPES.FLOW.code,
  DATA_STREAMS_TYPES.GRAPEVINE_DEAD_ARM.code,
  DATA_STREAMS_TYPES.HOURLY_ETO.code,
  DATA_STREAMS_TYPES.LEAF_WETNESS.code,
  DATA_STREAMS_TYPES.LEAF_WETNESS_HOURS.code,
  DATA_STREAMS_TYPES.MONTHLY_ETO.code,
  DATA_STREAMS_TYPES.PEAR_SCAB.code,
  DATA_STREAMS_TYPES.PH_200MV.code,
  DATA_STREAMS_TYPES.PIPE_PRESSURE.code,
  DATA_STREAMS_TYPES.PORE_WATER_CONDUCTIVITY.code,
  DATA_STREAMS_TYPES.PRESSURIZED_HOURS.code,
  DATA_STREAMS_TYPES.RAIN_24H.code,
  DATA_STREAMS_TYPES.RAIN_FALL.code,
  DATA_STREAMS_TYPES.RELATIVE_HUMIDITY.code,
  DATA_STREAMS_TYPES.SALINITY_WATER.code,
  DATA_STREAMS_TYPES.SCLEROTINIA_ROT.code,
  DATA_STREAMS_TYPES.SHIELDED_TEMPERATURE.code,
  DATA_STREAMS_TYPES.SOIL_ELECTRICAL_CONDUCTIVITY.code,
  DATA_STREAMS_TYPES.SOIL_NITRATE_CONCENTRATION.code,
  DATA_STREAMS_TYPES.SOIL_TEMPERATURE.code,
  DATA_STREAMS_TYPES.DEC_AIR_TEMPERATURE.code,
  DATA_STREAMS_TYPES.SOLAR_RADIATION.code,
  DATA_STREAMS_TYPES.STRAWBERRY_LEAF_SPOT.code,
  DATA_STREAMS_TYPES.TENSION_HOURS.code,
  DATA_STREAMS_TYPES.TOTAL_VOLUME.code,
  DATA_STREAMS_TYPES.TRANSDUCER_PRESSURE.code,
  DATA_STREAMS_TYPES.UNKNOWN.code,
  DATA_STREAMS_TYPES.UNSHIELDED_TEMPERATURE.code,
  DATA_STREAMS_TYPES.VPD.code,
  DATA_STREAMS_TYPES.WATER_DEPTH.code,
  DATA_STREAMS_TYPES.WATER_TEMPERATURE.code,
  DATA_STREAMS_TYPES.WEEKLY_ETO.code,
  DATA_STREAMS_TYPES.WEEKLY_RAIN.code,
  DATA_STREAMS_TYPES.WELL_DEPTH.code,
  DATA_STREAMS_TYPES.YEARLY_ETO.code,
  DATA_STREAMS_TYPES.YEARLY_LEACH.code,
  DATA_STREAMS_TYPES.YEARLY_RAIN.code,
];

export const WEATHER_DATA = [
  DATA_STREAMS_TYPES.ATMOSPHERIC_PRESSURE.code,
  DATA_STREAMS_TYPES.BLUEBERRY_GRAY_MOLD.code,
  DATA_STREAMS_TYPES.CHILL_PORTIONS.code,
  DATA_STREAMS_TYPES.CHILLING_HOURS.code,
  DATA_STREAMS_TYPES.DAILY_ETO.code,
  DATA_STREAMS_TYPES.DEGREE_DAYS.code,
  DATA_STREAMS_TYPES.DEW_POINT.code,
  DATA_STREAMS_TYPES.DIELECTRIC_PERMITTIVITY.code,
  DATA_STREAMS_TYPES.ELECTRICAL_CONDUCTIVITY.code,
  DATA_STREAMS_TYPES.GRAPEVINE_DEAD_ARM.code,
  DATA_STREAMS_TYPES.HAIL_24H.code,
  DATA_STREAMS_TYPES.HAIL_FALL.code,
  DATA_STREAMS_TYPES.HOURLY_ETO.code,
  DATA_STREAMS_TYPES.LEAF_WETNESS.code,
  DATA_STREAMS_TYPES.LEAF_WETNESS_HOURS.code,
  DATA_STREAMS_TYPES.MONTHLY_ETO.code,
  DATA_STREAMS_TYPES.PEAR_SCAB.code,
  DATA_STREAMS_TYPES.RAIN_24H.code,
  DATA_STREAMS_TYPES.RAIN_FALL.code,
  DATA_STREAMS_TYPES.RELATIVE_HUMIDITY.code,
  DATA_STREAMS_TYPES.RG10MINCH.code,
  DATA_STREAMS_TYPES.RST_TEMP_1820.code,
  DATA_STREAMS_TYPES.RST_TEMP_RSNTC.code,
  DATA_STREAMS_TYPES.SCLEROTINIA_ROT.code,
  DATA_STREAMS_TYPES.SHIELDED_TEMPERATURE.code,
  DATA_STREAMS_TYPES.SOLAR_RADIATION.code,
  DATA_STREAMS_TYPES.STRAWBERRY_LEAF_SPOT.code,
  DATA_STREAMS_TYPES.UNKNOWN.code,
  DATA_STREAMS_TYPES.UNSHIELDED_TEMPERATURE.code,
  DATA_STREAMS_TYPES.VPD.code,
  DATA_STREAMS_TYPES.WDIRDAVIS.code,
  DATA_STREAMS_TYPES.WATER_DEPTH.code,
  DATA_STREAMS_TYPES.WEEKLY_ETO.code,
  DATA_STREAMS_TYPES.WEEKLY_RAIN.code,
  DATA_STREAMS_TYPES.WELL_DEPTH.code,
  DATA_STREAMS_TYPES.WIND_DIRECTION.code,
  DATA_STREAMS_TYPES.WIND_GUST.code,
  DATA_STREAMS_TYPES.WIND_SPEED.code,
  DATA_STREAMS_TYPES.WS_AIR_TEMPERATURE.code,
  DATA_STREAMS_TYPES.WS_RELATIVE_HUMIDITY.code,
  DATA_STREAMS_TYPES.WSS225MPHHZ.code,
  DATA_STREAMS_TYPES.YEARLY_ETO.code,
  DATA_STREAMS_TYPES.YEARLY_LEACH.code,
  DATA_STREAMS_TYPES.YEARLY_RAIN.code,
];

export const HEALTH_DATA = [
  DATA_STREAMS_TYPES.TIME_SINCE_LAST_REPORT.code,
  DATA_STREAMS_TYPES.BATTERY_VOLTAGE_SUPPLY.code,
  DATA_STREAMS_TYPES.BATTERY_3V.code,
  DATA_STREAMS_TYPES.BATTERY_3_7_V.code,
  DATA_STREAMS_TYPES.BATTERY_12V.code,
  DATA_STREAMS_TYPES.RST_RM210_CHRG_CURRENT.code,
  DATA_STREAMS_TYPES.RST_RM210_MAIN_CURRENT.code,
  DATA_STREAMS_TYPES.SIGNAL_STRENGTH_DB.code,
  DATA_STREAMS_TYPES.CELLULAR_SUCCESS_RATE.code,
  DATA_STREAMS_TYPES.INTERNAL_TEMP.code,
  DATA_STREAMS_TYPES.WEB_BASE_CPU_TEMPERATURE.code,
  DATA_STREAMS_TYPES.CPU_USAGE.code,
  DATA_STREAMS_TYPES.RST_RS_INTHUM.code,
  DATA_STREAMS_TYPES.EXTERNAL_SENSOR_4_TO_20_MA.code,
  DATA_STREAMS_TYPES.AG_3000_BATTERY_VOLTAGE.code,
  DATA_STREAMS_TYPES.WD_3000_BATTERY_PERCENT.code,
  DATA_STREAMS_TYPES.UNKNOWN.code,
];
