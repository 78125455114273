import { View, StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';

//hooks
import { useTranslation } from '../../hooks/useTranslation';

//icons
import icnBattery from '../../assets/icons/icnBatteryWidget.png';
import icnSignal from '../../assets/icons/icnSignalStrengthWidget.png';
import icnNormal from '../../assets/icons/icnNormal.png';

//entity
import DeviceEntity from '../../models/entities/deviceEntity';

//constants
import FONTS from '../../fonts';
import COLORS from '../../colors';

// styles
import { MINIMUM_FONT_SCALE } from '../../styles.js';

//components
import SummaryBox from '../SummaryBox';
import EmptyView from '../EmptyView';

const DeviceDetailsSummary = ({ device, testId }) => {
  const { t } = useTranslation();

  const batteryText = device.batteryText(t);
  const signalText = device.signalText(t);

  const renderPodContent = (text) => {
    return (
      <View style={styles.podContent}>
        <Text style={styles.podText} adjustsFontSizeToFit minimumFontScale={MINIMUM_FONT_SCALE} numberOfLines={1}>
          {text}
        </Text>
      </View>
    );
  };

  const renderWidget = () => {
    if (batteryText || signalText) {
      return (
        <>
          {batteryText && (
            <SummaryBox title={t('device_health_battery')} image={icnBattery} podContent={renderPodContent(batteryText)} />
          )}
          {signalText && (
            <SummaryBox title={t('device_health_signal_strength')} image={icnSignal} podContent={renderPodContent(signalText)} />
          )}
        </>
      );
    } else {
      return <EmptyView icon={icnNormal} iconSizeStyle={styles.emptyIcon} textKey="device_detail_widget_empty" />;
    }
  };

  return (
    <View style={styles.container} testID={testId}>
      {renderWidget()}
    </View>
  );
};

DeviceDetailsSummary.propTypes = {
  device: PropTypes.instanceOf(DeviceEntity).isRequired,
  onClick: PropTypes.func,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  emptyIcon: {
    width: 44,
    height: 40,
  },
  podContent: {
    alignItems: 'center',
  },
  podText: {
    width: 72,
    textAlign: 'center',
    fontFamily: FONTS.notoSansBold,
    fontSize: 18,
    letterSpacing: 0.64,
    color: COLORS.white,
  },
});

export default DeviceDetailsSummary;
