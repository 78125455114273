import { useCallback, useEffect, useState, useRef } from 'react';
import { RefreshControl, ScrollView, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';

// proptype
import { navigationShape } from '../../shapes/navigation';

// hook
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import { useBackHandler } from '../../hooks/useBackHandler';

// model
import BlockEntity from '../../models/entities/blockEntity';
import * as CAPABILITIES from '../../models/capability';

// services
import { ORIENTATIONS } from '../../services/DeviceOrientations';
import { isPortrait, isLandscape } from '../../services/DeviceOrientationService';
import ANALYTICS from '../../services/AnalyticsEvents';

// presenter
import BlockAliasPresenter from '../../presenters/blockAlias/BlockAliasPresenter';

// styles
import { globalStyles, PADDING } from '../../styles';

// constants
import COLORS from '../../colors';

// components
import { computeInitialDateRange } from '../../components/graph/ChartXAxisHelper';
import BlockDetails from '../../components/blockDetails/BlockDetails';
import Divider from '../../components/Divider';
import GraphDetails from '../../components/blockDetails/GraphDetails';
import HeaderBack from '../../components/header/HeaderBack';
import LegendButton from '../../components/button/LegendButton';
import RotatableComponent from '../../components/RotatableComponent';
import LoadingOverlay from '../../components/LoadingOverlay';

import BlockDetailsSubHeader from './BlockDetailsSubHeader';
import * as TABS from './BlockDetailsTabs';

const BlockDetailsScreen = ({ navigation, route }) => {
  const { activeTab, capability, defaultSelectedStreamIds, displayTabs, routeToGoBackTo, unit } = route.params || {};
  const { block: blockHeader } = route.params || {};

  const currentSite = useSelector((state) => state.site.currentSite);
  const blockDetails = useSelector((state) => state.blocks.selectedBlock);
  const selectedDataStreams = useSelector((state) => state.graph.selectedDataStreams);
  const block = useSelector((state) => state.blocks.irrigationBlock);
  const loadingBlock = useSelector((state) => !!state.loading.effects.blocks.loadIrrigationBlockById);
  const [currentOrientation, setCurrentOrientation] = useState(ORIENTATIONS.PORTRAIT);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedTab, setSelectedTab] = useState(activeTab);
  const analyticsService = useAnalyticsContext();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const scrollViewRef = useRef(undefined);
  const loadedSelectedDataStreamsCache = useRef([]);
  const chartRef = useRef();
  const isFocused = useIsFocused();
  const dispatch = useDispatch();

  const [dataRange, setDataRange] = useState(computeInitialDateRange(currentSite));

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      scrollViewRef.current?.scrollTo({ x: 0, y: 0, animated: false });

      if (block) {
        onRefresh();
      }

      return () => {
        dispatch.graph.reset();
        loadedSelectedDataStreamsCache.current = [];
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigation]),
  );

  useEffect(() => {
    dispatch.blocks.updateSelectedEntityBlock(blockHeader);
  }, [blockHeader, dispatch.blocks]);

  /* istanbul ignore next */
  useEffect(() => {
    if (activeTab) {
      setSelectedTab(activeTab);
      analyticsService.trackEvent(activeTab.analyticsEvent);
    }
  }, [activeTab, analyticsService]);

  const [debouncedLoadDataStreams] = useDebouncedCallback(dispatch.graph.loadDataStreams, 200);

  const loadGraphData = (loadEvents) => {
    if (isFocused && dataRange.length > 0) {
      const [startDate, endDate] = dataRange;

      debouncedLoadDataStreams({
        siteId: currentSite.id,
        dataStreamIds: loadedSelectedDataStreamsCache.current,
        startDate,
        endDate,
      });

      if (block && loadEvents) {
        dispatch.graph.loadIrrigationEvents({
          siteId: currentSite.id,
          blockId: block.id,
          startDate,
          endDate,
        });
      }

      if (block && showTensionForecast) {
        dispatch.graph.loadTensionForecast({
          siteId: currentSite.id,
          blockId: block.id,
        });
      }
    }
  };

  const onRefresh = async () => {
    setIsRefreshing(true);

    if (selectedTab === TABS.GRAPH && dataRange.length > 0) {
      const [startDate, endDate] = dataRange;
      const dataStreamsPromise = dispatch.graph.loadDataStreams({
        siteId: currentSite.id,
        dataStreamIds: [...selectedDataStreams.left.ids, ...selectedDataStreams.right.ids],
        startDate,
        endDate,
      });

      const blockPromise = dispatch.blocks.loadBlockDataStreams({ blockId: block.id });

      const weatherPromise = dispatch.graph.loadSiteWeatherDataStreams();

      const irrigationEventsPromise = dispatch.graph.loadIrrigationEvents({
        siteId: currentSite.id,
        blockId: block.id,
        startDate,
        endDate,
      });
      await Promise.all([dataStreamsPromise, blockPromise, weatherPromise, irrigationEventsPromise]);
      chartRef.current?.refresh();
    } else {
      const blockPromise = dispatch.blocks.loadBlockDataStreams({ blockId: block.id });
      const irrigationBlockPromise = dispatch.blocks.loadIrrigationBlockById({ blockId: block.id });
      await Promise.all([blockPromise, irrigationBlockPromise]);
    }

    setIsRefreshing(false);
  };

  const goBack = useCallback(async () => {
    if (isLandscape(currentOrientation)) {
      setCurrentOrientation(ORIENTATIONS.PORTRAIT);
    }
    setSelectedTab(activeTab);
    await dispatch.blocks.updateIrrigationBlocks([]);
    if (routeToGoBackTo) {
      navigation.navigate(routeToGoBackTo);
    }
    return true;
  }, [activeTab, dispatch.blocks, navigation, routeToGoBackTo, currentOrientation]);

  useBackHandler(goBack);

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    if (defaultSelectedStreamIds?.length > 0) {
      dispatch.graph.setDefaultSelectedDataStreams({ left: { ids: defaultSelectedStreamIds, unit } });
    } else if (blockDetails?.dataStreams().length > 0) {
      const [{ id: firstId, unit: firstUnit }] = blockDetails.dataStreams();
      dispatch.graph.setDefaultSelectedDataStreams({ left: { ids: [firstId], unit: firstUnit } });
    }
  }, [defaultSelectedStreamIds, dispatch.graph, unit, blockDetails, isFocused, capability]);

  const needToReloadData = (ids) => ids.some((id) => loadedSelectedDataStreamsCache.current.indexOf(id) === -1);

  useEffect(() => {
    const idsNeeded = [...selectedDataStreams.left.ids, ...selectedDataStreams.right.ids];
    if (needToReloadData(idsNeeded)) {
      loadedSelectedDataStreamsCache.current = [...new Set([...loadedSelectedDataStreamsCache.current, ...idsNeeded])];
      loadGraphData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataStreams.left.ids, selectedDataStreams.right.ids]);

  useEffect(() => {
    loadGraphData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRange]);

  useEffect(() => {
    if (isPortrait(currentOrientation) && isModalOpened) {
      setIsModalOpened(false);
    }
    if (isLandscape(currentOrientation)) {
      analyticsService.trackEvent(ANALYTICS.eventRotateGraphLandscape);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrientation]);

  const changeActiveTab = (index) => {
    const newActiveTab = Object.values(TABS).find((tab) => tab.index === index);
    setSelectedTab(newActiveTab);
    analyticsService.trackEvent(newActiveTab.analyticsEvent);
  };

  const toggleModal = () => {
    setIsModalOpened(!isModalOpened);
  };

  const onGraphInteraction = (newDateRange) => setDataRange(newDateRange);

  const goToDetailsTab = () => {
    if (displayTabs) {
      changeActiveTab(TABS.DETAILS.index);
    }
  };

  const blockAliasColor = new BlockAliasPresenter(blockDetails, capability).getBlockHeaderColor();

  const isTabGraphActive = isFocused && selectedTab === TABS.GRAPH && block;

  const isTabDetailsActive = selectedTab === TABS.DETAILS && block;

  const baseBlock = capability === CAPABILITIES.TEMPERATURE ? blockHeader : block;

  const showTensionForecast =
    currentSite.supportsTensionForecast &&
    capability === CAPABILITIES.TENSION &&
    blockHeader?.isTensionCapable() &&
    [...selectedDataStreams.left.ids, ...selectedDataStreams.right.ids].some((id) => defaultSelectedStreamIds.includes(id));

  return (
    <>
      <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
        <View style={globalStyles.header} testID="block-details__subscreen-container">
          <HeaderBack
            screenName="block-details"
            navigation={navigation}
            goBack={goBack}
            title={blockHeader?.alias}
            subtitle={blockHeader?.name}
            titleColor={blockAliasColor}
            actions={isPortrait(currentOrientation) ? null : <LegendButton onPress={toggleModal} />}
          />
        </View>

        {displayTabs && isPortrait(currentOrientation) ? (
          <>
            <View>
              <BlockDetailsSubHeader selectedTab={selectedTab} changeActiveTab={changeActiveTab} />
            </View>

            <Divider />
          </>
        ) : null}

        <View style={isPortrait(currentOrientation) ? globalStyles.bottomContainer : styles.bottomContainerLandscape}>
          <ScrollView
            ref={scrollViewRef}
            contentContainerStyle={isPortrait(currentOrientation) ? styles.scrollContainerFlex : styles.scrollContainerLandscape}
            testID={'block-details__scroll-view'}
            refreshControl={
              <RefreshControl testID="block-details__refresh-controller" onRefresh={onRefresh} refreshing={isRefreshing} />
            }
            showsVerticalScrollIndicator={false}>
            {loadingBlock && !isRefreshing ? (
              <View style={styles.loading}>
                <LoadingOverlay isLoading={true} handleTouchEvents={false} />
              </View>
            ) : null}
            {isTabGraphActive ? (
              <RotatableComponent
                testID="block-details__rotatable-component"
                currentOrientation={currentOrientation}
                onOrientationChange={setCurrentOrientation}>
                <GraphDetails
                  ref={chartRef}
                  baseBlock={baseBlock}
                  capability={capability}
                  selectedDataStreams={selectedDataStreams}
                  goToDetails={goToDetailsTab}
                  onGraphInteraction={onGraphInteraction}
                  testID="block-details__graph-details"
                  isModalOpened={isModalOpened}
                  toggleModal={toggleModal}
                  showTensionForecast={showTensionForecast}
                />
              </RotatableComponent>
            ) : null}
            {isTabDetailsActive ? <BlockDetails block={block} navigation={navigation} /> : null}
          </ScrollView>
        </View>
      </SafeAreaView>
    </>
  );
};

BlockDetailsScreen.propTypes = {
  navigation: navigationShape.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      activeTab: PropTypes.shape({
        index: PropTypes.number,
        key: PropTypes.string,
        analyticsEvent: PropTypes.string,
      }).isRequired,
      capability: PropTypes.oneOf([CAPABILITIES.TENSION, CAPABILITIES.TEMPERATURE, CAPABILITIES.FLOW_STATION]).isRequired,
      block: PropTypes.instanceOf(BlockEntity).isRequired,
      defaultSelectedStreamIds: PropTypes.arrayOf(String).isRequired,
      displayTabs: PropTypes.bool.isRequired,
      reloadData: PropTypes.bool,
      routeToGoBackTo: PropTypes.string.isRequired,
      unit: PropTypes.string,
    }),
  }).isRequired,
};

const styles = StyleSheet.create({
  bottomContainerLandscape: {
    flex: 1,
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  scrollContainerFlex: {
    flexGrow: 1,
    paddingBottom: PADDING.LARGE,
    backgroundColor: COLORS.greyish20,
  },
  scrollContainerLandscape: {
    flexGrow: 1,
  },
});

export default BlockDetailsScreen;
