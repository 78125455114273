import PropTypes from 'prop-types';
import { Text, View, StyleSheet, Image, Pressable } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

// shape
import { styleShape } from '../../shapes/style';

// icons
import icnNavRight from '../../assets/icons/icnNavRight.png';

// services
import COLORS from '../../colors';
import FONTS from '../../fonts';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../navigation/routes';
import { navigationShape } from '../../shapes/navigation';
import { DashboardPresenter } from '../../presenters/DashboardPresenter';
import { MapDisplayMode, MapStyleMode } from '../../presenters/MapDisplayMode';
import ANALYTICS from '../../services/AnalyticsEvents';

// components
import ShadowedDivider from '../ShadowedDivider';
import { useAnalyticsContext } from '../initialization/AnalyticsProvider';
import SkeletonPlaceholder from '../skeletons/SkeletonPlaceholder';

const HEIGHT = 50;

const TemperatureProtection = ({ contentStyle, dashboardPresenter, loading, navigation, testId }) => {
  const { t } = useTranslation();
  const analyticsService = useAnalyticsContext();
  const criticalTemperature = dashboardPresenter.criticalTemperature();
  const dispatch = useDispatch();
  const mapStyle = useSelector((state) => state.map.style);

  const goToMap = () => {
    if (mapStyle === MapStyleMode.NDVI) {
      dispatch.map.setMapStyle(MapStyleMode.SATELLITE);
    }
    dispatch.map.setMapMode(MapDisplayMode.TEMPERATURE);
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewMap);
    dispatch.map.loadMapBlocksForCurrentSite();
    navigation.navigate(ROUTES.MAP, { routeToGoBackTo: ROUTES.DASHBOARD });
  };

  const renderTemperatureProtection = () => {
    if (loading) {
      return (
        <View style={[styles.content, styles.centerRow]} testID="loadingCriticalTemperature">
          <View style={styles.centerRow}>
            <SkeletonPlaceholder width={17} height={34} />
            <SkeletonPlaceholder width={80} height={11} style={styles.skeletonTemperature} />
          </View>
          <View style={styles.centerRow}>
            <SkeletonPlaceholder width={60} height={30} />
          </View>
        </View>
      );
    } else {
      return (
        <View style={[styles.content, styles.centerRow]}>
          <View style={styles.centerRow}>
            <Image source={criticalTemperature.icon()} style={styles.temperatureIcon} />
            <Text style={styles.temperatureText}>{criticalTemperature.formattedTemperature()}</Text>
          </View>

          <View style={styles.centerRow}>
            <Text style={styles.heatProtectionText}>{criticalTemperature.protectionState()}</Text>
            <Text
              style={[
                {
                  color: criticalTemperature.statusColor(),
                  backgroundColor: criticalTemperature.statusBackgroundColor(),
                },
                styles.heatProtectionBoxText,
              ]}>
              {criticalTemperature.status()}
            </Text>
          </View>
        </View>
      );
    }
  };

  if (criticalTemperature) {
    return (
      <View style={contentStyle} testID={testId}>
        <View style={[styles.header, styles.centerRow]}>
          <Text style={styles.title}>{t('weather_temperature')}</Text>

          <Pressable style={styles.centerRow} onPress={goToMap} testID={'critical-temperature_mapLink'}>
            <Text style={styles.details}>{t('see_map')}</Text>
            <Image source={icnNavRight} style={styles.arrowSpacing} />
          </Pressable>
        </View>

        <ShadowedDivider />

        {renderTemperatureProtection()}
      </View>
    );
  } else {
    return null;
  }
};

TemperatureProtection.propTypes = {
  contentStyle: styleShape,
  dashboardPresenter: PropTypes.instanceOf(DashboardPresenter).isRequired,
  loading: PropTypes.bool,
  navigation: navigationShape.isRequired,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  arrowSpacing: {
    marginLeft: 10,
  },
  centerRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    justifyContent: 'space-between',
    height: HEIGHT,
    marginHorizontal: 17,
  },
  details: {
    fontFamily: FONTS.firaSansLight,
    fontSize: 14,
    color: COLORS.greyishBrown,
  },
  header: {
    justifyContent: 'space-between',
    height: HEIGHT,
    paddingRight: 17,
    paddingLeft: 15,
  },
  heatProtectionText: {
    fontFamily: FONTS.firaSans,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
  heatProtectionBoxText: {
    paddingHorizontal: 9,
    paddingVertical: 5,
    marginLeft: 7,
    fontFamily: FONTS.notoSansBold,
    fontSize: 14,
  },
  skeletonTemperature: {
    marginLeft: 16,
  },
  temperatureIcon: {
    width: 17,
    height: 34,
  },
  temperatureText: {
    marginLeft: 16,
    fontFamily: FONTS.notoSansBold,
    fontSize: 22,
    color: COLORS.greyishBrown,
  },
  title: {
    fontFamily: FONTS.firaSansMedium,
    fontSize: 18,
    color: COLORS.greyishBrown,
  },
});

export default TemperatureProtection;
