import { StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

//constants
import FONTS from '../../fonts';
import COLORS from '../../colors';

//services
import BlueBandIndexStatuses from '../../models/entities/blueBandIndexStatuses';
import { useTranslation } from '../../hooks/useTranslation';

//component
import BlueBandIndexStackLegend from './BlueBandIndexStackLegend';

const SMALL_VALUE_TEXT_SIZE = 11;
const BIG_VALUE_TEXT_SIZE = 14;

const NO_TEXT_VALUE_LIMIT = 10;
const BIG_TEXT_VALUE_LIMIT = 20;

const GRAPH_HEIGHT_MULTIPLIER = 1.65;

const BlueBandIndexStack = ({ blueBandIndexData }) => {
  const { t } = useTranslation();

  const blueBandIndexes = new BlueBandIndexStatuses(blueBandIndexData);
  const { dry, good, wet } = blueBandIndexes.statuses(t);
  const isValidStatus = (status) => !!status && status.value !== null;

  const hasData = isValidStatus(dry) || isValidStatus(good) || isValidStatus(wet);

  const getNotNullStatus = () => [dry, good, wet].filter((status) => status && status.value);

  if (!hasData) {
    return (
      <View style={styles.container}>
        <BlueBandIndexStackLegend
          blueBandIndexStatuses={blueBandIndexes}
          hasData={hasData}
          notNullStatusCount={getNotNullStatus().length}
        />
        <View style={styles.graphContainer}>
          <View style={styles.naBar}>
            <Text style={styles.naText}>{t('blue_band_index_na')}</Text>
          </View>
        </View>
      </View>
    );
  }

  const createValueText = (value) => {
    if (value <= NO_TEXT_VALUE_LIMIT) {
      return null;
    }

    let fontSize = SMALL_VALUE_TEXT_SIZE;
    if (value >= BIG_TEXT_VALUE_LIMIT) {
      fontSize = BIG_VALUE_TEXT_SIZE;
    }
    return <Text style={[styles.valueText, { fontSize }]}>{`${value}%`}</Text>;
  };

  const generateGraphBar = (status, index) => {
    const barStyle = {
      backgroundColor: status.color,
      height: status.value * GRAPH_HEIGHT_MULTIPLIER,
      justifyContent: 'center',
    };

    return (
      <View style={barStyle} key={'bar' + index}>
        {createValueText(status.value)}
      </View>
    );
  };

  const addDividerStatus = (statusArray) => {
    const dividerStatus = {
      color: COLORS.white,
      value: 1,
    };

    return [...statusArray].reduce((statusWithDivider, status, index) => {
      if (index < statusArray.length - 1) {
        return statusWithDivider.concat([status, dividerStatus]);
      } else {
        return statusWithDivider.concat([status]);
      }
    }, []);
  };

  const generateGraph = () => {
    const notNullStatus = getNotNullStatus();
    const statusWithDivider = addDividerStatus(notNullStatus);
    const graphHeight = statusWithDivider.reduce((total, status) => total + status.value * GRAPH_HEIGHT_MULTIPLIER, 0);

    return (
      <View style={[styles.graphContainer, { height: graphHeight }]}>
        {statusWithDivider.map((status, index) => generateGraphBar(status, index))}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <BlueBandIndexStackLegend
        blueBandIndexStatuses={blueBandIndexes}
        hasData={hasData}
        notNullStatusCount={getNotNullStatus().length}
      />
      {generateGraph()}
    </View>
  );
};

BlueBandIndexStack.propTypes = {
  blueBandIndexData: PropTypes.object,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: '100%',
  },
  graphContainer: {
    alignSelf: 'center',
    overflow: 'hidden',
    width: 40,
    height: 165,
    borderRadius: 6,
    marginHorizontal: 5,
    backgroundColor: COLORS.white,
  },
  naBar: {
    justifyContent: 'center',
    height: '100%',
    backgroundColor: COLORS.whisper,
  },
  naText: {
    textAlign: 'center',
    fontFamily: FONTS.notoSansBold,
    fontSize: 14,
    letterSpacing: 0.5,
    color: COLORS.black,
  },
  valueText: {
    textAlign: 'center',
    fontFamily: FONTS.notoSansBold,
    letterSpacing: 0.5,
    color: COLORS.white,
  },
});

export default BlueBandIndexStack;
