import PropTypes from 'prop-types';
import { StyleSheet, Text } from 'react-native';

// constants
import COLORS from '../../../colors';
import FONTS from '../../../fonts';

const EventItemDuration = ({ duration, isEventActive }) => {
  const durationColor = isEventActive ? { color: COLORS.azure } : { color: COLORS.greyishBrown };

  return <Text style={[styles.duration, durationColor]}>{duration}</Text>;
};

EventItemDuration.propTypes = {
  duration: PropTypes.string,
  isEventActive: PropTypes.bool,
};

const styles = StyleSheet.create({
  duration: {
    marginHorizontal: 5,
    fontFamily: FONTS.firaSansBold,
    fontSize: 12,
  },
});

export default EventItemDuration;
