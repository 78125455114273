import { useState } from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, TextInput, Keyboard } from 'react-native';

import COLORS from '../../colors';
import FONTS from '../../fonts';
import SubmitButton from '../../components/button/SubmitButton';
import AuthContainer from '../../components/auth/AuthContainer';
import AuthSubHeader from '../../components/auth/AuthSubHeader';
import AuthHeader from '../../components/auth/AuthHeader';
import BackButton from '../../components/button/BackButton';
import { navigationShape } from '../../shapes/navigation';
import { useTranslation } from '../../hooks/useTranslation';
import OPACITIES from '../../opacities';

const ForgotForm = ({ onSubmit, loading, placeholder, navigation, headerText, subHeaderText }) => {
  const [value, setValue] = useState(undefined);
  const { t } = useTranslation();

  const isSubmittable = () => {
    return !!value;
  };

  const onSubmitForm = () => {
    if (isSubmittable()) {
      Keyboard.dismiss();
      onSubmit(value);
    }
  };

  return (
    <AuthContainer>
      <View style={styles.headerSection}>
        <AuthHeader headerText={headerText} />
        <AuthSubHeader description={subHeaderText} />
      </View>

      <View>
        <View style={styles.textInputContainer}>
          <TextInput
            autoCapitalize="none"
            placeholder={placeholder}
            value={value}
            onChangeText={(text) => setValue(text)}
            returnKeyType="go"
            onSubmitEditing={onSubmitForm}
            isRequired
            testID="forgot__email-input"
            style={styles.textInput}
          />
        </View>
      </View>

      <View style={styles.sendButton}>
        <SubmitButton
          loading={loading}
          onSubmit={onSubmitForm}
          isSubmittable={isSubmittable}
          title={t('forgot_submit_button')}
          testId="forgot_submit_button"
        />
      </View>
      <BackButton navigation={navigation} style={styles.topLeftButton} />
    </AuthContainer>
  );
};

ForgotForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  headerText: PropTypes.string.isRequired,
  subHeaderText: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  navigation: navigationShape.isRequired,
};

const styles = StyleSheet.create({
  headerSection: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 75,
  },
  sendButton: {
    marginTop: 40,
  },
  textInputContainer: {
    shadowColor: COLORS.black,
    shadowOffset: {
      width: 3,
      height: 3,
    },
    shadowOpacity: OPACITIES.shadow,
    shadowRadius: 9,
  },
  textInput: {
    height: 63,
    paddingHorizontal: 21,
    borderRadius: 4,
    backgroundColor: COLORS.white,
    fontFamily: FONTS.firaSans,
    fontSize: 17,
    color: COLORS.greyishBrown,
  },
  topLeftButton: {
    position: 'absolute',
    top: 65,
    left: 31,
  },
});

export default ForgotForm;
