import { Pressable, View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

// constants
import COLORS from '../../colors';
import { BORDER_RADIUS_SMALL } from '../../styles';

// shape
import { styleShape } from '../../shapes/style';

const ListBox = ({ customStyle = {}, onPress, renderContent, renderSubContent, testID, usePadding = true }) => {
  let headerBorderRadiusStyle = { borderRadius: BORDER_RADIUS_SMALL };
  const subContentStyle = [styles.content];

  if (usePadding) {
    subContentStyle.push(styles.contentPadding);
  }

  if (renderSubContent) {
    headerBorderRadiusStyle = {
      borderTopLeftRadius: BORDER_RADIUS_SMALL,
      borderTopRightRadius: BORDER_RADIUS_SMALL,
    };
  }

  return (
    <Pressable style={styles.container} onPress={onPress} testID={testID}>
      <View style={[styles.header, customStyle, headerBorderRadiusStyle]}>{renderContent()}</View>
      {renderSubContent ? <View style={subContentStyle}>{renderSubContent()}</View> : null}
    </Pressable>
  );
};

ListBox.propTypes = {
  customStyle: styleShape,
  onPress: PropTypes.func,
  renderContent: PropTypes.elementType.isRequired,
  renderSubContent: PropTypes.elementType,
  testID: PropTypes.string,
  usePadding: PropTypes.bool,
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 11,
    paddingVertical: 5,
  },
  content: {
    borderColor: COLORS.greyBorderColor,
    backgroundColor: COLORS.white,
    borderBottomLeftRadius: BORDER_RADIUS_SMALL,
    borderBottomRightRadius: BORDER_RADIUS_SMALL,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  contentPadding: {
    padding: 10,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: 10,
    borderColor: COLORS.greyBorderColor,
    borderWidth: 1,
    backgroundColor: COLORS.white,
  },
});

export default ListBox;
