import PropTypes from 'prop-types';
import { StyleSheet, View, Pressable, Text, Image } from 'react-native';

//icon
import icnNavRight from '../../assets/icons/icnNavRight.png';

//constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

const BlockDetailComponentHeader = ({ handleOnPress, linkName, buttonTestId, title }) => {
  return (
    <View style={[styles.container, styles.centerRow]}>
      <Text style={styles.titleText}>{title}</Text>
      <Pressable style={[styles.linkContainer, styles.centerRow]} onPress={handleOnPress} testID={buttonTestId}>
        <Text style={styles.linkText}>{linkName}</Text>
        <Image source={icnNavRight} style={styles.arrowSpacing} />
      </Pressable>
    </View>
  );
};

BlockDetailComponentHeader.propTypes = {
  handleOnPress: PropTypes.func,
  linkName: PropTypes.string.isRequired,
  buttonTestId: PropTypes.string,
  title: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  centerRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 50,
  },
  titleText: {
    marginVertical: 10,
    marginLeft: 17,
    textAlign: 'center',
    fontFamily: FONTS.firaSansMedium,
    fontSize: 18,
    color: COLORS.greyishBrown,
  },
  linkContainer: {
    marginVertical: 10,
    marginRight: 15,
  },
  linkText: {
    textAlign: 'center',
    fontFamily: FONTS.firaSansLight,
    fontSize: 14,
    color: COLORS.greyishBrown,
  },
  arrowSpacing: {
    marginLeft: 10,
  },
});

export default BlockDetailComponentHeader;
