import { useCallback, useRef } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { View } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDispatch } from 'react-redux';

// services
import { navigationShape } from '../../shapes/navigation';
import { compareEventByStartDate, SORT_ORDER } from '../../utils/sorting';

import { colorForStatus } from '../../models/status/tensionStatus';
import ANALYTICS from '../../services/AnalyticsEvents';
import { isAutomatedIrrigation } from '../../models/eventType';

// hooks
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import { useBackHandler } from '../../hooks/useBackHandler';

// styles
import { globalStyles } from '../../styles';

//entity
import BlockEntity from '../../models/entities/blockEntity';
import IrrigationEvent from '../../models/entities/irrigationEvent';

// constants
import ROUTES from '../../navigation/routes';
import * as CAPABILITIES from '../../models/capability';
import * as TABS from '../blockDetails/BlockDetailsTabs';
import { INFERRED, PLANNED } from '../../models/calendarType';

// components
import DayEvents from '../../components/event/DayEvents';
import Divider from '../../components/Divider';
import HeaderBack from '../../components/header/HeaderBack';

const BlockEventListScreen = ({ navigation, route }) => {
  const { block } = route.params || {};
  const eventsListRef = useRef(undefined);
  const blockAliasColor = colorForStatus(block?.tensionCapability().status());
  const analyticsService = useAnalyticsContext();
  const dispatch = useDispatch();

  const blockDetailsNavigationProps = {
    activeTab: TABS.DETAILS,
    block,
    capability: CAPABILITIES.TENSION,
    defaultSelectedStreamIds: block
      ?.tensionCapability()
      .tensionReadings()
      .map((reading) => reading.id),
    displayTabs: true,
    routeToGoBackTo: ROUTES.IRRIGATION,
  };

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      return () => {
        eventsListRef.current?.scrollToOffset({ animated: false, offset: 0 });
      };
    }, []),
  );

  const goBack = () => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewBlockDetail);
    navigation.navigate(ROUTES.BLOCK_DETAILS, blockDetailsNavigationProps);
    return true;
  };

  useBackHandler(goBack);

  // istanbul ignore next
  const onGoBackEventManagement = async () => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewBlockDetail);
    dispatch.blocks.loadIrrigationBlockById({ blockId: block?.id });
    await dispatch.blocks.updateIrrigationBlockEntity(undefined);

    navigation.navigate(ROUTES.BLOCK_DETAILS, blockDetailsNavigationProps);
    return true;
  };

  const navigateToManageEvent = (selectedEvent) => {
    dispatch.schedule.updateSchedule({ blocks: [block] });
    dispatch.schedule.updateSelectedBlockId(block?.id);
    dispatch.schedule.updateSelectedEvent(selectedEvent);
    navigation.navigate(ROUTES.MANAGE_EVENT, { onGoBack: onGoBackEventManagement });
  };

  const plannedEvents = block?.getPlannedEvents().map((event) => {
    const calendarType = isAutomatedIrrigation(event.type) ? null : PLANNED;
    return new IrrigationEvent({ ...event, calendarType });
  });
  const inferredEvents = block?.getInferredEvents().map((event) => new IrrigationEvent({ ...event, calendarType: INFERRED }));

  const blockEvents = plannedEvents && inferredEvents ? [...plannedEvents, ...inferredEvents] : [];

  const groupEventByDate = (events) => {
    events.sort((a, b) => compareEventByStartDate(a, b, SORT_ORDER.DESC));
    return events.reduce((acc, event) => {
      const startDate = event.startDate.clone().startOf('day').format();
      if (Object.prototype.hasOwnProperty.call(acc, startDate)) {
        acc[startDate].push(event);
      } else {
        acc[startDate] = [event];
      }
      return acc;
    }, {});
  };

  const groupedDaysEvents = Object.entries(groupEventByDate(blockEvents));

  const renderDayEvents = (element) => {
    const [day, events] = element.item;
    return <DayEvents events={events} day={moment(day)} navigateToManageEvent={navigateToManageEvent} />;
  };

  return (
    <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
      <View style={globalStyles.header} testID="schedule-event-list__subscreen-container">
        <HeaderBack
          screenName="block-event-list"
          navigation={navigation}
          goBack={goBack}
          title={block?.alias}
          subtitle={block?.name}
          titleColor={blockAliasColor}
        />
      </View>

      <Divider />

      <View testID="block-event-list__subscreen-container" style={globalStyles.bottomContainer}>
        <FlashList
          ref={eventsListRef}
          data={groupedDaysEvents}
          estimatedItemSize={160}
          keyExtractor={([day]) => day}
          contentContainerStyle={globalStyles.scrollContainer}
          renderItem={renderDayEvents}
          showsVerticalScrollIndicator={false}
        />
      </View>
    </SafeAreaView>
  );
};

BlockEventListScreen.propTypes = {
  navigation: navigationShape.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      block: PropTypes.instanceOf(BlockEntity).isRequired,
    }),
  }).isRequired,
};

export default BlockEventListScreen;
