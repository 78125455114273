import { useCallback, useEffect, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import PropTypes from 'prop-types';
import { StatusBar, StyleSheet, Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDispatch, useSelector, useStore } from 'react-redux';

// services
import { navigationShape } from '../../shapes/navigation';
import { isIos } from '../../services/PlatformService';
import { formatDateForAPI } from '../../utils/dateUtils';
import { useTranslation } from '../../hooks/useTranslation';

// styles
import { globalStyles } from '../../styles';
import COLORS from '../../colors';
import FONTS from '../../fonts';

// components
import Divider from '../../components/Divider';
import TabHeader from '../../components/header/TabHeader';
import PeriodPicker from '../../components/report/PeriodPicker';
import HeaderBar from '../../components/header/HeaderBar';
import Table from '../../components/report/Table';
import LoadingOverlay from '../../components/LoadingOverlay';
import NoDataView from '../../components/NoDataView';
import { EVENT_ACTIONS } from './eventActions';
import useReport from './useReport';

const ReportScreen = ({ navigation, route }) => {
  const store = useStore();
  const currentSite = useSelector((state) => state.site.currentSite);
  const defaultTabIndex = useSelector((state) => state.report.defaultTabIndex);
  const statistics = useSelector((state) => state.report.statistics);
  const volumeUnit = useSelector((state) => state.report.volumeUnit);
  const loading = useSelector((state) => !!state.loading.effects.report.loadStatistics);
  const isHortau = useSelector(store.select.user.isHortauStaff);
  const [state, dispatch] = useReport({ defaultTabIndex, volumeUnit, isHortau });
  const dispatchRef = useDispatch();
  const { t } = useTranslation();
  const [isRefreshing, setIsRefreshing] = useState(false);

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      if (route.params?.reloadData) {
        dispatch({ type: EVENT_ACTIONS.RESET });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigation]),
  );

  useEffect(() => {
    dispatch({ type: EVENT_ACTIONS.SET_DATATABLE, payload: [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSite]);

  useEffect(() => {
    dispatch({ type: EVENT_ACTIONS.SET_DATATABLE, payload: statistics });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statistics]);

  useEffect(() => {
    dispatchRef.report.loadStatistics({
      startDate: formatDateForAPI(state.startDatePeriod),
      endDate: formatDateForAPI(state.endDatePeriod),
      groupBy: state.groupBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.startDatePeriod, state.endDatePeriod]);

  const refresh = async () => {
    setIsRefreshing(true);
    await dispatchRef.report.loadStatistics({
      startDate: formatDateForAPI(state.startDatePeriod),
      endDate: formatDateForAPI(state.endDatePeriod),
      groupBy: state.groupBy,
    });
    setIsRefreshing(false);
  };

  const changeActiveTab = (index) => {
    dispatch({ type: EVENT_ACTIONS.SET_TAB_INDEX, payload: index });
    dispatchRef.preferences.writeReportTab({ tabIndex: index });
  };

  const nextPeriod = () => dispatch({ type: EVENT_ACTIONS.SET_NEXT_PERIOD });
  const previousPeriod = () => dispatch({ type: EVENT_ACTIONS.SET_PREVIOUS_PERIOD });

  const renderTable = () => {
    if (state.tableData === undefined || (state.tableData.length === 0 && loading)) {
      return null;
    }

    if (state.tableData.length === 0) {
      return <NoDataView />;
    }

    return (
      <>
        <Text style={styles.tableTitle}>{t('irrigation_statistics_hours')}</Text>

        <Table
          rowHeaders={state.tableRowHeader}
          colHeaders={state.tableColHeader}
          dataTable={state.tableData}
          totals={state.tableTotals}
          onRefresh={refresh}
          isRefreshing={isRefreshing}
          displayRowTotal={state.selectedTabIndex === 1}
          fitToScreenSize={state.selectedTabIndex === 0}
          scrollToColIndex={state.scrollToColIndex}
          isHortau={isHortau}
        />
      </>
    );
  };

  return (
    <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
      <StatusBar backgroundColor={COLORS.greyishBrown} barStyle={isIos() ? 'dark-content' : 'default'} translucent={false} />

      <View style={globalStyles.header2}>
        <HeaderBar siteName={currentSite.name} screenName={'report'} testId={'report__header'} navigation={navigation} />

        <TabHeader
          changeActiveTab={changeActiveTab}
          tabs={state.tabNames}
          selectedTabIndex={state.selectedTabIndex}
          screenName={'report'}
        />
      </View>

      <Divider />

      <View testID="report__subscreen-container" style={[globalStyles.bottomContainer, styles.periodPicker]}>
        <LoadingOverlay isLoading={loading && !isRefreshing} handleTouchEvents={false} />
        <PeriodPicker
          disableLeftArrow={state.previousPeriodDisabled}
          onLeftArrowClick={previousPeriod}
          disableRightArrow={state.nextPeriodDisabled}
          onRightArrowClick={nextPeriod}
          screenName={'report'}
          date={state.selectedPeriodText}
        />
        {renderTable()}
      </View>
    </SafeAreaView>
  );
};

ReportScreen.propTypes = {
  navigation: navigationShape.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      reloadData: PropTypes.bool,
    }),
  }),
};

const styles = StyleSheet.create({
  periodPicker: {
    paddingTop: 10,
  },
  tableTitle: {
    marginTop: 5,
    marginLeft: 20,
    fontFamily: FONTS.firaSansMedium,
    fontSize: 16,
    letterSpacing: 0.8,
    color: COLORS.greyishBrown,
  },
});

export default ReportScreen;
