import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import Modal from 'react-native-modal';

// constants
import COLORS from '../../colors';
import OPACITIES from '../../opacities';

const CustomModal = ({ children, isVisible, onCloseModal, testId, animationEffects, closeOnOutsidePress = true }) => {
  return (
    <Modal
      testID={testId}
      style={styles.modal}
      backdropColor={COLORS.black}
      backdropOpacity={OPACITIES.modalBackDrop}
      onBackdropPress={closeOnOutsidePress ? onCloseModal : undefined}
      onBackButtonPress={onCloseModal}
      isVisible={isVisible}
      useNativeDriver
      useNativeDriverForBackdrop
      hideModalContentWhileAnimating
      animationIn={'fadeIn'}
      animationOut={'fadeOut'}
      {...animationEffects}>
      {children}
    </Modal>
  );
};

CustomModal.propTypes = {
  animationEffects: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  closeOnOutsidePress: PropTypes.bool,
  isVisible: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func,
  optimized: PropTypes.bool,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  modal: {
    margin: 0,
  },
});

export default CustomModal;
