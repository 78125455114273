import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { RefreshControl, StatusBar, ScrollView, View } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';

// services
import { navigationShape } from '../../shapes/navigation';
import { isIos } from '../../services/PlatformService';

// styles
import { globalStyles } from '../../styles';

// constants
import COLORS from '../../colors';

// components
import BlocksSelectorHeader from '../../components/header/BlocksSelectorHeader';
import NutrientsDetail from '../../components/nutrients/NutrienstDetail';

const NutrientsScreen = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const currentSite = useSelector((state) => state.site.currentSite);
  const blocks = useSelector((state) => state.blocks.nutrients);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const selectedBlock = blocks[selectedIndex];

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      dispatch.blocks.loadNutrients();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigation]),
  );

  /* istanbul ignore next */
  useEffect(() => {
    return () => {
      setSelectedIndex(0);
    };
  }, [currentSite]);

  /* istanbul ignore next */
  const refresh = async () => {
    setIsRefreshing(true);
    await dispatch.blocks.loadNutrients();
    setIsRefreshing(false);
  };

  /* istanbul ignore next */
  const changeBlock = (index) => {
    setSelectedIndex(index);
  };

  return (
    <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
      <StatusBar backgroundColor={COLORS.greyishBrown} barStyle={isIos() ? 'dark-content' : 'default'} translucent={false} />

      <View style={globalStyles.header} testID="nutrients__header-container">
        <BlocksSelectorHeader
          navigation={navigation}
          siteName={currentSite.name}
          screenName={'nutrients'}
          currentBlock={selectedBlock}
          blocks={blocks}
          changeBlock={changeBlock}
        />
      </View>

      <View testID={'nutrients__screen-container'} style={globalStyles.bottomContainer}>
        <ScrollView
          refreshControl={<RefreshControl testID="nutrients__refresh-controller" onRefresh={refresh} refreshing={isRefreshing} />}
          testID={'nutrients__sroll-view'}
          contentContainerStyle={globalStyles.scrollContainer}
          showsVerticalScrollIndicator={false}>
          <NutrientsDetail selectedBlock={selectedBlock} />
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

NutrientsScreen.propTypes = {
  navigation: navigationShape.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      reloadData: PropTypes.bool,
    }),
  }),
};

export default NutrientsScreen;
