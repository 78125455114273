import { useEffect, useState, useCallback, memo } from 'react';
import { StyleSheet, Pressable } from 'react-native';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Animated, { useSharedValue, withTiming, useAnimatedStyle } from 'react-native-reanimated';

// constants
import COLORS from '../../colors';
import { UNKNOWN } from '../../models/automationCommandType';
import { AlertTypes } from '../../screens/main/AlertConstants';

// hooks
import { useAnalyticsContext } from '../initialization/AnalyticsProvider';
import { useBackHandler } from '../../hooks/useBackHandler';
import { useTranslation } from '../../hooks/useTranslation';

//services
import ANALYTICS from '../../services/AnalyticsEvents';
import { executeAutomationCommand } from '../../mutations/executeAutomationCommand';
import { getAutomationErrorTranslationKey } from '../../models/errors/automationErrorMapper';

//entity
import AutomationEntity from '../../models/entities/automationEntity';

// components
import Alert from '../../screens/main/Alert';
import AutomationPanelHeader from './AutomationPanelHeader';
import AutomationControl from './AutomationControl';
import AutomationConfirmModal from './confirmModal/AutomationConfirmModal';

const SLIDE_TOGGLE_ANIMATION_DURATION = 200;
const PANEL_SHOWN_Y = 0;
const PANEL_HIDDEN_Y = 305;

const AutomationPanel = ({ automationComponent, onClose, onCommandSent, show }) => {
  const { t } = useTranslation();
  const analyticsService = useAnalyticsContext();
  const dispatch = useDispatch();
  const verticalOffset = useSharedValue(PANEL_HIDDEN_Y);

  const currentSite = useSelector((state) => state.site.currentSite);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [commandType, setCommandType] = useState(UNKNOWN);
  const [isErrorAlertVisible, setIsErrorAlertVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  /* istanbul ignore next */
  const goBack = useCallback(() => {
    onClose();
    return true;
  }, [onClose]);

  useBackHandler(goBack);

  useEffect(() => {
    function open() {
      analyticsService.trackEvent(ANALYTICS.automationPanelOpen, { automationId: automationComponent.id });
      verticalOffset.value = withTiming(PANEL_SHOWN_Y, {
        duration: SLIDE_TOGGLE_ANIMATION_DURATION,
      });
    }

    function close() {
      analyticsService.trackEvent(ANALYTICS.automationPanelClose);
      verticalOffset.value = withTiming(
        PANEL_HIDDEN_Y,
        {
          duration: SLIDE_TOGGLE_ANIMATION_DURATION,
        },
        (function () {
          dispatch.automations.updateSelectedAutomationComponent(undefined);
        })(),
      );
    }

    show ? open() : close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: verticalOffset.value }],
    };
  });

  const onCommandButtonPress = (type, analyticsEvent) => {
    analyticsService.trackEvent(analyticsEvent, { automationId: automationComponent.id });
    setIsConfirmModalVisible(true);
    setCommandType(type);
  };

  const onCancel = () => {
    setIsConfirmModalVisible(false);
  };

  const closeErrorAlert = () => {
    setIsErrorAlertVisible(false);
    setErrorMessage('');
  };

  const onConfirmCommand = (durationInMin) => {
    setIsConfirmModalVisible(false);
    processCommand(durationInMin);
  };

  /* istanbul ignore next */
  const onChangeCommandType = (newCommandType) => {
    setCommandType(newCommandType);
  };

  const processCommand = async (durationInMin) => {
    const response = await executeAutomationCommand(currentSite.id, automationComponent.id, commandType.id, durationInMin);
    if (response?.errors) {
      const error0 = response.errors[0];
      const errorCode = error0.extensions?.code;
      const message = getAutomationErrorTranslationKey(errorCode);
      setErrorMessage(t(message));
      setIsErrorAlertVisible(true);
    } else {
      closeErrorAlert();
      onCommandSent(commandType);
      onClose();
    }
  };

  return (
    <>
      <Pressable style={styles.screenShadow} testID={'automation__screen-shadow'} onPress={onClose} />

      {isConfirmModalVisible ? (
        <AutomationConfirmModal
          commandType={commandType}
          onCancel={onCancel}
          onChangeCommandType={onChangeCommandType}
          onClosePanel={onClose}
          onConfirm={onConfirmCommand}
        />
      ) : null}

      <Alert
        alertType={AlertTypes.ERROR}
        onPress={closeErrorAlert}
        onCancel={closeErrorAlert}
        show={isErrorAlertVisible}
        title={t('generic_error_server_error_title')}
        message={errorMessage}
        isError={true}
        testID={'automation_error-modal'}
      />

      <Animated.View style={[styles.container, animatedStyle, { height: PANEL_HIDDEN_Y }]}>
        <AutomationPanelHeader automationComponent={automationComponent} />
        <AutomationControl automationComponent={automationComponent} onButtonPress={onCommandButtonPress} />
      </Animated.View>
    </>
  );
};

AutomationPanel.propTypes = {
  automationComponent: PropTypes.instanceOf(AutomationEntity).isRequired,
  onClose: PropTypes.func.isRequired,
  onCommandSent: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    zIndex: 4,
    bottom: PANEL_SHOWN_Y,
    width: '100%',
    backgroundColor: COLORS.white,
    elevation: 5,
  },
  screenShadow: {
    position: 'absolute',
    zIndex: 3,
    opacity: 0.3,
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.black,
    elevation: 4,
  },
});

export default memo(AutomationPanel);
