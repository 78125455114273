import { gql } from '@apollo/client';

export const IRRIGATION_BLOCK = gql`
  query irrigationBlock($siteId: ID!, $blockId: ID!, $startDate: String!, $endDate: String!) {
    viewer {
      site(id: $siteId) {
        id
        block(id: $blockId) {
          id
          name
          alias
          order
          capabilities {
            irrigation {
              isIrrigating
              currentEvent {
                ...blockDetailsEvent
              }
              previousEvent {
                ...blockDetailsEvent
              }
            }
            tension {
              trend
              status
              optimalTension {
                minimum
                maximum
              }
              blueBandIndex {
                dry
                good
                wet
              }
              readings {
                id
                depth
                depthUnit
                unit
                value
                colorCode
                dataStream {
                  id
                  value
                  unit
                  dataStreamType
                }
              }
            }
          }
          schedule {
            nextEvent {
              type
              startDate
              endDate
              anytime
              duration
            }
            scheduleEvents(startDate: $startDate, endDate: $endDate) {
              id
              type
              resource {
                id
                type
              }
              startDate
              endDate
              duration
            }
          }
          events(startDate: $startDate, endDate: $endDate) {
            ...blockDetailsEvent
          }
          calendars(startDate: $startDate, endDate: $endDate, calendarTypes: [PLANNED], eventTypes: [IRRIGATION]) {
            id
            type
            events {
              id
              type
              startDate
              endDate
              anytimeDayEvent
            }
          }
          lastUpdated
        }
      }
    }
  }
  fragment blockDetailsEvent on subSensorEvent {
    id: id
    startDate
    endDate
    subSensorId

    statistics {
      id: id
      maxValue
      minValue
      subSensorId
      subSensorType
      unit
    }
  }
`;
