import { View } from 'react-native';
import PropTypes from 'prop-types';

// Styles
import { globalStyles } from '../../styles';

// constants
import { navigationShape } from '../../shapes/navigation';

// components
import Title from './Title';
import BurgerMenuButton from './BurgerMenuButton';

const HeaderBar = ({ siteName, screenName, testId, navigation, children }) => {
  return (
    <View style={globalStyles.headerContainer}>
      <BurgerMenuButton screenName={screenName} navigation={navigation} />

      <View style={globalStyles.headerTitle}>
        <Title value={siteName} type="title" testId={testId} />
      </View>

      {children ? <View>{children}</View> : <View style={globalStyles.headerActions} />}
    </View>
  );
};

HeaderBar.propTypes = {
  siteName: PropTypes.string.isRequired,
  screenName: PropTypes.string.isRequired,
  testId: PropTypes.string,
  navigation: navigationShape.isRequired,
  children: PropTypes.node,
};

export default HeaderBar;
