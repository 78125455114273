import { StyleSheet, Text, View } from 'react-native';

// services
import { useTranslation } from '../../hooks/useTranslation';
import { getNDVILegends } from '../../screens/map/MapService';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

const BACKGROUND_LEGEND_OPACITY = '20';
const BORDER_RADIUS = 12.5;
const ITEM_SIZE = 52;

const NDVILegendItems = () => {
  const { t } = useTranslation();
  const ndviLegendItems = getNDVILegends(t);

  return (
    <>
      {ndviLegendItems.map((item, i) => (
        <View key={'ndvi-legend-item-' + i} style={styles.container}>
          <View style={[styles.legendItemCard, { backgroundColor: item.color + BACKGROUND_LEGEND_OPACITY }]}>
            <View style={[styles.legendItemSticker, { backgroundColor: item.color }]} />
            <Text style={styles.legendItemText}>{item.text}</Text>
          </View>
        </View>
      ))}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 15,
  },
  legendItemCard: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    height: ITEM_SIZE,
    borderBottomStartRadius: BORDER_RADIUS,
    borderTopStartRadius: BORDER_RADIUS,
    marginStart: 15,
  },
  legendItemSticker: {
    width: ITEM_SIZE,
    height: ITEM_SIZE,
    borderRadius: BORDER_RADIUS,
  },
  legendItemText: {
    width: '80%',
    paddingStart: 30,
    fontFamily: FONTS.notoSans,
    fontSize: 16,
    lineHeight: 18,
    color: COLORS.greyishBrown,
    textAlignVertical: 'center',
  },
});

export default NDVILegendItems;
