import { StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

// constants
import COLORS from '../colors';
import FONTS from '../fonts';

// components
import Divider from './Divider';

const Card = (props) => {
  return (
    <View style={[styles.cardContainer, props.cardStyle]}>
      <View style={styles.cardHeaderContainer}>
        <Text style={styles.headerText}>{props.title}</Text>
        {props.headerComponent}
      </View>
      <Divider style={styles.headerDivider} />
      <View style={props.contentStyle}>
        {/* eslint-disable-next-line react/prop-types */}
        {props.children}
      </View>
    </View>
  );
};

Card.propTypes = {
  testId: PropTypes.string,
  title: PropTypes.string.isRequired,
  cardStyle: PropTypes.object,
  contentStyle: PropTypes.object.isRequired,
  headerComponent: PropTypes.object,
};

const styles = StyleSheet.create({
  cardContainer: {
    borderColor: COLORS.greyBorderColor,
    borderWidth: 1,
    borderRadius: 4,
    backgroundColor: COLORS.white,
    elevation: 5,
    shadowColor: COLORS.fuscousGray,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  cardHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 15,
    marginVertical: 2,
  },
  headerDivider: {
    height: 1,
    borderColor: COLORS.whiteFive,
    borderStyle: 'solid',
    borderWidth: 1,
    elevation: 5,
    shadowColor: COLORS.pinkishGrey2,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 4,
  },
  headerText: {
    paddingVertical: 6,
    fontFamily: FONTS.firaSans,
    fontSize: 18,
    fontWeight: '500',
    color: COLORS.greyishBrown,
  },
});

export default Card;
