import { View, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import PropTypes from 'prop-types';

// constants
import COLORS from '../../colors';
import { GraphDataType } from '../../models/graphDataType';

// components
import TensionLegendElement from '../../components/graph/TensionLegendElement';
import TemperatureLegendElement from '../../components/graph/TemperatureLegendElement';
import FlowStationLegendElement from '../../components/graph/FlowStationLegendElement';

const legendComponents = {
  [GraphDataType.TENSION]: TensionLegendElement,
  [GraphDataType.FLOW]: FlowStationLegendElement,
  [GraphDataType.TEMPERATURE]: TemperatureLegendElement,
  [GraphDataType.WATER_LEVEL]: TensionLegendElement,
};

const MapBlockFooter = ({ mapDataType, data }) => {
  if (!legendComponents[mapDataType]) {
    return null;
  }

  const LegendComponent = legendComponents[mapDataType];

  return (
    <SafeAreaView style={styles.safeAreaView} edges={['right', 'left', 'bottom']}>
      <View style={styles.footer}>
        {data.map((item) => (
          <LegendComponent key={item.id} readings={item} />
        ))}
      </View>
    </SafeAreaView>
  );
};

MapBlockFooter.propTypes = {
  mapDataType: PropTypes.string,
  data: PropTypes.array,
};

const styles = StyleSheet.create({
  safeAreaView: {
    backgroundColor: COLORS.greyish22,
  },
  footer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    paddingVertical: 3,
  },
});

export default MapBlockFooter;
