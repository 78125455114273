import { StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';
import { ONGOING } from '../../models/status/automationStatus';

// entity
import AutomationEntity from '../../models/entities/automationEntity';

// services
import { useTranslation } from '../../hooks/useTranslation';

// components
import AutomationStatusIcon from '../../components/automation/AutomationStatusIcon';
import ComponentAlias from '../../components/automation/ComponentAlias';
import ListBox from '../../components/list/ListBox';
import ProgressBarDuration from '../../components/progressBar/ProgressBarDuration';
import AutomationRuleItemMinified from '../../components/automation/rule/AutomationRuleItemMinified';

const AutomationItem = ({ entity, keyIndex, onSelect, showRules, animatedOpacity }) => {
  const { t } = useTranslation();

  const itemBackgroundColor = entity.itemBackgroundColor();
  const aliasColor = entity.statusColor();
  const aliasBackgroundColor = entity.aliasBackgroundColor();

  const selectComponent = () => {
    onSelect(entity);
  };

  const renderRow = (i, rule, subSensors, withRules) => {
    return (
      <View key={i} style={styles.container}>
        <View style={styles.componentSection}>
          {i === 0 ? <ComponentAlias alias={entity.alias} color={aliasColor} backgroundColor={aliasBackgroundColor} /> : null}
        </View>

        <View style={styles.statusSection}>
          {i === 0 ? (
            <>
              <View style={styles.statusIcon}>
                <AutomationStatusIcon entity={entity} animatedOpacity={animatedOpacity} />
              </View>

              <View style={styles.statusText}>
                {ongoingOK ? (
                  <ProgressBarDuration startDate={startDate} endDate={endDate} />
                ) : (
                  <Text style={styles.statusFont}>{entity.statusText(t)}</Text>
                )}
              </View>
            </>
          ) : null}
        </View>

        {i === 0 && subSensors?.length > 0 ? (
          <View style={styles.rpmContainer} testID={'automation__rpm-list'}>
            {subSensors.map((subSensor, index) => (
              <View key={index} style={styles.rpmSection}>
                <Text style={styles.rpmFont}>{subSensor.getRPMValues()}</Text>
              </View>
            ))}
          </View>
        ) : (
          <View style={styles.rpmContainer} />
        )}

        {rule ? (
          <View style={styles.rulesContainer}>
            <AutomationRuleItemMinified
              ruleType={rule.ruleType}
              thresholdDown={rule.thresholdDown}
              thresholdUp={rule.thresholdUp}
              unit={rule.unit}
            />
          </View>
        ) : withRules ? (
          <View style={styles.rulesContainer} testID={'automation__rule-empty'} />
        ) : null}
      </View>
    );
  };

  const startDate = entity.currentExecution?.startDate;
  const endDate = entity.currentExecution?.endDate;
  const ongoingOK = startDate && endDate && (entity.statusType() === ONGOING || entity.ongoing);
  const subSensors = entity.getRPMSubSensors();

  return (
    <ListBox
      testID={`automation__componentItem-${keyIndex}`}
      customStyle={{ ...styles.listBox, backgroundColor: itemBackgroundColor }}
      onPress={selectComponent}
      renderContent={() => (
        <View style={styles.row}>
          {entity.getEnabledRules()?.length > 0
            ? entity.getEnabledRules()?.map((rule, i) => renderRow(i, rule, subSensors, showRules))
            : renderRow(0, null, subSensors, showRules)}
        </View>
      )}
    />
  );
};

AutomationItem.propTypes = {
  entity: PropTypes.instanceOf(AutomationEntity).isRequired,
  keyIndex: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  showRules: PropTypes.bool,
  animatedOpacity: PropTypes.shape({
    value: PropTypes.number.isRequired,
  }),
};

const styles = StyleSheet.create({
  componentSection: {
    width: '20%',
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listBox: {
    paddingHorizontal: 11,
  },
  rpmContainer: {
    justifyContent: 'space-between',
    width: '10%',
    marginVertical: 3,
  },
  rpmSection: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  rpmFont: {
    fontFamily: FONTS.firaSans,
    fontSize: 11,
    lineHeight: 15,
    letterSpacing: 0.52,
    color: COLORS.greyishBrown,
  },
  rulesContainer: {
    justifyContent: 'space-between',
    width: '20%',
    marginVertical: 3,
  },
  row: {
    flex: 1,
  },
  statusFont: {
    fontFamily: FONTS.firaSans,
    fontSize: 11,
    lineHeight: 15,
    letterSpacing: 0.52,
    color: COLORS.greyishBrown,
  },
  statusIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
  },
  statusSection: {
    flexDirection: 'row',
    width: '40%',
  },
  statusText: {
    justifyContent: 'center',
    width: '70%',
  },
});

export default AutomationItem;
