import { useState } from 'react';
import PropTypes from 'prop-types';
import { SvgXml } from 'react-native-svg';
import { Pressable, StyleSheet, Text, View, Image } from 'react-native';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

// shape
import { styleShape } from '../../shapes/style';
import { navigationShape } from '../../shapes/navigation';

/* istanbul ignore next */
const noop = () => {};

export const COLOR_MODE = {
  FILL: 'fill',
  STROKE: 'stroke',
};

const NavigationItem = ({
  beforeNavigate = noop,
  isCurrentItem = false,
  leftSvg,
  leftSvgColor = COLORS.pinkishGrey,
  leftSvgColorMode = COLOR_MODE.FILL,
  navigation,
  navigationItemStyle,
  params,
  preventNavigationToSameItem = true,
  rightImage,
  route,
  testId = 'navigation__item',
  textStyle = {},
  title,
  hasList = true,
  disabled = false,
}) => {
  const [isPressed, setIsPressed] = useState(false);

  const getIconColor = () => {
    if (isPressed) {
      return COLORS.greyishBrown;
    }

    return isCurrentItem ? COLORS.stone : leftSvgColor;
  };

  const onPress = async () => {
    if (hasList) {
      navigation.toggleDrawer();

      if (isCurrentItem && preventNavigationToSameItem) {
        return;
      }

      await beforeNavigate();
      navigation.navigate(route, params);
    }
  };

  const svgColorProp = { [leftSvgColorMode]: getIconColor() };

  return (
    <Pressable
      style={[styles.navigationItem, navigationItemStyle, isCurrentItem && styles.current]}
      disabled={disabled}
      onPress={onPress}
      onPressIn={() => setIsPressed(true)}
      onPressOut={() => setIsPressed(false)}
      testID={testId}>
      <SvgXml xml={leftSvg} {...svgColorProp} width={27.5} style={styles.leftSvg} />

      <Text
        style={[styles.navigationText, isCurrentItem && styles.current, textStyle]}
        ellipsizeMode={'middle'}
        numberOfLines={1}>
        {title}
      </Text>

      {hasList && rightImage ? (
        <View style={styles.imageContainer}>
          <Image source={rightImage} style={styles.rightImage} testID="side_menu_dropdown" />
        </View>
      ) : (
        <View style={styles.spacer} />
      )}
    </Pressable>
  );
};

NavigationItem.propTypes = {
  beforeNavigate: PropTypes.func,
  isCurrentItem: PropTypes.bool,
  leftSvg: PropTypes.any.isRequired,
  leftSvgColor: PropTypes.string,
  leftSvgColorMode: PropTypes.string,
  navigation: navigationShape.isRequired,
  navigationItemStyle: styleShape,
  params: PropTypes.any,
  preventNavigationToSameItem: PropTypes.bool,
  rightImage: PropTypes.any,
  textStyle: styleShape,
  route: PropTypes.string.isRequired,
  testId: PropTypes.string,
  title: PropTypes.string.isRequired,
  hasList: PropTypes.bool,
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
};

const styles = StyleSheet.create({
  navigationText: {
    flex: 4,
    marginLeft: 12,
    fontFamily: FONTS.notoSans,
    fontSize: 14,
    lineHeight: 37,
    letterSpacing: 0.88,
    color: COLORS.greyishBrown,
  },
  current: {
    borderColor: COLORS.stone,
    color: COLORS.stone,
  },
  navigationItem: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 20,
    borderColor: COLORS.white,
    marginBottom: 5,
    borderLeftWidth: 3,
  },
  imageContainer: {
    flex: 1,
    alignSelf: 'center',
  },
  leftSvg: {
    flex: 1,
  },
  rightImage: {
    width: 14,
    height: 8,
    marginLeft: 12,
    tintColor: COLORS.greyishBrown,
  },
  spacer: {
    paddingRight: 20,
  },
});

export default NavigationItem;
