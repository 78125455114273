import { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

// icons
import RoundButton from '../../components/button/RoundButton';
import icnPosition from '../../assets/icons/icnPosition.png';
import icnPositionOn from '../../assets/icons/icnPositionOn.png';

// services
import { isWeb } from '../../services/PlatformService';

const GoToUserLocationButton = ({ onPress }) => {
  const [isSelected, setIsSelected] = useState(false);

  return (
    <View style={styles.container}>
      <RoundButton
        onPress={onPress}
        onPressIn={() => setIsSelected(true)}
        onPressOut={() => setIsSelected(false)}
        icon={isSelected ? icnPositionOn : icnPosition}
        iconStyle={styles.iconStyle}
        testID={'map__go-to-user-location'}
      />
    </View>
  );
};

GoToUserLocationButton.propTypes = {
  onPress: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    .../* istanbul ignore next */ (isWeb()
      ? {
          left: 10,
          bottom: 15,
        }
      : {
          right: 5,
          bottom: 5,
        }),
  },
  iconStyle: {
    marginRight: 5,
  },
});

export default GoToUserLocationButton;
