import { StyleSheet, Text, Pressable, View } from 'react-native';
import Proptypes from 'prop-types';

// constants
import COLORS from '../../../../colors';
import FONTS from '../../../../fonts';

// services
import { useTranslation } from '../../../../hooks/useTranslation';

// components
import DurationPicker from '../../../durationPicker/DurationPicker';

// styles
import { globalStyles } from '../../../../styles';

const SelectDuration = ({
  durationConstraints,
  eventDuration,
  isDurationPickerVisible,
  label,
  setEventDuration,
  setDurationPickerVisibility,
}) => {
  const { t } = useTranslation();

  const eventDurationFormatted = t('duration_picker_value_format', Math.floor(eventDuration.asHours()), eventDuration.minutes());
  const labelTranslation = label ? label : t('edit_event_duration_label');

  return (
    <>
      <Pressable
        style={/* istanbul ignore next */ ({ pressed }) => [pressed ? globalStyles.touchOpacity : {}, styles.container]}
        onPress={setDurationPickerVisibility}
        testID={'schedule__duration-picker-button'}>
        <Text style={styles.labelText}>{labelTranslation}</Text>
        <Text style={styles.valueText}>{eventDurationFormatted}</Text>
      </Pressable>

      {isDurationPickerVisible ? (
        <View style={styles.pickerContainer}>
          <DurationPicker
            duration={eventDuration}
            setDuration={setEventDuration}
            maximumDurationInMinutes={durationConstraints?.maximumDurationInMinutes}
            minimumDurationInMinutes={durationConstraints?.minimumDurationInMinutes}
          />
        </View>
      ) : null}
    </>
  );
};

SelectDuration.propTypes = {
  durationConstraints: Proptypes.object,
  eventDuration: Proptypes.object.isRequired,
  isDurationPickerVisible: Proptypes.bool,
  label: Proptypes.string,
  setEventDuration: Proptypes.func.isRequired,
  setDurationPickerVisibility: Proptypes.func.isRequired,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 44,
    paddingRight: 10,
    paddingLeft: 16,
  },
  labelText: {
    fontFamily: FONTS.firaSans,
    fontSize: 17,
    color: COLORS.greyishBrown,
  },
  pickerContainer: {
    alignSelf: 'center',
    width: 310,
  },
  valueText: {
    fontFamily: FONTS.firaSansMedium,
    fontSize: 15,
    color: COLORS.greyishBrown,
  },
});

export default SelectDuration;
