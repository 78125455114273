import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { View, ScrollView, StyleSheet, Text, Image } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import PropTypes from 'prop-types';
import { useFocusEffect } from '@react-navigation/native';
import { useSelector, useStore } from 'react-redux';
import moment from 'moment-timezone';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';
import { AlertTypes } from '../main/AlertConstants';

// styles
import { globalStyles } from '../../styles';

//icons
import icnAutomationStop from '../../assets/icons/icnAutomationStop.png';
import icnAutomationAddRemove from '../../assets/icons/icnAutomationAddRemove.png';

// services
import { getEventErrorTranslationKey, EVENT_START_TOO_SOON_CODE } from '../../models/errors/EventErrorMapper';
import { isAutomatedIrrigation, isManualIrrigation } from '../../models/eventType';

// hooks
import { useTranslation } from '../../hooks/useTranslation';
import { useBackHandler } from '../../hooks/useBackHandler';

// models
import BlockEntity from '../../models/entities/blockEntity';
import IrrigationEvent from '../../models/entities/irrigationEvent';

// components
import Alert from '../main/Alert';
import BlocksSelector from '../../components/event/addEvent/BlocksSelector';
import Divider from '../../components/Divider';
import EventTypeSelector from '../../components/event/addEvent/EventTypeSelector';
import HeaderEdition from '../../components/header/HeaderEdition';
import TimeSelector from '../../components/event/addEvent/TimeSelector';

// mutations
import { createEvent } from '../../mutations/manageEvent';
import { createScheduleEvents } from '../../mutations/createScheduleEvents';
import { updateScheduleEvents } from '../../mutations/updateScheduleEvent';
import { deleteScheduleEvent } from '../../mutations/deleteScheduleEvents';

// helpers
import { formatManualIrrigation, formatAutomatedIrrigation } from './eventFormatter';
import { areBlocksWithoutAutomationAreSelected, areVirtualBlocksSelected } from './eventFilter';
import { EVENT_ACTIONS } from './eventActions';

import useManageEvents from './useManageEvents';

const ManageEventScreen = ({ route }) => {
  const { onGoBack: goBack } = route.params || {};

  const { t } = useTranslation();

  useBackHandler(goBack);

  const store = useStore();

  const isEditMode = useSelector(store.select.schedule.getIsEditMode);
  const isCreateMode = !isEditMode;

  const block = useSelector(store.select.schedule.getSelectedBlock);
  const event = useSelector(store.select.schedule.getSelectedEvent);

  const activeBlocks = useSelector(store.select.schedule.getActiveBlocks);
  const activeBlocksWithAutomations = useSelector(store.select.schedule.getActiveBlocksWithAutomations);

  const timezone = useSelector(store.select.site.getTimezone);
  const automationEventConstraints = useSelector(store.select.site.getAutomationEventConstraints);
  const automMinSafeStartDelayInMin = automationEventConstraints.minimumSafeStartDelayFromNowInMinutes;
  const manualEventConstraints = useSelector(store.select.site.getManualEventConstraints);

  const hasAutomations = useSelector(store.select.site.hasAutomations);
  const hasModifyAutomationEventsPermission = useSelector(store.select.user.hasModifyAutomationEventsPermission);
  const canModifyAutomationEvents = hasAutomations && hasModifyAutomationEventsPermission;

  const [state, dispatch] = useManageEvents({
    isEditMode,
    block,
    event,
    activeBlocks,
    activeBlocksWithAutomations,
    canModifyAutomationEvents,
    automationEventConstraints,
    manualEventConstraints,
  });

  const [isReadyGoBack, setIsReadyGoBack] = useState(false);

  const initialAlertState = {
    show: false,
    alertType: null,
    title: '',
    message: '',
    messageAdvanced: null,
    onPress: null,
    onCancel: null,
    okButtonKey: '',
    cancelButtonKey: '',
    cancelDisabled: false,
  };

  const [alertState, setAlertState] = useState(initialAlertState);

  const isManualIrrigationSelected = isManualIrrigation(state.eventTypeSelected);
  const isAutomatedIrrigationSelected = isAutomatedIrrigation(state.eventTypeSelected);

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      return () => {
        store.dispatch.schedule.updateSelectedBlockId(null);
        store.dispatch.schedule.updateSelectedEvent(null);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  );

  /* istanbul ignore next */
  useLayoutEffect(() => {
    dispatch({ type: EVENT_ACTIONS.RESET });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, block, event]);

  /* istanbul ignore next */
  useEffect(() => {
    // Go to the previous page once the ALERT is closed on the current page and not on the "back" page
    if (isReadyGoBack) {
      setIsReadyGoBack(false);
      goBack();
    }
  }, [isReadyGoBack, goBack]);

  const handleChangeEventTypeTouch = (newEventType) => {
    if (isAutomatedIrrigation(newEventType) && areBlocksWithoutAutomationAreSelected(state.eventBlocks)) {
      handleDisplayDiscardBlocksWithoutAutomationWarning(newEventType);
    } else if (isManualIrrigation(newEventType) && areVirtualBlocksSelected(state.eventBlocks)) {
      handleDisplayDiscardBlocksWithoutIrrigationCapabilityWarning(newEventType);
    } else {
      handleChangeEventType(newEventType);
    }
  };

  const handleChangeEventType = (newEventType) => {
    handleHideAlert();
    dispatch({ type: EVENT_ACTIONS.SET_EVENT_TYPE, payload: newEventType });
  };

  const handleChangeSelectedBlocks = (newSelectedBlocks) => {
    dispatch({ type: EVENT_ACTIONS.SET_EVENT_BLOCKS, payload: newSelectedBlocks });
  };

  const handleChangeDate = (newDate) => {
    dispatch({ type: EVENT_ACTIONS.SET_EVENT_DATE, payload: newDate.tz(timezone) });
  };

  const handleChangeAnytime = () => {
    if (!state.isAnytimeEvent) {
      handleChangeDate(state.eventDate);
    }
    dispatch({ type: EVENT_ACTIONS.SET_ANYTIME_EVENT, payload: !state.isAnytimeEvent });
  };

  /* istanbul ignore next */
  const handleChangeDuration = (newDuration) => {
    dispatch({ type: EVENT_ACTIONS.SET_EVENT_DURATION, payload: newDuration });
  };

  const onSaveEvent = async (ignoreWarning = false) => {
    const response = await handleSaveEvent(ignoreWarning);

    if (response.errors) {
      const message = getErrorMessageTranslation(response);
      handleDisplayError(message);
    } else if (response.event) {
      setIsReadyGoBack(true);
    }
  };

  const validSafeStartDate = (eventDate) => {
    const nowWithSafeDelay = moment().add(automMinSafeStartDelayInMin, 'minutes');
    return eventDate.isAfter(nowWithSafeDelay);
  };

  const isSafeStartDate = () => {
    const currentDateIsSafe = validSafeStartDate(state.eventDate);

    if (isCreateMode) {
      return currentDateIsSafe;
    }

    const previousStartDate = event.startDate;
    return currentDateIsSafe && validSafeStartDate(previousStartDate);
  };

  const isRunning = () => {
    const now = moment();
    const startDate = event.startDate;
    const endDate = event.endDate;
    return startDate.isBefore(now) && endDate.isAfter(now);
  };

  const handleSaveEvent = async (ignoreWarning) => {
    if (isAutomatedIrrigationSelected) {
      if (!ignoreWarning) {
        if (isEditMode && isRunning()) {
          showIsRunningWarning();
          return { reponse: {} };
        } else if (!isSafeStartDate()) {
          showUnsafeStartWarning();
          return { reponse: {} };
        }
      } else {
        handleHideAlert();
      }

      if (isCreateMode) {
        return createScheduleEvents(formatAutomatedIrrigation(state));
      }
      const { duration, startDate } = formatAutomatedIrrigation(state);
      return updateScheduleEvents(duration, event.id, startDate);
    } else {
      if (isCreateMode) {
        return createEvent(formatManualIrrigation(state));
      }
      return createEvent(formatManualIrrigation(state), event.id);
    }
  };

  const handleDeleteEventConfirm = async () => {
    handleHideAlert();

    const response = await handleDeleteEvent();

    if (response?.errors) {
      const message = getErrorMessageTranslation(response);
      handleDisplayError(message);
    } else {
      setIsReadyGoBack(true);
    }
  };

  const handleDeleteEvent = async () => {
    if (isManualIrrigationSelected) {
      return createEvent(null, event.id);
    }

    /* istanbul ignore next */
    if (isAutomatedIrrigationSelected) {
      return deleteScheduleEvent(event.id);
    }
  };

  const handleDisplayError = (message) => {
    setAlertState({
      show: true,
      alertType: AlertTypes.ERROR,
      title: t('edit_event_error_title'),
      message: t(message),
      onPress: handleHideAlert,
      onCancel: handleHideAlert,
      okButtonKey: '',
      cancelButtonKey: '',
      cancelDisabled: false,
      testID: 'manage-event_error-modal',
    });
  };

  const onDeleteEvent = () => {
    const confirmMessage = isSafeStartDate()
      ? t('edit_event_delete_confirm_message')
      : t('edit_event_delete_unsafe_confirm_message', automMinSafeStartDelayInMin);

    setAlertState({
      show: true,
      alertType: AlertTypes.WARNING,
      title: t('edit_event_delete_confirm_title'),
      message: confirmMessage,
      onPress: handleDeleteEventConfirm,
      onCancel: handleHideAlert,
      okButtonKey: 'edit_event_delete_button_title',
      cancelButtonKey: 'edit_event_cancel_button_title',
      cancelDisabled: false,
      testID: 'manage-event_delete-confirmation-modal',
    });
  };

  const handleDisplayDiscardBlocksWithoutAutomationWarning = (newEventType) => {
    setAlertState({
      show: true,
      alertType: AlertTypes.WARNING,
      title: t('warning'),
      message: t('manage_event_change_event_type_for_automated_warning_message'),
      onPress: () => handleChangeEventType(newEventType),
      onCancel: handleHideAlert,
      okButtonKey: t('answer_yes'),
      cancelButtonKey: t('answer_no'),
      cancelDisabled: false,
      testID: 'manage-event_change-irrigation-type_from-manual_modal',
    });
  };

  const handleDisplayDiscardBlocksWithoutIrrigationCapabilityWarning = (newEventType) => {
    setAlertState({
      show: true,
      alertType: AlertTypes.WARNING,
      title: t('warning'),
      message: t('manage_event_change_event_type_for_manual_warning_message'),
      onPress: () => handleChangeEventType(newEventType),
      onCancel: handleHideAlert,
      okButtonKey: t('answer_yes'),
      cancelButtonKey: t('answer_no'),
      cancelDisabled: false,
      testID: 'manage-event_change-irrigation-type_from-automated_modal',
    });
  };

  const showUnsafeStartWarning = () => {
    setAlertState({
      show: true,
      alertType: AlertTypes.WARNING,
      title: t('warning'),
      message: t('manage_event_unsafe_start_warning_message'),
      onPress: () => onSaveEvent(true),
      onCancel: handleHideAlert,
      okButtonKey: t('generic_button_ok'),
      cancelButtonKey: t('generic_button_cancel'),
      cancelDisabled: false,
      testID: 'manage-event_unsafe_start_warning',
    });
  };

  const showIsRunningWarning = () => {
    setAlertState({
      show: true,
      alertType: AlertTypes.WARNING,
      title: t('warning'),
      messageAdvanced: getAdvancedWarningMessage(),
      onPress: () => onSaveEvent(true),
      onCancel: handleHideAlert,
      okButtonKey: t('answer_yes'),
      cancelButtonKey: t('answer_no'),
      cancelDisabled: false,
      testID: 'manage-event_is_running_warning',
    });
  };

  const handleHideAlert = () => {
    setAlertState(initialAlertState);
  };

  const getErrorMessageTranslation = (response) => {
    const error0 = response.errors[0];
    const errorCode = error0.extensions?.code;
    const message = getEventErrorTranslationKey(errorCode);
    const minimumDelayConstraint = state.eventConstraints?.minimumAllowedStartDelayFromNowInMinutes;

    /* istanbul ignore next */
    return errorCode === EVENT_START_TOO_SOON_CODE ? t(message, minimumDelayConstraint) : t(message);
  };

  const getAdvancedWarningMessage = () => {
    const message = t('manage_event_is_running_warning_message');
    const question = t('question_continue');

    return (
      <View style={styles.modalTextContainer}>
        <Text style={styles.modalDescription}>{message}</Text>

        <View style={styles.buttonContainer}>
          <Image style={styles.icon} source={icnAutomationAddRemove} testID={'schedule__automation-button__add-remove'} />
          <Image style={styles.icon} source={icnAutomationStop} testID={'schedule__automation-button__stop'} />
        </View>

        <Text style={styles.modalDescription}>{question}</Text>
      </View>
    );
  };

  return (
    <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
      <Alert
        show={alertState.show}
        alertType={alertState.alertType}
        title={alertState.title}
        message={alertState.message}
        messageAdvanced={alertState.messageAdvanced}
        onPress={alertState.onPress}
        onCancel={alertState.onCancel}
        okButtonKey={alertState.okButtonKey}
        cancelButtonKey={alertState.cancelButtonKey}
        cancelDisabled={alertState.cancelDisabled}
      />

      <View style={globalStyles.header}>
        <HeaderEdition
          onCancel={goBack}
          onSave={() => onSaveEvent()}
          onDelete={onDeleteEvent}
          deleteEnabled={isEditMode}
          title={state.screenTitle}
        />
      </View>

      <Divider />

      <View style={globalStyles.bottomContainer}>
        <ScrollView
          style={styles.content}
          contentContainerStyle={globalStyles.scrollContainer}
          showsVerticalScrollIndicator={false}>
          {canModifyAutomationEvents ? (
            <EventTypeSelector
              eventTypeSelected={state.eventTypeSelected}
              onChange={handleChangeEventTypeTouch}
              isModificationMode={isCreateMode}
            />
          ) : null}

          <BlocksSelector
            availableBlocks={state.availableBlocks}
            eventBlocks={state.eventBlocks}
            setEventBlocks={handleChangeSelectedBlocks}
            showSelectBlocksButton={isCreateMode}
          />

          <TimeSelector
            eventDate={state.eventDate.clone()}
            eventDuration={state.eventDuration}
            setEventDuration={handleChangeDuration}
            isAnytimeEvent={state.isAnytimeEvent}
            isAnytimeEventVisible={state.isAnytimeEventVisible}
            onSelectAnytime={handleChangeAnytime}
            onSelectDate={handleChangeDate}
            eventConstraints={state.eventConstraints}
          />
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

ManageEventScreen.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({
      block: PropTypes.instanceOf(BlockEntity),
      event: PropTypes.instanceOf(IrrigationEvent),
      onGoBack: PropTypes.func.isRequired,
    }),
  }),
};

const styles = StyleSheet.create({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20,
  },
  content: {
    backgroundColor: COLORS.white,
  },
  icon: {
    width: 60,
    height: 60,
  },
  modalDescription: {
    paddingHorizontal: 10,
    marginTop: 20,
    textAlign: 'center',
    fontFamily: FONTS.firaSans,
    fontSize: 16,
    color: COLORS.greyishBrown,
  },
  modalTextContainer: {
    alignItems: 'center',
    paddingHorizontal: 5,
  },
});

export default ManageEventScreen;
