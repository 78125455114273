import COLORS from '../../colors';
import { PIN_ICONS } from '../../assets/icons/mapPins/pinIcons';

export const NORMAL = 'NORMAL';
export const COMMUNICATION_ERROR = 'COMMUNICATION_ERROR';
export const OUT_OF_FIELD = 'OUT_OF_FIELD';

const weatherStationColorsByStatus = {
  NORMAL: COLORS.greyishBrown,
  COMMUNICATION_ERROR: COLORS.greyishThree,
  OUT_OF_FIELD: COLORS.greyishThree,
  [undefined]: COLORS.darkBlue,
  default: COLORS.greyishBrown,
};

const mapWeatherStationIconByStatus = {
  NORMAL: PIN_ICONS.weather,
  OUT_OF_FIELD: PIN_ICONS.outOfField,
  COMMUNICATION_ERROR: PIN_ICONS.weatherCommunicationProblem,
  default: PIN_ICONS.weatherCommunicationProblem,
};

export const colorForStatus = (status) => weatherStationColorsByStatus[status] || weatherStationColorsByStatus.default;

export const iconForMapStatus = (status) => mapWeatherStationIconByStatus[status] || mapWeatherStationIconByStatus.default;
