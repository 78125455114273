import PropTypes from 'prop-types';
import { Image, StyleSheet, Text, Pressable, View } from 'react-native';

// hooks
import { useTranslation } from '../../hooks/useTranslation';
import { useAnalyticsContext } from '../initialization/AnalyticsProvider';

// icons
import icnNavRight from '../../assets/icons/icnNavRight.png';
import icnTension from '../../assets/icons/icnTension.png';

// services
import { navigationShape } from '../../shapes/navigation';
import ANALYTICS from '../../services/AnalyticsEvents';

// entity
import BlockEntity from '../../models/entities/blockEntity';

// constants
import FONTS from '../../fonts';
import COLORS from '../../colors';
import ROUTES from '../../navigation/routes';

// components
import EmptySectionView from '../EmptySectionView';
import SkeletonPlaceholder from '../skeletons/SkeletonPlaceholder';
import CriticalBlockItem from './CriticalBlockItem';

const CriticalBlocks = ({ blocks, loading, navigation, testId }) => {
  const { t } = useTranslation();
  const analyticsService = useAnalyticsContext();

  const goToIrrigation = () => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewIrrigation);
    navigation.navigate(ROUTES.IRRIGATION, { routeToGoBackTo: ROUTES.DASHBOARD });
  };

  const renderCriticalBlocks = () => {
    if (loading) {
      return (
        <View style={styles.skeletonContainer} testID="loadingBlocks">
          {[...Array(3)].map((e, i) => (
            <SkeletonPlaceholder width={60} height={43} style={styles.skeletonBlock} key={'block' + i} />
          ))}
        </View>
      );
    } else if (!blocks || !blocks.length) {
      return (
        <EmptySectionView
          backColor={{ backgroundColor: COLORS.lightGreen }}
          icon={icnTension}
          iconColor={{ tintColor: COLORS.seafoamBlue }}
          iconSizeStyle={styles.tensionIconSize}
          textColor={{ color: COLORS.seafoamBlue }}
          textKey="critical_blocks_empty"
        />
      );
    } else {
      return (
        <View style={styles.listStyle} testID="dashboard__criticalBlocksList">
          {blocks.map((block, i) => (
            <CriticalBlockItem block={block} navigation={navigation} key={block.id} />
          ))}
        </View>
      );
    }
  };

  return (
    <View testID={testId}>
      <View style={[styles.header, styles.centerRow]}>
        <Text style={styles.title}>{t('critical_blocks')}</Text>

        <Pressable style={styles.centerRow} onPress={goToIrrigation} testID="full-list-button">
          <Text style={styles.details}>{t('see_full_list')}</Text>
          <Image source={icnNavRight} style={styles.arrowSpacing} />
        </Pressable>
      </View>
      <View style={styles.content} testID="critical_blocks-content">
        {renderCriticalBlocks()}
      </View>
    </View>
  );
};

CriticalBlocks.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.instanceOf(BlockEntity)),
  loading: PropTypes.bool,
  navigation: navigationShape.isRequired,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  arrowSpacing: {
    marginLeft: 10,
  },
  centerRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    marginHorizontal: 13,
    marginVertical: 13,
  },
  details: {
    fontFamily: FONTS.firaSansLight,
    fontSize: 14,
    color: COLORS.greyishBrown,
  },
  header: {
    justifyContent: 'space-between',
    height: 'auto',
    paddingRight: 17,
    paddingLeft: 15,
    marginTop: 10,
  },
  listStyle: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: -13,
  },
  tensionIconSize: {
    width: 22,
    height: 29,
  },
  title: {
    fontFamily: FONTS.firaSans,
    fontSize: 16,
    color: COLORS.greyishBrown,
  },
  skeletonBlock: {
    marginRight: 24,
  },
  skeletonContainer: {
    flexDirection: 'row',
  },
});

export default CriticalBlocks;
