export const DEGREES_CELCIUS = {
  name: 'DEGREES_CELCIUS',
  displayValue: '\u00b0C',
  translationReference: 'unit_degrees_celcius',
};

export const DEGREES_FAHRENHEIT = {
  name: 'DEGREES_FAHRENHEIT',
  displayValue: '\u00b0F',
  translationReference: 'unit_degrees_fahrenheit',
};

export const TemperatureUnits = {
  DEGREES_CELCIUS,
  DEGREES_FAHRENHEIT,
};
