import { memo } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import COLORS from '../../colors';
import FONTS from '../../fonts';
import { TemperatureSensorPresenter } from '../../presenters/TemperatureSensorPresenter';
import { useTranslation } from '../../hooks/useTranslation';
import { SensorShape } from '../../shapes/sensor';
import LastUpdate from '../../components/block/LastUpdate';
import SensorName from '../../components/block/SensorName';
import SensorData from '../../components/block/SensorData';

const TemperatureSensorItem = ({ temperature, index, onPress }) => {
  const temperatureSensorPresenter = new TemperatureSensorPresenter(temperature);
  const { t } = useTranslation();

  return (
    <Pressable testID={`temperature__sensorItem-${temperature.id}`} onPress={onPress}>
      <View
        style={temperatureSensorPresenter.containerStyle(index)}
        testID={`temperature-sensor-${temperature.id}-${temperatureSensorPresenter.type}`}>
        <SensorName presenter={temperatureSensorPresenter} containerStyle={styles.temperatureContainer} />
        <SensorData
          value={temperatureSensorPresenter.temperatureValue(t)}
          containerStyle={styles.sensorDataContainerStyle}
          textStyle={styles.temperature}
        />
        <LastUpdate
          lastUpdatedTimeStyle={styles.lastUpdatedTime}
          date={temperatureSensorPresenter.lastUpdated(t)}
          showHeader={false}
          containerStyle={styles.lastUpdateContainer}
        />
      </View>
    </Pressable>
  );
};

TemperatureSensorItem.propTypes = {
  index: PropTypes.number.isRequired,
  temperature: SensorShape.isRequired,
  onPress: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  lastUpdateContainer: {
    width: 100,
  },
  lastUpdatedTime: {
    textAlign: 'right',
    fontFamily: FONTS.notoSansBold,
    fontSize: 11,
    letterSpacing: -0.03,
    color: COLORS.greyishBrown,
  },
  sensorDataContainerStyle: {
    flex: 1,
  },
  temperature: {
    textAlign: 'right',
    fontFamily: FONTS.notoSansBold,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
  temperatureContainer: {
    flex: 1,
  },
});

export default memo(TemperatureSensorItem);
