import PropTypes from 'prop-types';
import { StyleSheet, View, Text } from 'react-native';
import moment from 'moment';
import { useTranslation } from '../../hooks/useTranslation';
import FONTS from '../../fonts';
import COLORS from '../../colors';

// shape
import { styleShape } from '../../shapes/style';

const LastUpdate = ({ date, showHeader = true, lastUpdatedTimeStyle, containerStyle }) => {
  const { t } = useTranslation();
  const lastUpdated = date ? moment(date).fromNow() : '';

  return (
    <View style={containerStyle}>
      {lastUpdated !== '' && showHeader && <Text style={styles.lastUpdatedHeader}>{t('graph_view_last_update')}</Text>}
      <Text style={lastUpdatedTimeStyle ? lastUpdatedTimeStyle : styles.lastUpdatedTime}>{lastUpdated}</Text>
    </View>
  );
};

LastUpdate.propTypes = {
  containerStyle: styleShape,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lastUpdatedTimeStyle: styleShape,
  showHeader: PropTypes.bool,
};

const styles = StyleSheet.create({
  lastUpdatedTime: {
    textAlign: 'right',
    fontFamily: FONTS.notoSansItalic,
    fontSize: 12,
    letterSpacing: -0.03,
    color: COLORS.black,
  },
  lastUpdatedHeader: {
    textAlign: 'right',
    fontFamily: FONTS.notoSansItalic,
    fontSize: 10,
    letterSpacing: -0.04,
    color: COLORS.greyishBrown,
  },
});

export default LastUpdate;
