import { useEffect, useRef, useState, useCallback } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useFocusEffect } from '@react-navigation/native';

// services
import { navigationShape } from '../../shapes/navigation';
import { SORT_ORDER, toggleSort } from '../../utils/sorting';

// styles
import { globalStyles } from '../../styles';

// components
import SortableList from '../../components/SortableList';
import SkeletonMultiRowView from '../../components/skeletons/SkeletonMultiRow';
import TemperatureBlockItem from './TemperatureBlockItem';
import SortableTemperatureHeader from './SortableTemperatureHeader';
import { SORT_COLUMNS } from './sorting';

const TemperatureScreen = ({ navigation }) => {
  const loading = useSelector((state) => !!state.loading.effects.blocks.loadTemperatureBlocksForCurrentSite);
  const sortLoading = useSelector((state) => !!state.loading.effects.blocks.sortTemperatureBlocks);
  const [refreshing, setRefreshing] = useState(false);
  const sortedTemperatureBlocks = useSelector((state) => state.blocks.sortedTemperatureBlocks);
  const dispatch = useDispatch();

  const renderEmptyView = () => {
    return <SkeletonMultiRowView repetition={4} rows={2} />;
  };

  const listRef = useRef(undefined);

  const initialSortOrderState = {
    block: SORT_ORDER.NONE,
    temperature: SORT_ORDER.NONE,
    lastUpdated: SORT_ORDER.NONE,
  };

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      setSortedColumn(initialSortOrderState);
      dispatch.blocks.loadTemperatureBlocksForCurrentSite();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  );

  const [sortedColumn, setSortedColumn] = useState(initialSortOrderState);
  const toggle = toggleSort(sortedColumn, setSortedColumn, initialSortOrderState);

  const sortTemperatureBlocksFromSortedColumn = () => {
    if (sortedColumn.block !== SORT_ORDER.NONE) {
      dispatch.blocks.sortTemperatureBlocks({ column: SORT_COLUMNS.BLOCK, order: sortedColumn.block });
    } else if (sortedColumn.temperature !== SORT_ORDER.NONE) {
      dispatch.blocks.sortTemperatureBlocks({ column: SORT_COLUMNS.TEMPERATURE, order: sortedColumn.temperature });
    } else if (sortedColumn.lastUpdated !== SORT_ORDER.NONE) {
      dispatch.blocks.sortTemperatureBlocks({ column: SORT_COLUMNS.LAST_UPDATED, order: sortedColumn.lastUpdated });
    }
  };

  useEffect(() => {
    sortTemperatureBlocksFromSortedColumn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedColumn]);

  const toggleSortAndScrollToTop = (payload) => {
    /* istanbul ignore next */
    if (listRef.current && !loading) {
      listRef.current.scrollToOffset({ x: 0, y: 0, animated: false });
      toggle(payload);
    }
  };

  const renderBlockItem = (element) => {
    return (
      <TemperatureBlockItem
        navigation={navigation}
        block={element.item}
        keyIndex={element.index}
        testID={`temperature__block-item-${element.item.id}`}
      />
    );
  };

  const loadAndSortBlocks = async () => {
    await dispatch.blocks.loadTemperatureBlocksForCurrentSite();
    sortTemperatureBlocksFromSortedColumn();
  };

  const refreshBlocks = async () => {
    setRefreshing(true);
    await loadAndSortBlocks();
    setRefreshing(false);
  };

  return (
    <View testID="temperature__subscreen-container" style={globalStyles.bottomContainer}>
      <SortableTemperatureHeader sortedColumn={sortedColumn} toggle={toggleSortAndScrollToTop} loading={sortLoading} />
      <SortableList
        testID="temperature__subscreen-list"
        ref={listRef}
        elements={sortedTemperatureBlocks}
        loading={loading}
        refreshing={refreshing}
        sort={dispatch.blocks.sortTemperatureBlocks}
        renderEmptyView={renderEmptyView}
        renderItem={renderBlockItem}
        screenName={'temperature'}
        onRefresh={refreshBlocks}
        initialNumToRender={5}
      />
    </View>
  );
};

TemperatureScreen.propTypes = {
  navigation: navigationShape.isRequired,
};

export default TemperatureScreen;
