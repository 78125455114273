import PropTypes from 'prop-types';
import { StyleSheet, View, Text } from 'react-native';

// constants
import COLORS from '../../../colors';
import FONTS from '../../../fonts';
import { DAILY_WEATHER_ROW_HEIGHT } from '../../../styles';

// helpers
import { getDayOfTheWeek } from '../../../utils/dateUtils';

// hooks
import { useTranslation } from '../../../hooks/useTranslation';

// components
import ForecastDetailsData from '../ForecastDetailsData';
import WeatherIcon from '../WeatherIcon';
import { WeatherForecastPresenter } from '../../../presenters/WeatherForecastPresenter';

const DailyWeather = ({
  date,
  etoUnit,
  etoValue,
  temperatureMax,
  temperatureMin,
  temperatureUnit,
  rainPrecipitation,
  rainProbability,
  rainUnit,
  weatherCode,
  windDirection,
  windSpeed,
  windUnit,
}) => {
  const { t } = useTranslation();

  const weatherForecastPresenter = new WeatherForecastPresenter(
    t,
    etoUnit,
    etoValue,
    rainPrecipitation,
    rainProbability,
    rainUnit,
    temperatureMax,
    temperatureMin,
    temperatureUnit,
    weatherCode,
    windDirection,
    windSpeed,
    windUnit,
  );

  const formattedTemperatureMin = weatherForecastPresenter.formattedTemperatureMin();
  const formattedTemperatureMax = weatherForecastPresenter.formattedTemperatureMax();
  const formattedEto = weatherForecastPresenter.formattedEto();
  const formattedRainPrecipitation = weatherForecastPresenter.formattedRainPrecipitation();
  const formattedRainProbability = weatherForecastPresenter.formattedRainProbability();
  const formattedWindSpeed = weatherForecastPresenter.formattedWindSpeed();

  const dayTranslationKey = getDayOfTheWeek(date).toLowerCase();

  return (
    <View style={styles.container}>
      <View style={styles.topRow}>
        <View style={styles.icon}>
          <WeatherIcon weatherCode={weatherCode} />
        </View>
        <Text style={styles.today}>{t(dayTranslationKey)}</Text>
        <View style={styles.flexPadding} />
      </View>

      <View style={styles.bottomRow}>
        <View style={styles.column}>
          <View style={styles.twoElements}>
            <ForecastDetailsData style={styles.columnContent} value={formattedTemperatureMin} boldFont={false} />
            <ForecastDetailsData style={styles.columnContent} value={formattedTemperatureMax} boldFont={false} />
          </View>
        </View>

        <View style={styles.column}>
          <View style={styles.twoElements}>
            <ForecastDetailsData style={styles.columnContent} value={formattedRainProbability} boldFont={false} />
            <ForecastDetailsData style={styles.columnContent} value={formattedRainPrecipitation} boldFont={false} />
          </View>
        </View>

        <View style={styles.column}>
          <ForecastDetailsData style={styles.columnContent} value={formattedEto} boldFont={false} />
        </View>

        <View style={styles.column}>
          <ForecastDetailsData style={styles.columnContent} value={formattedWindSpeed} boldFont={false} />
        </View>
      </View>
    </View>
  );
};

DailyWeather.propTypes = {
  date: PropTypes.number,
  etoUnit: PropTypes.string,
  etoValue: PropTypes.number,
  rainPrecipitation: PropTypes.number,
  rainProbability: PropTypes.number,
  rainUnit: PropTypes.string,
  temperatureMax: PropTypes.number,
  temperatureMin: PropTypes.number,
  temperatureUnit: PropTypes.string,
  weatherCode: PropTypes.string,
  windDirection: PropTypes.number,
  windSpeed: PropTypes.number,
  windUnit: PropTypes.string,
};

const styles = StyleSheet.create({
  bottomRow: {
    flexDirection: 'row',
  },
  column: {
    flex: 1,
    alignItems: 'center',
  },
  columnContent: {
    fontWeight: 'bold',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    height: DAILY_WEATHER_ROW_HEIGHT,
  },
  flexPadding: {
    flex: 1,
  },
  icon: {
    flex: 1,
    alignItems: 'center',
  },
  today: {
    flex: 2,
    textAlign: 'center',
    fontFamily: FONTS.notoSans,
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 15,
    color: COLORS.greyishBrown,
  },
  topRow: {
    flexDirection: 'row',
    marginBottom: 8,
  },
  twoElements: {
    flexDirection: 'row',
  },
});

export default DailyWeather;
