import icnTempEmpty from '../assets/icons/temperature/icnTempEmpty.png';
import icnTempShielded from '../assets/icons/temperature/icnTempShielded.png';
import icnTempUnshielded from '../assets/icons/temperature/icnTempUnshielded.png';
import icnTemperatureWater from '../assets/icons/temperature/icnTemperatureWater.png';
import COLORS from '../colors';
import { TemperatureUnits } from '../models/units/temperatureUnits';
import { DATA_STREAMS_TYPES } from '../models/dataStreamTypes';

export class TemperatureSensorPresenter {
  constructor(temperatureSensor) {
    this.temperatureSensor = temperatureSensor;
    this.type = temperatureSensor ? temperatureSensor.dataStreamType : undefined;
    this.isVirtual = temperatureSensor ? this.temperatureSensor.isVirtual : false;
    this.description = temperatureSensor ? temperatureSensor.description : undefined;
  }

  icon() {
    switch (this.type) {
      case DATA_STREAMS_TYPES.SHIELDED_TEMPERATURE.code:
        return {
          name: 'icnTempShielded',
          icon: icnTempShielded,
        };
      case DATA_STREAMS_TYPES.UNSHIELDED_TEMPERATURE.code:
        return {
          name: 'icnTempUnshielded',
          icon: icnTempUnshielded,
        };
      case DATA_STREAMS_TYPES.WATER_TEMPERATURE.code:
        return {
          name: 'icnTemperatureWater',
          icon: icnTemperatureWater,
        };
      default:
        return {
          name: 'icnTempEmpty',
          icon: icnTempEmpty,
        };
    }
  }

  name(t) {
    if (this.description) {
      return this.description;
    }

    switch (this.type) {
      case DATA_STREAMS_TYPES.SHIELDED_TEMPERATURE.code:
        return t('data_stream_button_name_shielded_temperature');
      case DATA_STREAMS_TYPES.UNSHIELDED_TEMPERATURE.code:
        return t('data_stream_button_name_unshielded_temperature');
      case DATA_STREAMS_TYPES.WATER_TEMPERATURE.code:
        return t('data_stream_button_name_water_temperature');
      default:
        return this.description;
    }
  }

  lastUpdated(t) {
    return this.temperatureSensor.lastUpdated ? this.temperatureSensor.lastUpdated : t('blue_band_index_na');
  }

  temperatureValue(t) {
    if (this.temperatureSensor === undefined) {
      return '- -';
    } else if (this.temperatureSensor.value === undefined || this.temperatureSensor.value === null) {
      return `- -${t(TemperatureUnits[this.temperatureSensor.unit].translationReference)}`;
    }
    return `${this.temperatureSensor.value}${t(TemperatureUnits[this.temperatureSensor.unit].translationReference)}`;
  }

  containerStyle(index) {
    const container = {
      alignItems: 'center',
      flexDirection: 'row',
      height: 46,
      justifyContent: 'space-between',
      paddingRight: 8,
    };
    const greyContainer = { ...container, backgroundColor: COLORS.greyish22 };

    return index % 2 === 0 ? greyContainer : container;
  }

  displayIcon() {
    return true;
  }
}
