import { StyleSheet, View } from 'react-native';
import SegmentedControlTab from 'react-native-segmented-control-tab';
import PropTypes from 'prop-types';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';
import OPACITIES from '../../opacities';

const TabHeader = ({ selectedTabIndex, changeActiveTab, tabs, screenName }) => {
  return (
    <View style={styles.segmentedViewContainer} testID={`${screenName}__segmented-view-container`}>
      <SegmentedControlTab
        tabsContainerStyle={styles.tabsContainerStyle}
        tabStyle={styles.tabStyle}
        firstTabStyle={styles.firstTabStyle}
        lastTabStyle={styles.lastTabStyle}
        tabTextStyle={styles.tabTextStyle}
        activeTabStyle={selectedTabIndex === 0 ? styles.firstTabActiveStyle : styles.lastTabActiveStyle}
        activeTabTextStyle={styles.activeTabTextStyle}
        selectedIndex={selectedTabIndex}
        allowFontScaling={false}
        values={tabs}
        onTabPress={changeActiveTab}
      />
    </View>
  );
};

TabHeader.propTypes = {
  selectedTabIndex: PropTypes.number.isRequired,
  changeActiveTab: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  screenName: PropTypes.string.isRequired,
};

const activeTabStyle = {
  backgroundColor: COLORS.white,
  borderRadius: 8,
  borderWidth: 0,
  margin: 1,
  shadowColor: COLORS.warmGrey,
  shadowOpacity: OPACITIES.shadow,
  shadowRadius: 2,
  elevation: 3,
};

const styles = StyleSheet.create({
  segmentedViewContainer: {
    alignItems: 'center',
    paddingHorizontal: 13,
    paddingVertical: 10,
    backgroundColor: COLORS.white,
  },
  tabsContainerStyle: {
    height: 35,
    borderColor: COLORS.midGrey,
    borderWidth: 1,
    borderRadius: 5,
    backgroundColor: COLORS.midGrey,
  },
  tabStyle: {
    borderWidth: 0,
    borderRadius: 5,
    margin: 1,
    backgroundColor: COLORS.midGrey,
  },
  firstTabStyle: {
    borderRadius: 8,
    margin: 1,
    borderRightWidth: 0,
  },
  lastTabStyle: {
    borderWidth: 0,
  },
  tabTextStyle: {
    paddingHorizontal: 35,
    fontFamily: FONTS.notoSans,
    fontSize: 13,
    color: COLORS.greyishBrown,
  },
  firstTabActiveStyle: {
    ...activeTabStyle,
    shadowOffset: {
      width: 2,
      height: 2,
    },
  },
  lastTabActiveStyle: {
    ...activeTabStyle,
    shadowOffset: {
      width: -2,
      height: 2,
    },
  },
  activeTabTextStyle: {
    fontFamily: FONTS.notoSans,
    fontSize: 13,
    color: COLORS.greyishBrown,
  },
});

export default TabHeader;
