import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

// constants
import COLORS from '../../colors';
import OPACITIES from '../../opacities';

// services
import { useTranslation } from '../../hooks/useTranslation';

// components
import SortableHeaderItem from '../../components/header/SortableHeaderItem';
import { SORT_COLUMNS } from './sorting';

const SortableAlertsHeader = ({ sortedColumn, toggle, loading }) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.bar}>
        <SortableHeaderItem
          style={styles.statusHeader}
          title={t('alerts_sort_button_level')}
          titleStyle={styles.alignLeft}
          onPress={toggle}
          sortOrder={sortedColumn.level}
          sortKey={SORT_COLUMNS.LEVEL}
          testID="list__alerts-header-item-level"
          loading={loading}
        />
        <SortableHeaderItem
          style={styles.serialHeader}
          title={t('alerts_sort_button_name')}
          titleStyle={styles.alignLeft}
          onPress={toggle}
          sortOrder={sortedColumn.name}
          sortKey={SORT_COLUMNS.NAME}
          testID="list__alerts-header-item-name"
          loading={loading}
        />
        <View style={styles.emptyCell} />
        <SortableHeaderItem
          style={styles.blockHeader}
          title={t('alerts_sort_button_status')}
          titleStyle={styles.alignLeft}
          onPress={toggle}
          sortOrder={sortedColumn.status}
          sortKey={SORT_COLUMNS.STATUS}
          testID="list__alerts-header-item-status"
          loading={loading}
        />
      </View>
    </View>
  );
};

SortableAlertsHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
  sortedColumn: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  alignLeft: {
    justifyContent: 'flex-start',
  },
  bar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  blockHeader: {
    width: 80,
  },
  container: {
    zIndex: 2,
    height: 52,
    backgroundColor: COLORS.white,
    elevation: 3,
    shadowColor: COLORS.warmGrey,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: OPACITIES.shadow,
    shadowRadius: 2,
  },
  emptyCell: {
    flex: 1,
    alignItems: 'flex-end',
  },
  serialHeader: {
    width: 80,
    marginLeft: 5,
  },
  statusHeader: {
    width: 60,
    marginStart: 15,
  },
});

export default SortableAlertsHeader;
