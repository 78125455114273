import { PIN_ICONS } from '../../assets/icons/mapPins/pinIcons';

export const COMMUNICATION_ERROR = 'COMMUNICATION_ERROR';
export const DISABLED = 'DISABLED';
export const ERROR = 'ERROR';
export const ERROR_WHILE_IRRIGATING = 'ERROR_WHILE_IRRIGATING';
export const OFF = 'OFF';
export const ONGOING = 'ONGOING';
export const SYNCHRONIZING = 'SYNCHRONIZING';
export const UNKNOWN = 'UNKNOWN';

const mapValveIconByStatus = {
  COMMUNICATION_ERROR: PIN_ICONS.valveDisabled,
  DISABLED: PIN_ICONS.valveDisabled,
  ERROR: PIN_ICONS.valveError,
  ERROR_WHILE_IRRIGATING: PIN_ICONS.valveErrorIrrigating,
  OFF: PIN_ICONS.valveGood,
  ONGOING: PIN_ICONS.valveIrrigating,
  SYNCHRONIZING: PIN_ICONS.valveSync,
  UNKNOWN: PIN_ICONS.valveUnknown,
};

export const isValveIcon = (icon) => {
  const icons = Object.values(mapValveIconByStatus);

  for (let i = 0; i < icons.length; i++) {
    if (icons[i].normal.name === icon || icons[i].active.name === icon) {
      return true;
    }
  }

  return false;
};

export const iconForMapStatus = (status) => mapValveIconByStatus[status] || mapValveIconByStatus.UNKNOWN;
