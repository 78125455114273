// client
import { getApolloClient } from '../clients/apollo';

//constants
import { FORCE_START, ADD_TIME, REMOVE_TIME } from '../models/automationCommandType';
import { EXECUTE_AUTOMATION_COMMAND } from './executeAutomationCommand.graphql';

async function executeAutomationCommand(siteId, automationId, commandId, durationInMin) {
  try {
    await getApolloClient().mutate({
      mutation: EXECUTE_AUTOMATION_COMMAND,
      variables: {
        siteId,
        automationId,
        commandId,
        options: createOptionObject(commandId, durationInMin),
      },
    });
  } catch (error) {
    if (error.graphQLErrors?.length > 0) {
      return { errors: error.graphQLErrors };
    } else {
      return { errors: [{}] };
    }
  }
}

/* istanbul ignore next */
function createOptionObject(commandId, durationInMin) {
  if (commandId === FORCE_START.id) {
    return { runFor: durationInMin };
  }
  if (commandId === ADD_TIME.id || commandId === REMOVE_TIME.id) {
    return { duration: durationInMin };
  }
  return { preventRunningFor: durationInMin };
}

export { executeAutomationCommand };
