import { useState } from 'react';
import { StyleSheet, Text, Pressable, View } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import PropTypes from 'prop-types';

// consts
import COLORS from '../../../colors';
import FONTS from '../../../fonts';

// hooks
import { useTranslation } from '../../../hooks/useTranslation';

// styles
import { globalStyles, MINIMUM_FONT_SCALE } from '../../../styles';

//components
import CustomModal from '../../modal/CustomModal';
import GenericSection from '../../layouts/GenericSection';
import SelectBlocks from './blocks/SelectBlocks';

const BlocksSelector = ({ availableBlocks, eventBlocks, setEventBlocks, showSelectBlocksButton = false }) => {
  const { t } = useTranslation();

  const [isSelectBlocksModalVisible, setSelectBlocksModalVisible] = useState(false);

  // istanbul ignore next
  const onSelectBlocks = (blocks) => {
    setEventBlocks(blocks);
    return setSelectBlocksModalVisibility();
  };

  // istanbul ignore next
  const setSelectBlocksModalVisibility = () => setSelectBlocksModalVisible(!isSelectBlocksModalVisible);

  const selectedBlockList = () => (
    <View style={styles.blocksContainer}>
      {eventBlocks.map((block) => (
        <View style={styles.blockContainer} key={block.id} testID={`schedule__selected-block-alias-${block.id}`}>
          <Text
            ellipsizeMode={'tail'}
            adjustsFontSizeToFit
            minimumFontScale={MINIMUM_FONT_SCALE}
            numberOfLines={1}
            style={styles.blockText}>
            {block.alias}
          </Text>
        </View>
      ))}
    </View>
  );

  return (
    <>
      <CustomModal
        onCloseModal={setSelectBlocksModalVisibility}
        isVisible={isSelectBlocksModalVisible}
        testID={'schedule__modal-select-blocks'}
        animationEffects={{ animationIn: 'slideInRight', animationOut: 'slideOutRight' }}>
        <SafeAreaProvider>
          <SelectBlocks
            onCancel={setSelectBlocksModalVisibility}
            onConfirm={onSelectBlocks}
            availableBlocks={availableBlocks}
            selectedBlocks={eventBlocks}
          />
        </SafeAreaProvider>
      </CustomModal>

      <GenericSection title={t('edit_event_blocks_list_section_title')}>
        {showSelectBlocksButton ? (
          <Pressable
            style={/* istanbul ignore next */ ({ pressed }) => (pressed ? globalStyles.touchOpacity : {})}
            onPress={setSelectBlocksModalVisibility}
            testID={'schedule__select-blocks-button'}>
            <View style={styles.selectBlocksContainer}>
              <Text style={styles.selectBlocksTitle}>{t('edit_event_select_blocks_button_title')}</Text>
            </View>
            {selectedBlockList()}
          </Pressable>
        ) : (
          selectedBlockList()
        )}
      </GenericSection>
    </>
  );
};

BlocksSelector.propTypes = {
  availableBlocks: PropTypes.array,
  eventBlocks: PropTypes.array.isRequired,
  setEventBlocks: PropTypes.func,
  showSelectBlocksButton: PropTypes.bool,
};

const styles = StyleSheet.create({
  blocksContainer: {
    flexGrow: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    minHeight: 33.5,
    paddingHorizontal: 6,
    paddingBottom: 6,
  },
  blockContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 43,
    padding: 2,
    borderColor: COLORS.warmGrey,
    borderWidth: 2,
    borderRadius: 2,
    marginHorizontal: 5,
    marginVertical: 6,
  },
  blockText: {
    fontFamily: FONTS.notoSansBold,
    fontSize: 14,
    letterSpacing: 0.66,
    color: COLORS.warmGrey,
  },
  selectBlocksContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  selectBlocksTitle: {
    fontFamily: FONTS.notoSansBold,
    fontSize: 12,
    color: COLORS.stone,
  },
});

export default BlocksSelector;
