/* istanbul ignore file */
import { useEffect, useRef, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { View, StyleSheet, Dimensions } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import mapboxgl from 'mapbox-gl';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';

// icons
import { PIN_ICONS } from '../../assets/icons/mapPins/pinIcons';
import icnRefresh from '../../assets/icons/icnRefresh.png';
import icnZoomOut from '../../assets/icons/icnZoomOut.png';
import icnZoomIn from '../../assets/icons/icnZoomIn.png';

// styles
import { globalStyles } from '../../styles';

//constants
import ROUTES from '../../navigation/routes';

// services
import { MapStyleMode, findMapStyleUrl } from '../../presenters/MapDisplayMode';
import { nextMapDisplayMode, shouldDisplayMapMode, MAP_ACTIONS } from '../../presenters/MapPresenter';
import ANALYTICS from '../../services/AnalyticsEvents';
import { navigationShape } from '../../shapes/navigation';

// hooks
import useAppState from '../../hooks/useAppState';
import useMap from '../../hooks/useMap';

// components
import LoadingOverlay from '../../components/LoadingOverlay';
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import Divider from '../../components/Divider';
import HeaderBar from '../../components/header/HeaderBar';
import TouchIconButton from '../../components/button/TouchIconButton';
import RoundButton from '../../components/button/RoundButton';
import { normalizeCoordinate } from './MapService';
import GoToUserLocationButton from './GoToUserLocationButton';
import MapDisplayModeChange from './MapDisplayModeChange';
import ItemsDetailsContainer from './ItemsDetailsContainer';
import NDVILegendButton from './NDVILegendButton';
import NDVILegendDetails from './NDVILegendDetails';
import NDVIDate from './NDVIDate';
import SymbolLayer from './SymbolLayer.web';

const DEFAULT_CAMERA = {
  centerCoordinate: [-99, 42],
  zoomLevel: 2,
};
const DEFAULT_FLY_TO_SPEED = 0.3;
const DEFAULT_ZOOM_LEVEL = 13;
const SCREEN_HEIGHT = Dimensions.get('window').height;

const getGeolocationPermissionStatus = async () => {
  return new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(
      () => resolve(true),
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          resolve(false);
        }

        // some other error, but not one which is related to a denied permission
        resolve(true);
      },
      {
        maximumAge: Infinity,
        timeout: 0,
      },
    );
  });
};

const loadImage = (map, icon) => {
  return new Promise((resolve, reject) => {
    map.loadImage(icon, (error, image) => {
      if (error) {
        reject(error);
      } else {
        resolve(image);
      }
    });
  });
};

const addImage = (map, name, image) => {
  return new Promise((resolve, reject) => {
    map.addImage(name, image, (error) => {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  });
};

const loadImages = async (map) =>
  Promise.all(
    Object.entries(PIN_ICONS).map(async ([_key, pin]) => {
      const imageNormal = await loadImage(map, pin.normal.icon);
      addImage(map, pin.normal.name, imageNormal);
      const imageActive = await loadImage(map, pin.active.icon);
      addImage(map, pin.active.name, imageActive);
    }),
  );

const MapScreen = ({ navigation }) => {
  const loading = useSelector((state) => !!state.loading.effects.map.loadMapBlocksForCurrentSite);
  const mapMode = useSelector((state) => state.map.mode);
  const blocks = useSelector((state) => state.map.blocks);
  const mapItems = useSelector((state) => state.map.items);
  const mapStyle = useSelector((state) => state.map.style);
  const currentSite = useSelector((state) => state.site.currentSite);
  const { currentAppState, previousAppState } = useAppState();
  const dispatchRef = useDispatch();
  const isFocused = useIsFocused();

  const mapContainer = useRef(null);
  const map = useRef(null);
  const mapCamera = useRef(null);
  const carouselRef = useRef(undefined);
  const presenters = useRef(null);
  const lastActiveBlockIdWithValue = useRef(null);
  const [refreshing, setRefreshing] = useState(false);
  const [ndviDate] = useState('');
  const [hasLocationPermission, setHasLocationPermission] = useState(false);
  const [showItemDetails, setShowItemDetails] = useState(false);
  const [isNDVILegendVisible, setIsNDVILegendVisible] = useState(undefined);

  const analyticsService = useAnalyticsContext();

  const changeMapStyle = (newMapStyle) => {
    if (mapStyle === MapStyleMode.NDVI || newMapStyle === MapStyleMode.NDVI) {
      dispatch({ type: MAP_ACTIONS.RESET_PINS });
    }
    dispatchRef.map.setMapStyle(newMapStyle);
    map.current.setStyle(findMapStyleUrl(newMapStyle));
  };

  const toggleMapMode = () => {
    setShowItemDetails(false);
    dispatchRef.map.setMapMode(nextMapDisplayMode(mapMode));
  };

  const gotoUserLocation = () => {
    if (!hasLocationPermission) {
      return;
    }

    navigator.geolocation.getCurrentPosition((position) => {
      map.current?.flyTo(
        {
          center: normalizeCoordinate([position.coords.longitude, position.coords.latitude]),
          speed: DEFAULT_FLY_TO_SPEED,
          zoom: DEFAULT_ZOOM_LEVEL,
        },
        { isFlying: true },
      );
    });
  };

  const refreshMap = () => {
    dispatchRef.map.reset();
    dispatch({ type: MAP_ACTIONS.LOAD_BLOCKS_WITHOUT_PINS_RESET });
  };

  const zoomIn = () => {
    map.current.zoomIn();
  };

  const zoomOut = () => {
    map.current.zoomOut();
  };

  const [state, dispatch] = useMap({
    showItemDetails,
    setShowItemDetails,
    dispatchRef,
    currentSite,
    loading,
    mapMode,
    mapStyle,
    presenters,
    analyticsService,
    lastActiveBlockIdWithValue,
    mapCamera,
    blocks,
    mapItems,
    gotoUserLocation,
  });

  useEffect(() => {
    if (navigator.geolocation) {
      getGeolocationPermissionStatus().then((response) => {
        setHasLocationPermission(response);
      });
    }
  }, []);

  useFocusEffect(
    useCallback(() => {
      return () => {
        const currentState = navigation.getState();
        const goToRoute = currentState.routeNames[currentState.index];

        if (goToRoute !== ROUTES.BLOCK_DETAILS && goToRoute !== ROUTES.SITE_SELECTOR) {
          if (showItemDetails) {
            setShowItemDetails(false);
          }
          dispatchRef.map.updateMap({ blocks: [], automations: [] });
        }
        setIsNDVILegendVisible(undefined);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchRef.blocks, navigation]),
  );

  useEffect(() => {
    if (map.current) {
      return;
    }
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: findMapStyleUrl(mapStyle),
      center: DEFAULT_CAMERA.centerCoordinate,
      zoom: DEFAULT_CAMERA.zoomLevel,
      logoPosition: 'top-left',
      attributionControl: false,
      crossSourceCollisions: false,
    });

    map.current.on('load', async () => {
      await loadImages(map.current);
    });
  });

  useEffect(() => {
    if (isFocused && currentAppState === 'active' && previousAppState.match(/inactive|background/)) {
      dispatch({ type: MAP_ACTIONS.LOAD_BLOCKS_WITHOUT_PINS_RESET });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAppState, previousAppState]);

  useEffect(() => {
    if (currentSite.id) {
      dispatch({ type: MAP_ACTIONS.CHANGE_MAP_MODE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapMode, currentSite]);

  useEffect(() => {
    if (currentSite.id) {
      dispatch({ type: MAP_ACTIONS.CHANGE_MAP_STYLE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapStyle, currentSite]);

  useEffect(() => {
    if (loading && !refreshing) {
      dispatch({ type: MAP_ACTIONS.RESET_PINS });
    } else {
      if (refreshing) {
        setRefreshing(false);
      }
      if (state.activeBlockId) {
        dispatch({ type: MAP_ACTIONS.MOVE_TO_ACTIVE_PIN });
      } else {
        dispatch({ type: MAP_ACTIONS.ZOOM_ON_PINS });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    dispatch({ type: MAP_ACTIONS.CHANGE_PINS });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.presentersForMapDisplayMode]);

  useEffect(() => {
    dispatch({ type: MAP_ACTIONS.CHANGE_PINS });
    if (state.activeBlockId) {
      dispatch({ type: MAP_ACTIONS.MOVE_TO_ACTIVE_PIN });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.activeBlockId]);

  useEffect(() => {
    dispatch({ type: MAP_ACTIONS.RECEIVE_BLOCKS });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocks, mapItems]);

  const onItemSnapped = (presenter) => {
    dispatch({ type: MAP_ACTIONS.SNAP_ITEM, presenter });
  };

  const openNDVILegend = () => {
    setIsNDVILegendVisible(true);
    analyticsService.trackEvent(ANALYTICS.NDVILegend);
  };

  return (
    <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
      <View style={globalStyles.header}>
        <HeaderBar siteName={currentSite.name} screenName={'map'} testId={'map__header'} navigation={navigation}>
          <TouchIconButton testId={'map__refresh-button'} onPress={refreshMap} icon={icnRefresh} />
        </HeaderBar>
      </View>

      <Divider />

      <View testID="map__subscreen-container" style={styles.page}>
        <View style={styles.mapContainer}>
          <View ref={mapContainer} style={styles.map} />
          <SymbolLayer
            map={map}
            blocks={state.featureCollection}
            items={state.markerCollection}
            activeBlockId={state.activeBlockId}
          />

          <MapDisplayModeChange
            mapStyle={mapStyle}
            displayMapMode={shouldDisplayMapMode(currentSite)}
            toggleMapMode={toggleMapMode}
            changeMapStyle={changeMapStyle}
            mapMode={mapMode}
          />

          <View style={styles.zoomButtons}>
            <RoundButton
              isSmall={true}
              icon={icnZoomIn}
              iconStyle={styles.zoomIcons}
              onPress={zoomIn}
              testID="map__zoom-in-button"
            />
            <RoundButton
              isSmall={true}
              icon={icnZoomOut}
              iconStyle={styles.zoomIcons}
              onPress={zoomOut}
              testID="map__zoom-out-button"
            />
          </View>

          <NDVILegendButton onPress={openNDVILegend} isVisible={mapStyle === MapStyleMode.NDVI} />
          <NDVILegendDetails
            enabled={mapStyle === MapStyleMode.NDVI}
            onClose={() => setIsNDVILegendVisible(false)}
            show={isNDVILegendVisible}
          />

          <NDVIDate date={ndviDate} isVisible={mapStyle === MapStyleMode.NDVI} />

          {hasLocationPermission ? <GoToUserLocationButton onPress={gotoUserLocation} /> : null}

          <ItemsDetailsContainer
            presenters={state.presentersForMapDisplayMode}
            carouselRef={carouselRef}
            onItemSnapped={onItemSnapped}
            show={showItemDetails}
            activeBlockId={state.activeBlockId}
            navigation={navigation}
          />

          <LoadingOverlay isLoading={loading} />
          <View style={styles.mapDrawerOverlay} />
        </View>
      </View>
    </SafeAreaView>
  );
};

MapScreen.propTypes = {
  navigation: navigationShape.isRequired,
};

const styles = StyleSheet.create({
  page: {
    flex: 1,
    alignItems: 'center',
  },
  map: {
    flex: 1,
  },
  mapContainer: {
    width: '100%',
    height: '100%',
  },
  mapDrawerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    width: '4%',
    height: SCREEN_HEIGHT,
  },
  zoomButtons: {
    position: 'absolute',
    right: 25,
    bottom: 25,
    opacity: 0.8,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: 118,
  },
  zoomIcons: {
    opacity: 0.6,
  },
});

export default MapScreen;
