/* istanbul ignore file */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';

const MapBoxBootstrapper = ({ accessToken }) => {
  useEffect(() => {
    mapboxgl.accessToken = accessToken;
  }, [accessToken]);

  return <></>;
};

MapBoxBootstrapper.propTypes = {
  accessToken: PropTypes.string.isRequired,
};

export { MapBoxBootstrapper };
