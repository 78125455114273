import PropTypes from 'prop-types';
import { Text, View, StyleSheet, Pressable, Image } from 'react-native';

//propsTypes
import { dataStreamShape } from '../../shapes/dataStreams';

//hook
import { useTranslation } from '../../hooks/useTranslation';

//services
import { isIos } from '../../services/PlatformService';
import { DataStreamButtonPresenter } from '../../presenters/DataStreamButtonPresenter';

//constants
import FONTS from '../../fonts';
import COLORS from '../../colors';
import OPACITIES from '../../opacities';

const DataStreamButton = ({ isSelected = false, onPress, dataStream, iconColor = COLORS.greyishBrown }) => {
  const { t, i18n } = useTranslation();
  const presenter = new DataStreamButtonPresenter(dataStream, t);
  const backgroundColor = { backgroundColor: isSelected ? COLORS.blackSqueeze : COLORS.white };
  const shadow = isSelected
    ? {
        shadowColor: COLORS.greyShadow,
        shadowOffset: { width: 2, height: 3 },
        shadowRadius: 2,
        shadowOpacity: OPACITIES.dataStreamButtonShadow,
        elevation: 6,
      }
    : {};

  const iconColorStyle = { tintColor: iconColor };
  const unknownIconStyle = { color: iconColor };
  const icon = presenter.icon();

  const press = () => onPress(dataStream.id, dataStream.unit);

  return (
    <View>
      <Pressable
        style={[styles.buttonContainer, backgroundColor, shadow]}
        onPress={press}
        testID={`graph__data-stream-button-${dataStream.id}`}>
        <View style={styles.topRow}>
          {icon ? (
            <View style={styles.iconContainer}>
              <Image source={presenter.icon()} style={iconColorStyle} />
            </View>
          ) : (
            <Text style={[styles.unknownIcon, unknownIconStyle]}>{presenter.getFirstLetterOfDataStreamType()}</Text>
          )}
          <View style={styles.valueSection}>
            <Text style={styles.value}>{presenter.getValue(i18n)}</Text>
            <Text style={styles.unit}>{presenter.getUnit()}</Text>
          </View>
        </View>
        <View style={styles.bottomRow}>
          <Text ellipsizeMode="middle" numberOfLines={1} style={styles.dataStreamType}>
            {presenter.getDescription()}
          </Text>
        </View>
      </Pressable>
    </View>
  );
};

DataStreamButton.propTypes = {
  isSelected: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
  dataStream: dataStreamShape.isRequired,
  iconColor: PropTypes.string,
};

const styles = StyleSheet.create({
  topRow: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  bottomRow: {
    flex: 1,
    width: '100%',
  },
  buttonContainer: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 62,
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderColor: COLORS.greyBorderColor2,
    borderWidth: 2,
    borderRadius: 1,
  },
  value: {
    alignSelf: 'center',
    textAlign: 'right',
    fontFamily: FONTS.notoSans,
    fontSize: 14,
    color: COLORS.greyishBrown,
  },
  unit: {
    alignSelf: 'center',
    marginLeft: 3,
    fontSize: isIos() ? 10 : 9,
    color: COLORS.greyishBrown,
  },
  valueSection: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  dataStreamType: {
    textAlign: 'center',
    fontFamily: FONTS.notoSans,
    fontSize: 10,
    color: COLORS.greyishBrown,
  },
  unknownIcon: {
    fontFamily: FONTS.notoSansBold,
    fontSize: 20,
  },
  iconContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    width: 30,
    height: 30,
  },
});

export default DataStreamButton;
