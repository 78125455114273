import { navigationShape } from '../../shapes/navigation';

// Screen
import * as LocalizedImages from '../../i18n/LocalizedImages';
import PreviewScreen from './PreviewScreen';

const FlowStationPreviewScreen = ({ navigation }) => {
  return (
    <PreviewScreen
      navigation={navigation}
      screenName={'flowmeter_preview_screen'}
      testId={'flowmeter__preview__screen__'}
      localizedImages={LocalizedImages.FLOWMETERS_PREVIEW}
    />
  );
};

FlowStationPreviewScreen.propTypes = {
  navigation: navigationShape.isRequired,
};

export default FlowStationPreviewScreen;
