import { StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';

// hooks
import { useTranslation } from '../../../hooks/useTranslation';

// constants
import FONTS from '../../../fonts';
import COLORS from '../../../colors';

const AutomationRuleThresholds = ({ thresholdUp, thresholdDown, unit }) => {
  const { t } = useTranslation();
  const translatedUnit = t(`unit_${unit.toLowerCase()}`);

  const renderThreshold = (value) => {
    return (
      <View style={styles.textContainer}>
        <Text style={styles.font} ellipsizeMode={'tail'} numberOfLines={1}>
          {value}
        </Text>
        <Text style={styles.font} ellipsizeMode={'clip'} numberOfLines={1}>
          {translatedUnit}
        </Text>
      </View>
    );
  };

  return (
    <View style={styles.columnThreshold}>
      {renderThreshold(thresholdUp)}

      {renderThreshold(thresholdDown)}
    </View>
  );
};

AutomationRuleThresholds.propTypes = {
  thresholdUp: PropTypes.number.isRequired,
  thresholdDown: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  columnThreshold: {
    justifyContent: 'space-evenly',
    alignItems: 'flex-end',
    height: '100%',
    flexShrink: 1,
  },
  font: {
    fontFamily: FONTS.firaSans,
    fontSize: 11,
    lineHeight: 15,
    letterSpacing: 0.52,
    color: COLORS.greyishBrown,
    flexShrink: 1,
  },
  textContainer: {
    flexDirection: 'row',
  },
});

export default AutomationRuleThresholds;
