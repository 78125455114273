import { useEffect, useState } from 'react';
import { View, StyleSheet, StatusBar } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ROUTES from '../../navigation/routes';

// components
import HeaderBar from '../../components/header/HeaderBar';
import Divider from '../../components/Divider';

// services
import { isDebug } from '../../services/EnvironmentService';
import { useTranslation } from '../../hooks/useTranslation';
import { isIos } from '../../services/PlatformService';

// styles
import { globalStyles } from '../../styles';

// constants
import COLORS from '../../colors';
import { navigationShape } from '../../shapes/navigation';
import Alert from './Alert';
import { AlertTypes } from './AlertConstants';

const MainScreen = ({ navigation, route }) => {
  const currentSite = useSelector((state) => state.site.currentSite);
  const apolloError = useSelector((state) => state.error.apolloError);
  const refreshTokenError = useSelector((state) => state.error.refreshTokenError);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const genericErrorMessage = t('generic_error_server_error_message');
  const [errorMessage, setErrorMessage] = useState(genericErrorMessage);
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);

  useEffect(() => {
    if (apolloError) {
      setErrorModalVisible(true);
      if (isDebug() && apolloError.message) {
        setErrorMessage(apolloError.message);
      } else {
        setErrorMessage(genericErrorMessage);
      }
    }
    // We don't want to display the alert when the translation (t) changed, causing a double alert box
  }, [apolloError, genericErrorMessage]);

  useEffect(() => {
    if (refreshTokenError) {
      setErrorModalVisible(true);
      if (isDebug() && refreshTokenError.message) {
        setErrorMessage(refreshTokenError.message);
      } else {
        setErrorMessage(genericErrorMessage);
      }
    }
    // We don't want to display the alert when the translation (t) changed, causing a double alert box
  }, [refreshTokenError, genericErrorMessage]);

  const onClose = async () => {
    dispatch.error.clearRefreshTokenError();
    dispatch.error.clearApolloError();
    if (refreshTokenError) {
      await dispatch.authentication.logOut();
      navigation.navigate(ROUTES.AUTH);
    }
    if (apolloError) {
      setErrorModalVisible(!isErrorModalVisible);
    }
  };

  return (
    <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']} testID="main__banner">
      <StatusBar backgroundColor={COLORS.greyishBrown} barStyle={isIos() ? 'dark-content' : 'default'} translucent={false} />

      <View style={globalStyles.header}>
        <HeaderBar
          siteName={currentSite.name}
          screenName={route.params?.subScreenName}
          style={globalStyles.title}
          testId={'main__header'}
          navigation={navigation}
        />
      </View>

      <Divider />

      <View style={styles.subScreen}>{route.params?.subScreen({ navigation, route })}</View>

      <Alert
        onPress={onClose}
        onCancel={onClose}
        show={isErrorModalVisible}
        title={t('generic_error_server_error_title')}
        message={errorMessage}
        alertType={AlertTypes.ERROR}
        testID={'server_error-modal'}
      />
    </SafeAreaView>
  );
};

MainScreen.propTypes = {
  navigation: navigationShape,
  route: PropTypes.shape({
    params: PropTypes.shape({
      subScreenName: PropTypes.string.isRequired,
      subScreen: PropTypes.func.isRequired,
    }),
  }),
};

const styles = StyleSheet.create({
  subScreen: {
    flexGrow: 1,
  },
});

export default MainScreen;
