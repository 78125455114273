import PropTypes from 'prop-types';
import { Image, StyleSheet, Pressable } from 'react-native';

// constants
import COLORS from '../../colors';

// icons
import icnBtnData from '../../assets/icons/icnBtnData.png';

const LegendButton = ({ onPress }) => {
  return (
    <Pressable style={styles.imageContainer} onPress={onPress} testID="block-detail-modal-button">
      <Image source={icnBtnData} style={styles.icnBtnData} />
    </Pressable>
  );
};

LegendButton.propTypes = {
  onPress: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  imageContainer: {
    paddingHorizontal: 11,
    paddingVertical: 11,
    resizeMode: 'contain',
    tintColor: COLORS.white,
  },
  icnBtnData: {
    width: 21,
    height: 21,
  },
});

export default LegendButton;
