import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';

// icons
import icnNavLeft from '../../assets/icons/icnNavLeft.png';
import icnNavRight from '../../assets/icons/icnNavRight.png';
import icnAddEvent from '../../assets/icons/icnAddEvent.png';

// constants
import { navigationShape } from '../../shapes/navigation';
import { getScheduleEndDate, getScheduleStartDate } from '../../screens/schedule/ScheduleConstants';

// services
import { isIos } from '../../services/PlatformService';
import { formatWeekLabel } from '../../utils/dateUtils';

// styles
import { globalStyles, SIZES } from '../../styles';

// components
import TouchIconButton from '../button/TouchIconButton';
import TodayButton from '../button/TodayButton';
import Title from './Title';
import BurgerMenuButton from './BurgerMenuButton';

const ScheduleHeaderBar = ({
  dateRange,
  siteName,
  screenName,
  testId,
  navigation,
  onLeftArrowClick,
  onRightArrowClick,
  onTodayButtonPress,
  onAddEventPress,
  loading = false,
}) => {
  const disableLeftArrow = dateRange.from && getScheduleStartDate().isSameOrAfter(dateRange.from);
  const disableRightArrow = dateRange.to && getScheduleEndDate().isSameOrBefore(dateRange.to);
  const endDate = dateRange.to.clone().subtract(1, 'day');
  const headerLabel = formatWeekLabel(dateRange.from, endDate);

  return (
    <View style={globalStyles.headerContainer}>
      <View style={styles.nav}>
        <BurgerMenuButton screenName={screenName} navigation={navigation} />
      </View>

      <View style={styles.main}>
        <TouchIconButton
          disabled={disableLeftArrow || loading}
          testId={`${screenName}__left-arrow`}
          onPress={onLeftArrowClick}
          icon={icnNavLeft}
          iconStyle={globalStyles.icnNav}
        />

        <View style={globalStyles.headerTitle}>
          <Title value={siteName} type="title" testId={testId} />
          <Title value={headerLabel} type="subtitle" />
        </View>

        <TouchIconButton
          disabled={disableRightArrow || loading}
          testId={`${screenName}__right-arrow`}
          onPress={onRightArrowClick}
          icon={icnNavRight}
          iconStyle={globalStyles.icnNav}
        />
      </View>

      <View style={styles.actions}>
        <TodayButton testId={`${screenName}__today-button`} onPress={onTodayButtonPress} disabled={loading} />

        {isIos() ? <TouchIconButton testId={'schedule__add-event-button'} onPress={onAddEventPress} icon={icnAddEvent} /> : null}
      </View>
    </View>
  );
};

ScheduleHeaderBar.propTypes = {
  dateRange: PropTypes.object.isRequired,
  siteName: PropTypes.string.isRequired,
  screenName: PropTypes.string.isRequired,
  testId: PropTypes.string,
  navigation: navigationShape.isRequired,
  onLeftArrowClick: PropTypes.func.isRequired,
  onRightArrowClick: PropTypes.func.isRequired,
  onTodayButtonPress: PropTypes.func.isRequired,
  onAddEventPress: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const styles = StyleSheet.create({
  nav: {
    paddingRight: isIos() ? SIZES.ICON : 0,
  },
  main: {
    flex: 1,
    flexDirection: 'row',
  },
  actions: {
    flexDirection: 'row',
  },
});

export default ScheduleHeaderBar;
