import { StyleSheet, Text, View, Image } from 'react-native';

//proptype
import { SensorShape } from '../../shapes/sensor';

//hooks
import { useTranslation } from '../../hooks/useTranslation';

//presenter
import { MapTemperatureSensorPresenter } from '../../presenters/MapTemperatureSensorPresenter';

//constants
import FONTS from '../../fonts';
import COLORS from '../../colors';

const TemperatureLegendElement = ({ readings }) => {
  const temperatureSensorPresenter = new MapTemperatureSensorPresenter(readings);
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <Image source={temperatureSensorPresenter.icon().icon} style={{ tintColor: readings.color }} />
        <Text style={styles.temperatureValue}>{temperatureSensorPresenter.temperatureValue(t)}</Text>
      </View>
    </View>
  );
};

TemperatureLegendElement.propTypes = {
  readings: SensorShape.isRequired,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '33.33%',
  },
  contentContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 55,
    height: 28,
  },
  temperatureValue: {
    fontFamily: FONTS.notoSansBold,
    fontSize: 11,
    color: COLORS.greyishBrown,
  },
});

export default TemperatureLegendElement;
