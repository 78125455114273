import { LogBox } from 'react-native';

// Ignore log notification by message, should be a temporary fix only
/* istanbul ignore next */
export const ignoreLogs = () => {
  LogBox.ignoreLogs([
    // https://reactnavigation.org/docs/troubleshooting/#i-get-the-warning-non-serializable-values-were-found-in-the-navigation-state
    'Non-serializable values were found in the navigation state',
  ]);
};
