import { Alert } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

// services
import { navigationShape } from '../../shapes/navigation';
import ROUTES from '../../navigation/routes';
import { useTranslation } from '../../hooks/useTranslation';

// screens
import ForgotForm from './ForgotForm';

const ForgotPasswordScreen = ({ navigation }) => {
  const loading = useSelector((state) => !!state.loading.effects.authentication.sendForgotPasswordRequest);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = async ({ usernameOrEmail }) => {
    await dispatch.authentication.sendForgotPasswordRequest({ username: usernameOrEmail, lang: i18n.language });
    Alert.alert(t('forgot_password_success_title'), t('forgot_password_success_message'), [
      { text: t('generic_button_close'), onPress: () => navigation.navigate(ROUTES.SIGN_IN) },
    ]);
  };

  return (
    <ForgotForm
      onSubmit={(usernameOrEmail) => onSubmit({ usernameOrEmail })}
      loading={loading}
      placeholder={t('forgot_password_username_placeholder')}
      headerText={t('forgot_password_title')}
      subHeaderText={t('forgot_password_subtitle')}
      navigation={navigation}
    />
  );
};

ForgotPasswordScreen.propTypes = {
  navigation: navigationShape.isRequired,
};

export default ForgotPasswordScreen;
