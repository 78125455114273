import icnPinHighActive from '../icnPinHighActive.png';
import icnPinHighOff from '../icnPinHighOff.png';
import icnPinIrrigatingActive from '../icnPinIrrigatingActive.png';
import icnPinIrrigatingOff from '../icnPinIrrigatingOff.png';
import icnPinLowActive from '../icnPinLowActive.png';
import icnPinLowOff from '../icnPinLowOff.png';
import icnPinMedActive from '../icnPinMedActive.png';
import icnPinMedOff from '../icnPinMedOff.png';
import icnPinNDVI from '../icnPinNDVI.png';
import icnPinNDVIActive from '../icnPinNDVIActive.png';
import icnPinOutoffieldActive from '../icnPinOutoffieldActive.png';
import icnPinOutoffieldOff from '../icnPinOutoffieldOff.png';
import icnPinProblemsActive from '../icnPinProblemsActive.png';
import icnPinProblemsOff from '../icnPinProblemsOff.png';
import icnPinUnknActive from '../icnPinUnknActive.png';
import icnPinUnknOff from '../icnPinUnknOff.png';
import icnPinWetActive from '../icnPinWetActive.png';
import icnPumpOutOfFieldOff from '../icnPumpOutOfFieldOff.png';
import icnPumpOutOfFieldOn from '../icnPumpOutOfFieldOn.png';
import icnPumpErrorOff from '../icnPumpErrorOff.png';
import icnPumpErrorOn from '../icnPumpErrorOn.png';
import icnPumpErrorIrrigatingOff from '../icnPumpErrorIrrigatingOff.png';
import icnPumpErrorIrrigatingOn from '../icnPumpErrorIrrigatingOn.png';
import icnPumpGoodOff from '../icnPumpGoodOff.png';
import icnPumpGoodOn from '../icnPumpGoodOn.png';
import icnPumpIrrigatingOff from '../icnPumpIrrigatingOff.png';
import icnPumpIrrigatingOn from '../icnPumpIrrigatingOn.png';
import icnPumpSyncOff from '../icnPumpSyncOff.png';
import icnPumpSyncOn from '../icnPumpSyncOn.png';
import icnPumpOff from '../icnPumpOff.png';
import icnPumpOn from '../icnPumpOn.png';
import icnFlowActive from '../icnFlowActive.png';
import icnFlowIrrigatingActive from '../icnFlowIrrigatingActive.png';
import pumpCommProblemActive from '../icnPumpCommActive.png';
import icnTempHighActive from '../icnTempHighActive.png';
import icnTempMedActive from '../icnTempMedActive.png';
import icnTempLowActive from '../icnTempLowActive.png';
import icnTempUnknActive from '../icnTempUnknActive.png';
import icnTempCommActive from '../icnTempCommActive.png';
import icnWeatherActive from '../icnWeatherActive.png';
import icnWeatherProblemActive from '../icnWeatherProblemActive.png';
import icnPinWetOff from '../icnPinWetOff.png';
import icnFlowOff from '../icnFlowOff.png';
import icnFlowIrrigatingOff from '../icnFlowIrrigatingOff.png';
import pumpCommProblemOff from '../icnPumpCommOff.png';
import icnTempHighOff from '../icnTempHighOff.png';
import icnTempMedOff from '../icnTempMedOff.png';
import icnTempLowOff from '../icnTempLowOff.png';
import icnTempUnknOff from '../icnTempUnknOff.png';
import icnTempCommOff from '../icnTempCommOff.png';
import icnValveOutOfFieldOff from '../icnValveOutOfFieldOff.png';
import icnValveOutOfFieldOn from '../icnValveOutOfFieldOn.png';
import icnValveErrorOff from '../icnValveErrorOff.png';
import icnValveErrorOn from '../icnValveErrorOn.png';
import icnValveErrorIrrigatingOff from '../icnValveErrorIrrigatingOff.png';
import icnValveErrorIrrigatingOn from '../icnValveErrorIrrigatingOn.png';
import icnValveGoodOff from '../icnValveGoodOff.png';
import icnValveGoodOn from '../icnValveGoodOn.png';
import icnValveIrrigatingOff from '../icnValveIrrigatingOff.png';
import icnValveIrrigatingOn from '../icnValveIrrigatingOn.png';
import icnValveSyncOff from '../icnValveSyncOff.png';
import icnValveSyncOn from '../icnValveSyncOn.png';
import icnValveOff from '../icnValveOff.png';
import icnValveOn from '../icnValveOn.png';
import icnWaterLevelOn from '../icnWaterLevelOn.png';
import icnWaterLevelOff from '../icnWaterLevelOff.png';
import icnWeatherOff from '../icnWeatherOff.png';
import icnWeatherProblemOff from '../icnWeatherProblemOff.png';

export const PIN_ICONS = {
  high: {
    normal: {
      name: 'iconPinHighOff',
      icon: icnPinHighOff,
    },
    active: {
      name: 'icnPinHighActive',
      icon: icnPinHighActive,
    },
  },
  irrigating: {
    normal: {
      name: 'iconPinIrrigatingOff',
      icon: icnPinIrrigatingOff,
    },
    active: {
      name: 'icnPinIrrigatingActive',
      icon: icnPinIrrigatingActive,
    },
  },
  low: {
    normal: {
      name: 'iconPinLowOff',
      icon: icnPinLowOff,
    },
    active: {
      name: 'iconPinLowActive',
      icon: icnPinLowActive,
    },
  },
  med: {
    normal: {
      name: 'iconPinMedOff',
      icon: icnPinMedOff,
    },
    active: {
      name: 'iconPinMedActive',
      icon: icnPinMedActive,
    },
  },
  ndvi: {
    normal: {
      name: 'iconPinNDVI',
      icon: icnPinNDVI,
    },
    active: {
      name: 'iconPinNDVIActive',
      icon: icnPinNDVIActive,
    },
  },
  outOfField: {
    normal: {
      name: 'iconPinOutOfFieldOff',
      icon: icnPinOutoffieldOff,
    },
    active: {
      name: 'iconPinOutOfFieldActive',
      icon: icnPinOutoffieldActive,
    },
  },
  problems: {
    normal: {
      name: 'iconPinProblemsOff',
      icon: icnPinProblemsOff,
    },
    active: {
      name: 'iconPinProblemsActive',
      icon: icnPinProblemsActive,
    },
  },
  unknown: {
    normal: {
      name: 'iconPinUnknownOff',
      icon: icnPinUnknOff,
    },
    active: {
      name: 'iconPinUnknownActive',
      icon: icnPinUnknActive,
    },
  },
  wet: {
    normal: {
      name: 'iconPinWetOff',
      icon: icnPinWetOff,
    },
    active: {
      name: 'iconPinWetActive',
      icon: icnPinWetActive,
    },
  },
  flow: {
    normal: {
      name: 'iconFlowOff',
      icon: icnFlowOff,
    },
    active: {
      name: 'iconFlowActive',
      icon: icnFlowActive,
    },
  },
  flowIrrigating: {
    normal: {
      name: 'iconFlowIrrigatingOff',
      icon: icnFlowIrrigatingOff,
    },
    active: {
      name: 'iconFlowIrrigatingActive',
      icon: icnFlowIrrigatingActive,
    },
  },
  pumpDisabled: {
    normal: {
      name: 'icnPumpOutOfFieldOff',
      icon: icnPumpOutOfFieldOff,
    },
    active: {
      name: 'icnPumpOutOfFieldOn',
      icon: icnPumpOutOfFieldOn,
    },
  },
  pumpError: {
    normal: {
      name: 'icnPumpErrorOff',
      icon: icnPumpErrorOff,
    },
    active: {
      name: 'icnPumpErrorOn',
      icon: icnPumpErrorOn,
    },
  },
  pumpErrorIrrigating: {
    normal: {
      name: 'icnPumpErrorIrrigatingOff',
      icon: icnPumpErrorIrrigatingOff,
    },
    active: {
      name: 'icnPumpErrorIrrigatingOn',
      icon: icnPumpErrorIrrigatingOn,
    },
  },
  pumpGood: {
    normal: {
      name: 'icnPumpGoodOff',
      icon: icnPumpGoodOff,
    },
    active: {
      name: 'icnPumpGoodOn',
      icon: icnPumpGoodOn,
    },
  },
  pumpIrrigating: {
    normal: {
      name: 'icnPumpIrrigatingOff',
      icon: icnPumpIrrigatingOff,
    },
    active: {
      name: 'icnPumpIrrigatingOn',
      icon: icnPumpIrrigatingOn,
    },
  },
  pumpSync: {
    normal: {
      name: 'icnPumpSyncOff',
      icon: icnPumpSyncOff,
    },
    active: {
      name: 'icnPumpSyncOn',
      icon: icnPumpSyncOn,
    },
  },
  pumpUnknown: {
    normal: {
      name: 'icnPumpOff',
      icon: icnPumpOff,
    },
    active: {
      name: 'icnPumpOn',
      icon: icnPumpOn,
    },
  },
  valveDisabled: {
    normal: {
      name: 'icnValveOutOfFieldOff',
      icon: icnValveOutOfFieldOff,
    },
    active: {
      name: 'icnValveOutOfFieldOn',
      icon: icnValveOutOfFieldOn,
    },
  },
  valveError: {
    normal: {
      name: 'icnValveErrorOff',
      icon: icnValveErrorOff,
    },
    active: {
      name: 'icnValveErrorOn',
      icon: icnValveErrorOn,
    },
  },
  valveErrorIrrigating: {
    normal: {
      name: 'icnValveErrorIrrigatingOff',
      icon: icnValveErrorIrrigatingOff,
    },
    active: {
      name: 'icnValveErrorIrrigatingOn',
      icon: icnValveErrorIrrigatingOn,
    },
  },
  valveGood: {
    normal: {
      name: 'icnValveGoodOff',
      icon: icnValveGoodOff,
    },
    active: {
      name: 'icnValveGoodOn',
      icon: icnValveGoodOn,
    },
  },
  valveIrrigating: {
    normal: {
      name: 'icnValveIrrigatingOff',
      icon: icnValveIrrigatingOff,
    },
    active: {
      name: 'icnValveIrrigatingOn',
      icon: icnValveIrrigatingOn,
    },
  },
  valveSync: {
    normal: {
      name: 'icnValveSyncOff',
      icon: icnValveSyncOff,
    },
    active: {
      name: 'icnValveSyncOn',
      icon: icnValveSyncOn,
    },
  },
  valveUnknown: {
    normal: {
      name: 'icnValveOff',
      icon: icnValveOff,
    },
    active: {
      name: 'icnValveOn',
      icon: icnValveOn,
    },
  },
  pumpCommunicationProblem: {
    normal: {
      name: 'iconPumpCommunicationProblemOff',
      icon: pumpCommProblemOff,
    },
    active: {
      name: 'iconPumpCommunicationProblemActive',
      icon: pumpCommProblemActive,
    },
  },
  tempHigh: {
    normal: {
      name: 'iconTempHighOff',
      icon: icnTempHighOff,
    },
    active: {
      name: 'iconTempHighActive',
      icon: icnTempHighActive,
    },
  },
  tempMed: {
    normal: {
      name: 'iconTempMedOff',
      icon: icnTempMedOff,
    },
    active: {
      name: 'iconTempMedActive',
      icon: icnTempMedActive,
    },
  },
  tempLow: {
    normal: {
      name: 'iconTempLowOff',
      icon: icnTempLowOff,
    },
    active: {
      name: 'iconTempLowActive',
      icon: icnTempLowActive,
    },
  },
  tempUnknown: {
    normal: {
      name: 'iconTempUnknownOff',
      icon: icnTempUnknOff,
    },
    active: {
      name: 'iconTempUnknownActive',
      icon: icnTempUnknActive,
    },
  },
  tempCommunicationProblem: {
    normal: {
      name: 'iconTempCommunicationProblemOff',
      icon: icnTempCommOff,
    },
    active: {
      name: 'iconTempCommunicationProblemActive',
      icon: icnTempCommActive,
    },
  },
  waterLevel: {
    normal: {
      name: 'iconWaterLevelOff',
      icon: icnWaterLevelOff,
    },
    active: {
      name: 'iconWaterLevelOn',
      icon: icnWaterLevelOn,
    },
  },
  weather: {
    normal: {
      name: 'iconWeatherOff',
      icon: icnWeatherOff,
    },
    active: {
      name: 'iconWeatherActive',
      icon: icnWeatherActive,
    },
  },
  weatherCommunicationProblem: {
    normal: {
      name: 'iconWeatherCommunicationProblemOff',
      icon: icnWeatherProblemOff,
    },
    active: {
      name: 'iconWeatherCommunicationProblemActive',
      icon: icnWeatherProblemActive,
    },
  },
};

const pinIcons = {};
for (const [, value] of Object.entries(PIN_ICONS)) {
  pinIcons[value.normal.name] = value.normal.icon;
  pinIcons[value.active.name] = value.active.icon;
}

export const PIN_ICONS_MAP = pinIcons;
