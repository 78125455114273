import PropTypes from 'prop-types';
import { Image, StyleSheet, Text, Pressable, View } from 'react-native';

// icons
import icnNavRight from '../../assets/icons/icnNavRight.png';
import icnSchedule from '../../assets/icons/icnSchedule.png';

// services
import { navigationShape } from '../../shapes/navigation';
import COLORS from '../../colors';
import FONTS from '../../fonts';
import { useTranslation } from '../../hooks/useTranslation';
import ANALYTICS from '../../services/AnalyticsEvents';
import ROUTES from '../../navigation/routes';
import { DashboardSchedulePresenter } from '../../presenters/DashboardSchedulePresenter';

// components
import { useAnalyticsContext } from '../initialization/AnalyticsProvider';
import EmptySectionView from '../EmptySectionView';
import SkeletonPlaceholder from '../skeletons/SkeletonPlaceholder';
import ScheduleActivityCounter from './ScheduleActivityCounter';

const circleSize = 42;

const ScheduleSummary = ({ data, loading, navigation, testId }) => {
  const { t } = useTranslation();
  const analyticsService = useAnalyticsContext();
  const presenter = new DashboardSchedulePresenter(data, t);

  const goToSchedule = () => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewSchedule);
    navigation.navigate(ROUTES.SCHEDULE, { routeToGoBackTo: ROUTES.DASHBOARD });
  };

  const renderActivities = () => {
    if (loading) {
      const childs = [...Array(3)];
      return (
        <View testID="loadingActivity">
          {childs.map((e, i) => (
            <View style={i === childs.length - 1 ? styles.skeletonLastRow : styles.skeletonRow} key={'row' + i}>
              <SkeletonPlaceholder width={circleSize} height={circleSize} style={styles.skeletonCircle} />
              <SkeletonPlaceholder width={200} height={11} style={styles.skeletonText} />
            </View>
          ))}
        </View>
      );
    } else if (presenter.shouldDisplayEmptyScheduleMessage()) {
      return <EmptySectionView icon={icnSchedule} iconSizeStyle={styles.scheduleIconSize} textKey="dashboard_schedule_empty" />;
    } else {
      return (
        <>
          <ScheduleActivityCounter
            style={styles.spaceAfter}
            count={presenter.ongoingCount()}
            countColor={COLORS.azure}
            text={presenter.ongoingMessage()}
          />
          <ScheduleActivityCounter
            style={styles.spaceAfter}
            count={presenter.lateCount()}
            countColor={COLORS.fadedOrange}
            text={presenter.lateMessage()}
          />
          <ScheduleActivityCounter
            count={presenter.plannedCount()}
            countColor={COLORS.greyishBrown}
            text={presenter.plannedMessage()}
          />
        </>
      );
    }
  };

  return (
    <View testID={testId}>
      <View style={[styles.header, styles.centerRow]}>
        <Text style={styles.title}>{t('dashboard_schedule')}</Text>

        <Pressable style={styles.centerRow} onPress={goToSchedule} testID={'full-schedule-button'}>
          <Text style={styles.details}>{t('see_full_schedule')}</Text>
          <Image source={icnNavRight} style={styles.arrowSpacing} />
        </Pressable>
      </View>
      <View style={styles.content} testID="dashboard-schedule-content">
        {renderActivities()}
      </View>
    </View>
  );
};

ScheduleSummary.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  navigation: navigationShape.isRequired,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  arrowSpacing: {
    marginLeft: 10,
  },
  centerRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    marginHorizontal: 13,
    marginVertical: 13,
  },
  details: {
    fontFamily: FONTS.firaSansLight,
    fontSize: 14,
    color: COLORS.greyishBrown,
  },
  header: {
    justifyContent: 'space-between',
    height: 'auto',
    paddingRight: 17,
    paddingLeft: 15,
    marginTop: 10,
  },
  scheduleIconSize: {
    width: 26,
    height: 26,
  },
  spaceAfter: {
    marginBottom: 10,
  },
  skeletonCircle: {
    borderRadius: circleSize / 2,
  },
  title: {
    fontFamily: FONTS.firaSans,
    fontSize: 16,
    color: COLORS.greyishBrown,
  },
  skeletonLastRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  skeletonRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  skeletonText: {
    marginLeft: 11,
  },
});

export default ScheduleSummary;
