import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';

// proptype
import { navigationShape } from '../../shapes/navigation';

// entity
import BlockEntity from '../../models/entities/blockEntity';

// constants
import COLORS from '../../colors';
import OPACITIES from '../../opacities';

// components
import BlockDetailStatus from './BlockDetailStatus';
import BlockDetailIrrigation from './irrigation/BlockDetailIrrigation';
import BlockDetailBlueBandIndex from './BlockDetailBlueBandIndex';
import LocalDataList from './LocalDataList';

const BlockDetails = ({ block, navigation }) => {
  return (
    <View style={styles.container} testID="block-details__details-container">
      <BlockDetailStatus block={block} containerStyle={styles.componentStyle} />
      <BlockDetailBlueBandIndex block={block} containerStyle={styles.componentStyle} navigation={navigation} />
      <BlockDetailIrrigation block={block} containerStyle={styles.componentStyle} navigation={navigation} />
      <LocalDataList containerStyle={styles.componentStyle} />
    </View>
  );
};

BlockDetails.propTypes = {
  block: PropTypes.instanceOf(BlockEntity).isRequired,
  navigation: navigationShape.isRequired,
};

const styles = StyleSheet.create({
  componentStyle: {
    borderRadius: 4,
    marginHorizontal: 11,
    marginTop: 11,
    backgroundColor: COLORS.white,
    elevation: 2,
    shadowColor: COLORS.greyShadow,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: OPACITIES.dataStreamButtonShadow,
    shadowRadius: 2,
  },
  container: {
    height: '100%',
    paddingBottom: 11,
    backgroundColor: COLORS.greyish20,
  },
});

export default BlockDetails;
