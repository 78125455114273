import { MapStyleMode } from '../presenters/MapDisplayMode';
import { PIN_ICONS } from '../assets/icons/mapPins/pinIcons';
import * as PumpStatus from './status/pumpStatus';
import * as ValveStatus from './status/valveStatus';
import * as WaterLevelStatus from './status/waterLevelStatus';

const PUMP = 'PUMP';
const VALVE = 'VALVE';
const WATER_LEVEL = 'WATER_LEVEL';

export { PUMP, VALVE, WATER_LEVEL };

const mapIconByItemType = {
  PUMP: PumpStatus,
  VALVE: ValveStatus,
  WATER_LEVEL: WaterLevelStatus,
};

const mapItemStyleTypes = {
  NDVI: { types: [WATER_LEVEL] },
};

const mapItemModeTypes = {
  TENSION: { types: [PUMP, VALVE, WATER_LEVEL] },
  TEMPERATURE: { types: [PUMP, VALVE] },
};

export const iconForMapItemType = (type, status, style) => {
  if (style === MapStyleMode.NDVI) {
    return PIN_ICONS.ndvi;
  }

  const iconStatus = mapIconByItemType[type];
  if (!iconStatus) {
    return;
  }
  return iconStatus.iconForMapStatus(status);
};

export const isAutomationIcon = (icon) => {
  return PumpStatus.isPumpIcon(icon) || ValveStatus.isValveIcon(icon);
};

export const mapItemTypesForLayer = (style, mode) => {
  const styleTypes = mapItemStyleTypes[style];
  if (styleTypes) {
    return styleTypes.types;
  }

  const modeTypes = mapItemModeTypes[mode];
  if (!modeTypes) {
    return [];
  }
  return modeTypes.types;
};
