import { findBestLanguageTag } from 'react-native-localize';
import * as reactNativeLocalizationSettings from 'react-native-localization-settings';
import { changeLanguage } from 'i18next';

const fallbackLanguage = 'en';
const validLanguages = ['en', 'fr', 'es'];
const languageInfo = [
  { language: 'en', translationKey: 'language_english' },
  { language: 'es', translationKey: 'language_spanish' },
  { language: 'fr', translationKey: 'language_french' },
];

const getLanguage = () => {
  const bestLanguage = findBestLanguageTag(validLanguages);

  if (!bestLanguage) {
    return fallbackLanguage;
  }

  return bestLanguage.languageTag;
};

const setLanguage = (language) => {
  if (!validLanguages.includes(language)) {
    return;
  }

  changeLanguage(language);
  reactNativeLocalizationSettings.setLanguage(language);
};

export { getLanguage, fallbackLanguage, languageInfo, setLanguage, validLanguages };
