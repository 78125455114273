import alertIcon from '../../assets/icons/icnMenuNotif.png';
import icnCriticalityHigh from '../../assets/icons/icnCriticalityHigh.png';
import icnCriticalityLow from '../../assets/icons/icnCriticalityLow.png';

export default class AlertEntity {
  constructor(alert) {
    if (alert) {
      this.id = alert.id;
      this.name = alert.name;
      this.criticality = alert.criticality;
      this.blockCount = alert.blockCount;
      this.responderCount = alert.responderCount;
      this.status = alert.status;
    }
  }

  statusIcon() {
    const criticalityMap = {
      LOW: icnCriticalityLow,
      HIGH: icnCriticalityHigh,
      default: alertIcon,
    };

    return criticalityMap[this.criticality] || criticalityMap.default;
  }

  statusText () {
    const statusMap = {
      'enabled': 'alert_enabled',
      'disabled': 'alert_disabled',
      'inAlarm': 'alert_in-alarm',
      'acknowledged': 'alert_acknowledged',
    };

    return statusMap[this.status] || '';
  }

  inAlarm() {
    return this.status === 'inAlarm';
  }
}
