import { gql } from '@apollo/client';

export const UPDATE_AUTOMATION_LOW_RULE = gql`
  mutation UpdateAutomationLowRule($automation: AutomationRuleOnLowValuePartial!, $siteId: ID!) {
    partialUpdateOnLowRule(automation: $automation, siteId: $siteId)
  }
`;

export const UPDATE_AUTOMATION_HIGH_RULE = gql`
  mutation UpdateAutomationHighRule($automation: AutomationRuleOnHighValuePartial!, $siteId: ID!) {
    partialUpdateOnHighRule(automation: $automation, siteId: $siteId)
  }
`;
