import { StyleSheet, Text, View, Image } from 'react-native';
import PropTypes from 'prop-types';

//icon
import tensionIcon from '../../assets/icons/icnTensionBBI.png';

//constants
import FONTS from '../../fonts';

//services
import { useTranslation } from '../../hooks/useTranslation';
import BlueBandIndexStatuses from '../../models/entities/blueBandIndexStatuses';

const BlueBandIndexStack = ({ blueBandIndexStatuses, hasData, notNullStatusCount }) => {
  const { t } = useTranslation();
  const { dry, good, wet } = blueBandIndexStatuses.statuses(t);

  const centerStyle = () => {
    const style = notNullStatusCount > 1 ? 'space-between' : 'center';
    return { justifyContent: style };
  };

  if (!hasData) {
    return (
      <View style={[styles.legendContainer, centerStyle()]}>
        <Image source={tensionIcon} style={styles.tensionIcon} />
      </View>
    );
  }

  const generateLegendComponent = (status, component, nullComponent) => {
    if (!status.value || status.value === 0) {
      return notNullStatusCount > 1 ? nullComponent : null;
    }
    return component;
  };

  const createLegendText = (status) => {
    return <Text style={[styles.legendText, { color: status.color }]}>{t(`block_detail_performance_${status.type}`)}</Text>;
  };

  return (
    <View style={[styles.legendContainer, centerStyle()]}>
      {generateLegendComponent(dry, createLegendText(dry), <Text />)}
      {generateLegendComponent(good, <Image source={tensionIcon} style={styles.tensionIcon} />, <Image />)}
      {generateLegendComponent(wet, createLegendText(wet), <Text />)}
    </View>
  );
};

BlueBandIndexStack.propTypes = {
  blueBandIndexStatuses: PropTypes.instanceOf(BlueBandIndexStatuses).isRequired,
  hasData: PropTypes.bool.isRequired,
  notNullStatusCount: PropTypes.number.isRequired,
};

const styles = StyleSheet.create({
  legendContainer: {
    height: '100%',
    paddingVertical: 10,
    paddingRight: 5,
    paddingLeft: 20,
  },
  legendText: {
    textAlign: 'right',
    fontFamily: FONTS.notoSansBold,
    fontSize: 12,
    letterSpacing: 0,
  },
  tensionIcon: {
    alignSelf: 'flex-end',
    width: 28,
    height: 40,
  },
});

export default BlueBandIndexStack;
