const TENSION = 'TENSION';
const WEATHER_STATION = 'WEATHER_STATION';
const IRRIGATION = 'IRRIGATION';
const TEMPERATURE = 'TEMPERATURE';
const FLOW_STATION = 'FLOW_STATION';

const CAPABILITIES = {
  TENSION,
  WEATHER_STATION,
  IRRIGATION,
  TEMPERATURE,
  FLOW_STATION,
};

export { TENSION, WEATHER_STATION, IRRIGATION, TEMPERATURE, FLOW_STATION, CAPABILITIES };
