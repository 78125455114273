import PropTypes from 'prop-types';
import { StyleSheet, View, Text } from 'react-native';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

const NutrientsHeader = ({ title }) => {
  return (
    <View style={[styles.container]}>
      <Text style={styles.titleText}>{title}</Text>
    </View>
  );
};

NutrientsHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
  },
  titleText: {
    marginVertical: 10,
    marginLeft: 17,
    textAlign: 'center',
    fontFamily: FONTS.firaSansMedium,
    fontSize: 18,
    color: COLORS.greyishBrown,
  },
});

export default NutrientsHeader;
