import { Image, StyleSheet, Text, Pressable, View } from 'react-native';
import Proptypes from 'prop-types';

// services
import { useTranslation } from '../../hooks/useTranslation';

// icons
import icnCheck from '../../assets/icons/icnCheck.png';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

// styles
import { globalStyles } from '../../styles';

// component
import ShadowedDivider from '../ShadowedDivider';

const AnytimeEventForm = ({ isAnytimeEvent, onSelectAnytime }) => {
  const { t } = useTranslation();

  const selectButtonStyle = isAnytimeEvent ? styles.selectAnytime : styles.unselectAnytime;

  return (
    <>
      <Pressable
        onPress={onSelectAnytime}
        style={/* istanbul ignore next */ ({ pressed }) => [pressed ? globalStyles.touchOpacity : {}, styles.container]}
        testID={'schedule__add-event-anytime-checkbox'}>
        <Text style={styles.legendText}>{t('edit_event_any_time_checkbox_title')}</Text>
        <View style={[styles.anytime, selectButtonStyle]}>
          {isAnytimeEvent ? (
            <Image source={icnCheck} style={styles.selectIcon} testID="schedule__add-event-anytime-checkbox--checked" />
          ) : null}
        </View>
      </Pressable>

      <ShadowedDivider style={styles.divider} />
    </>
  );
};

AnytimeEventForm.propTypes = {
  isAnytimeEvent: Proptypes.bool,
  onSelectAnytime: Proptypes.func.isRequired,
};

const styles = StyleSheet.create({
  anytime: {
    width: 24,
    height: 24,
    borderWidth: 1.5,
    borderRadius: 3,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 44,
    paddingRight: 10,
    paddingLeft: 16,
  },
  divider: {
    marginLeft: 16,
  },
  legendText: {
    fontFamily: FONTS.firaSans,
    fontSize: 17,
    color: COLORS.greyishBrown,
  },
  selectAnytime: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: COLORS.seafoamBlue,
    backgroundColor: COLORS.lightSeafoamBlue,
  },
  unselectAnytime: {
    borderColor: COLORS.lightPeach,
  },
  selectIcon: {
    width: 10,
    height: 10,
  },
});

export default AnytimeEventForm;
