import PropTypes from 'prop-types';
import { View } from 'react-native';

// shape
import { styleShape } from '../../shapes/style';

const DataStreamButtonPlaceholder = ({ numberOfItems = 1, style }) => {
  const renderEmptyViews = () => {
    const emptyViews = [];
    for (let i = 0; i < numberOfItems; i++) {
      emptyViews.push(<View key={'button' + i} style={style} />);
    }
    return emptyViews;
  };

  return renderEmptyViews();
};

DataStreamButtonPlaceholder.propTypes = {
  numberOfItems: PropTypes.number,
  style: styleShape,
};

export default DataStreamButtonPlaceholder;
