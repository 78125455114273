import { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useFocusEffect } from '@react-navigation/native';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

// propType
import { navigationShape } from '../../shapes/navigation';
import BlockEntity from '../../models/entities/blockEntity';

// services
import { SORT_ORDER, toggleSort } from '../../utils/sorting';
import ANALYTICS from '../../services/AnalyticsEvents';

// hooks
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';
import { useBackHandler } from '../../hooks/useBackHandler';

// styles
import { globalStyles } from '../../styles';

// constants
import ROUTES from '../../navigation/routes';
import * as CAPABILITIES from '../../models/capability';
import * as TABS from '../blockDetails/BlockDetailsTabs';

// components
import SortableList from '../../components/SortableList';
import HeaderBack from '../../components/header/HeaderBack';
import Divider from '../../components/Divider';
import BlockAliasPresenter from '../../presenters/blockAlias/BlockAliasPresenter';
import BlueBandIndexListSkeleton from '../../components/blueBandIndex/BlueBandIndexListSkeleton';
import NoDataView from '../../components/NoDataView';
import BlockBlueBandIndexItem from './BlockBlueBandIndexItem';
import SortableBlockBlueBandIndexHeader from './SortableBlockBlueBandIndexHeader';

import { SORT_COLUMNS } from './sorting';

const NUMBER_OF_DAY_IN_THE_PAST = 6;
const QUERY_DATE_FORMAT = 'YYYY-MM-DD';

const BlockBlueBandIndexScreen = ({ navigation, route }) => {
  const { block } = route.params || {};
  const analyticsService = useAnalyticsContext();
  const loading = useSelector((state) => !!state.loading.effects.blueBandIndex.loadBlockBlueBandIndexes);
  const sortLoading = useSelector((state) => !!state.loading.effects.blueBandIndex.sortBlockBlueBandIndexes);
  const [refreshing, setRefreshing] = useState(false);
  const blockBlueBandIndexes = useSelector((state) => state.blueBandIndex.sortedBlockBlueBandIndexes);
  const listRef = useRef(undefined);
  const initialSortOrderState = {
    date: SORT_ORDER.NONE,
    dry: SORT_ORDER.NONE,
    good: SORT_ORDER.NONE,
    wet: SORT_ORDER.NONE,
  };
  const [sortedColumn, setSortedColumn] = useState(initialSortOrderState);
  const toggle = toggleSort(sortedColumn, setSortedColumn, initialSortOrderState);

  const blockAliasColor = new BlockAliasPresenter(block).getBlockHeaderColor();
  const dispatch = useDispatch();

  const getDateRange = () => {
    return {
      endDate: moment.utc().format(QUERY_DATE_FORMAT),
      startDate: moment.utc().subtract(NUMBER_OF_DAY_IN_THE_PAST, 'days').format(QUERY_DATE_FORMAT),
    };
  };

  /* istanbul ignore next */
  useFocusEffect(
    useCallback(() => {
      setSortedColumn(initialSortOrderState);
      dispatch.blueBandIndex.loadBlockBlueBandIndexes(getDateRange());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  );

  useEffect(() => {
    sortBlockBlueBandIndexesFromSortedColumn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedColumn]);

  const goBack = useCallback(() => {
    analyticsService.trackNavigationEvent(ANALYTICS.eventViewBlockDetail);
    navigation.navigate(ROUTES.BLOCK_DETAILS, {
      activeTab: TABS.DETAILS,
      block,
      capability: CAPABILITIES.TENSION,
      defaultSelectedStreamIds: block
        .tensionCapability()
        .tensionReadings()
        .map((reading) => reading.id),
      displayTabs: true,
      routeToGoBackTo: ROUTES.IRRIGATION,
    });

    return true;
  }, [analyticsService, block, navigation]);

  useBackHandler(goBack);

  const sortBlockBlueBandIndexesFromSortedColumn = () => {
    if (sortedColumn.date !== SORT_ORDER.NONE) {
      dispatch.blueBandIndex.sortBlockBlueBandIndexes({ column: SORT_COLUMNS.DATE, order: sortedColumn.date });
    } else if (sortedColumn.dry !== SORT_ORDER.NONE) {
      dispatch.blueBandIndex.sortBlockBlueBandIndexes({ column: SORT_COLUMNS.DRY_BLUE_BAND_INDEX, order: sortedColumn.dry });
    } else if (sortedColumn.good !== SORT_ORDER.NONE) {
      dispatch.blueBandIndex.sortBlockBlueBandIndexes({ column: SORT_COLUMNS.GOOD_BLUE_BAND_INDEX, order: sortedColumn.good });
    } else if (sortedColumn.wet !== SORT_ORDER.NONE) {
      dispatch.blueBandIndex.sortBlockBlueBandIndexes({ column: SORT_COLUMNS.WET_BLUE_BAND_INDEX, order: sortedColumn.wet });
    }
  };

  const loadAndSortBlockBlueBandIndexes = async () => {
    await dispatch.blueBandIndex.loadBlockBlueBandIndexes(getDateRange());
    sortBlockBlueBandIndexesFromSortedColumn();
  };

  const refreshBlockBlueBandIndexes = async () => {
    setRefreshing(true);
    await loadAndSortBlockBlueBandIndexes();
    setRefreshing(false);
  };

  const renderBlockBlueBandIndexItem = (element) => {
    return <BlockBlueBandIndexItem blueBandIndex={element.item} testId={`block-blue-band-index__block-item-${element.index}`} />;
  };

  const hasDataForTimeRange = () => {
    if (blockBlueBandIndexes.length > 0) {
      return blockBlueBandIndexes.some((blockBlueBandIndex) => {
        return (
          blockBlueBandIndex.value.dry() !== null ||
          blockBlueBandIndex.value.good() !== null ||
          blockBlueBandIndex.value.wet() !== null
        );
      });
    }
    return false;
  };

  return (
    <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
      <View style={globalStyles.header}>
        <HeaderBack
          screenName="site-blue-band-index"
          navigation={navigation}
          goBack={goBack}
          title={block?.alias}
          subtitle={block?.name}
          titleColor={blockAliasColor}
          testID="block-details__screen-title"
        />
      </View>

      <Divider />

      <View testID="block-blue-band-index__subscreen-container" style={globalStyles.bottomContainer}>
        <SortableBlockBlueBandIndexHeader sortedColumn={sortedColumn} toggle={toggle} loading={sortLoading} />

        {hasDataForTimeRange() || loading ? (
          <SortableList
            testID={'block-blue-band-index__subscreen_list'}
            ref={listRef}
            elements={blockBlueBandIndexes}
            loading={loading}
            refreshing={refreshing}
            sort={dispatch.blueBandIndex.sortBlockBlueBandIndexes}
            renderEmptyView={<BlueBandIndexListSkeleton />}
            renderItem={renderBlockBlueBandIndexItem}
            screenName={'BlockBlueBandIndex'}
            onRefresh={refreshBlockBlueBandIndexes}
          />
        ) : (
          <NoDataView />
        )}
      </View>
    </SafeAreaView>
  );
};

BlockBlueBandIndexScreen.propTypes = {
  navigation: navigationShape.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      block: PropTypes.instanceOf(BlockEntity).isRequired,
    }),
  }).isRequired,
};

export default BlockBlueBandIndexScreen;
