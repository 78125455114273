export const KM_H = {
  name: 'KM_H',
  displayValue: 'km/h',
  translationReference: 'unit_km_h',
};

export const MI_H = {
  name: 'MI_H',
  displayValue: 'mph',
  translationReference: 'unit_mi_h',
};

export const WindSpeedUnits = {
  KM_H,
  MI_H,
};
