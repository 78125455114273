import { memo } from 'react';
import PropTypes from 'prop-types';
import { Image, StyleSheet, Text, View } from 'react-native';

// constants
import COLORS from '../../colors';
import FONTS from '../../fonts';

// services
import { useTranslation } from '../../hooks/useTranslation';
import DeviceEntity from '../../models/entities/deviceEntity';

// components
import LastUpdate from '../block/LastUpdate';

const DeviceDetailsContentHeader = ({ device, testId }) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container} testID={testId}>
      <View style={styles.leftSection}>
        <Image source={device.statusIcon()} style={styles.statusImage} />
        <Text style={styles.status} ellipsizeMode="tail" numberOfLines={1} testID={'devices__deviceDetailsContentHeader-status'}>
          {device.statusText(t)}
        </Text>
      </View>

      <View style={styles.serialSection}>
        <Text
          style={styles.serialText}
          ellipsizeMode="tail"
          numberOfLines={1}
          testID={'devices__deviceDetailsContentHeader-serial'}>
          {device.serialNumber}
        </Text>
      </View>
      <LastUpdate date={device.lastUpdated} containerStyle={styles.lastUpdate} />
    </View>
  );
};

DeviceDetailsContentHeader.propTypes = {
  device: PropTypes.instanceOf(DeviceEntity).isRequired,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 15,
  },
  lastUpdate: {
    flex: 1,
    justifyContent: 'flex-end',
    paddingRight: 5,
  },
  leftSection: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  serialSection: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  serialText: {
    width: '100%',
    textAlign: 'center',
    fontFamily: FONTS.notoSans,
    fontSize: 11,
    color: COLORS.greyishBrown,
  },
  status: {
    alignSelf: 'center',
    marginLeft: 8,
    fontFamily: FONTS.notoSansBold,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
  statusImage: {
    width: 20,
    height: 20,
  },
});

export default memo(DeviceDetailsContentHeader);
