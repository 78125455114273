import { memo } from 'react';
import { SafeAreaView, StyleSheet, Text, View } from 'react-native';
import { useSharedValue } from 'react-native-reanimated';
import PropTypes from 'prop-types';

// hooks
import { useTranslation } from '../../hooks/useTranslation';

// constants
import FONTS from '../../fonts';
import COLORS from '../../colors';

// entity
import AutomationEntity from '../../models/entities/automationEntity';

// components
import ProgressBarDuration from '../progressBar/ProgressBarDuration';
import Divider from '../Divider';
import AutomationStatusIcon from './AutomationStatusIcon';

const AutomationMapCard = ({ irrigatingDates, sensors, entity }) => {
  const { t } = useTranslation();
  const irrigationAnimatedOpacity = useSharedValue(1);

  const renderAutomationValues = () => {
    return (
      <View style={styles.sensors}>
        {sensors?.map((sensor) => (
          <View style={styles.info} key={sensor.subSensorID}>
            <Text style={styles.unit}>{sensor.unit}</Text>
            <Text style={styles.value}>{sensor.value}</Text>
          </View>
        ))}
      </View>
    );
  };

  return (
    <SafeAreaView style={styles.screenBottom} edges={['right', 'left', 'bottom']}>
      <View style={styles.box} testID={'automation-map-card__component'}>
        <View style={styles.containerTop}>
          {sensors?.length > 0 ? (
            renderAutomationValues()
          ) : (
            <View style={styles.icon}>
              <AutomationStatusIcon entity={entity} animatedOpacity={irrigationAnimatedOpacity} big={true} />
            </View>
          )}

          <Text style={styles.text}>{entity.statusText(t)}</Text>
        </View>

        <Divider style={styles.divider} />

        <View style={styles.containerBottom}>
          <View style={styles.subContainer}>
            <View style={styles.center}>
              <Text style={styles.labelDuration}>{t('automation_elapsed_remaining')}</Text>
            </View>

            <ProgressBarDuration startDate={irrigatingDates?.startDate} endDate={irrigatingDates?.endDate} />
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};

AutomationMapCard.propTypes = {
  irrigatingDates: PropTypes.object,
  sensors: PropTypes.arrayOf(PropTypes.any),
  entity: PropTypes.instanceOf(AutomationEntity).isRequired,
};

const styles = StyleSheet.create({
  box: {
    height: '100%',
  },
  center: {
    alignItems: 'center',
  },
  containerBottom: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '40%',
  },
  containerTop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60%',
  },
  divider: {
    height: 1,
    backgroundColor: COLORS.brownGrey,
  },
  icon: {
    marginBottom: 10,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: 90,
    height: 55,
    borderRadius: 4,
    marginHorizontal: 15,
    marginBottom: 10,
    backgroundColor: COLORS.greyish20,
  },
  labelDuration: {
    fontFamily: FONTS.firaSans,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
  screenBottom: {
    flex: 1,
  },
  sensors: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  subContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '80%',
  },
  text: {
    fontFamily: FONTS.notoSans,
    fontSize: 12,
    color: COLORS.greyishBrown,
  },
  unit: {
    fontFamily: FONTS.firaSansLight,
    fontSize: 10,
    color: COLORS.greyishBrown,
  },
  value: {
    fontFamily: FONTS.firaSansBold,
    fontSize: 16,
    color: COLORS.greyishBrown,
  },
});

export default memo(AutomationMapCard);
