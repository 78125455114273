import { consoleWarn } from '../utils/loggerUtil';

const executeApolloQuery = async (dispatch, call, successCallback) => {
  const tokenExpired = await dispatch.authentication.isTokenExpired();
  if (tokenExpired) {
    const refreshToken = await dispatch.authentication.refreshToken();
    if (refreshToken && refreshToken.error) {
      await dispatch.error.setRefreshTokenError({
        message: refreshToken.error,
      });
      return;
    }
  }

  try {
    const apolloData = await call();

    if (apolloData.error) {
      await dispatch.error.setApolloError({
        message: apolloData.error,
        retryCall: call,
        successCallback,
      });
    } else {
      await successCallback(apolloData);
    }
  } catch (e) {
    if (e.networkError?.statusCode === 401) {
      await dispatch.error.setRefreshTokenError({
        message: 'unauthorized',
      });
      return;
    }

    consoleWarn(`Error within executeApolloQuery ${e}`);
    await dispatch.error.setApolloError({
      message: undefined,
      retryCall: call,
      successCallback,
    });
  }
};

export { executeApolloQuery };
