import COLORS from '../../colors';
import { BACKGROUND_COLOR_ALPHA_VALUE } from '../../styles';
import { BlockPresenter } from '../BlockPresenter';

export default class NutrientsBlockAliasPresenter extends BlockPresenter {
  constructor(block, capability) {
    super(block, capability);
  }

  getOverlayIcon() {}

  getBlockHeaderColor() {
    const singleNitrateStatus = this.block?.getSingleNutrientStatus();
    return singleNitrateStatus ? singleNitrateStatus : COLORS.darkBlue;
  }

  getBackgroundColor() {
    const statusColor = this.getBlockHeaderColor();
    return statusColor + BACKGROUND_COLOR_ALPHA_VALUE;
  }

  alias() {
    return this.block.alias;
  }

  lastUpdated() {
    return this.block.lastUpdated;
  }
}
