import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';

const RoundedUnderline = ({ color }) => {
  const backgroundColor = {
    backgroundColor: color,
  };
  return <View style={[styles.main, backgroundColor]} />;
};

RoundedUnderline.propTypes = {
  color: PropTypes.string,
};

const styles = StyleSheet.create({
  main: {
    width: 20,
    height: 3,
    borderRadius: 3,
  },
});

export default RoundedUnderline;
