import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';

// services
import COLORS from '../../colors';

// constants
import { ScheduleConstants } from '../../screens/schedule/ScheduleConstants';

// components
import Block from './Block';

const BlockContainer = ({ block, expanded, index, paddingLeft }) => {
  const testIDExpand = expanded ? 'expanded-' : '';
  const testIDType = block.isVirtual ? 'virtualBlock' : 'block';
  const testId = `schedule__${testIDExpand}${testIDType}-item-${index}`;

  return (
    <View style={[styles.remainingBlockContainer, { paddingLeft }]}>
      <Block expanded={expanded} block={block} testId={testId} />
    </View>
  );
};

BlockContainer.propTypes = {
  block: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  paddingLeft: PropTypes.number,
};

const styles = StyleSheet.create({
  remainingBlockContainer: {
    height: ScheduleConstants.CELL_HEIGHT,
    borderColor: ScheduleConstants.BORDER_COLOR,
    backgroundColor: COLORS.whiteTwo,
    borderBottomWidth: 1,
  },
});

export default BlockContainer;
