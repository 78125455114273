import { gql } from '@apollo/client';

export const MAP_ITEMS = gql`
  query MapItems($siteId: ID!, $includeAutomations: Boolean!) {
    viewer {
      site(id: $siteId) {
        id
        mapItems {
          type
          features {
            id
            type
            geometry {
              type
              coordinates
            }
            properties {
              name
              itemType
              status
              value
              reference {
                id
                type
              }
            }
          }
        }
        automations @include(if: $includeAutomations) {
          id
          name
          alias
          ongoing
          status {
            automationId
            communicationError
            disabled
            synchronizing
            inError
            lastHeartbeat
            error {
              kind
              component
              resourceId
              errorCodes
            }
          }
          currentExecution {
            id
            automationId
            startDate
            endDate
            plannedDuration
          }
          subSensorsReading {
            id
            type
            value
            unit
          }
        }
      }
    }
  }
`;
