import PropTypes from 'prop-types';

// services
import { isIos } from '../../services/PlatformService';

// icons
import icnAdd from '../../assets/icons/icnAddEvent.png';

// components
import TouchIconButton from './TouchIconButton';

const IOSAddButton = ({ onPress, disabled = false, testId }) => {
  if (!isIos()) {
    return null;
  }
  return <TouchIconButton icon={icnAdd} onPress={onPress} testId={testId} disabled={disabled} />;
};

IOSAddButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
};

export default IOSAddButton;
