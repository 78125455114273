import { useCallback, useEffect, useState } from 'react';
import { Keyboard } from 'react-native';
import { FlashList } from '@shopify/flash-list';
import { SafeAreaView } from 'react-native-safe-area-context';
import { DrawerActions } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// styles
import { globalStyles } from '../../styles';

// constants
import { navigationShape } from '../../shapes/navigation';
import ROUTES from '../../navigation/routes';

// services
import ANALYTICS from '../../services/AnalyticsEvents';
import { useAnalyticsContext } from '../../components/initialization/AnalyticsProvider';

import Site from './Site';
import SiteSearchBar from './SiteSearchBar';

const SiteSelectorScreen = ({ navigation, route }) => {
  const analyticsService = useAnalyticsContext();
  const { routeToGoBackTo } = route.params || {};
  const currentSite = useSelector((state) => state.site.currentSite);
  const [otherSites, setOtherSites] = useState([]);
  const [siteFilter, setSiteFilter] = useState('');
  const sites = useSelector((state) => state.site.sites);
  const dispatch = useDispatch();

  const handleChangeSite = useCallback(
    async (selectedSiteId) => {
      await dispatch.preferences.writeSelectedSite({ selectedSiteId });
      await dispatch.site.setCurrentSite(selectedSiteId);
      await dispatch.map.reset();
      dispatch.map.loadMapBlocksForCurrentSite();
      setSiteFilter('');
      analyticsService.trackNavigationEvent(ANALYTICS.eventViewMap);
      navigation.navigate(ROUTES.MAP);
      Keyboard.dismiss();
    },
    [analyticsService, dispatch.preferences, dispatch.site, dispatch.map, navigation],
  );

  const onSearchFilterChanged = useCallback((newFilter) => {
    setSiteFilter(newFilter.toUpperCase());
  }, []);

  const cancelSiteSelection = () => {
    if (routeToGoBackTo) {
      navigation.navigate(routeToGoBackTo);
    }
    navigation.dispatch(DrawerActions.openDrawer());
  };

  const isCurrentSite = useCallback((site) => currentSite !== undefined && site.id === currentSite.id, [currentSite]);

  useEffect(() => {
    setOtherSites(sites?.filter((site) => !isCurrentSite(site)) || []);
  }, [sites, isCurrentSite]);

  useEffect(() => {
    setOtherSites(
      sites?.filter((site) => {
        return !isCurrentSite(site) && (siteFilter === '' || site.name.toUpperCase().indexOf(siteFilter) > -1);
      }) || [],
    );
  }, [sites, currentSite, setOtherSites, siteFilter, isCurrentSite]);

  const renderItem = useCallback(
    ({ item, index }) => <Site item={item} index={index} onItemClick={handleChangeSite} />,
    [handleChangeSite],
  );
  const keyExtractor = useCallback((item) => item.id, []);

  return (
    <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
      <FlashList
        keyboardShouldPersistTaps={'always'}
        keyboardDismissMode={'on-drag'}
        data={otherSites}
        contentContainerStyle={globalStyles.scrollContainer}
        scrollIndicatorInsets={{ right: 1 }}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ListHeaderComponent={
          <SiteSearchBar
            currentSite={currentSite.name}
            onSearchFilterChanged={onSearchFilterChanged}
            onCancelClick={cancelSiteSelection}
            testId={'siteSelector__searchbar'}
          />
        }
        estimatedItemSize={49}
      />
    </SafeAreaView>
  );
};

SiteSelectorScreen.propTypes = {
  navigation: navigationShape.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      routeToGoBackTo: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default SiteSelectorScreen;
