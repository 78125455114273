import { gql } from '@apollo/client';

export const MANAGE_CREATE_NOTE = gql`
  mutation CreateNote($blockId: ID, $content: String!, $startDate: String!, $endDate: String!, $siteId: ID!) {
    createNote(blockId: $blockId, content: $content, siteId: $siteId, endDate: $endDate, startDate: $startDate) {
      id
      content
      createdAt
      createdBy
    }
  }
`;

export const MANAGE_UPDATE_NOTE = gql`
  mutation UpdateNote($content: String!, $noteId: ID!) {
    updateNote(content: $content, noteId: $noteId) {
      id
      content
      createdAt
      createdBy
    }
  }
`;

export const MANAGE_DELETE_NOTE = gql`
  mutation deleteNote($noteId: ID!) {
    deleteNote(noteId: $noteId)
  }
`;
