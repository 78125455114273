import { SORT_ORDER } from '../../utils/sorting';

const SORT_COLUMNS = {
  LEVEL: 'level',
  NAME: 'name',
  STATUS: 'status',
};

const sortAlerts = (alerts, column, order) => {
  const compareByName = (a, b) => {
    const nameA = a.name ? a.name.toLowerCase() : '';
    const nameB = b.name ? b.name.toLowerCase() : '';

    const comparison = nameA.localeCompare(nameB);

    return order === SORT_ORDER.ASC ? comparison : -comparison;
  };


  const compareByLevel = (a, b) => {
    const priority = {
      'HIGH': 2,
      'LOW': 1,
      'default': 0,
    };

    const levelA = priority[a.criticality] || priority.default;
    const levelB = priority[b.criticality] || priority.default;

    return order === SORT_ORDER.ASC ? levelA - levelB : levelB - levelA;
  };

  const compareByStatus = (a, b) => {
    const priority = {
      'inAlarm': 4,
      'acknowledged': 3,
      'enabled': 2,
      'disabled': 1,
      'default': 0,
    };

    const statusA = priority[a.status] || priority.default;
    const statusB = priority[b.status] || priority.default;

    return order === SORT_ORDER.ASC ? statusA - statusB : statusB - statusA;
  };

  switch (column) {
    case SORT_COLUMNS.LEVEL: {
      alerts.sort(compareByLevel);
      break;
    }
    case SORT_COLUMNS.NAME: {
      alerts.sort(compareByName);
      break;
    }
    case SORT_COLUMNS.STATUS: {
      alerts.sort(compareByStatus);
      break;
    }
  }
};

export { sortAlerts, SORT_COLUMNS };
